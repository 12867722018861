import { ContractOperationProductManage } from "src/api/contract/contract-types";
import { AcGroupContractManageModel } from "src/api/access/ac-group-types";
import { BaseButton, BaseRadio, BaseTooltip } from "src/components";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import { useRecoilValue } from "recoil";
import { globalPartnerState } from "src/recoil/partners/atom";
import { PaPartner } from "src/types/partner";
import Link from "src/components/PartnerLink";
import { PagePath } from "src/pages/product/details";

type Props = {
  productManage: ContractOperationProductManage;
  contractManage: AcGroupContractManageModel | undefined;
  isAuthority?: boolean;
  onChangeVisitorInvite: (data: ContractOperationProductManage) => void;
  onChangeVisitorApprove: (visitorDefaultAccessGroupYn: boolean) => void;
  visitorUrl: string;
};

const VisitorAccessSection = ({ productManage, contractManage, onChangeVisitorInvite, onChangeVisitorApprove, isAuthority, visitorUrl }: Props) => {
  const { fetchAuthorityReadonly } = usePartnerAuthority();
  const partner = useRecoilValue(globalPartnerState);

  const isVisitorAcSelect = //방문자 출입 권한 선택 기능 여부 > false 일 경우 비활성화
    partner?.features?.some((feature: PaPartner) => feature.name === "CTRLROOM_VISITOR_ACCESS_GROUP_SELECT" && feature.active) || false;

  return (
    <section className="contents-container__1200">
      <BaseSectionTitle title={"방문자 관리"} />
      <div className="contents-contract-user px20 py30">
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>방문자 초대 기능</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="flex-row flex-center-start">
              <BaseRadio
                id={`visitorInvite-1`}
                className="mr10"
                name="visitorInvite"
                label={"사용"}
                value={"사용"}
                checked={productManage.isUseVisitorInvite || false}
                onChange={(_: boolean) => {
                  if (isAuthority) {
                    const calendarType = productManage.calendarType !== "" ? productManage.calendarType : "NONE";

                    const newProductManage = {
                      ...productManage,
                      isUseVisitorInvite: true,
                      calendarType,
                    };
                    onChangeVisitorInvite(newProductManage);
                  } else {
                    fetchAuthorityReadonly(true);
                  }
                }}
              />
              <BaseRadio
                id={`visitorInvite-2`}
                className="mr10"
                name="visitorInvite"
                label={"사용안함"}
                value={"사용안함"}
                checked={!productManage.isUseVisitorInvite || false}
                onChange={(_: boolean) => {
                  if (isAuthority) {
                    const calendarType = productManage.calendarType !== "" ? productManage.calendarType : "NONE";

                    const newProductManage = {
                      ...productManage,
                      isUseVisitorInvite: false,
                      calendarType,
                    };
                    onChangeVisitorInvite(newProductManage);
                  } else {
                    fetchAuthorityReadonly(true);
                  }
                }}
              />
            </div>
          </div>
        </section>

        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>방문자 출입 권한</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="flex-row flex-center-start">
              <div className="flex-row flex-center-start mr10">
                <BaseRadio
                  id={`visitorApproval-1`}
                  name="visitorApproval"
                  label={"기본 권한"}
                  value={"기본 권한"}
                  checked={contractManage?.visitorDefaultAccessGroupYn || false}
                  onChange={(_: boolean) => {
                    if (isAuthority) {
                      onChangeVisitorApprove(true);
                    } else {
                      fetchAuthorityReadonly(true);
                    }
                  }}
                  disabled={!isVisitorAcSelect}
                />
                <BaseTooltip contents="방문자의 출입권한은 계약을 따릅니다." touchIcon="QUESTION" />
              </div>

              <div className="flex-row flex-center-start">
                <BaseRadio
                  id={`visitorApproval-2`}
                  name="visitorApproval"
                  label={"커스텀 권한"}
                  value={"커스텀 권한"}
                  onChange={(_: boolean) => {
                    if (isAuthority) {
                      onChangeVisitorApprove(false);
                    } else {
                      fetchAuthorityReadonly(true);
                    }
                  }}
                  checked={!(contractManage?.visitorDefaultAccessGroupYn || false)}
                  disabled={!isVisitorAcSelect}
                />
                <BaseTooltip contents="방문자를 초대할 때마다 출입권한을 다르게 설정할 수 있습니다." className="mr10" touchIcon="QUESTION" />
              </div>
            </div>
          </div>
        </section>

        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>방문자 초대 설정</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="">
              <Link to={visitorUrl} target="_blank">
                <BaseButton title="방문자 초대" className="color-white icon-invite" />
              </Link>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};

export default VisitorAccessSection;
