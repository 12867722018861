import { useQuery } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import { getProductListAsync } from "src/api/product/product-api";
import { ProductListParams, ProductStatus } from "src/api/product/product-types";
import { BaseButton, BaseInputWithSearch, BaseSelect, BaseTable } from "src/components";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import useNavigate from "src/hooks/usePartnerNavigate";
import { useQueryParams } from "src/hooks/useQueryParams";
import PagePath from "src/pagePath.json";
import { axiosInstance } from "src/utils";
import { ProductColumns } from "./columns";
import { isProductsUsedOptions, productTypeOptions, searchTypeOptions, statusOptions } from "./product-types";
import { Order } from "src/api/public-types";

/* 
  공간상품 > 목록 화면
 */

const ProductList = () => {
  const navigate = useNavigate();
  const { isAuthority } = usePartnerAuthority();

  // URL 쿼리 파라미터 관리
  const { queryParams, queryParamsNavigate, location } = useQueryParams<ProductListParams>({
    page: 0,
    size: 20,
    sort: { orders: [{ property: "id", direction: "DESC" }] },
  });

  // 검색어 입력 상태 관리
  const [searchParams, setSearchParams] = useState({
    keyword: queryParams.keyword || "",
    searchType: queryParams.searchType || "",
  });

  // URL 파라미터가 변경될 때 검색 상태 동기화
  useEffect(() => {
    setSearchParams({
      keyword: queryParams.keyword || "",
      searchType: queryParams.searchType || "",
    });
  }, [queryParams.keyword, queryParams.searchType]);

  // 상품 목록 조회
  const { data: productListRes } = useQuery({
    queryKey: ["getProductListAsync", queryParams, location],
    queryFn: () => getProductListAsync(axiosInstance, queryParams).then((res) => res.data),
  });

  // 검색 필터 변경 시 URL 업데이트
  const updateSearchFilter = (updates: Partial<ProductListParams>) => {
    queryParamsNavigate({ ...queryParams, ...updates, page: 0 });
  };

  return (
    <div className="page-product-list">
      <div className="contents-container__table">
        <div className="contents-container__search-wrap">
          <div className="left-area">
            <section>
              <div className="left-area__index">
                <span>조건검색</span>
              </div>
              <div className="left-area__contents">
                <div className="minmax120 mr8">
                  <BaseSelect
                    value={queryParams.isUsed || ""}
                    stateOptions={isProductsUsedOptions}
                    setStateValue={(value: string) => updateSearchFilter({ isUsed: value })}
                    placeholder="이용여부"
                  />
                </div>
                <div className="minmax120 mr8">
                  <BaseSelect
                    value={queryParams.status || ""}
                    stateOptions={statusOptions}
                    setStateValue={(value: ProductStatus) => updateSearchFilter({ status: value })}
                    placeholder="공개상태"
                  />
                </div>
                <div className="minmax170 mr8">
                  <BaseSelect
                    value={queryParams.productType || ""}
                    stateOptions={productTypeOptions}
                    setStateValue={(value: string) => updateSearchFilter({ productType: value })}
                    placeholder="상품 유형"
                  />
                </div>
                <BaseInputWithSearch
                  type="text"
                  selectValue={searchParams.searchType}
                  inputValue={searchParams.keyword}
                  stateOptions={searchTypeOptions}
                  setStateValue={(searchType: string) => setSearchParams((prev) => ({ ...prev, searchType }))}
                  onChange={(keyword: string) => setSearchParams((prev) => ({ ...prev, keyword }))}
                  onClearClick={() => {
                    updateSearchFilter({ keyword: "", searchType: "" });
                  }}
                  onKeyUp={() => updateSearchFilter(searchParams)}
                  onSearchClick={() => updateSearchFilter(searchParams)}
                />
              </div>
            </section>
          </div>
          {isAuthority("w") && (
            <div className="right-area">
              <BaseButton title="+ 상품 등록" onClick={() => navigate(PagePath.product.form)} />
            </div>
          )}
        </div>
        {productListRes?.data?.content && (
          <BaseTable
            data={productListRes.data.content}
            columns={ProductColumns}
            currentSize={Number(queryParams.size) || 20}
            sizeOption={(size) => updateSearchFilter({ size })}
            pageIndex={Number(queryParams?.page || 0)}
            totalPages={productListRes.meta.pageMeta?.totalPages || 0}
            totalElements={productListRes.meta.pageMeta?.totalElements}
            goPage={(page: number) => queryParamsNavigate({ ...queryParams, page })}
            orders={queryParams.sort?.orders}
            setOrders={(orders: Order[]) => orders && updateSearchFilter({ sort: { orders } })}
            disabledSortHeaders={["rentalCost"]}
          />
        )}
      </div>
    </div>
  );
};

export default ProductList;
