import React, { useEffect, useRef, useState } from "react";
import { useBlockLayout, useTable } from "react-table";
import { useSticky } from "react-table-sticky";
import ScrollShadowProvider from "src/components/ScrollShadowProvider";
import useScrollShadow from "src/hooks/useScrollShadow";
interface Props {
  data?: Array<any>;
  columns: Array<any>;
  className?: string;
  [key: string]: any;
}

const SpaceBaseTable = ({ data, columns, className, ...props }: Props) => {
  const tableRef = useRef<HTMLDivElement>(null);

  useScrollShadow(tableRef);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns: columns,
      data: data ?? [],
      ...props,
    },
    useBlockLayout,
    useSticky,
  );
  return (
    <ScrollShadowProvider tableRef={tableRef} className="position-relative z-index-1">
      {/* 추가된 공간 테이블 */}
      <div ref={tableRef} {...getTableProps()} className={`base-table sticky ${className ? className : ""}`}>
        <div className="header">
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
              {headerGroup.headers.map((header) => {
                return (
                  <div {...header.getHeaderProps()} className="base-table__th">
                    {header.render("Header")}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        <div {...getTableBodyProps()} className="body">
          {rows.map((row) => {
            prepareRow(row);
            return (
              <div {...row.getRowProps()} className={`base-table__tr`}>
                {row.cells.map((cell) => {
                  return (
                    <div {...cell.getCellProps()} className="base-table__td product_table_td">
                      {cell.render("Cell")}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        {rows.length === 0 && (
          <div className="base-table__tr table-cursor-unset">
            <div className="base-table__td w-100 text-center">
              <div className="w-100">
                <span>데이터가 없습니다.</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </ScrollShadowProvider>
  );
};

export default SpaceBaseTable;
