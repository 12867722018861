//@ts-nocheck
/* eslint-disable array-callback-return */
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { getContractProductSnapshotDetailPopup } from "src/api/contract/contract-api";
import { useApiOperation } from "src/api/hooks";
import { providerDetailAsync } from "src/api/provider/provider-api";
import { BaseButton } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import { numberToStringWithComma } from "src/utils/common-util";
import TableHeader from "./components/TableHeader";
import TableTextCell from "./components/TableTextCell";
import useContractApi from "../../hooks/useContractApi";
import useEstimateApi from "../../hooks/useEstimateApi";
import useProductFacilityApi from "../../hooks/useProductFacilityApi";
type Props = {
  contractId: number;
  isOpen: boolean;
  detailData: any;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
};
const arrBasicLabels: any = [
  { label: "프로바이더명", domain: "product", attrName: "providerName", attrValue: "" },
  { label: "상품유형", domain: "product", attrName: "productType", attrValue: "" },
  { label: "상품 ID", domain: "domainId", attrName: "", attrValue: "" },
  { label: "상품명", domain: "product", attrName: "productName", attrValue: "" },
  { label: "건물명", domain: "building", attrName: "buildingName", attrValue: "" },
  { label: "층/호실", domain: "buildingFloor", attrName: "buildingRoom", attrValue: "" },
];
const arrPriceLabels: any = [
  { label: "보증금(원)", domain: "product", attrName: "deposit", attrValue: "" },
  { label: "계약금(원)", domain: "product", attrName: "earnest", attrValue: "" },
  { label: "잔금(원)", domain: "product", attrName: "calculate", attrValue: "" },
  { label: "이용료/1개월(VAT 별도)", domain: "product", attrName: "productionPrice", attrValue: "" },
  { label: "공용공간 요금 규정", domain: "product", attrName: "", attrValue: "" },
  { label: "환불 규정", domain: "product", attrName: "", attrValue: "" },
];
const arrFacilityLabels: any = [
  { label: "회의실", domain: "meetingRoom", attrName: "facilityName", attrValue: "" },
  { label: "좌석", domain: "desk", attrName: "facilityName", attrValue: "" },
  { label: "편의시설", domain: "refreshRoom", attrName: "facilityName", attrValue: "" },
];
const arrUsersLabels: any = [
  { label: "사용 인원", domain: "product", attrName: "useNums", attrValue: "" },
  { label: "계정 최대 인원", domain: "product", attrName: "maxNums", attrValue: "" },
];

const SnapShotModal = ({ contractId, detailData, isOpen, onClose }: Props) => {
  // console.log("contractId", contractId);
  const [product, setProduct] = useState<any>([]);
  const { getProductData } = useContractApi();
  const { getEstimateList } = useEstimateApi();
  const { makeSpaceList } = useProductFacilityApi();

  const [estimateData, SetEstimateData] = useState([]);

  const { executeAsync: getSnapshot } = useApiOperation(getContractProductSnapshotDetailPopup);
  const { executeAsync: getProviderDetail } = useApiOperation(providerDetailAsync, { noLoading: true });
  const initDatas = useCallback(async () => {
    if (detailData?.contract?.quotationList) {
      let ids = "";
      detailData?.contract?.quotationList.forEach((list: any) => {
        if (ids !== "") {
          ids = ids + "," + list.quotationId;
        } else {
          ids = list.quotationId;
        }
      });

      let tableData: any = [];
      const result = await getProductData(Number(detailData?.contractManage?.spaceProductId));
      if (result) {
        tableData = await makeSpaceList(result);
      }
      const response = await getEstimateList({ id: ids });
      if (response.data.data.content) {
        response.data.data.content.forEach((item: any) => {
          const find: any = tableData.find((list: any) => list.id === item?.quotationItemList[0].buildingCommonFacilityId);
          if (find) {
            item.buildingCommonFacilityId = item?.quotationItemList[0].buildingCommonFacilityId;
            item.buildingCommonFacilityName = find.facilityName;
          } else {
            item.buildingCommonFacilityId = 0;
            item.buildingCommonFacilityName = "없는 공용공간입니다.";
          }
          item.subRows = item?.quotationItemList;
        });
        console.log("response.data.data.content", response.data.data.content);
        SetEstimateData(response.data.data.content);
      }
    }
  }, [detailData?.contract?.quotationList, detailData?.contractManage?.spaceProductId, getEstimateList, getProductData, makeSpaceList]);
  const callSnapShot = useCallback(
    async (contractId: number) => {
      if (contractId) {
        const response: any = await getSnapshot({ id: contractId });

        if (response.status > 199 && response.status < 300) {
          const findedProvidorId = response.data.data.content.find((arr: any) => arr.domain === "product" && arr.attrName === "providerId");
          const provider = await getProviderDetail({ providerId: findedProvidorId.attrValue });

          if (provider.status > 199 && provider.status < 300) {
            const setProviderName = _.cloneDeep(findedProvidorId);
            setProviderName.attrName = "providerName";
            setProviderName.attrValue = provider.data.data.provider?.providerName;

            response.data.data.content.push(setProviderName);
          }

          let floors = "";
          arrBasicLabels.forEach((arr: any) => {
            if (arr.domain === "domainId") {
              const attr = response.data.data.content.find((arr: any) => arr.domainId);
              arr.attrValue = attr.domainId;
            } else if (arr.domain === "building" && arr.attrName === "buildingName") {
              arr.attrValue = filteredArr(response.data.data.content!, arr.domain, arr.attrName);

              const buildings = response.data.data.content.filter((arr: any) => arr.domain === "building" && arr.attrName === "buildingName");

              buildings.map((bd: any, index: number) => {
                index !== 0 ? (floors = floors + ", " + bd.attrValue + "(") : (floors = floors + bd.attrValue + "(");
                const floorFilter = response.data.data.content.filter(
                  (arr: any) => arr.domain === "buildingFloor" && arr.attrName === "floorName" && arr.nestedId.includes(bd.nestedId),
                );

                floorFilter.map((fl: any, index: number) => {
                  const flrNums: any = response.data.data.content.find((arr2: any) => arr2.nestedId === fl.nestedId);
                  const divide = Number(flrNums.attrValue) > 0 ? " 지상 " : " 지하 ";
                  if (index !== 0) {
                    floors = floors + ", ";
                  }

                  floors = floors + divide + fl.attrValue;
                  const roomFilter = response.data.data.content.filter(
                    (arr: any) => arr.domain === "buildingRoom" && arr.attrName === "roomName" && arr.nestedId.includes(fl.nestedId),
                  );
                  floors = floors + " " + roomFilter.map((rm: any) => rm.attrValue).join(",");
                });
                floors = floors + ")";
              });
            } else if (arr.domain === "buildingFloor" && arr.attrName === "buildingRoom") {
              arrBasicLabels[arrBasicLabels.length - 1].attrValue = floors;
            } else {
              const attr = findedArr(response.data.data.content!, arr.domain, arr.attrName);

              arr.attrValue = attr;
            }
          });

          arrPriceLabels.forEach((arr: any) => {
            if (arr.domain === "product" && arr.attrName === "calculate") {
              const deposit = response.data.data.content.find((arr: any) => arr.domain === "product" && arr.attrName === "deposit");
              const earnest = response.data.data.content.find((arr: any) => arr.domain === "product" && arr.attrName === "earnest");
              if (deposit && earnest) {
                arr.attrValue = numberToStringWithComma(Number(deposit.attrValue) - Number(earnest.attrValue));
              } else {
                arr.attrValue = "0";
              }
            } else {
              const attr = findedArr(response.data.data.content!, arr.domain, arr.attrName);
              if (Number(attr) > 0) {
                arr.attrValue = numberToStringWithComma(Number(attr));
              } else {
                arr.attrValue = attr;
              }
            }
          });

          arrFacilityLabels.forEach((arr: any) => {
            const attr = response.data.data.content.filter((pr: any) => pr.domain === arr.domain && pr.attrName === arr.attrName);

            arr.attrValue = attr.map((finded: any) => finded.attrValue + "(" + finded.domainId + ")").join(", ");
          });

          arrUsersLabels.forEach((arr: any) => {
            const attr = findedArr(response.data.data.content!, arr.domain, arr.attrName);

            arr.attrValue = attr;
          });

          setProduct(response.data.data.content);
        }
      }
    },
    [getProviderDetail, getSnapshot],
  );

  const findedArr = (arrayData: any, domain: string, attrName: string) => {
    let value = "";
    const fineded = arrayData.find((pr: any) => pr.domain === domain && pr.attrName === attrName);
    if (fineded) {
      if (fineded.domain === "product" && fineded.attrName === "productType") {
        switch (fineded.attrValue) {
          case "FULL_COURT":
            value = "공간임대";
            break;
          case "OPEN_COURT":
            value = "공간임대";
            break;
          case "TIME_COURT":
            value = "공간예약";
            break;
          case "MAINTENANCE_FEE":
            value = "관리비";
            break;
          case "NON_RESIDENT_OFFICE":
            value = "비상주";
            break;
          default:
            value = "";
            break;
        }
      } else {
        value = fineded.attrValue;
      }
    }

    return value;
  };

  const filteredArr = (arrayData: any, domain: string, attrName: string) => {
    let value = "";
    const fineded = arrayData.filter((pr: any) => pr.domain === domain && pr.attrName === attrName);
    if (fineded.length > 0) {
      value = fineded.map((find: any) => find.attrValue).join(", ");
    }

    return value;
  };

  useEffect(() => {
    if (isOpen && contractId) {
      callSnapShot(contractId);
    }
    if (detailData.product.productType === "TIME_COURT") {
      initDatas();
    }
  }, [callSnapShot, contractId, detailData.product.productType, initDatas, isOpen]);

  return (
    <BaseAbstractModal size="xlarge" isOpen={isOpen}>
      <>
        <section className="base-abstract-modal__title">
          <div className="flex-row flex-center-between w-100">
            <h1>계약 데이터 상세</h1>
          </div>
        </section>
        <section className="base-abstract-modal__contents px30">
          {/* 기본정보 */}
          <table className="inner-table" width="100%">
            <TableHeader title={"기본정보"} />
            <tbody>
              {arrBasicLabels &&
                arrBasicLabels.map((pr: any, index: number) => {
                  if (detailData?.product?.productType !== "TIME_COURT") {
                    return <TableTextCell title={pr.label} value={pr.attrValue} key={index} />;
                  } else {
                    if (pr.domain !== "buildingFloor") {
                      return <TableTextCell title={pr.label} value={pr.attrValue} key={index} />;
                    }
                  }
                })}
            </tbody>
          </table>
          {/* 상품정보 */}
          {detailData?.product?.productType !== "TIME_COURT" && (
            <table className="inner-table" width="100%">
              <TableHeader title={"요금"} />
              <tbody>
                {arrPriceLabels &&
                  arrPriceLabels.map((price: any, index: number) => {
                    return <TableTextCell title={price.label} value={price.attrValue} key={index} />;
                  })}
              </tbody>
            </table>
          )}
          {/* 공용 공간 */}
          {detailData?.product?.productType !== "TIME_COURT" ? (
            <table className="inner-table" width="100%">
              <TableHeader title={"공용 공간"} />
              <tbody>
                {arrFacilityLabels &&
                  arrFacilityLabels.map((fa: any, index: number) => {
                    return <TableTextCell title={fa.label} value={fa.attrValue} key={index} />;
                  })}
              </tbody>
            </table>
          ) : (
            <table className="inner-table" width="100%">
              <TableHeader title={"공용 공간"} />
              <tbody>
                {estimateData &&
                  estimateData.map((fa: any, index: number) => {
                    return (
                      <TableTextCell
                        title={"신청 공용공간"}
                        value={fa.buildingCommonFacilityName + "(" + fa.buildingCommonFacilityId + ")"}
                        key={index}
                      />
                    );
                  })}
              </tbody>
            </table>
          )}
          <table className="inner-table" width="100%">
            <TableHeader title={"출입"} />
            <tbody>
              {arrUsersLabels &&
                arrUsersLabels.map((user: any, index: number) => {
                  if (detailData?.product?.productType !== "TIME_COURT") {
                    return <TableTextCell title={user.label} value={user.attrValue} key={index} />;
                  } else {
                    if (user.attrName !== "useNums") {
                      return <TableTextCell title={user.label} value={user.attrValue} key={index} />;
                    }
                  }
                })}
            </tbody>
          </table>
        </section>
        <section className="base-abstract-modal__btn-wrap">
          <BaseButton title={"닫기"} className="color-white" onClick={onClose} />
        </section>
      </>
    </BaseAbstractModal>
  );
};

export default SnapShotModal;
