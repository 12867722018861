import ApiPath from "src/api/apiPath.json";
import { ApiResponse } from "src/api/public-types";
import { AxiosInstance, AxiosResponse } from "axios";
import qs from "qs";
import {
  ReservationConfigModel,
  ReservationConfigRequest,
  ReservationHistoryResponse,
  ReservationListModel,
  ReservationListParams,
  ReservationListResponse,
  ReservationRequest,
  ReservationUpdateResponseData,
  ReservationUsageResponse,
} from "./reservation-types";

/**
 * RRV01. 예약 상세 목록 페이지 조회
 */

export const getReservationListAsync = async (
  axios: AxiosInstance,
  params?: ReservationListParams,
): Promise<AxiosResponse<ApiResponse<ReservationListResponse>>> => {
  return await axios.get<ApiResponse<ReservationListResponse>>(ApiPath.reservation.list, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
};

/**
 * RRV02. 예약 상태 변경 요청
 */
export async function changeReservationStatusAsync(
  axios: AxiosInstance,
  params?: {
    id: string;
    statusCode: "ACCEPTED" | "CANCELLED";
  },
): Promise<AxiosResponse<ApiResponse<ReservationUpdateResponseData>>> {
  if (!params?.id) {
    throw Error("reservationId is not found.");
  }
  if (!params?.statusCode) {
    throw Error("status is not found.");
  }
  const path = ApiPath.reservation.changeStatus.replace("{id}", params.id.toString());
  return await axios.patch(path, {
    statusCode: params.statusCode,
  });
}

/**
 * RRV03. 예약 소진 시간 조회
 */

export const getReservationUsagesAsync = async (
  axios: AxiosInstance,
  params?: {
    id: string;
  },
): Promise<AxiosResponse<ApiResponse<ReservationUsageResponse>>> => {
  if (!params?.id) {
    throw Error("reservationId is not found.");
  }
  return await axios.get<ApiResponse<ReservationUsageResponse>>(ApiPath.reservation.usages.replace("{id}", params.id.toString()));
};

/**
 * RRV04. 예약 소진 시간 목록 조회
 */

export const getReservationUsageListAsync = async (
  axios: AxiosInstance,
  params?: ReservationListParams,
): Promise<AxiosResponse<ApiResponse<ReservationListResponse>>> => {
  return await axios.get<ApiResponse<ReservationListResponse>>(ApiPath.reservation.usagesList, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
};

/**
 * RRV05. 예약 이력 목록 조회
 */

export const getReservationHistoryAsync = async (
  axios: AxiosInstance,
  params?: { id: string | number },
): Promise<AxiosResponse<ApiResponse<{ histories: ReservationHistoryResponse[] }>>> => {
  if (!params?.id) {
    throw Error("reservationId is not found.");
  }
  return await axios.get(ApiPath.reservation.history.replace("{id}", params?.id.toString()), {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
};

/**
 * RRV06. 예약 취소 처리
 */
export async function deleteReservationAsync(
  axios: AxiosInstance,
  params?: {
    reservationId: string;
  },
): Promise<AxiosResponse<ApiResponse<any>>> {
  if (!params?.reservationId) {
    throw Error("reservationId is not found.");
  }

  const path = ApiPath.reservation.delReservation.replace("{reservationId}", params.reservationId.toString());
  return await axios.delete(path);
}

/**RRV07. 예약 접수 처리*/
export const postRservationAsync = (
  axios: AxiosInstance,
  params?: ReservationRequest, //
): Promise<AxiosResponse<ApiResponse<{ reservation: ReservationListModel }>>> => {
  return axios.post(ApiPath.reservation.add, params);
};

/**RRV08. 예약 수정 처리*/
export const editRservationAsync = (
  axios: AxiosInstance,
  params?: ReservationRequest, //
): Promise<AxiosResponse<ApiResponse<{ reservation: ReservationListModel }>>> => {
  const path = ApiPath.reservation.delReservation.replace("{reservationId}", String(params?.reservationId));
  return axios.put(path, params);
};

/**
 * RRVC01. 파트너 예약 설정 정보 조회
 */
export const getReservationConfigAsync = async (axios: AxiosInstance): Promise<AxiosResponse<ApiResponse<{ config: ReservationConfigModel }>>> => {
  return await axios.get(ApiPath.reservation.config);
};
/**
 * RRVC02. 파트너 예약 설정 정보 저장 처리
 */
export const updateReservationConfigAsync = async (
  axios: AxiosInstance,
  params?: ReservationConfigRequest,
): Promise<AxiosResponse<ApiResponse<ReservationConfigModel>>> => {
  return await axios.put(ApiPath.reservation.config, params);
};
