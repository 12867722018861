import { useEffect, useState } from "react";
import { Cell } from "react-table";

import { useApiOperation } from "src/api/hooks";
import { PageMeta, Sort } from "src/api/public-types";

import { getProviderList as getProviderListAsync } from "src/api/provider/provider-api";
import { ProviderModel } from "src/api/provider/provider-types";
import { BaseTooltip } from "src/components";
import BaseSelectTable from "src/components/BaseSelectTable";
import { companyRegistrationHypen } from "src/utils";

const columns: Array<any> = [
  {
    Header: "id",
    accessor: "providerId",
    width: 100,
  },
  {
    Header: "프로바이더 명",
    accessor: "providerName",
    width: 240,
    Cell: ({ value }: Cell<ProviderModel>) => <BaseTooltip className="w-100 text-left" contents={value || "-"} />,
  },
  {
    Header: "사업자 등록 번호",
    accessor: "businessRegistrationNumber",
    width: 220,
    Cell: ({ value }: Cell<ProviderModel>) => (
      <div>
        <span>{companyRegistrationHypen(value) || "-"}</span>
      </div>
    ),
  },
  {
    Header: "등록일",
    accessor: "createdDate",
    width: 150,
    Cell: (props: Cell<ProviderModel>) => {
      return <BaseTooltip contents={props.value} type="date" />;
    },
  },
  {
    Header: "최종 수정일",
    accessor: "modifiedDate",
    width: 150,
    Cell: (props: Cell<ProviderModel>) => {
      return <BaseTooltip contents={props.value} type="date" />;
    },
  },
];

type QueryParams = {
  page?: number;
  size?: number;
  sort?: Sort;
  searchValue?: string;
  supplyType?: string;
};

type Props = {
  onClick?: (checkedKeyList: string[], checkedObjList: ProviderModel[]) => void;
  onClose?: () => void;

  defaultCheckedList?: string[]; // 프로바이더id
};

const SelectProviderPopup = ({ onClick, onClose, defaultCheckedList }: Props) => {
  const [providerList, setProviderList] = useState<ProviderModel[]>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();

  const { executeAsync: getProviderList } = useApiOperation(getProviderListAsync);

  const [params, setParams] = useState<QueryParams>({
    page: 0,
    size: 20,
    searchValue: "",
    supplyType: "RENTAL",
    sort: {
      orders: [
        {
          property: "id",
          direction: "DESC",
        },
      ],
    },
  });

  useEffect(() => {
    const fetchApi = async () => {
      const getProviderListRes = await getProviderList(params);

      setProviderList(getProviderListRes?.data?.data?.content ?? []);
      setPageMeta(getProviderListRes?.data?.meta?.pageMeta);
    };

    fetchApi();
  }, [params]);

  return (
    <BaseSelectTable
      setParams={setParams}
      defaultCheckedList={defaultCheckedList}
      title="프로바이더 선택"
      selectKey={"providerId"}
      columns={columns}
      data={providerList ?? []}
      checkboxType={"radio"}
      onClick={(checkedKeyList, checkedObjList) => onClick && onClick(checkedKeyList, checkedObjList)}
      onClose={() => onClose && onClose()}
      pageMeta={pageMeta}
      searchInputProps={{
        placeholder: "프로바이더 명 또는 사업자 등록번호 입력",
        onSearch(value) {
          setParams((prev) => ({ ...prev, page: 0, searchValue: value }));
        },
      }}
    />
  );
};

export default SelectProviderPopup;
