import { ko } from "date-fns/locale";
import moment from "moment";
import React, { useCallback } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { createPortal } from "react-dom";

interface BaseDatePickerState {
  selectedDate?: Date | null;
  setDate: Function;
  className?: string;
  disabled?: boolean;
  errorText?: string;
  timeIntervals?: number;
  data?: any;
  setData?: Function;
  name?: string;
  validation?: boolean;
  readonly?: boolean;
  placeholder?: string;
  filteredTime?: Date | undefined;
  useFilterdTime?: boolean;
  excludeTimes?: any[];
  maxDate?: Date;
  minDate?: Date;
  filterdReverse?: Boolean;
  dateFormat?: string;
  icon?: boolean;
  filterTime?: (time: Date) => boolean;
  injectTimes?: Date[];
  minTime?: Date;
  maxTime?: Date;
  nonMarkedDate?: boolean;
}

export const FullDatePicker = (props: BaseDatePickerState) => {
  const getFullDateTimeValue = useCallback(
    (date: Date | null) => {
      if (!date) {
        props.setDate(null);
        return;
      }

      // 선택한 날짜가 오늘인 경우
      if (moment(date).isSame(moment(), "day")) {
        const now = moment();
        const currentFlooredTime = moment()
          .startOf("hour")
          .minutes(
            props.timeIntervals
              ? Math.ceil(now.minutes() / props.timeIntervals) * props.timeIntervals //ex 공지사항 예
              : Math.floor(now.minutes() / 30) * 30,
          );

        // 선택된 시간이 단위 시작 시간보다 이전이면 조정
        if (moment(date).isBefore(now)) {
          props.setDate(currentFlooredTime.toDate());
          return;
        }
      }

      props.setDate(date);
    },
    [props],
  );

  const CalendarContainer: React.FC = ({ children }: any) => {
    const el = document.getElementById("calendar");

    if (el === null) return null;

    return createPortal(<>{children}</>, el!);
  };

  const filterPassedTime = (time: Date) => {
    let currentDate = new Date();
    if (props.filteredTime) {
      currentDate = new Date(props.filteredTime);
    }
    const selectedDate = new Date(time);

    let flag = true;

    // 공지사항예시처럼 timeIntervals가 있을 때
    if (props.timeIntervals && props.useFilterdTime) {
      if (selectedDate.getDate() === new Date().getDate()) {
        const now = moment();
        const currentFlooredTime = moment()
          .startOf("hour")
          .minutes(Math.ceil(now.minutes() / props.timeIntervals) * props.timeIntervals);

        if (moment(selectedDate).isBefore(currentFlooredTime)) {
          flag = false;
        } else {
          flag = true;
        }
      }
    } else if (props.useFilterdTime) {
      if (selectedDate.getDate() === new Date().getDate()) {
        if (new Date().getTime() >= selectedDate.getTime()) {
          flag = false;
        } else {
          flag = true;
        }
      }
    }

    if (props.filterdReverse) {
      flag = !flag;
    }

    return flag;
  };

  return (
    <>
      <div className={`base-input  ${props.className ? props.className : ""}`}>
        <DatePicker
          selected={props.selectedDate}
          closeOnScroll={true}
          onChange={(date: Date) => getFullDateTimeValue(date)}
          locale={ko}
          dateFormat="yyyy.MM.dd aa h:mm"
          showPopperArrow={false}
          calendarClassName={`base-datepicker full-datepicker ${props.nonMarkedDate ? "end-date-picker" : ""}`} // v1,21 harry 수정 > 추후 필요할수도
          placeholderText={props.placeholder ? props.placeholder : "날짜와 시간을 선택해주세요"}
          showTimeSelect
          filterTime={props.useFilterdTime ? filterPassedTime : undefined}
          excludeTimes={props.excludeTimes ? props.excludeTimes : []}
          maxDate={props.maxDate}
          minDate={props.minDate}
          minTime={props.minTime}
          maxTime={props.maxTime}
          disabled={props.disabled}
          readOnly={props.readonly ? true : false}
          popperContainer={CalendarContainer}
          timeIntervals={props.timeIntervals ? props.timeIntervals : 30}
        />
        {props.errorText && !props.selectedDate && <p className="validation-text">{props.errorText}</p>}
      </div>
    </>
  );
};
