import _ from "lodash";
import qs from "qs";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { AcGroupContractManageModel } from "src/api/access/ac-group-types";
import { getContractOperationInfo } from "src/api/contract/contract-api";
import { ContractOperationProductManage } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import { checkDataNMake } from "src/pages/contract/hooks/useContractApi";
import useContractFacilityApi from "src/pages/contract/hooks/useContractFacilityApi";
import { PagePath } from "src/pages/product/details";
import { useModal } from "src/recoil/modalState/hook";
import { useToast } from "src/recoil/toast/hook";
import UserAccessSection from "./UserAccessSection";
import VisitorAccessSection from "./VisitorAccessSection";

type Props = {
  contractApplyNumber: string;
  contractManage: AcGroupContractManageModel | undefined;
  onChangeVisitorApprove: (visitorDefaultAccessGroupYn: boolean) => Promise<void>;
};

const UserAndVisitorAccess = ({ contractApplyNumber, contractManage, onChangeVisitorApprove }: Props) => {
  const { id } = useParams();
  const location = useLocation();

  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  const { openToast } = useToast();
  const { isAuthority } = usePartnerAuthority();
  const { setBaseModal } = useModal();

  const [productManage, setProductManage] = useState<ContractOperationProductManage>();
  const [originalProductManage, setOriginalProductManage] = useState<ContractOperationProductManage>();

  /**RCA31. 계약 - 운영정보 상세 조회*/
  const { executeAsync: getContractOperation } = useApiOperation(getContractOperationInfo);

  /**RCA33. 계약 - 운영정보 수정   */

  const { setContractOperation } = useContractFacilityApi();

  const contractId = useMemo(() => {
    return queryParams?.id ? String(queryParams.id) : id;
  }, [id, queryParams.id]);
  // console.log(`contractId`, contractId);

  // 운영정보 상세 - 최대인원 멤버 및 현재 이용자 수 확인
  const fetchContractOperation = async (id: string) => {
    const response = await getContractOperation({ id: Number(id) });
    if (response.status >= 200 && response.status <= 299) {
      const result = response.data.data.content.productManage;
      console.log("result", result);
      setProductManage(result);
      setOriginalProductManage(_.cloneDeep(result));
    }
  };

  useEffect(() => {
    if (contractId) {
      fetchContractOperation(contractId);
    }
  }, []);

  const moveUserList = useMemo(() => {
    return PagePath.user.list + "?contractApplyNumber=" + contractApplyNumber + "&contractId=" + contractId;
  }, [contractApplyNumber, contractId]);

  const moveVisitorList = useMemo(() => {
    return PagePath.visitor.list + "?contractApplyNumber=" + contractApplyNumber + "&contractId=" + contractId;
  }, [contractApplyNumber, contractId]);

  // 이용자 관리 - 최대 이용자수 변경
  const editProductManageData = async (data: ContractOperationProductManage) => {
    if (data) {
      let rtnValidation = { validationPass: false, message: "", response: {} };
      let rtnData: any = {};
      let response: any = checkDataNMake(rtnValidation, originalProductManage, data, rtnData);

      if (response.rtnData.validationPass) {
        const res = await setContractOperation({ id: Number(contractId), productManage: response.makeData });
        if (res.status >= 200 && res.status <= 299) {
          fetchContractOperation(String(contractId));
        }
      }
    }
  };

  const handleVisitorChangeEvent = (requestFn: Function, type?: "invite" | "approval") => {
    setBaseModal({
      isOpen: true,
      title: "선택을 변경하시겠습니까?",
      children: type === "invite" ? "[Taap > 방문자 초대] 사용 가능 여부가 즉시 적용됩니다." : "이미 초대한 방문자의 권한에도 적용됩니다.",
      onClick: () => {
        requestFn();
        setBaseModal({ isOpen: false });
      },
      onClose: () => {
        setBaseModal({ isOpen: false });
      },
      btnLeftTitle: "취소",
      btnRightTitle: "확인",
    });
  };

  return (
    <>
      {/* 이용자 관리 */}
      <article className="contents-container__wrap-article">
        <UserAccessSection
          productManage={productManage || {}}
          goToList={moveUserList}
          setData={(data: ContractOperationProductManage) => {
            if (data) {
              editProductManageData(data);
            }
          }}
          isAuthority={isAuthority("w")}
        />
      </article>
      {/* 방문자 관리 */}

      <article className="contents-container__wrap-article">
        <VisitorAccessSection
          productManage={productManage || {}}
          contractManage={contractManage}
          onChangeVisitorInvite={(data: ContractOperationProductManage) => {
            // 방문자 초대 기능 수정
            if (data) {
              handleVisitorChangeEvent(() => {
                editProductManageData(data);
              }, "invite");
            }
          }}
          onChangeVisitorApprove={(visitorDefaultAccessGroupYn: boolean) => {
            handleVisitorChangeEvent(() => {
              onChangeVisitorApprove(visitorDefaultAccessGroupYn);
            });
          }}
          isAuthority={isAuthority("w")}
          visitorUrl={moveVisitorList}
        />
      </article>
    </>
  );
};

export default UserAndVisitorAccess;
