import React from "react";
import { QueryClient, QueryClientProvider, QueryCache, MutationCache } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useErrorModal } from "./recoil/errorModal/hook";
import { AxiosError } from "axios";
import { ApiResponse } from "./api/public-types";

/**
 * 아래의 링크들의 내용 꼭 봐주세요
 * react-query v5
 * https://github.com/TanStack/query/discussions/5279
 * https://tkdodo.eu/blog/effective-react-query-keys
 */
const ReactQueryProvider = ({ children }: { children: React.ReactNode }) => {
  const { openErrorModal } = useErrorModal();

  const handlerError = (_error: AxiosError<ApiResponse<any>>) => {
    // 에러
    let errorCode = "";
    let errorMessage = "";
    let statusCode = 0;

    if (_error?.response?.status) {
      statusCode = _error?.response?.status;
    }
    if (_error?.response?.data?.meta?.errorCode) {
      errorCode = _error?.response?.data?.meta?.errorCode;
    }
    if (_error?.response?.data?.meta?.errorMessage) {
      errorMessage = _error?.response?.data?.meta?.errorMessage;
    }

    // 에러 모달
    openErrorModal({ errorCode, statusCode, errorMessage });
  };

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
      },
    },

    queryCache: new QueryCache({
      onError(error, query) {
        if (error instanceof AxiosError) {
          if (query.meta?.noHandleError) {
            return; //query.meta.noHandleError 의 값이 false 거나 undefined 일 때 전역 에러모달오픈
          }

          if (query.meta?.onError) {
            return query.meta?.onError(error, query);
          }

          // 모든 케이스 통과시 openErrorModal
          handlerError(error);
        } else {
          // error가 AxiosError 가 아니고 SyntaxError | TypeError | ReferenceError 등 기타 에러일 때
          console.log("queryCache onError error :>> ", error);
        }
      },
      onSuccess(data, query) {
        if (query.meta?.onSuccess) {
          return query.meta?.onSuccess(data, query);
        }
      },
    }),

    mutationCache: new MutationCache({
      // useMutation 사용시 커스텀 에러를 사용하고싶으면 useMutation에서 onError를 사용하면 override 됨
      onError(error) {
        if (error instanceof AxiosError) {
          console.log("mutationCache 에러 실행 오버라이드 안됨 :>> ");
          handlerError(error);
        } else {
          console.log("mutationCache onError error :>> ", error);
        }
      },
    }),
  });

  return (
    <QueryClientProvider client={queryClient}>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      {children}
    </QueryClientProvider>
  );
};

export default ReactQueryProvider;
