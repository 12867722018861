import { ReactNode } from "react";
import { createPortal } from "react-dom";
import { useLocation } from "react-router-dom";
import { isPublicPage } from "src/utils/common-util";
interface state {
  isOpen: boolean;
  children?: ReactNode;
  containerClassName?: string;
  className?: string;
  size?: "small" | "medium" | "large" | "xlarge"; // default xlarge(1000) size
  isTwoDepth?: boolean; // 이중팝업 여부
  isNotUseWhiteBoard?: boolean; // 화이트배경 미사용 여부
  opacity?: number; // 0 ~ 1 default 0.6
}
/*
 * 완전 커스텀 가능한 다이얼로그 모달.
 * 새로운 공통 모달 룰 및 레이아웃 적용하려면 아래와 같이 사용
 */

/*
<BaseAbstractModal>
<section className="base-abstract-modal__title">
  <h1>타이틀</h1>
</section>
  <section className="base-abstract-modal__contents"> // 테이블 없을때 px30 추가
    <section className="base-abstract-modal__contents-subtitle"> 
      <h2>서브타이틀</h2>
    </section>
  </section>
  <section className="base-abstract-modal__btn-wrap">
    <BaseButton title={"취소"} className="color-white" />
    <BaseButton title={"확인"} />
  </section>
</BaseAbstractModal>;
 */

export const BaseAbstractModal = (props: state) => {
  const el = document.getElementById("modal");
  const { pathname } = useLocation();

  if (!props.isOpen) return null;

  return createPortal(
    <div
      className={isPublicPage(pathname) ? `dim mobile` : `dim ${props.containerClassName ? props.containerClassName : ""}`}
      id="dim"
      style={props.opacity !== undefined && props.opacity !== null ? { backgroundColor: `rgba(0,0,0,${props.opacity})` } : {}}
    >
      {!props.isNotUseWhiteBoard ? (
        <div className={`${props.className} base-abstract-modal ${props.size ? props.size : ""} ${props.isTwoDepth ? "two-depth" : ""}`}>
          {props.children}
        </div>
      ) : (
        <>{props.children}</>
      )}
    </div>,
    el!,
  );
};
