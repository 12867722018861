import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Ac2AccessGroupModel } from "src/api/access/ac-group-types";
import { getAccessGroupListAsync } from "src/api/access/productac-api";
import { useApiOperation } from "src/api/hooks";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";
import SpaceBaseTable from "src/pages/product/forms/commonSpaceForm/components/SpaceBaseTable";
import useVisitor from "../hooks/useVisitor";
import { acGroupColumns } from "./acColumns";
import { PageType } from "src/api/visitor/visitor-types";

/**
 * 방문자 출입그룹 조회 컴포넌트 신청계약 출입그룹에서 권한이 기본 권한일때 신청계약쪽 출입그룹을 수정불가능한 형태로 노출
 */
const VisitorOnlyViewAccessGroup = ({ pageType }: { pageType: PageType }) => {
  const { contractApplyNumber, visitApplyNumber } = useParams();

  const { contractManage } = useVisitor({
    contractApplyNumber: contractApplyNumber,
    visitApplyNumber: visitApplyNumber,
  });

  // 출입그룹 목록
  const [accessGroupList, setAccessGroupList] = useState<Ac2AccessGroupModel[]>([]);

  const newColumns = useMemo(() => {
    return acGroupColumns;
  }, []);

  //출입그룹 목록 조회
  const { executeAsync: getAccessList } = useApiOperation(getAccessGroupListAsync);

  const fetchContractAccess = useCallback(async () => {
    //신청계약 쪽 출입그룹을 조회
    const { data } = await getAccessList({
      contractManageId: contractManage?.contractManageId?.toString(),
      visitorAccessYn: true,
    });

    setAccessGroupList(data?.data.content || []);
  }, [getAccessList, contractManage]);

  useEffect(() => {
    // 출입 그룹 조회
    fetchContractAccess();
  }, [fetchContractAccess]);

  return (
    <article className="contents-container__wrap-article">
      <div className="contents-container__sub-title mb0">
        <div className="flex-center-between w-100">
          <BaseSectionTitle title={"출입그룹 관리"} className="pb0" noHashLink={pageType === "add"} />
          <div className="contents-container__grid-contents flex-center"></div>
        </div>
      </div>
      <section>
        <SpaceBaseTable columns={newColumns} data={accessGroupList} />
      </section>
    </article>
  );
};

export default VisitorOnlyViewAccessGroup;
