import { useEffect, useRef, useState } from "react";
import Select from "react-select";
export interface BaseSelectState {
  placeholder?: string;
  stateOptions: Array<{
    value: string | number;
    label: string;
    isDisabled?: boolean;
  }>;
  value?: string | number | null;
  name?: string;
  setStateValue: Function;
  className?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  errorText?: string;
  isDisabled?: boolean;
  menuPosition?: "fixed" | "absolute";
  menuPortalTarget?: HTMLElement | undefined;
  menuPlacement?: "auto" | "top" | "bottom"; // 수정: prop 타입 변경
  maxMenuHeight?: number;
}

export const BaseSelect = (props: BaseSelectState) => {
  let selectedValue = null; // null 로 초기화 해야 선택안함이 정상적으로 초기화됨
  const maxMenuHeight = props.maxMenuHeight ?? 300;
  const stateOptions = props.stateOptions;
  if (props.value) {
    selectedValue = props.stateOptions.find((option) => String(option.value) === String(props.value));
  }

  const selectRef = useRef<HTMLDivElement | null>(null);
  const [internalMenuPlacement, setInternalMenuPlacement] = useState<"top" | "bottom" | "auto">(props.menuPlacement ?? "auto"); // 내부 상태로 menuPlacement 관리

  const handleMenuOpen = () => {
    if (props.menuPlacement !== "top" && selectRef.current) {
      const rect = selectRef.current.getBoundingClientRect();
      const BelowHeight = window.innerHeight - rect.bottom;
      const itemHeight = 36;
      const itemCount = stateOptions?.length;
      const itemListHeight = itemHeight * itemCount;
      const menuHeight = maxMenuHeight > itemListHeight ? itemListHeight : maxMenuHeight;

      if (BelowHeight < menuHeight + 20) {
        setInternalMenuPlacement("top");
      } else {
        setInternalMenuPlacement("bottom");
      }
    }
  };

  useEffect(() => {
    if (props.menuPlacement !== "auto") {
      setInternalMenuPlacement(props.menuPlacement || "bottom");
    }
  }, [props.menuPlacement]);

  return (
    <div ref={selectRef as any} className={`w-100  base-select ${props.className ? props.className : ""}`}>
      <Select
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        placeholder={props?.placeholder || "선택해주세요"}
        isSearchable={false}
        value={selectedValue ?? null}
        className={`react-select-container`}
        classNamePrefix="react-select"
        onChange={(e) => {
          e && props.setStateValue(e.value);
        }}
        options={stateOptions}
        menuPortalTarget={props.menuPortalTarget}
        isDisabled={props.isDisabled}
        menuPosition={props.menuPlacement === "auto" ? "fixed" : props.menuPosition || "absolute"}
        menuPlacement={internalMenuPlacement as any} // 내부 상태 사용
        onMenuOpen={handleMenuOpen}
        maxMenuHeight={props.maxMenuHeight}
        // closeMenuOnScroll={() => props.menuPlacement === "auto"}
        // menuIsOpen={true}
      />
      {props.errorText && <p className="validation-text">{props.errorText}</p>}
    </div>
  );
};
