import { Control, Controller, FieldArrayWithId, UseFieldArrayRemove, UseFormReturn } from "react-hook-form";

import { isValidPhoneNumber } from "libphonenumber-js";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Row } from "react-table";
import { PageType, PreDiscountModal, VisitorFormData, VisitStatusMap } from "src/api/visitor/visitor-types";
import { BaseButton, BaseInput, BaseSelect, BaseTooltip } from "src/components";
import BaseNewTabLink from "src/components/BaseNewTabLink";
import { useModal } from "src/recoil/modalState/hook";
import { formatPhoneNumber, validateEmailText } from "src/utils";
import { getVisitorAccessInfoPath } from "src/utils/route-util";
import useCsnkey from "../hooks/useCsnkey";
import usePatchVisitor from "../hooks/usePatchVisitor";
import useVisitor from "../hooks/useVisitor";
import CsnkeyModal from "./CsnkeyModal";

export interface VisitorCellProps {
  row: Row<FieldArrayWithId<VisitorFormData, "visitorInfoList", "id">>;
  control: Control<VisitorFormData>;
  useFormReturn: UseFormReturn<VisitorFormData>;
  pageType: PageType;
  remove: UseFieldArrayRemove;
  preDiscountList?: PreDiscountModal[];
}

const requiredMessage = "필수입력 항목입니다";

export const visitorAddColumns: any[] = [
  {
    Header: <span className="required">이름</span>,
    accessor: "visitorName",
    width: 160,
    Cell: ({ row, pageType, control }: VisitorCellProps) => {
      return (
        <div key={row.original.id}>
          <Controller
            control={control}
            name={`visitorInfoList.${row.index}.visitorName`}
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <div>
                  <BaseInput placeholder="이름" disabled={pageType === "edit"} value={field.value} onChange={field.onChange} />
                </div>
              );
            }}
          />
        </div>
      );
    },
  },

  {
    Header: <span className="required">방문자 연락처 및 이메일</span>,
    accessor: "invitationType",
    width: 415,
    Cell: ({ row, pageType, control, useFormReturn }: VisitorCellProps) => {
      const { resetField, watch } = useFormReturn;
      const [isFocusList, setIsFocusList] = useState<{ index: number; isFocus: boolean }>({ index: 0, isFocus: false });
      return (
        <div key={row.original.id} className="w-full flex-row flex-start">
          <div className={`base-select-input ${pageType === "edit" ? "readonly" : ""} ${isFocusList.isFocus ? "base-select-input__focus" : ""}`}>
            <div>
              <Controller
                control={control}
                name={`visitorInfoList.${row.index}.invitationType`}
                rules={{ required: true }}
                render={({ field }) => {
                  return (
                    <BaseSelect
                      menuPlacement="auto"
                      isDisabled={pageType === "edit"}
                      value={field.value ?? "phone"}
                      placeholder="초대방법"
                      stateOptions={[
                        {
                          value: "phone",
                          label: "휴대폰",
                        },
                        {
                          value: "email",
                          label: "이메일",
                        },
                      ]}
                      setStateValue={(value: string) => {
                        field.onChange(value);
                        resetField(`visitorInfoList.${row.index}.visitorMobileNumber`);
                        resetField(`visitorInfoList.${row.index}.visitorEmail`);
                      }}
                      onFocus={() =>
                        setIsFocusList({
                          index: row.index,
                          isFocus: true,
                        })
                      }
                      onBlur={() =>
                        setIsFocusList({
                          index: row.index,
                          isFocus: false,
                        })
                      }
                    />
                  );
                }}
              />
            </div>

            {watch(`visitorInfoList.${row.index}.invitationType`) === "phone" ? (
              <Controller
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: requiredMessage,
                  },
                  validate: (value) => (isValidPhoneNumber(value!, "KR") ? true : "유효한 휴대폰 번호를 입력해주세요"),
                }}
                name={`visitorInfoList.${row.index}.visitorMobileNumber`}
                render={({ field }) => (
                  <BaseInput
                    name={`visitorMobileNumber`}
                    type="phone"
                    placeholder="휴대폰 번호"
                    disabled={pageType === "edit"}
                    className="minmax200 mr10"
                    value={field.value}
                    onChange={field.onChange}
                    onFocus={() =>
                      setIsFocusList({
                        index: row.index,
                        isFocus: true,
                      })
                    }
                    onBlur={() =>
                      setIsFocusList({
                        index: row.index,
                        isFocus: false,
                      })
                    }
                  />
                )}
              />
            ) : (
              <Controller
                control={control}
                rules={{ required: { value: true, message: requiredMessage }, validate: (value) => validateEmailText(value) }}
                name={`visitorInfoList.${row.index}.visitorEmail`}
                render={({ field }) => (
                  <BaseInput
                    name={`visitorEmail`}
                    type="email"
                    placeholder="이메일 주소"
                    disabled={pageType === "edit"}
                    className="minmax200 mr10"
                    value={field.value}
                    onChange={field.onChange}
                    onFocus={() =>
                      setIsFocusList({
                        index: row.index,
                        isFocus: true,
                      })
                    }
                    onBlur={() =>
                      setIsFocusList({
                        index: row.index,
                        isFocus: false,
                      })
                    }
                  />
                )}
              />
            )}
          </div>
          {watch(`visitorInfoList.${row.index}.invitationType`) === "email" && (
            <Controller
              control={control}
              defaultValue={"en"}
              name={`visitorInfoList.${row.index}.visitorEmailLang`}
              render={({ field }) => {
                return (
                  <BaseSelect
                    menuPlacement="auto"
                    isDisabled={pageType === "edit"}
                    className="minmax80"
                    value={field.value ?? "en"}
                    stateOptions={[
                      {
                        value: "ko",
                        label: "국문",
                      },
                      {
                        value: "en",
                        label: "영문",
                      },
                    ]}
                    setStateValue={(value: string) => {
                      field.onChange(value);
                    }}
                  />
                );
              }}
            />
          )}
        </div>
      );
    },
  },
  {
    Header: "주차 차량 번호",
    accessor: "visitorCarNumber",
    width: 170,
    Cell: ({ row, pageType, control }: VisitorCellProps) => {
      return (
        <div key={row.original.id}>
          <Controller
            control={control}
            name={`visitorInfoList.${row.index}.visitorCarNumber`}
            render={({ field }) => (
              <BaseInput
                placeholder="주차 차량 번호 (선택)"
                disabled={pageType === "edit"}
                className="minmax160"
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </div>
      );
    },
  },
  {
    // add 페이지 키카드 할당
    Header: (
      <div className="flex-row flex-start-start">
        키카드 할당 <BaseTooltip touchIcon={"QUESTION"} tooltip="키카드 할당은 저장 후 [상세페이지]에서 가능합니다."></BaseTooltip>
      </div>
    ),
    accessor: "visitorKeyCardButton",
    width: 105,
    Cell: ({ row, pageType, control, remove }: VisitorCellProps) => {
      return (
        <div key={row.original.id}>
          <BaseButton title="할당" className="color-white" disabled={true} />
        </div>
      );
    },
  },
  {
    // add 페이지 키카드 번호
    Header: (
      <div className="flex-row flex-start-start">
        키카드 번호 <BaseTooltip touchIcon={"QUESTION"} tooltip="키카드 번호 삭제는 저장 후 [상세페이지]에서 가능합니다."></BaseTooltip>
      </div>
    ),
    accessor: "visitorKeyCard",
    width: 150,
    Cell: ({ row, pageType, control, remove }: VisitorCellProps) => {
      return (
        <div key={row.original.id} className="w-100 flex-row flex-start-start">
          <span>{"-"}</span>
        </div>
      );
    },
  },
  {
    Header: "삭제",
    accessor: "delete",
    width: 48,
    Cell: ({ row, pageType, control, remove }: VisitorCellProps) => {
      return (
        <div key={row.original.id}>
          <button className="base-trash-btn color-gray" onClick={() => remove(row.index)} type="button" />
        </div>
      );
    },
  },
];

export const visitorDetailColumns: any[] = [
  {
    Header: "방문자 Id",
    accessor: "visitorId",
    width: 80,
    Cell: ({ row, pageType, control, remove }: VisitorCellProps) => {
      return <BaseTooltip contents={String(row.original?.contractVisitorId) || "-"} isSingleLine />;
    },
  },
  {
    Header: "상태",
    accessor: "visitStatus",
    width: 80,
    Cell: ({ row, useFormReturn }: VisitorCellProps) => {
      const { watch } = useFormReturn;
      const isDeleted = row?.original?.isDeleted;
      const visitStatus = watch("invitationInfo.visitStatus");

      if (isDeleted) return VisitStatusMap["VISIT_DELETE"];

      return VisitStatusMap[visitStatus as keyof typeof VisitStatusMap];
    },
  },
  {
    Header: "이름",
    accessor: "visitorName",
    width: 140,
    Cell: ({ row, pageType, control, remove }: VisitorCellProps) => {
      return <BaseTooltip contents={row.original?.visitorName || "-"} isSingleLine />;
    },
  },

  {
    Header: "방문자 연락처",
    accessor: "visitorMobileNumber",
    width: 120,
    Cell: ({ row }: VisitorCellProps) => {
      const { visitorMobileNumber } = row?.original;
      if (visitorMobileNumber) {
        return <BaseTooltip contents={formatPhoneNumber(visitorMobileNumber) || "-"} />;
      } else {
        return <span>-</span>;
      }
    },
  },
  {
    Header: "방문자 이메일",
    accessor: "visitorEmail",
    width: 136,
    Cell: ({ row }: VisitorCellProps) => {
      const { visitorEmail, visitorEmailLang } = row?.original;
      if (visitorEmail) {
        return <BaseTooltip contents={`${visitorEmail} ${visitorEmailLang === "ko" ? "(국문)" : "(영문)"}`} />;
      } else {
        return <span>-</span>;
      }
    },
  },
  {
    Header: "주차 차량 번호",
    accessor: "visitorCarNumber",
    width: 124,
    Cell: ({ row, preDiscountList }: VisitorCellProps) => {
      const { visitorCarNumber } = row?.original;
      return (
        <div className="flex-center-center flex-column">
          <BaseTooltip contents={visitorCarNumber || "-"} isSingleLine />

          {preDiscountList?.some((preDiscountList) => String(preDiscountList?.preDiscountId) === String(row?.original?.preDiscountId)) && (
            <span className="text-primary3 font14">주차 혜택 적용</span>
          )}
        </div>
      );
    },
  },
  {
    Header: "엑세스 권한",
    accessor: "contractVisitorId",
    width: 88,
    Cell: ({ row }: VisitorCellProps) => {
      const { contractApplyNumber, visitApplyNumber } = useParams();

      const { contractManage } = useVisitor({ contractApplyNumber, visitApplyNumber });

      return (
        <div className="flex-center-center">
          <BaseNewTabLink
            path={getVisitorAccessInfoPath({
              contractVisitorId: String(row?.original?.contractVisitorId),
              contractManageId: String(contractManage?.contractManageId),
              contractApplyNumber: String(contractApplyNumber),
              visitApplyNumber: String(visitApplyNumber),
            })}
            value={"확인하기"}
            className="w-100 text-left"
          />
        </div>
      );
    },
  },

  {
    Header: "QR 링크",
    accessor: "QRLink",
    width: 88,
    Cell: ({ row }: VisitorCellProps) => {
      const { visitApplyNumber } = useParams();
      const visitorEmailLang = row?.original.visitorEmailLang;
      const QRLink = `${process.env.REACT_APP_COURT_BASSEURL}/front/court/visitor/detail/${visitApplyNumber}${row?.original?.contractVisitorId}`;

      return (
        <div className="flex-center-center">
          <BaseNewTabLink path={QRLink + `?lang=${visitorEmailLang}`} value={"바로가기"} className="w-100 text-left" />
        </div>
      );
    },
  },

  {
    // 상세 페이지 키카드할당
    Header: (
      <div className="flex-row flex-start-start">
        키카드 할당 <BaseTooltip touchIcon={"QUESTION"} tooltip="키카드 할당은 저장 후 [상세페이지]에서 가능합니다."></BaseTooltip>
      </div>
    ),
    accessor: "visitorKeyCardButton",
    width: 105,

    Cell: ({ row, useFormReturn }: VisitorCellProps) => {
      const { setAbstractModalZ1 } = useModal();
      const { watch } = useFormReturn;

      const isDeleted = row?.original?.isDeleted;
      const visitStatus = watch("invitationInfo.visitStatus");

      // 방문자가 삭제되었거나 방문상태가 삭제/종료 경우 할당 불가
      const isDisabled = isDeleted || visitStatus === "VISIT_DELETE" || visitStatus === "VISIT_CLOSE";

      return (
        <div>
          <BaseButton
            disabled={isDisabled} // 삭제상태면 할당 불가
            title="할당"
            className="color-white"
            onClick={() =>
              setAbstractModalZ1({
                isOpen: true,
                children: <CsnkeyModal contractVisitor={row?.original} />,
              })
            }
          />
        </div>
      );
    },
  },
  {
    // 상세 페이지 키카드 번호
    Header: (
      <div className="flex-row flex-start-start">
        키카드 번호 <BaseTooltip touchIcon={"QUESTION"} tooltip="키카드 번호 삭제는 저장 후 [상세페이지]에서 가능합니다."></BaseTooltip>
      </div>
    ),
    accessor: "visitorKeyCard",
    width: 164,
    Cell: ({ row }: VisitorCellProps) => {
      const { setBaseModal } = useModal();
      const contractVisitorId = row?.original?.contractVisitorId?.toString() || "";

      const { deleteCardNumber, csnkeyList } = useCsnkey([contractVisitorId]);

      const cardNumber = csnkeyList?.data?.data?.content?.find((item) => String(item.contractVisitorId) === String(contractVisitorId))?.accessToken;

      return (
        <div className="w-100 flex-row flex-start-start">
          {cardNumber ? (
            <>
              <BaseTooltip className="flex-1" contents={cardNumber} isSingleLine={true}></BaseTooltip>
              <div
                className="delete-btn-x shrink-0"
                onClick={() =>
                  setBaseModal({
                    isOpen: true,
                    title: "키카드 번호를 삭제하시겠습니까?",
                    children: "삭제하면 입력되어 있는 번호가 삭제됩니다.",
                    btnRightTitle: "확인",
                    btnLeftTitle: "취소",
                    onClick: () => {
                      deleteCardNumber(
                        { contractVisitorId: contractVisitorId },
                        {
                          onSuccess: () => {
                            setBaseModal({ isOpen: false });
                          },
                        },
                      );
                    },
                  })
                }
              ></div>
            </>
          ) : (
            <span>-</span>
          )}
        </div>
      );
    },
  },

  {
    Header: "삭제",
    accessor: "delete",
    width: 48,
    Cell: ({ row, useFormReturn }: VisitorCellProps) => {
      const { watch } = useFormReturn;
      const isDeleted = row?.original?.isDeleted;
      const visitStatus = watch("invitationInfo.visitStatus");

      // 방문자가 삭제되었거나 방문상태가 삭제/종료 경우 삭제 불가
      const isDisabled = isDeleted || visitStatus === "VISIT_DELETE" || visitStatus === "VISIT_CLOSE";

      const { setBaseModal } = useModal();

      const { getValues } = useFormReturn;

      const { mutate: patchVisitor } = usePatchVisitor();

      const contractVisitorId = row?.original?.contractVisitorId?.toString() || "";

      const { csnkeyList } = useCsnkey([contractVisitorId]);

      const cardNumber = csnkeyList?.data?.data?.content?.find((item) => String(item.contractVisitorId) === String(contractVisitorId))?.accessToken;

      return (
        <div>
          <button
            className="base-trash-btn color-gray"
            disabled={isDisabled}
            onClick={() => {
              if (getValues(`visitorInfoList`).length === 1) {
                return setBaseModal({
                  isOpen: true,
                  title: "1명 이상의 방문자 정보가 필요합니다.",
                  btnRightTitle: "확인",
                });
              }

              setBaseModal({
                isOpen: true,
                title: "방문자를 삭제하시겠습니까?",
                children: "삭제하면 방문자 정보가 삭제됩니다.",
                btnRightTitle: "확인",
                btnLeftTitle: "취소",
                onClick: () => {
                  patchVisitor({
                    contractVisitorGroupId: row?.original?.contractVisitorGroupId?.toString() || "",
                    contractVisitorList: [
                      {
                        contractVisitorId: row?.original?.contractVisitorId,
                        isDeleted: true,
                      },
                    ],
                  });

                  setBaseModal({ isOpen: false });
                },
              });
            }}
            type="button"
          />
        </div>
      );
    },
  },
];
