import { DebounceClick } from "@toss/react";
import React, { useEffect, useRef, useState } from "react";

interface BaseButtonState {
  title: string;
  onClick?: Function;
  className?: string;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  width?: string;
  setRef?: (ref: React.RefObject<HTMLButtonElement>) => void;
  form?: string;
  name?: string;
}
/**
 * className으로 UI변경
 * color-white
 * size-small, size-medium, size-large
 */
// color-white, size-small

export const BaseButton = (props: BaseButtonState) => {
  const [isClicked, setIsClicked] = useState(false);
  const onPropsClick = () => {
    setIsClicked(true);
    if (props.onClick) {
      props.onClick();
    }
    setIsClicked(false);
  };

  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (props.setRef) {
      props.setRef(ref);
    }
  }, [ref]);

  return (
    <DebounceClick wait={150}>
      <button
        disabled={props.disabled ? Boolean(props.disabled) : isClicked || false}
        type={props?.type || "button"}
        className={`base-btn ${props.className ? props.className : ""}`}
        style={{ width: props.width }}
        name={props.name}
        onClick={onPropsClick}
        onMouseUp={(event) => {
          if (event.button === 1) {
            // 마우스의 휠 버튼 클릭 여부 확인
            event.currentTarget.click();
          }
        }}
        ref={ref}
        form={props.form}
      >
        <span>{props.title}</span>
      </button>
    </DebounceClick>
  );
};
