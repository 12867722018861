import moment from "moment";
import qs from "qs";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { ReservationAttendee, ReservationListModel } from "src/api/reservation/reservation-types";
import { useI18n } from "src/hooks/useI18n";
import { maskEmail, maskMiddleChars, maskString } from "src/utils";

type Props = {
  timeSlotsTime: string;
  facility?: any; // 공용공간 예약데이터
  handleReservationDetailModal: (type: string, payload: any) => void;
  getCurrentTimePosition: number;
  currentPage: number;
};

const cellHeight = 45; //css에 정의한 셀 한칸의 높이값이랑 같아야함.

const getStatus = (startTime: string, endTime: string) => {
  const now = moment();
  const start = moment(startTime);
  const end = moment(endTime);
  if (end.isBefore(now)) {
    return "off";
  } else if (now.isBetween(start, end)) {
    return "on";
  } else if (start.isAfter(now)) {
    return "ready";
  }
  return "off";
};

const getAttendeeLength = (attendees: ReservationAttendee[], organizerMemberNo: string) => {
  return attendees.filter((attendee) => attendee.memberNo !== organizerMemberNo)?.length;
};

const ReservationBox = ({ handleReservationDetailModal, facility, timeSlotsTime, getCurrentTimePosition, currentPage }: Props) => {
  const location = useLocation();
  const { t } = useI18n();
  const [isTopOutOfView, setIsTopOutOfView] = useState(true);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const boxRef = useRef<HTMLDivElement>(null);
  const queryParams: any = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    allowDots: true,
    decoder: (c) => c,
  });

  // 타이틀이 완전히 보이는지 확인후, 현재 타임라인의 위치에 타이틀을 이동.
  useEffect(() => {
    if (!boxRef.current) return;

    let isTransitioning = false;

    const checkVisibility = () => {
      if (!boxRef.current || isTransitioning) return;
      const rect = boxRef.current.getBoundingClientRect();

      const outOfView = rect.top < 280 - 34; // 위쪽여백, + 타이틀까지 = 최소 타이틀이 가려지면

      setIsTopOutOfView(outOfView);

      if (wrapperRef.current) {
        const status = boxRef.current && getStatus(facility?.reservations?.[0]?.start, facility?.reservations?.[0]?.end);
        const shouldAlignToCurrentTime = status === "on" && outOfView;

        if (shouldAlignToCurrentTime) {
          const offsetPosition = getBoxOffsetFromScrollContainer();
          wrapperRef.current.style.position = "absolute";
          wrapperRef.current.style.top = `${getCurrentTimePosition - offsetPosition - 10}px`;
          wrapperRef.current.style.zIndex = "100";
          wrapperRef.current.style.width = "calc(100% - 40px)";
        } else {
          wrapperRef.current.style.position = "";
          wrapperRef.current.style.top = "";
          wrapperRef.current.style.zIndex = "";
          wrapperRef.current.style.width = "";
        }
      }
    };

    const initialCheckTimeout = setTimeout(() => {
      checkVisibility();
    }, 300);

    let scrollTimer: ReturnType<typeof setTimeout> | null = null;
    const handleScroll = () => {
      if (scrollTimer) clearTimeout(scrollTimer);

      if (isTransitioning) return;

      scrollTimer = setTimeout(() => {
        checkVisibility();
      }, 200);
    };

    const scrollContainer = boxRef.current.closest(".reservation-calendar-content-scroll");
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    window.addEventListener("resize", handleScroll);

    const slideChangeHandler = () => {
      isTransitioning = true;
      setTimeout(() => {
        isTransitioning = false;
        checkVisibility();
      }, 300);
    };

    const swiperContainer = document.querySelector(".swiper-container");
    if (swiperContainer) {
      swiperContainer.addEventListener("transitionend", slideChangeHandler);
    }

    const slideChangeObserver = new MutationObserver(() => {
      setTimeout(checkVisibility, 300);
    });

    if (boxRef.current.closest(".swiper-slide")) {
      slideChangeObserver.observe(boxRef.current.closest(".swiper-slide")!, {
        attributes: true,
        attributeFilter: ["class"],
      });
    }

    return () => {
      clearTimeout(initialCheckTimeout);
      if (scrollTimer) clearTimeout(scrollTimer);

      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
      window.removeEventListener("resize", handleScroll);

      if (swiperContainer) {
        swiperContainer.removeEventListener("transitionend", slideChangeHandler);
      }
    };
  }, [getCurrentTimePosition, currentPage, facility]);

  // 페이지가 변경될 때마다 visibility 체크 다시 실행하기 위한 useEffect
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (wrapperRef.current) {
        wrapperRef.current.removeAttribute("style");
      }

      setIsTopOutOfView(false);

      const activeSlide = document.querySelector(".swiper-slide-active");
      if (activeSlide && boxRef.current && activeSlide.contains(boxRef.current)) {
        const boxRect = boxRef.current.getBoundingClientRect();
        const outOfView = boxRect.top < 280 - 34;
        setIsTopOutOfView(outOfView);
      }
    }, 100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [currentPage]);

  // 타이틀이 박스로부터 얼마나 떨어진 위치에 있는지 확인(타이틀위치 계산)
  const getBoxOffsetFromScrollContainer = () => {
    if (!boxRef.current) return 0;

    const boxRect = boxRef.current.getBoundingClientRect();
    const scrollContainer = boxRef.current.closest(".reservation-calendar-content-scroll");

    if (!scrollContainer) return 0;

    const scrollContainerRect = scrollContainer.getBoundingClientRect();
    const scrollTop = scrollContainer.scrollTop;

    return boxRect.top - scrollContainerRect.top + scrollTop;
  };

  // 페이지가 변경되면 변경된 위치를 일단 초기화시킴 (wrapperRef의 인라인 스타일 제거)
  useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.removeAttribute("style");
    }
  }, [currentPage]);

  // 타이틀 렌더링
  const renderTitle = (reservation: ReservationListModel) => {
    if (queryParams.reservationName === "public") {
      return reservation?.summary ? reservation?.summary : " ";
    } else if (queryParams.reservationName === "restricted") {
      return reservation?.summary ? maskString(reservation?.summary, 3) : " ";
    } else {
      return t("reservation.display.body.private");
    }
  };

  // 주최자 렌더링
  const renderAttendeeInfo = (reservation: ReservationListModel) => {
    if (queryParams.reservationName === "public") {
      return `${reservation.organizer.displayName && reservation.organizer.displayName}(${reservation.organizer.userEmail}) ${
        getAttendeeLength(reservation.attendees, reservation.organizer.memberNo) > 0
          ? "외 " + getAttendeeLength(reservation.attendees, reservation.organizer.memberNo) + "명"
          : ""
      }`;
    } else if (queryParams.reservationName === "restricted") {
      return `${reservation.organizer.displayName && maskMiddleChars(reservation.organizer.displayName)}(${maskEmail(
        reservation.organizer.userEmail,
      )}) ${
        getAttendeeLength(reservation.attendees, reservation.organizer.memberNo) > 0
          ? "외 " + getAttendeeLength(reservation.attendees, reservation.organizer.memberNo) + "명"
          : ""
      }`;
    } else {
      return t("reservation.display.body.private");
    }
  };

  const renderReservationBox = (reservation: any) => {
    const isTodayReservation = moment(reservation.start).isSame(moment(), "day") && moment(reservation.start)?.format("HH:mm") === timeSlotsTime;
    const isBeforeAndAfter =
      moment(reservation?.start).isBefore(moment(), "day") && moment(reservation?.end).isAfter(moment(), "day") && timeSlotsTime === "00:00";
    const isBeforeAndToday =
      moment(reservation?.start).isBefore(moment(), "day") && moment(reservation?.end).isSame(moment(), "day") && timeSlotsTime === "00:00";

    const startToDateMinute = moment(reservation?.end).diff(moment().startOf("day"), "minutes"); // 오늘 00:00부터부터 끝나는시점까지의 분

    if ((isTodayReservation || isBeforeAndAfter || isBeforeAndToday) && reservation.status.code === "ACKNOWLEDGED") {
      return (
        <div
          key={reservation.id}
          ref={boxRef}
          className={`reservation-box ${getStatus(reservation?.start, reservation?.end)} ${isBeforeAndAfter ? "full-reservation" : ""} ${
            isBeforeAndToday ? "before-today-reservation" : ""
          }`}
          style={{
            height: isTodayReservation
              ? (reservation?.minutes / 30) * cellHeight || 45 * 3
              : isBeforeAndAfter
              ? 2 * 25 * cellHeight || 45 * 3
              : (startToDateMinute / 30) * cellHeight + cellHeight || 45 * 3,
          }}
          onClick={() => handleReservationDetailModal("open", reservation)}
        >
          <div className="reservation-box-wrap" ref={wrapperRef}>
            <p className="reservation-box-title">{renderTitle(reservation)}</p>
            <p className="reservation-box-first-info">
              {moment(reservation.start).format("HH:mm")} - {moment(reservation.end).format("HH:mm")}
            </p>
            <p className="reservation-box-second-info ellipsis">{renderAttendeeInfo(reservation)}</p>
            {reservation.minutes / 30 >= 3 && reservation.mbOrganizationName && (
              <p className="reservation-box-third-info">
                {queryParams.companyName === "public"
                  ? reservation.mbOrganizationName
                  : queryParams.companyName === "restricted"
                  ? maskString(reservation.mbOrganizationName, 1)
                  : ""}
              </p>
            )}
          </div>
        </div>
      );
    }
  };

  if (facility?.reservations) {
    return facility?.reservations?.map((reservation: any) => {
      return renderReservationBox(reservation);
    });
  }
};

export default ReservationBox;
