import { useQuery } from "@tanstack/react-query";
import { getContractAcGroupManageAsync } from "src/api/access/ac-group-api";
import { AcGroupContractManageModel } from "src/api/access/ac-group-types";
import { useApiOperation } from "src/api/hooks";

/**
 * 계약 관리 출입 설정 상세 조회(커스텀 권한 조회)
 * @param contractManageId 계약 관리 id
 * @returns 계약 관리 출입 설정 상세 조회 데이터
 */
const useGetPermissionAcGroup = (contractManageId?: number) => {
  const { executeAsync: getContractAcGroup } = useApiOperation(getContractAcGroupManageAsync);

  const query = useQuery<{ contractManage: AcGroupContractManageModel }>({
    queryKey: ["contractAcGroup", contractManageId],
    queryFn: async () => {
      if (!contractManageId) throw new Error("contractManageId is required");
      const { data } = await getContractAcGroup({ contractManageId });
      return data.data;
    },
    enabled: !!contractManageId,
  });

  return { ...query, visitorDefaultAccessGroupYn: query.data?.contractManage.visitorDefaultAccessGroupYn };
};

export default useGetPermissionAcGroup;
