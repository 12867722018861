import { visitorAccessType } from "src/api/access/ac-group-types";

export const accessGroupTabs = [
  {
    value: "basicInfo",
    label: "기본정보",
  },
];

export const accessGroupSearchType = [
  {
    value: "",
    label: "전체",
  },
  {
    value: "id",
    label: "id",
  },
  {
    value: "accessGroupName",
    label: "출입그룹명",
  },
];

type RadioOption = {
  label: string;
  value: string | boolean;
};
// 라디오 버튼 그룹 타입 정의
type RadioGroupConfig = {
  title: string;
  name: string;
  options: RadioOption[];
  defaultValue?: string | boolean;
  tooltip?: string;
};

export const visitorSettings: RadioGroupConfig[] = [
  {
    title: "Taap 노출",
    name: "visitorAccessGroupSelectableYn",
    options: [
      { label: "노출", value: true },
      { label: "노출안함", value: false },
    ],
    tooltip: "노출하지 않는 경우, [Taap > 방문자 초대 > 출입권한]에 해당 출입그룹이 표시되지 않습니다.",
    defaultValue: false,
  },
  {
    title: "관리자 승인",
    name: "visitorInvitationApprovalYn",
    options: [
      { label: "자동승인", value: false },
      { label: "승인필요", value: true },
    ],
    tooltip: "[방문자 초대 > 출입권한]에서 '승인필요'한 출입그룹을 포함하여 초대하는 경우, 관리자 승인 후에 방문자에게 초대장이 발송됩니다.",
    defaultValue: false,
  },
  {
    title: "QR 발급",
    name: "visitorAccessType",
    options: [
      { label: "발급", value: visitorAccessType.VISITOR_MUTABLE },
      { label: "미발급", value: visitorAccessType.VISITOR_NO_MUTABLE },
    ],
    tooltip: "미발급하는 경우, 방문자에게 QR코드 비활성화된 초대장이 발송됩니다.",
    defaultValue: visitorAccessType.VISITOR_NO_MUTABLE,
  },
];
