import { useCallback, useMemo, useState } from "react";
import { BaseButton, BaseInput } from "src/components";

import SpaceBaseTable from "src/pages/product/forms/commonSpaceForm/components/SpaceBaseTable";
import { useModal } from "src/recoil/modalState/hook";
import useCsnkey from "../hooks/useCsnkey";
import BaseNewTabLink from "src/components/BaseNewTabLink";
import pagePath from "src/pagePath.json";
import { ContractVisitor } from "src/api/visitor/visitor-types";
import { useErrorModal } from "src/recoil/errorModal/hook";
import { BULLET } from "src/utils";

const columns = [
  {
    Header: "방문자 Id",
    accessor: "contractVisitorId",
    width: 160,
  },
  {
    Header: "이름",
    accessor: "visitorName",
    width: 160,
  },
  {
    Header: "방문자 연락처",
    accessor: "visitorMobileNumber",
    width: 178,
  },
  {
    Header: "방문자 이메일",
    accessor: "visitorEmail",
    width: 178,
  },
  {
    Header: "키카드 번호",
    accessor: "cardNumber",
    width: 264,
    Cell: ({ cardNumber, setCardNumber }: { cardNumber: string; setCardNumber: (value: string) => void }) => {
      return (
        <div>
          <BaseInput
            placeholder="6~50자 이내로 입력해 주세요."
            value={cardNumber}
            maxLength={50}
            onChange={(value: string) => {
              setCardNumber(value);
            }}
          />
        </div>
      );
    },
  },
];

interface CnskeyModalProps {
  contractVisitor: ContractVisitor;
}

const CsnkeyModal = ({ contractVisitor }: CnskeyModalProps) => {
  const { setAbstractModalZ1, setBaseModal } = useModal();
  const { openErrorModal } = useErrorModal();

  //키카드 관련 훅
  const { saveCardNumber, csnkeyList } = useCsnkey([contractVisitor.contractVisitorId?.toString() || ""]);

  const handleCancel = useCallback(() => {
    setAbstractModalZ1({ isOpen: false });
  }, [setAbstractModalZ1]);

  const [cardNumber, setCardNumber] = useState<string>(
    csnkeyList?.data?.data?.content?.find((item) => String(item.contractVisitorId) === String(contractVisitor.contractVisitorId))?.accessToken || "",
  );

  const handleSave = () => {
    if (!cardNumber) {
      alert("카드 번호를 입력해주세요.");
      return;
    }

    saveCardNumber(
      { contractVisitorId: contractVisitor.contractVisitorId?.toString() || "", accessToken: cardNumber },
      {
        onSuccess: (data) => {
          // 고정 CSN 저장 시 반환되는 에러 409
          // 다른 방문자가 사용중 errorCode: eAC106
          // "errorData": {
          //   "contractApplyNumber": "1464581055",
          //   "contractVisitorId": 4031,
          //   "visitApplyNumber": "2011429183"
          // },
          // 다른 이용자가 사용중 errorCode: eAC107
          // "errorData": {
          //   "memberNo": "1008755197"
          // },
          // 다른 파트너가 사용중 errorCode: eAC105
          // "errorData": null,

          console.log("data onSuccess :>> ", data);

          if (data?.status === 200) {
            //성공 모달 닫기
            setAbstractModalZ1({ isOpen: false });
          } else if (data?.data?.meta?.errorCode === "eAC106") {
            //실패 케이스
            setBaseModal({
              isOpen: true,
              title: "다른 방문자가 사용중인 키카드입니다.",
              children: (
                <div className="key-card-modal">
                  <p className="text-primary3 title">아래의 방문자에서 키카드 삭제 후 사용할 수 있습니다.</p>
                  <div className="link-warp mt20">
                    <span className="link-before">{BULLET}</span>
                    <BaseNewTabLink
                      value={`방문자id : ${data.data.meta.errorData?.contractVisitorId}`}
                      path={pagePath.visitor.detail
                        .replace(":contractApplyNumber", data.data.meta.errorData?.contractApplyNumber || "")
                        .replace(":visitApplyNumber", data.data.meta.errorData?.visitApplyNumber || "")}
                    />
                  </div>
                </div>
              ),
              btnRightTitle: "확인",
              onClose: () => {
                setBaseModal({ isOpen: false });
              },
            });
          } else if (data?.data?.meta?.errorCode === "eAC107") {
            setBaseModal({
              isOpen: true,
              title: "다른 이용자가 사용중인 키카드입니다.",
              children: (
                <div className="key-card-modal">
                  <p className="text-primary3 title">{`이용자가 직접 카드 정보를 삭제해야 합니다. (Taap > 마이 > 출입카드 등록 > 삭제)`}</p>
                  <div className="link-warp mt20">
                    <span className="link-before">{BULLET}</span>
                    <BaseNewTabLink
                      value={`이용자id : ${data.data.meta.errorData?.memberNo}`}
                      path={pagePath.user.list + "?searchType=MEMBER_NO&searchValue=" + data.data.meta.errorData?.memberNo}
                    />
                  </div>
                </div>
              ),
              btnRightTitle: "확인",
              onClose: () => {
                setBaseModal({ isOpen: false });
              },
            });
          } else if (data?.data?.meta?.errorCode === "eAC105") {
            setBaseModal({
              isOpen: true,
              title: "다른 파트너에서 사용중인 키카드입니다.",
              children: (
                <div>
                  <p className="text-primary3">일련번호 중복으로 해당 카드는 사용하실 수 없습니다. 핀포인트 담당자에게 문의하시기 바랍니다.</p>
                </div>
              ),
              btnRightTitle: "확인",
              onClose: () => {
                setBaseModal({ isOpen: false });
              },
            });
          } else {
            openErrorModal({ errorCode: data?.data?.meta?.errorCode, statusCode: data?.status, errorMessage: data?.data?.meta?.errorMessage });
          }
        },
      },
    );
  };

  return (
    <article className="base-abstract-modal">
      <section className="base-abstract-modal__title pb20">
        <h1>키카드 할당 설정</h1>
      </section>
      <section className="base-abstract-modal__contents-wrap px30">
        <div className="mb20 font14 text-primary3">
          <p>방문자에게 발급할 키카드 번호를 입력할 수 있습니다.</p>
          <p>카드 리더기에 키카드를 태그하거나 카드 시리얼 번호를 직접 입력 후 저장해주세요.</p>
        </div>

        <p className="py20 font18 font-weight-semibold">방문자 정보</p>
        <SpaceBaseTable columns={columns} data={[contractVisitor]} cardNumber={cardNumber} setCardNumber={setCardNumber} />
      </section>
      <section className="base-abstract-modal__btn-wrap">
        <BaseButton title="취소" onClick={handleCancel} className="color-white" />
        <BaseButton disabled={cardNumber.length < 6} title="저장" onClick={handleSave} />
      </section>
    </article>
  );
};

export default CsnkeyModal;
