import _ from "lodash";
import moment from "moment";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { ServiceTypes } from "src/api/adminMemo/adminmemo-types";
import { getContractOperationInfo } from "src/api/contract/contract-api";
import { useApiOperation } from "src/api/hooks";
import { deleteReservationAsync, getReservationListAsync } from "src/api/reservation/reservation-api";
import {
  addUsers,
  delUser,
  getUser,
  resolveWatchCalendar,
  retryWatchCalendar,
  subscribeCalendar,
  unWatchCalendar,
  watchCalendar,
} from "src/api/user/user-api";
import { MemberStatus, UserDetailModel } from "src/api/user/user-types";
import { deleteVisitorByInviter, getVisitorsAsync } from "src/api/visitor/visitor-api";
import { BaseButton, BaseModal, ContentsTitle } from "src/components";
import AdminMemoV3 from "src/components/AdminMemoV3/AdminMemoV3";
import BaseNewTabLink from "src/components/BaseNewTabLink";
import GoToListButton from "src/components/GoToListButton";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";
import MetaTag from "src/components/layout/MetaTag";
import RegistrationInformation from "src/components/RegistrationInformation";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import useNavigate from "src/hooks/usePartnerNavigate";
import PagePath from "src/pagePath.json";
import { useErrorModal } from "src/recoil/errorModal/hook";
import { globalPartnerState } from "src/recoil/partners/atom";
import { useToast } from "src/recoil/toast/hook";
import { formatPhoneNumber, ViewYmdFormat, YmdFormat } from "src/utils/common-util";

const UserDetail = () => {
  const { isAuthority } = usePartnerAuthority();
  const { openErrorModal } = useErrorModal();
  const navigate = useNavigate();
  const location = useLocation();
  const tabs = [
    {
      label: "기본정보",
      value: "basic",
      disabled: false,
    },
  ];
  const query = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const pv: any = {
    contractApplyNumber: query.contractApplyNumber,
    visitStatus: "VISIT_USE",
    searchStartTime: moment().format(YmdFormat.WITH_TIME_ZONE),
    searchEndTime: "9999-12-31T23:59:59.999+09:00",
    isDeletedVisitorDisplay: false,
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const pr: any = {
    contractId: "",
    statusCode: "ACCEPTED,ACKNOWLEDGED,INCOMPLETE",
    containsOrganizerMemberNo: "",
    greaterThanEqualsEnd: "",
  };

  const { memberId: contractMemberId } = useParams();
  const { openToast } = useToast();

  const [isDelete, setIsDelete] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isDeleteReservations, setIsDeleteReservations] = useState(false);
  const [isDeleteInvitations, setIsDeleteInvitations] = useState(false);
  const [isDeleteReservations2, setIsDeleteReservations2] = useState(false);
  const [isDeleteInvitations2, setIsDeleteInvitations2] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isUpdateCheck, setIsUpdateCheck] = useState(false);
  const [isMaster, setIsMaster] = useState(false);
  const [isNotMaster, setIsNotMaster] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState("");
  const [showIssue, setShowIssue] = useState("-");
  const [isRetryInvite, setIsRetryInvite] = useState(false);

  const [user, setUser] = useState<UserDetailModel>();

  const [viewStatus, setViewStatus] = useState("");
  const [operation, setOperation] = useState<any>();
  const partner = useRecoilValue(globalPartnerState);

  const [remainingSharedSpaceReservation, setRemainingSharedSpaceReservation] = useState<Number>(0);
  const [remainingVisitorInvitations, setRemainingVisitorInvitations] = useState<Number>(0);
  const [remainingSharedSpaceReservationList, setRemainingSharedSpaceReservationList] = useState<any>();
  const [remainingVisitorInvitationsList, setRemainingVisitorInvitationsList] = useState<any>();

  const [paramsVisitor, setParamsVisitor] = useState<any>();
  const [paramsReservation, setParamsReservation] = useState<any>();

  const [visitPath, setVisitPath] = useState("");
  const [reservationPath, setReservationPath] = useState("");

  const { executeAsync: detail } = useApiOperation(getUser, { noHandleError: true });

  //잔여 방문자 초대 조회 API
  const { executeAsync: getVisitorList } = useApiOperation(getVisitorsAsync, { noHandleError: true });
  //잔여 공용공간 조회 API
  const { executeAsync: getReservationList } = useApiOperation(getReservationListAsync, { noHandleError: true });
  //잔여 방문자 초대 삭제 API
  const { executeAsync: delVisiterByInviter } = useApiOperation(deleteVisitorByInviter);
  //잔여 공용공간 삭제 API
  const { executeAsync: delReservation } = useApiOperation(deleteReservationAsync);

  const { executeAsync: dUser } = useApiOperation(delUser);
  const { executeAsync: changeCalendar } = useApiOperation(watchCalendar, {
    noHandleError: true,
  });

  const { executeAsync: changeCalendar2 } = useApiOperation(unWatchCalendar, {
    noHandleError: true,
  });

  //TODO RRV33. 이용자 캘린더 연동 처리 subscribeCalendar
  const { executeAsync: activeCalendar } = useApiOperation(subscribeCalendar, {
    noHandleError: true,
  });

  const { executeAsync: retryRelationCalendar } = useApiOperation(retryWatchCalendar, {
    noHandleError: true,
  });
  const { executeAsync: resolveRelationCalendar } = useApiOperation(resolveWatchCalendar, {
    noHandleError: true,
  });

  const { executeAsync: getOperation } = useApiOperation(getContractOperationInfo, {
    noHandleError: true,
  });

  const { executeAsync: reInviteUser } = useApiOperation(addUsers, {
    noHandleError: true,
  });

  const callOperation = useCallback(
    async (contractManageId: number) => {
      const res: any = await getOperation({ id: Number(contractManageId) });
      if (res.status >= 200 && res.status <= 299) {
        console.log("res :>> ", res.data.data.content);
        const length = res.data.data.content.productManage.clientSecretValue.length;

        res.data.data.content.productManage.clientSecretValueBlocked = "*".repeat(length);
        setOperation(res.data.data.content);
      } else {
        console.log(`res`, res);
        openErrorModal({
          statusCode: res.status,
          errorCode: res.data?.meta?.errorCode,
        });
      }
    },
    [getOperation, openErrorModal],
  );

  //회원의 공용공간 예약 목록 조회
  const getMemberReservationList = useCallback(
    async (params: any) => {
      params.contractId = user?.content.contractId;
      params.greaterThanEqualsEnd = moment().format(YmdFormat.WITH_TIME_ZONE);
      const response: any = await getReservationList(params);

      setRemainingSharedSpaceReservation(response.data.data.content.length);
      setRemainingSharedSpaceReservationList(response.data.data.content);
    },
    [getReservationList, user?.content.contractId],
  );
  //회원이 초대한 방문자의 목록을 조회
  const getMembersInvitationVisitors = useCallback(
    async (params: any) => {
      if (!params.contractId) {
        params.contractId = user?.content.contractId;
      }
      const response: any = await getVisitorList(params);
      // console.log("called!!", response);
      if (response.data.data.contractVisitor) {
        setRemainingVisitorInvitationsList(response.data.data.contractVisitor);
        setRemainingVisitorInvitations(response.data.data.contractVisitor.length || 0);
      }
    },
    [getVisitorList, user?.content.contractId],
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callDelDatas = useCallback(
    _.debounce(async () => {
      if (paramsReservation.contractId) {
        await getMemberReservationList({ ...paramsReservation, greaterThanEqualsEnd: today });
      }
      if (user?.content?.contractManageId) {
        await getMembersInvitationVisitors({ ...paramsVisitor, contractApplyNumber: query.contractApplyNumber });
      }
      // 3. 0.5초안에 호출된 가장 마지막 api만 서버로 호출합니다.
    }, 500),
    [paramsReservation, user, paramsVisitor],
  );

  const today = useMemo(() => {
    return moment().format(YmdFormat.WITH_TIME_ZONE);
  }, []);

  const callDetail = useCallback(
    async (contractMemberId: number) => {
      const res: any = await detail({ contractMemberId });
      res.data.data.contractManageId = query.contractManageId;
      if (res.status >= 200 && res.status <= 299) {
        console.log("res.data.data", res.data.data);
        if (res.data.data?.hrUser?.isExistsIssues !== undefined && String(res.data.data?.hrUser?.isExistsIssues) === "true") {
          let text = "실패 (";

          if (res.data.data?.hrUser?.isResolvable === true) {
            text = text + "재 연동 필요) ";
          } else {
            text = text + "관리자 문의) ";
          }
          text += moment(res.data.data?.hrUser?.issueOccurredDate).format(YmdFormat.FULL);
          setShowIssue(text);
        }
        if (Number(res.data.data.content.contractId) > 0) {
          callOperation(res.data.data.content.contractId);
        }

        setParamsVisitor(pv);

        setParamsReservation({
          ...pr,
          contractId: res.data.data!.content!.contractId!,
          containsOrganizerMemberNo: res.data.data!.content!.memberNo!,
        });
        const reserPath =
          PagePath.reservation.list +
          "?keyword=" +
          res.data.data!.content!.memberNo! +
          "&statusCode=ACCEPTED,ACKNOWLEDGED,INCOMPLETE" +
          "&greaterThanEqualsEnd=" +
          today +
          "&containsOrganizerMemberNo=" +
          res.data.data!.content!.memberNo! +
          "&contractId=" +
          res.data.data!.content!.contractId!;
        setReservationPath(reserPath);
        //
        setUser(res.data.data);
        if (res.data.data?.content.memberNo !== undefined && res.data.data?.content.memberNo !== "") {
          pv.inviteeMemberNo = res.data.data!.content!.memberNo!;
          const path =
            PagePath.visitor.list +
            "?contractApplyNumber=" +
            query.contractApplyNumber! +
            "&searchType=INVITEE_MEMBER_NO&searchValue=" +
            pv.inviteeMemberNo +
            "&visitStatus=VISIT_USE&searchStartTime=" +
            today +
            "&searchEndTime=" +
            pv.searchEndTime;
          setVisitPath(path);
        }

        setViewStatus(res.data.data.content.memberStatus);
      } else {
        openErrorModal({
          statusCode: res.status,
          errorCode: res.data?.meta?.errorCode,
        });
      }
    },
    [detail, query.contractManageId, query.contractApplyNumber, callOperation, pv, pr, today, openErrorModal],
  );

  const retryCalendar = useCallback(async () => {
    const response: any = await retryRelationCalendar({
      contractId: Number(user?.hrUser.contractId),
      memberNo: String(user?.content.memberNo),
      userEmail: String(user?.hrUser.email),
    });

    if (response.status >= 200 && response.status <= 299) {
      openToast({
        type: "SUCCESS",
        content: "캘린더 동기화 요청에 성공하였습니다.",
      });
      callDetail(Number(contractMemberId));
    } else {
      openToast({
        type: "FAIL",
        content: "캘린더 동기화 요청에 실패하였습니다.",
      });
    }
  }, [callDetail, contractMemberId, openToast, retryRelationCalendar, user?.content.memberNo, user?.hrUser.contractId, user?.hrUser.email]);

  const resolveCalendar = useCallback(async () => {
    const response: any = await resolveRelationCalendar({
      contractId: Number(user?.hrUser.contractId),
      memberNo: String(user?.content.memberNo),
      userEmail: String(user?.hrUser.email),
    });
    if (response.status >= 200 && response.status <= 299) {
      // setSuccess("캘린더 연동에 성공하였습니다.");
      openToast({
        type: "SUCCESS",
        content: "캘린더 동기화 요청에 성공하였습니다.",
      });
      callDetail(Number(contractMemberId));
    } else {
      openToast({
        type: "FAIL",
        content: "캘린더 동기화 요청에 실패하였습니다.",
      });
    }
  }, [callDetail, contractMemberId, openToast, resolveRelationCalendar, user?.content.memberNo, user?.hrUser.contractId, user?.hrUser.email]);

  const retryInvite = useCallback(async () => {
    setIsRetryInvite(false);

    const res: any = await reInviteUser({
      contractManageId: user?.content?.contractManageId,
      contractMemberList: [{ inviteMobileNumber: user?.content.inviteMobileNumber }],
      partnerId: partner?.id,
    });

    if (res.status >= 200 && res.status <= 299) {
      callDetail(Number(contractMemberId));
    } else {
      openErrorModal({
        statusCode: res.status,
        errorCode: res.data?.meta?.errorCode,
      });
    }
  }, [callDetail, contractMemberId, openErrorModal, partner?.id, reInviteUser, user?.content.inviteMobileNumber, user?.content?.contractManageId]);

  const onClickUpdate = useCallback(
    async (active: boolean) => {
      const res: any = await activeCalendar({
        contractId: Number(user?.hrUser.contractId),
        memberNo: String(user?.content.memberNo),
        active: active,
      });
      if (res.status >= 200 && res.status <= 400) {
        console.log("res", res, active ? "캘린더 구독이 활성화 되었습니다." : "캘린더 구독이 비활성화 되었습니다.");
        setSuccess(active ? "캘린더 구독이 활성화 되었습니다." : "캘린더 구독이 비활성화 되었습니다.");
      } else {
        openErrorModal({
          statusCode: res.status,
          errorCode: res.data?.meta?.errorCode,
        });
      }
      callDetail(Number(contractMemberId));
    },
    [activeCalendar, user?.hrUser.contractId, user?.content.memberNo, callDetail, contractMemberId, openErrorModal],
  );

  const onMasterToggle = useCallback(
    async (isAdmin: boolean | undefined) => {
      setIsMaster(false);
      setIsNotMaster(false);
      const res: any = await dUser({
        contractMemberId: Number(contractMemberId),
        contractManageId: Number(user?.content?.contractManageId),
        isAdmin: isAdmin,
        delete: false,
      });
      if (res.status >= 200 && res.status <= 299) {
        setSuccess("권한이 변경되었습니다.");
        setIsDeleted(true);
      } else {
        openErrorModal({
          statusCode: res.status,
          errorCode: res.data?.meta?.errorCode,
        });
      }
    },
    [contractMemberId, dUser, openErrorModal, user?.content?.contractManageId],
  );

  const onDelete = useCallback(async () => {
    const res: any = await dUser({
      contractMemberId: Number(contractMemberId),
      contractManageId: Number(user?.content?.contractManageId),
      delete: true,
    });
    if (res.status >= 200 && res.status <= 299) {
      setIsDelete(false);
      setSuccess("삭제 되었습니다.");
      setIsDeleted(true);
    } else {
      openErrorModal({
        statusCode: res.status,
        errorCode: res.data?.meta?.errorCode,
      });
    }
  }, [contractMemberId, dUser, openErrorModal, user?.content?.contractManageId]);

  const onDeleted = useCallback(() => {
    // const path = PagePath.user.list + "?contractApplyNumber=" + query.contractApplyNumber;
    // navigate(path);
    setIsDeleted(false);
    setIsEditable(false);
    callDetail(Number(contractMemberId));
  }, [callDetail, contractMemberId]);

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (query?.tab) {
      return tabs.find((tab) => tab.value === query?.tab);
    }
    return tabs[0];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  // 탭 클릭시 callback 함수
  const clickTab = useCallback(
    (tab: { value: string; label: string }) => {
      // console.log("clickTab", tab);
      const newQueryParams = { ...query };
      if (newQueryParams?.tab) {
        delete newQueryParams.tab;
      }

      newQueryParams.tab = tab.value;

      // query parameter 에 tab 추가
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location, query],
  );

  const delDisable = useMemo(() => {
    return Number(remainingSharedSpaceReservation) !== 0 || Number(remainingVisitorInvitations) !== 0;
  }, [remainingSharedSpaceReservation, remainingVisitorInvitations]);

  //잔여 공용공간 삭제 함수
  const deleteReservation = useCallback(async () => {
    //promise.allSettled 사용하여 삭제 시 쪼개서 순회하기
    let chunk: any = [];
    for (let i = 0; i < remainingSharedSpaceReservationList.length; i += 20) {
      const innerList: any = remainingSharedSpaceReservationList.slice(i, i + 20);
      let innerArr: any = [];

      for (let j = 0; j < innerList.length; j++) {
        // TODO 삭제 API 태울 것
        innerArr.push(delReservation({ reservationId: innerList[j].id }));
      }
      chunk.push(innerArr);
    }

    const result: any = await Promise.allSettled(chunk);
    // console.log("result", result);
    let resultCheck = false;
    let retryChunk: any = [];
    result.forEach((res: any, index: number) => {
      if (res.status !== "fulfilled") {
        res.value.forEach((innerData: any) => {
          if (innerData.status !== "fulfilled") {
            retryChunk.push(delReservation({ reservationId: innerData.data.data.reservation.id }));
          }
        }, []);
      }
    }, []);

    //실패한 건들을 한번 더 삭제를 시도
    if (retryChunk.length > 0) {
      const retryResult: any = await Promise.allSettled(retryChunk);
      retryResult.forEach((res: any, index: number) => {
        if (res.status !== "fulfilled") {
          resultCheck = true;
        }
      }, []);
    }

    // 최종적으로 성공이면
    if (resultCheck === false) {
      setIsDeleteReservations2(true);
    } else {
      //최종적으로 실패시에는? 관리자에게 문의하세요?
    }
  }, [delReservation, remainingSharedSpaceReservationList]);

  //잔여 방문자 초대 삭제 함수
  const deleteVisitors = useCallback(async () => {
    //promise.allSettled 사용하여 삭제 시 쪼개서 순회하기
    let chunk: any = [];
    for (let i = 0; i < remainingVisitorInvitationsList.length; i++) {
      chunk.push(remainingVisitorInvitationsList[i].contractVisitorId);
    }

    const rtnData: any = await delVisiterByInviter({ inviteeMemberNo: user?.content.memberNo!, contractVisitorIds: chunk });
    if (rtnData.status > 199 && rtnData.status < 399) {
      setIsDeleteInvitations2(true);
    }
  }, [delVisiterByInviter, remainingVisitorInvitationsList, user?.content.memberNo]);

  const deleteReservations = useCallback(async () => {
    setIsDeleteReservations(false);
    await deleteReservation();
  }, [deleteReservation]);
  const deleteInvitations = useCallback(async () => {
    // setRemainingVisitorInvitations(0);
    setIsDeleteInvitations(false);
    const ps: any = await deleteVisitors();
  }, [deleteVisitors]);

  useEffect(() => {
    if (contractMemberId) {
      callDetail(Number(contractMemberId));
    }
  }, [contractMemberId]);

  useEffect(() => {
    if (isDelete || isDeleteReservations2 || isDeleteInvitations2) {
      callDelDatas();
    }
  }, [callDelDatas, isDelete, isDeleteInvitations2, isDeleteReservations2]);

  return (
    <>
      <MetaTag subTitle={String(user?.content.memberNo)} />
      <div>
        <ContentsTitle
          title="이용자 초대"
          tabs={tabs}
          activeTab={activeTab}
          clickTab={clickTab}
          detailInfo={[
            { title: "id", value: contractMemberId! },
            { title: "신청번호", value: String(query?.contractApplyNumber!) },
          ]}
        />
        <div className="contents-container__scroll">
          <div className="contents-container__wrap page-user-form">
            {activeTab!.value === "basic" && (
              <>
                <article className="contents-container__wrap-article">
                  <BaseSectionTitle title={"기본정보"} />
                  <section className="contents-container__grid">
                    <div className="contents-container__grid-index">
                      <p>회원번호</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <div>{user?.content.memberNo ? <p>{user?.content.memberNo}</p> : "-"}</div>
                    </div>
                  </section>

                  <section className="contents-container__grid">
                    <div className="contents-container__grid-index">
                      <p>휴대폰 번호</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <p>{formatPhoneNumber(user?.content.inviteMobileNumber!)}</p>
                    </div>
                  </section>

                  <section className="contents-container__grid">
                    <div className="contents-container__grid-index">
                      <p>이메일</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <div>
                        <p>{user?.hrUser.email ? user?.hrUser.email : "-"}</p>
                      </div>
                    </div>
                  </section>
                  <section className="contents-container__grid">
                    <div className="contents-container__grid-index">
                      <p>이메일 인증</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <div>{user?.hrUser.isEmailVerified ? <p>{user?.hrUser.isEmailVerified ? "인증" : "미인증"}</p> : <p>{"-"}</p>}</div>
                    </div>
                  </section>

                  <section className="contents-container__grid">
                    <div className="contents-container__grid-index">
                      <p>닉네임</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <div>
                        <p>{user?.hrUser.nickname || "-"}</p>
                      </div>
                    </div>
                  </section>

                  <section className="contents-container__grid">
                    <div className="contents-container__grid-index">
                      <p>상태</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <div className="flex-center-start flex-row">
                        <p
                          className={`${
                            (viewStatus === "MEMBER_INVITE" && "status A") ||
                            (viewStatus === "MEMBER_USE" && "status B") ||
                            (viewStatus === "MEMBER_DELETE" && "status D") ||
                            (viewStatus === "MEMBER_CLOSE" && "status D") ||
                            (viewStatus === "MEMBER_EXPIRE" && "status D")
                          }`}
                        >
                          {viewStatus === MemberStatus.MEMBER_INVITE && "초대"}
                          {viewStatus === MemberStatus.MEMBER_USE && "사용"}
                          {viewStatus === MemberStatus.MEMBER_DELETE && "삭제"}
                          {viewStatus === MemberStatus.MEMBER_CLOSE && "종료"}
                          {viewStatus === MemberStatus.MEMBER_EXPIRE && "만료"}
                        </p>
                        {isAuthority("w") && viewStatus === MemberStatus.MEMBER_EXPIRE && (
                          <BaseButton title="재 초대" onClick={() => setIsRetryInvite(true)} className="color-white ml10" />
                        )}
                      </div>
                    </div>
                  </section>

                  <section className="contents-container__grid">
                    <div className="contents-container__grid-index">
                      <p>초대일시</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <p>{user?.content?.inviteDate ? moment(user?.content?.inviteDate).format(ViewYmdFormat.FULL) : "-"}</p>
                    </div>
                  </section>

                  <section className="contents-container__grid">
                    <div className="contents-container__grid-index">
                      <p>가입일시</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <p>{user?.hrUser?.signUpTime ? moment(user?.hrUser?.signUpTime).format(ViewYmdFormat.FULL) : "-"}</p>
                    </div>
                  </section>

                  <section className="contents-container__grid">
                    <div className="contents-container__grid-index">
                      <p>초대자</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <div className="flex-row flex-center-start">
                        <span>
                          {user?.inviteeInfo?.inviteeMemberId
                            ? user?.inviteeInfo?.inviteeMemberId
                            : user?.inviteeInfo?.inviteeMemberNo
                            ? user?.inviteeInfo?.inviteeMemberNo
                            : "-"}
                        </span>
                        <span className="ml10">
                          (
                          {user?.inviteeInfo?.inviteeMemberId
                            ? "Central"
                            : formatPhoneNumber(user?.inviteeInfo?.inviteePhoneNumber!)
                            ? user?.inviteeInfo?.inviteePhoneNumber
                            : "-"}
                          )
                        </span>
                      </div>
                    </div>
                  </section>
                  {operation && operation?.productManage?.isUseCalendar === true && (
                    <div>
                      <section className="contents-container__grid">
                        <div className="contents-container__grid-index">
                          <p>캘린더 연동</p>
                        </div>
                        <div className="contents-container__grid-contents">
                          {isEditable === false ? (
                            <div>
                              <p>{user?.hrUser?.isWatchingCalendar ? "연동" : "미연동"}</p>
                            </div>
                          ) : (
                            <div className="flex-center-start flex-row">
                              <span className="mr10">{user?.hrUser?.isWatchingCalendar ? "연동" : "미연동"}</span>

                              <BaseButton
                                title={user?.hrUser?.isWatchingCalendar ? "미연동" : "연동"}
                                onClick={() => (user?.hrUser?.isWatchingCalendar === false ? onClickUpdate(true) : onClickUpdate(false))}
                                className="color-white"
                              />
                            </div>
                          )}
                        </div>
                      </section>
                      {user?.hrUser?.isWatchingCalendar && (
                        <section className="contents-container__grid">
                          <div className="contents-container__grid-index">
                            <p>캘린더 동기화</p>
                          </div>
                          <div className="contents-container__grid-contents">
                            {isEditable === false ? (
                              <div>
                                <p>{showIssue}</p>
                              </div>
                            ) : (
                              <div className="flex-center-start flex-row">
                                <span className="mr10">
                                  <p>{showIssue}</p>
                                </span>

                                {user?.hrUser?.isExistsIssues !== undefined && user?.hrUser?.isExistsIssues === false && (
                                  <BaseButton title="재시도" onClick={() => retryCalendar()} className="color-white" />
                                )}
                                {user?.hrUser?.isExistsIssues !== undefined &&
                                  user?.hrUser?.isExistsIssues === true &&
                                  user?.hrUser?.isResolvable === true && (
                                    <BaseButton title="재시도" onClick={() => resolveCalendar()} className="color-white" />
                                  )}
                              </div>
                            )}
                          </div>
                        </section>
                      )}
                    </div>
                  )}
                  <section className="contents-container__grid">
                    <div className="contents-container__grid-index">
                      <p>권한</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      {isEditable === false ? (
                        <div>
                          <p>
                            {user?.content?.isAdmin === false && user?.content?.isContractor === false && "없음"}
                            {user?.content?.isAdmin === false && user?.content?.isContractor === true && "계약자"}
                            {user?.content?.isAdmin === true && user?.content?.isContractor === true && "계약자, 마스터"}
                            {user?.content?.isAdmin === true && user?.content?.isContractor === false && "마스터"}
                          </p>
                        </div>
                      ) : (
                        <div className="flex-center-start flex-row">
                          <span className="mr10">
                            <div className="flex-center-start flex-row">
                              <div>{user?.content?.isAdmin === false && user?.content?.isContractor === false && "없음"}</div>

                              <div className="flex-center">
                                {user?.content?.isAdmin === false && user?.content?.isContractor === true && "계약자"}
                              </div>

                              <div className="flex-center">
                                {user?.content?.isAdmin === true && user?.content?.isContractor === true && "계약자, 마스터"}
                              </div>

                              <div className="flex-center">
                                {user?.content?.isAdmin === true && user?.content?.isContractor === false && "마스터"}
                              </div>
                              {user?.content?.isContractor === false && (
                                <BaseButton
                                  title={user?.content?.isAdmin === false ? "마스터 지정" : "마스터 해제"}
                                  onClick={() => {
                                    if (user?.content?.isAdmin === true) {
                                      setIsNotMaster(true);
                                    } else {
                                      setIsMaster(true);
                                    }
                                  }}
                                  className="ml10 color-white"
                                />
                              )}
                            </div>
                          </span>
                        </div>
                      )}
                    </div>
                  </section>
                </article>
                <RegistrationInformation data={user?.content} />

                {isEditable === false && <AdminMemoV3 serviceId={Number(contractMemberId)} serviceType={ServiceTypes.SERVICE_INVITED_USER} />}
              </>
            )}
          </div>
        </div>
        <BaseModal
          title="이용자를 삭제하시겠습니까?"
          isOpen={isDelete}
          btnLeftTitle={"취소"}
          btnRightTitle={"삭제"}
          btnRightDisable={delDisable}
          onClose={() => setIsDelete(false)}
          onClick={onDelete}
          children={
            <div>
              <div>
                잔여 공용공간 예약 및 방문자 초대가 없는 경우
                <br /> 이용자를 삭제할 수 있습니다.
              </div>

              <div className="flex-center-between minmax290 mt20" style={{ backgroundColor: "#F2F4F6", height: 40, padding: 10 }}>
                <div>
                  잔여 공용공간 예약 :{" "}
                  {Number(remainingSharedSpaceReservation) > 0 ? (
                    <BaseNewTabLink path={reservationPath} value={remainingSharedSpaceReservation + "건"}></BaseNewTabLink>
                  ) : (
                    remainingSharedSpaceReservation + "건"
                  )}
                </div>
                <button
                  className="base-trash-btn color-gray size-20"
                  disabled={Number(remainingSharedSpaceReservation) === 0}
                  onClick={(e) => {
                    e.preventDefault();
                    setIsDeleteReservations(true);
                  }}
                ></button>
              </div>

              <div className="flex-center-between minmax290 mt10" style={{ backgroundColor: "#F2F4F6", height: 40, padding: 10 }}>
                <div>
                  잔여 방문자 초대 :{" "}
                  {Number(remainingVisitorInvitations) > 0 ? (
                    <BaseNewTabLink path={visitPath} value={remainingVisitorInvitations + "건"}></BaseNewTabLink>
                  ) : (
                    remainingVisitorInvitations + "건"
                  )}
                </div>
                <button
                  className="base-trash-btn color-gray size-20"
                  disabled={Number(remainingVisitorInvitations) === 0}
                  onClick={(e) => {
                    e.preventDefault();
                    setIsDeleteInvitations(true);
                  }}
                ></button>
              </div>
            </div>
          }
        />

        <BaseModal title={success} isOpen={isDeleted} btnRightTitle={"확인"} onClick={onDeleted} />
        <BaseModal title={message} isOpen={isUpdateCheck} btnRightTitle={"확인"} onClick={() => setIsUpdateCheck(false)} />
        {/* 공용공간 삭제 */}
        <BaseModal
          title="해당 이용자의 잔여 공용공간 예약을 모두 삭제하시겠습니까?"
          isOpen={isDeleteReservations}
          btnLeftTitle={"취소"}
          btnRightTitle={"삭제"}
          onClose={() => setIsDeleteReservations(false)}
          onClick={() => deleteReservations()}
        />

        {/* 방문자 초대 삭제 */}
        <BaseModal
          title="해당 이용자의 잔여 방문자 초대를 모두 삭제하시겠습니까?"
          isOpen={isDeleteInvitations}
          btnLeftTitle={"취소"}
          btnRightTitle={"삭제"}
          onClose={() => setIsDeleteInvitations(false)}
          onClick={() => deleteInvitations()}
        />

        <BaseModal
          title="해당 이용자의 잔여 공용공간 예약이 모두 삭제되었습니다."
          isOpen={isDeleteReservations2}
          btnRightTitle={"확인"}
          onClick={() => setIsDeleteReservations2(false)}
        />

        {/* 방문자 초대 삭제 */}
        <BaseModal
          title="해당 이용자의 잔여 방문자 초대가 모두 삭제되었습니다."
          isOpen={isDeleteInvitations2}
          btnRightTitle={"확인"}
          onClick={() => setIsDeleteInvitations2(false)}
        />

        <BaseModal
          title="마스터 지정하시겠습니까?"
          isOpen={isMaster}
          btnLeftTitle={"취소"}
          btnRightTitle={"확인"}
          onClose={() => setIsMaster(false)}
          onClick={() => onMasterToggle(true)}
        >
          <div>
            <p>해당 이용자는 마스터가 되면, 다른 이용자를 초대/삭제할 수 있는 권한을 가지게 됩니다.</p>
          </div>
        </BaseModal>
        <BaseModal
          title="마스터 해제하시겠습니까?"
          isOpen={isNotMaster}
          btnLeftTitle={"취소"}
          btnRightTitle={"확인"}
          onClose={() => setIsNotMaster(false)}
          onClick={() => onMasterToggle(false)}
        >
          <div>
            <p>해당 이용자는 즉시 다른 이용자를 초대/삭제할 수 있는 권한이 없어지게 됩니다.</p>
          </div>
        </BaseModal>
        <BaseModal
          title="재 초대를 하시겠습니까?"
          isOpen={isRetryInvite}
          btnLeftTitle={"취소"}
          btnRightTitle={"확인"}
          onClose={() => setIsRetryInvite(false)}
          onClick={() => retryInvite()}
        />
        <div className="contents-container__btn-wrap">
          <div className="left-area d-flex">
            {isEditable ? null : <GoToListButton title={"목록으로"} />}

            <div className="right-area">
              {isAuthority("d") &&
                (((user?.content?.isContractor !== true && user?.content?.memberStatus === MemberStatus.MEMBER_USE) ||
                  user?.content?.memberStatus === MemberStatus.MEMBER_INVITE) &&
                !isEditable ? (
                  <BaseButton title="삭제" onClick={() => setIsDelete(true)} className="no-outline color-white size-large text-red900 ml10" />
                ) : (
                  (viewStatus === MemberStatus.MEMBER_INVITE || viewStatus === MemberStatus.MEMBER_USE) && (
                    <div className="ml40">
                      <p className="contents-container__sub-title-info">{"계약자는 이용자 삭제가 되지 않습니다."}</p>
                    </div>
                  )
                ))}
            </div>
          </div>
          <div className="right-area">
            {isAuthority("w") && user?.content?.memberStatus === MemberStatus.MEMBER_USE && isEditable === false && (
              <BaseButton title="수정" onClick={() => setIsEditable(true)} className="size-large" />
            )}
            {user?.content?.memberStatus === MemberStatus.MEMBER_USE && isEditable === true && (
              <>
                <BaseButton title={"수정취소"} className="color-white size-large mr10" onClick={() => setIsEditable(false)} />
                <BaseButton title="저장" className="size-large ml10" onClick={() => setIsEditable(false)} />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default UserDetail;
