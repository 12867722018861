import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { editContractVisitorAcGroupManageAsync } from "src/api/access/ac-group-api";
import { Ac2AccessGroupModel } from "src/api/access/ac-group-types";
import { getAccessGroupListAsync } from "src/api/access/productac-api";
import { useApiOperation } from "src/api/hooks";
import { BaseButton } from "src/components";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import SelectAcGroupModal from "src/pages/product/forms/accessLevelForm/modals/SelectAcGroupModal";
import SpaceBaseTable from "src/pages/product/forms/commonSpaceForm/components/SpaceBaseTable";
import { useModal } from "src/recoil/modalState/hook";
import useGetPermissionAcGroup from "../hooks/useGetPermissionAcGroup";
import useVisitor from "../hooks/useVisitor";
import { acGroupColumns, editTypeDeleteCol } from "./acColumns";

/**
 * 방문자 출입그룹 수정시에만 사용하는 컴포넌트
 */
const VisitorEditAccessGroup = () => {
  const { isAuthority } = usePartnerAuthority();
  const { setBaseModal } = useModal();

  const { contractApplyNumber, visitApplyNumber } = useParams();

  const { visitorGroup } = useVisitor({
    contractApplyNumber: contractApplyNumber,
    visitApplyNumber: visitApplyNumber,
  });

  const [showSelectModal, setShowSelectModal] = useState(false);

  // 출입그룹 목록
  const [accessGroupList, setAccessGroupList] = useState<Ac2AccessGroupModel[]>([]);

  const newColumns = useMemo(() => {
    /* 방문상태가 종료,삭제일때 수정불가 */
    if (visitorGroup?.visitStatus !== "VISIT_CLOSE" && visitorGroup?.visitStatus !== "VISIT_DELETE") {
      // 대기 상태일때 삭제 가능
      if (visitorGroup?.approvalStatus === "APPROVAL_PENDING" && isAuthority("d")) {
        return [...acGroupColumns, ...editTypeDeleteCol];
      }
    }

    return acGroupColumns;
  }, [visitorGroup?.approvalStatus, isAuthority, visitorGroup?.visitStatus]);

  // 계약 관리 출입 설정 상세 조회(커스텀 권한 조회)
  const { visitorDefaultAccessGroupYn } = useGetPermissionAcGroup(Number(visitorGroup?.contractManageId));

  //출입그룹 목록 조회
  const { executeAsync: getAccessList } = useApiOperation(getAccessGroupListAsync);

  const { executeAsync: editContractVisitorAcGroup } = useApiOperation(editContractVisitorAcGroupManageAsync);

  const fetchContractAccess = useCallback(async () => {
    if (visitorDefaultAccessGroupYn === false) {
      const { data } = await getAccessList({
        contractVisitorGroupId: visitorGroup?.contractVisitorGroupId?.toString(),
        visitorAccessYn: true,
      });
      setAccessGroupList(data?.data.content || []);
    }
  }, [getAccessList, visitorDefaultAccessGroupYn, visitorGroup?.contractVisitorGroupId]);

  // 출입그룹 추가 핸들러
  const handleAddAccessGroups = useCallback(
    async (selectedGroups: Ac2AccessGroupModel[]) => {
      // 이미 존재하는 그룹은 id는 제외하고 보내기
      const fillteredAccessGroupList = selectedGroups.filter((group) => !accessGroupList.some((g) => g.id === group.id));

      console.log("contractVisitorGroupId :>> ", visitorGroup?.contractVisitorGroupId);

      await editContractVisitorAcGroup({
        contractVisitorGroupId: visitorGroup?.contractVisitorGroupId?.toString() || "",
        accessGroupAddIds: fillteredAccessGroupList.map((group) => group.id),
      });

      //출입그룹 리프래시
      await fetchContractAccess();
      setShowSelectModal(false);
    },
    [accessGroupList, editContractVisitorAcGroup, fetchContractAccess, visitorGroup?.contractVisitorGroupId],
  );

  useEffect(() => {
    // 출입 그룹 조회
    fetchContractAccess();
  }, [fetchContractAccess]);

  // 출입그룹 삭제 핸들러
  const handleDeleteAccessGroup = useCallback(
    async (deleteId: number) => {
      // 이미 존재하는 그룹은 id는 제외하고 보내기

      setBaseModal({
        isOpen: true,
        title: "해당 출입그룹을 삭제합니다",
        children: "삭제한 즉시 해당 출입그룹에 액세스 할 수 없습니다",
        btnLeftTitle: "취소",
        btnRightTitle: "확인",
        onClick: async () => {
          await editContractVisitorAcGroup({
            contractVisitorGroupId: visitorGroup?.contractVisitorGroupId?.toString() || "",
            accessGroupDeleteIds: [Number(deleteId)],
          });

          //출입그룹 리프래시
          await fetchContractAccess();
          setShowSelectModal(false);
        },
      });
    },
    [editContractVisitorAcGroup, fetchContractAccess, visitorGroup?.contractVisitorGroupId],
  );

  return (
    <article className="contents-container__wrap-article">
      <div className="contents-container__sub-title mb0">
        <div className="flex-center-between w-100">
          <BaseSectionTitle title={"출입그룹 관리"} className="pb0" />
          <div className="contents-container__grid-contents flex-center">
            <div className="">
              {/* 종료,삭제일때 수정불가 */}
              {visitorGroup?.visitStatus !== "VISIT_CLOSE" && visitorGroup?.visitStatus !== "VISIT_DELETE" && (
                <>
                  {isAuthority("w") && visitorGroup?.approvalStatus === "APPROVAL_PENDING" && (
                    // 대기 상태일때 추가 가능
                    <BaseButton
                      title="+ 출입그룹 추가"
                      onClick={() => {
                        setShowSelectModal(true);
                      }}
                    />
                  )}
                </>
              )}

              {showSelectModal && visitorGroup?.contractManageId && (
                <SelectAcGroupModal
                  setShowModal={setShowSelectModal}
                  onSelectedAccessGroups={handleAddAccessGroups}
                  existingAccess={accessGroupList}
                  contractManageId={visitorGroup?.contractManageId?.toString()}
                  visitorAccessYn={true}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <section>
        <SpaceBaseTable columns={newColumns} data={accessGroupList} handleDeleteAccessGroup={handleDeleteAccessGroup} />
      </section>
    </article>
  );
};

export default VisitorEditAccessGroup;
