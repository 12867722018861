import React from "react";
import { Controller, useForm } from "react-hook-form";
import { BaseButton, BaseRadio, BaseToggle, BaseTooltip } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import { PagePath } from "src/pages/product/details";
import { CalendarSetting, FORM_NAMES } from "../../reservation-types";
import { useRecoilValue } from "recoil";
import { globalPartnerState } from "src/recoil/partners/atom";

type Props = {
  onClose: () => void;
};

const CalendarModal = ({ onClose }: Props) => {
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors, defaultValues },
  } = useForm<CalendarSetting>({
    defaultValues: {
      transportType: false,
      reservationName: "restricted", // restricted, private
      attendeeInfo: "restricted",
      companyName: "restricted",
    },
  });
  const partner = useRecoilValue(globalPartnerState);

  const onSubmit = (data: CalendarSetting) => {
    const newData = data.transportType ? { ...data, transportType: "on" } : { ...data, transportType: "off" };
    const queryParams = new URLSearchParams(newData);

    const pathWithQueryString = `${PagePath.reservation.calendar.replace(":partnerId", String(partner?.id))}?${queryParams.toString()}`;
    console.log(pathWithQueryString);
    window.open(pathWithQueryString, "_blank");
    onClose();
  };

  return (
    <BaseAbstractModal isOpen={true} size="medium">
      <form onSubmit={handleSubmit(onSubmit)}>
        <section className="base-abstract-modal__title flex-column flex-start border-bottom">
          <h1>공간 예약 현황 설정</h1>
        </section>
        <section className="base-abstract-modal__contents px30">
          <section className="base-abstract-modal__contents-subtitle">
            <h2>화면 이동 설정</h2>
          </section>
          <div className="d-flex mb20">
            <div className="flex-center minmax145 font14 font-weight-600 text-primary3">
              <p>자동 페이지 이동</p>
              <BaseTooltip
                touchIcon={"QUESTION"}
                className="ml4"
                contents={`터치를 지원하지 않는 모니터를 사용하실 경우 이 기능을 활성화하면 30초마다 자동으로 다음 페이지로 이동됩니다.\n
단, 설정 기능만 사용할 수 있으며, 스크롤이나 예약 상세 보기 기능은 제한됩니다.`}
              />
            </div>
            <div className="flex-center">
              <Controller
                control={control}
                name={"transportType"}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <BaseToggle
                      onChange={(checked: boolean) => {
                        onChange(checked);
                      }}
                      checked={value}
                      name={name}
                    />
                  );
                }}
              ></Controller>
            </div>
          </div>
        </section>
        <section className="base-abstract-modal__contents px30">
          <section className="base-abstract-modal__contents-subtitle mt0">
            <h2>예약 정보 보안 설정</h2>
          </section>
          <div className="d-flex h-40">
            <div className="flex-center minmax145 font14 font-weight-600 text-primary3">
              <p>예약명</p>
            </div>
            <div className="flex-center">
              <Controller
                control={control}
                name={FORM_NAMES.RESERVATION_NAME}
                render={({ field }) => {
                  return (
                    <div className="flex-row flex-center-start">
                      {[
                        { value: "public", label: "공개" },
                        { value: "restricted", label: "일부공개" },
                        { value: "private", label: "비공개" },
                      ].map((data, index) => (
                        <div className="flex-center" key={index}>
                          <BaseRadio
                            id={FORM_NAMES.RESERVATION_NAME + data.label}
                            name={FORM_NAMES.RESERVATION_NAME}
                            label={data.label}
                            onChange={(e) => {
                              if (e) {
                                setValue(FORM_NAMES.RESERVATION_NAME, data.value);
                              }
                            }}
                            checked={field.value === data.value}
                            className={data.value === "restricted" ? "" : "mr10"}
                          />
                          {data.value === "restricted" && (
                            <BaseTooltip
                              touchIcon={"QUESTION"}
                              preformatted
                              className="mr10 cursor-pointer"
                              contents={`첫 3글자를 제외하고 모두 가려집니다. \n예) 고객사 미팅 → 고객사****`}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  );
                }}
              />
            </div>
          </div>
          <div className="d-flex h-40">
            <div className="flex-center minmax145 font14 font-weight-600 text-primary3">
              <p>주최자/참석자 정보</p>
            </div>
            <div className="flex-center">
              <Controller
                control={control}
                name={FORM_NAMES.ATTENDEE_INFO}
                render={({ field }) => {
                  return (
                    <div className="flex-row flex-center-start">
                      {[
                        { value: "public", label: "공개" },
                        { value: "restricted", label: "일부공개" },
                        { value: "private", label: "비공개" },
                      ].map((data, index) => (
                        <div className="flex-center" key={index}>
                          <BaseRadio
                            key={index}
                            id={FORM_NAMES.ATTENDEE_INFO + data.label}
                            name={FORM_NAMES.ATTENDEE_INFO}
                            label={data.label}
                            onChange={(e) => {
                              if (e) {
                                setValue(FORM_NAMES.ATTENDEE_INFO, data.value);
                              }
                            }}
                            checked={field.value === data.value}
                            className={data.value === "restricted" ? "" : "mr10"}
                          />
                          {data.value === "restricted" && (
                            <BaseTooltip
                              touchIcon={"QUESTION"}
                              preformatted
                              className="mr10 cursor-pointer"
                              contents={`닉네임과 이메일의 일부분이 가려집니다.\n예) 김수미(example@email.com) → 김*미(ex*****@*********)`}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  );
                }}
              />
            </div>
          </div>
          <div className="d-flex h-40">
            <div className="flex-center minmax145 font14 font-weight-600 text-primary3">
              <p>소속 회사</p>
            </div>
            <div className="flex-center">
              <Controller
                control={control}
                name={FORM_NAMES.COMPANY_NAME}
                render={({ field }) => {
                  return (
                    <div className="flex-row flex-center-start">
                      {[
                        { value: "public", label: "공개" },
                        { value: "restricted", label: "일부공개" },
                        { value: "private", label: "비공개" },
                      ].map((data, index) => (
                        <div className="flex-center" key={index}>
                          <BaseRadio
                            key={index}
                            id={FORM_NAMES.COMPANY_NAME + data.label}
                            name={FORM_NAMES.COMPANY_NAME + data.label}
                            label={data.label}
                            onChange={(e) => {
                              if (e) {
                                setValue(FORM_NAMES.COMPANY_NAME, data.value);
                              }
                            }}
                            checked={field.value === data.value}
                            className={data.value === "restricted" ? "" : "mr10"}
                          />
                          {data.value === "restricted" && (
                            <BaseTooltip
                              touchIcon={"QUESTION"}
                              preformatted
                              className="mr10 cursor-pointer"
                              contents={`회사명의 첫 글자를 제외한 모든 글자가 가려집니다. \n예) 핀포인트 → 핀***`}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  );
                }}
              />
            </div>
          </div>
        </section>
        <section className="base-abstract-modal__btn-wrap">
          <BaseButton title={"취소"} type="button" className="color-white" onClick={onClose} />
          <BaseButton title={"완료"} type="submit" />
        </section>
      </form>
    </BaseAbstractModal>
  );
};

export default CalendarModal;
