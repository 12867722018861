import { useForm } from "react-hook-form";
import { postAcGroupAsync } from "src/api/access/ac-group-api";
import { Ac2AccessGroupRequest } from "src/api/access/ac-group-types";
import { useApiOperation } from "src/api/hooks";
import { BaseButton } from "src/components";
import GoToListButton from "src/components/GoToListButton";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import useNavigate from "src/hooks/usePartnerNavigate";
import { PagePath } from "src/pages/product/details";
import { useModal } from "src/recoil/modalState/hook";
import AcDeviceSection from "./components/AcDeviceSection";
import AcGroupBasicInfoForm from "./components/AcGroupBasicInfoForm";
import AccessGroupSettingForm from "./components/AccessGroupSettingForm";

const BasicInfoForm = () => {
  const navigate = useNavigate();
  const { setBaseModal } = useModal();

  const { isAuthority } = usePartnerAuthority();

  const { handleSubmit, control, watch, setValue } = useForm<Ac2AccessGroupRequest>({
    defaultValues: {
      accessGroupName: "",
      memberAccessYn: true, // 이용자 출입 여부
      visitorAccessYn: false, // 방문자 초대 여부
      visitorAccessGroupSelectableYn: false, // Taap 노출 여부
      visitorInvitationApprovalYn: false, // 관리자 승인 여부
      visitorAccessType: "VISITOR_MUTABLE", // QR 발급 타입
      deviceAddIds: [],
    },
  });

  // 출입그룹 생성
  const { executeAsync: postAcGroup } = useApiOperation(postAcGroupAsync);

  const postAcGroupHandler = async (request: Ac2AccessGroupRequest) => {
    const { data, status } = await postAcGroup({ accessGroup: request });

    if (status >= 200 && status < 300) {
      navigate(PagePath.accessGroup.detail.replace(":id", `${data.data.accessGroup.id}`));
    }
    setBaseModal({ isOpen: false });
  };

  const onSubmit = (data: Ac2AccessGroupRequest) => {
    setBaseModal({
      isOpen: true,
      title: "저장하시겠습니까?",
      onClick: () => postAcGroupHandler(data),
      onClose: () => setBaseModal({ isOpen: false }),
      btnRightTitle: "확인",
      btnLeftTitle: "취소",
    });
  };

  const onError = (error: any) => {
    console.log(error);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="contents-container__scroll">
          <div className="contents-container__wrap">
            {/* 기본정보 */}
            <AcGroupBasicInfoForm control={control} />
            {/* 설정 */}
            <AccessGroupSettingForm control={control} watch={watch} setValue={setValue} />
            {/* 출입장치 */}
            <AcDeviceSection control={control} watch={watch} setValue={setValue} isAuthority={isAuthority} />
          </div>
        </div>

        {/* 버튼영역 */}
        <div className="contents-container__btn-wrap">
          <div className="left-area">
            <GoToListButton />
          </div>

          <div className="right-area">
            {isAuthority("w") && ( //
              <BaseButton title="저장" className="size-large" type="submit" />
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default BasicInfoForm;
