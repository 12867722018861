import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { getAdminMemoList } from "src/api/adminMemo/adminmemo-api";
import { UnionServiceType } from "src/api/adminMemo/adminmemo-types";
import { axiosInstance } from "src/utils";

import AdminMemoForm from "./AdminMemoFormV3";

import useAdminMemoState from "src/recoil/adminMemo/hooks";
import { useModal } from "src/recoil/modalState/hook";
import BaseSectionTitle from "../layout/BaseSectionTitle";
import AdminMemoList from "./AdminMemoListV3";

type Props = {
  serviceId: number; //
  serviceType: UnionServiceType;
};

export type AdminMemoFormData = {
  id?: string;
  serviceId?: number;
  memo: string;
  adminMemoType: string;
};

const AdminMemoV3 = ({ serviceId, serviceType }: Props) => {
  const [orderBy, setOrderBy] = useState<"DESC" | "ASC">("DESC"); //기본 최신순
  const { globalIsEdit, setGlobalIsEdit } = useAdminMemoState(); //관리자 메모 전역 편집 상태
  const { setBaseModal } = useModal();

  //getAdminMemoList 호출부
  const { data } = useQuery({
    queryKey: ["getAdminMemoList", serviceId, serviceType, orderBy],
    queryFn: async () =>
      getAdminMemoList(axiosInstance, {
        contentsList: [
          {
            serviceId: serviceId,
            serviceType: serviceType,
          },
        ],
        size: 1000,
        sort: {
          orders: [{ property: "createdDate", direction: orderBy }],
        },
      }),
    enabled: !!(serviceId && serviceType),
  });
  const adminMemoList = data?.data?.data?.content;

  useEffect(() => {
    //마운트 시점에 default false
    setGlobalIsEdit(false);
  }, []);

  //hash link 코드
  useEffect(() => {
    if (window.location.hash) {
      const hash = window.location.hash.substring(1); // '#' 제거
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [data?.status]);

  return (
    <>
      <article className="contents-container__wrap-article memo mb50">
        <BaseSectionTitle title={"메모"} />
        {globalIsEdit === false && <AdminMemoForm serviceId={serviceId} serviceType={serviceType} />}
        {adminMemoList && adminMemoList?.length > 0 && (
          <div className="list-warp">
            <div className="list-title-warp d-flex flex-row gap10 pt30 pb10">
              <span className="BD5">메모 {adminMemoList?.length ?? "-"}</span>
              <span
                onClick={() => {
                  if (globalIsEdit) {
                    setBaseModal({
                      isOpen: true,
                      title: `이 메모가 지워집니다.\n그래도 취소하겠습니까?`,
                      btnLeftTitle: "취소",
                      btnRightTitle: "확인",
                      onClick: () => {
                        setGlobalIsEdit(false);
                        setBaseModal({ isOpen: false });
                        setOrderBy("DESC");
                      },
                    });
                  } else {
                    setOrderBy("DESC");
                  }
                }}
                className={clsx("cursor-pointer", orderBy === "DESC" ? "BD5" : "BD6 text-primary3")}
              >
                최신순
              </span>
              <span
                onClick={() => {
                  if (globalIsEdit) {
                    setBaseModal({
                      isOpen: true,
                      title: `이 메모가 지워집니다.\n그래도 취소하겠습니까?`,
                      btnLeftTitle: "취소",
                      btnRightTitle: "확인",
                      onClick: () => {
                        setGlobalIsEdit(false);
                        setBaseModal({ isOpen: false });
                        setOrderBy("ASC");
                      },
                    });
                  } else {
                    setOrderBy("ASC");
                  }
                }}
                className={clsx("cursor-pointer", orderBy === "ASC" ? "BD5" : "BD6 text-primary3")}
              >
                오래된순
              </span>
              <div></div>
            </div>
            {adminMemoList?.map((memo) => (
              <AdminMemoList key={memo.id} memo={memo} />
            ))}
          </div>
        )}
      </article>
    </>
  );
};

export default AdminMemoV3;
