import { useCallback, useEffect, useRef, useState } from "react";

// 카운트다운 커스텀 훅
const useCountdown = (initialValue: number, callbackFunction: () => void, interval = 1000) => {
  const [countdown, setCountdown] = useState(initialValue);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const callbackRef = useRef<() => void>(callbackFunction);
  const isPausedRef = useRef<boolean>(false);

  // 콜백 업데이트
  useEffect(() => {
    callbackRef.current = callbackFunction;
  }, [callbackFunction]);

  // 카운트다운 시작
  const start = useCallback(() => {
    if (intervalRef.current) clearInterval(intervalRef.current);

    setCountdown(initialValue);
    isPausedRef.current = false;

    intervalRef.current = setInterval(() => {
      if (!isPausedRef.current) {
        setCountdown((prev: number) => {
          const newValue = prev - 1;
          if (newValue <= 0) {
            callbackRef.current?.();
            return initialValue;
          }
          return newValue;
        });
      }
    }, interval);
  }, [initialValue, interval]);

  // 카운트다운 일시 정지
  const pause = useCallback(() => {
    isPausedRef.current = true;
  }, []);

  // 카운트다운 재개
  const resume = useCallback(() => {
    isPausedRef.current = false;
  }, []);

  // 카운트다운 중지
  const stop = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }, []);

  // 카운트다운 재설정
  const reset = useCallback(() => {
    stop();
    start();
  }, [stop, start]);

  // 컴포넌트 언마운트 시 인터벌 정리
  useEffect(() => {
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, []);

  return { countdown, start, stop, reset, pause, resume };
};

export default useCountdown;
