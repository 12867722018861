import { useState } from "react";
import { Cell } from "react-table";
import { Ac2AccessGroupModel } from "src/api/access/ac-group-types";
import { getStatusMessage } from "src/components/ErrorModal";
import AccessGroupDetailModal from "src/pages/product/components/AccessGroupDetailModal";
import { Modal } from "../visitor-types";
import { visitorSettings } from "src/pages/accessGroup/const";
import { PageType } from "src/api/visitor/visitor-types";

type ErrorData = {
  error: { errorCode: string; errorMessage: string; status: number };
};

// 출입그룹 관리 테이블 컬럼
export const acGroupColumns: any[] = [
  {
    Header: "출입그룹 id",
    accessor: "id",
    width: 80,
  },
  {
    Header: "출입 그룹명",
    accessor: "accessGroupName",
    width: 360,
    Cell: ({ row, value }: Cell<Ac2AccessGroupModel & ErrorData>) => {
      const error = row?.original?.error;
      const errorMessage = error?.status && getStatusMessage(error?.status);
      const errorCode = error?.errorCode;
      return (
        <div className="w-100 text-left">
          <p>{value}</p>
          {errorMessage && <p className="font12 text-red mt3">{`${errorMessage} ${errorCode && `[${errorCode}]`}`}</p>}
        </div>
      );
    },
  },
  {
    Header: "이용자 출입",
    accessor: "memberAccessYn",
    width: 100,
    Cell: ({ value }: Cell<Ac2AccessGroupModel>) => {
      return <span>{value ? "출입가능" : "출입불가"}</span>;
    },
  },
  {
    Header: "방문자 초대",
    accessor: "visitorAccessYn",
    width: 100,
    Cell: ({ value }: Cell<Ac2AccessGroupModel>) => {
      return <span>{value ? "초대가능" : "초대불가"}</span>;
    },
  },
  {
    Header: "Taap 노출",
    accessor: "visitorAccessGroupSelectableYn",
    width: 100,
    Cell: ({ value }: Cell<Ac2AccessGroupModel>) => {
      return (
        <span>
          {visitorSettings.find((item) => item.name === "visitorAccessGroupSelectableYn")?.options.find((option) => option.value === value)?.label ||
            "-"}
        </span>
      );
    },
  },
  {
    Header: "관리자 승인",
    accessor: "visitorInvitationApprovalYn",
    width: 100,
    Cell: ({ value }: Cell<Ac2AccessGroupModel>) => {
      return (
        <span>
          {visitorSettings.find((item) => item.name === "visitorInvitationApprovalYn")?.options.find((option) => option.value === value)?.label ||
            "-"}
        </span>
      );
    },
  },
  {
    Header: "QR 발급",
    accessor: "visitorAccessType",
    width: 100,
    Cell: ({ value }: Cell<Ac2AccessGroupModel>) => {
      return (
        <span>
          {visitorSettings.find((item) => item.name === "visitorAccessType")?.options.find((option) => option.value === value)?.label || "-"}
        </span>
      );
    },
  },

  {
    Header: "출입장치",
    accessor: "accessDeviceView",
    width: 80,
    Cell: ({ row }: Cell<Ac2AccessGroupModel>) => {
      // 상세정보 팝업
      const [showDetailModal, setShowDetailModal] = useState<Modal>({ isOpen: false });
      return (
        <>
          <div
            className="flex-center-center"
            onClick={() => {
              setShowDetailModal({ isOpen: true, payload: row.original.accessGroupName });
            }}
          >
            <span className="cursor-pointer text-underline text-blue900">보기</span>
          </div>
          {showDetailModal.isOpen && (
            <AccessGroupDetailModal showModal={showDetailModal} setShowModal={setShowDetailModal} accessGroupId={Number(row?.original?.id)} />
          )}
        </>
      );
    },
  },
];

// 삭제 컬럼
export const addTypeDeleteCol = [
  {
    Header: "삭제",
    accessor: "addTypeDeleteCol",
    width: 50,
    Cell: ({ row, handleDeleteAccessGroup }: { row: any; handleDeleteAccessGroup: (id: number) => void; pageType: PageType }) => {
      return (
        <button
          className="base-trash-btn color-gray"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleDeleteAccessGroup(row.original.id);
          }}
        ></button>
      );
    },
  },
];

// 삭제 컬럼
export const editTypeDeleteCol = [
  {
    Header: "삭제",
    accessor: "editTypeDeleteCol",
    width: 50,
    Cell: ({ row, handleDeleteAccessGroup }: { row: any; handleDeleteAccessGroup: (id: number) => void; pageType: PageType }) => {
      return (
        <button
          className="base-trash-btn color-gray"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleDeleteAccessGroup(row.original.id);
          }}
        ></button>
      );
    },
  },
];
