import qs from "qs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
// Swiper styles
import { throttle } from "lodash"; // lodash 추가 필요
import moment from "moment";
import QRCode from "react-qr-code";
import { useRecoilValue } from "recoil";
import { getCommonFacilityListAsync } from "src/api/building/building-api";
import { CommonFacilityListModel } from "src/api/building/building-types";
import { getContractApply } from "src/api/contract/contract-api";
import { useApiOperation } from "src/api/hooks";
import { getReservationListAsync } from "src/api/reservation/reservation-api";
import { ReservationListModel } from "src/api/reservation/reservation-types";
import { useI18n } from "src/hooks/useI18n";
import useNavigate from "src/hooks/usePartnerNavigate";
import { globalPartnerState } from "src/recoil/partners/atom";
import { resizedImageUrl, ViewYmdFormat } from "src/utils";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { convertDisplayDate, Modal, timeSlots } from "../reservation-types";
import ReservationBox from "./components/ReservationBox";
import ReservationDetailModal from "./components/ReservationDetailModal";
import ReservationSettingModal from "./components/ReservationSettingModal";
import useCountdown from "./hooks/useCountdown";

SwiperCore.use([Navigation, Pagination, Autoplay]);
type SwiperType = SwiperCore;

type SearchParams = {
  page?: number;
  size?: number;
};

// 상수
const TIME_INTERVAL = 30000; // 데이터 호출 주기 30초
const AUTOPLAY_DELAY = 30000; // 오토플레이 주기 30초
const PIXEL_PER_BLOCK = 45; // 한 칸(30분)은 45px
const COUNTDOWN_INTERVAL = 1000; // 1초마다 카운트다운 업데이트
const SLIDE_SPEED = 500; // 애니메이션속도

const ReservationCalendar = () => {
  const [currentTime, setCurrentTime] = useState<Date>(new Date());
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [swiper, setSwiper] = useState<SwiperType | null>(null);

  const [reservationDetailModal, setReservationDetailModal] = useState<Modal>({ isOpen: false });
  const [reservationSettingModal, setReservationSettingModal] = useState<Modal>({ isOpen: false });
  const [facilityList, setFacilityList] = useState<CommonFacilityListModel[]>([]);
  const [allFacilityChunks, setAllFacilityChunks] = useState<CommonFacilityListModel[][]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isManualNavigation, setIsManualNavigation] = useState(false);
  const browserLanguage = navigator.language.split("-")[0];

  // Ref
  const scrollContainerRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});

  // Hooks
  const location = useLocation();
  const navigate = useNavigate();

  const { t } = useI18n();
  const partner = useRecoilValue(globalPartnerState);

  const { executeAsync: getCommonFacilityList } = useApiOperation(getCommonFacilityListAsync, { noLoading: true, noHandleError: true });
  const { executeAsync: getReservationList } = useApiOperation(getReservationListAsync, { noLoading: true, noHandleError: true });
  const { executeAsync: getContractList } = useApiOperation(getContractApply, { noLoading: true, noHandleError: true });

  const queryParams: any = useMemo(() => {
    const _queryParams: any = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      allowDots: true,
      decoder: (c) => c,
    });
    return {
      ..._queryParams,
    };
  }, [location]);

  const isAutoplayEnabled = queryParams.transportType === "on";

  // 현재 타임라인 위치 계산
  const getCurrentTimePosition = useMemo(() => {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();

    const pixelsPerMinute = PIXEL_PER_BLOCK / 30; // 1분당 픽셀 = 45px / 30분
    const hoursInPixels = hours * (PIXEL_PER_BLOCK * 2);
    const minutesInPixels = minutes * pixelsPerMinute;

    return PIXEL_PER_BLOCK + hoursInPixels + minutesInPixels - 1; // 보더 오차 1픽셀 빼주기
  }, [currentTime]);

  // 쿼리파라미터 정보와 함께 네비게이션
  const navigateWithQueryParams = useCallback(
    (passParams?: SearchParams) => {
      const newQueryParams = passParams ? { ...passParams } : { ...queryParams };
      const filteredRest: any = {};

      Object.keys(newQueryParams).forEach((key) => {
        const value = newQueryParams[key as keyof typeof newQueryParams];
        if (value !== 0 && value !== "" && value != null) {
          filteredRest[key] = value;
        }
      });

      const newQueryParamStr = qs.stringify(filteredRest, { allowDots: true, encode: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location.pathname, queryParams],
  );

  // 공용공간 호출
  const fetchCommonfacility = useCallback(async () => {
    const { data: facilityData, status: facilityStatus } = await getCommonFacilityList({
      sort: {
        orders: [
          {
            property: "id",
            direction: "DESC",
          },
        ],
      },
    });
    if (facilityStatus >= 200 && facilityStatus < 300 && facilityData?.data?.content) {
      const facilities = facilityData.data.content;
      setFacilityList(facilities);
    }
  }, [getCommonFacilityList]);

  // 예약 호출 및 매핑
  const fetchReservationList = useCallback(
    async (page: number, passParams?: any) => {
      try {
        setIsLoading(true);
        if (facilityList && facilityList.length > 0) {
          let filteredFacilityList = [...facilityList];
          if (passParams) {
            if (passParams.buildingIdList) {
              filteredFacilityList = facilityList.filter((facility) => Number(facility.buildingId) === Number(passParams.buildingIdList));
            }
            if (passParams.commonFacilityTypeList) {
              filteredFacilityList = filteredFacilityList.filter((facility) => facility.commonFacilityType === passParams.commonFacilityTypeList);
            }
            if (passParams.floor) {
              filteredFacilityList = filteredFacilityList.filter((facility) => {
                if (Number(passParams.floor) > 0) {
                  return facility.isGround === true && facility.floorNum === Math.abs(Number(passParams.floor));
                } else {
                  return facility.isGround === false && facility.floorNum === Math.abs(Number(passParams.floor));
                }
              });
            }
          }

          const facilityIdArray = filteredFacilityList?.map((item) => item.id);
          // 100개씩 나누기
          const MAX_FACILITIES_PER_REQUEST = 100;
          const facilityIdChunks = [];

          for (let i = 0; i < facilityIdArray.length; i += MAX_FACILITIES_PER_REQUEST) {
            facilityIdChunks.push(facilityIdArray.slice(i, i + MAX_FACILITIES_PER_REQUEST));
          }

          let allReservations: ReservationListModel[] = [];

          for (const chunk of facilityIdChunks) {
            const facilityIdsString = chunk.join(",");

            const { data: reservationData, status: reservationStatus } = await getReservationList({
              facilityId: facilityIdsString,
              greaterThanEqualsEnd: moment().startOf("day").format(),
              lessThanEqualsStart: moment().endOf("day").format(),
            });

            if (reservationStatus >= 200 && reservationStatus < 300 && reservationData.data.content && reservationData.data.content.length > 0) {
              allReservations = [...allReservations, ...reservationData.data.content];
            }
          }

          if (allReservations.length > 0) {
            const contractIds = [...new Set(allReservations.map((reservation) => reservation.contract.id))].join(",");

            const { data: contract } = await getContractList({ contractIds: String(contractIds) });

            // 각 시설에 예약 정보 매핑
            filteredFacilityList = filteredFacilityList.map((facility: any) => {
              const matchingReservations = allReservations.filter((reservation) => reservation.facility.id === facility.id);
              const calculateTimeReservations = matchingReservations.map((reservation) => {
                const startTime = moment(reservation.start);
                const endTime = moment(reservation.end);
                return { ...reservation, minutes: endTime.diff(startTime, "minutes") };
              });
              const ReservationWithMbOrganizationName = calculateTimeReservations.map((reservation) => {
                const findMbOrganization = contract.data.content.find((contract) => contract.contractId === Number(reservation.contract.id));
                return { ...reservation, mbOrganizationName: findMbOrganization?.mbOrganizationName };
              });

              if (ReservationWithMbOrganizationName.length > 0) {
                facility.reservations = ReservationWithMbOrganizationName;
              }
              return facility;
            });
          }
          // 배열을 이중배열로 6개씩 나누기
          const ITEMS_PER_PAGE = 6;
          const createChunks = (facilities: CommonFacilityListModel[]): CommonFacilityListModel[][] => {
            if (!facilities.length) {
              return [Array(ITEMS_PER_PAGE).fill({} as CommonFacilityListModel)];
            }

            return Array.from({ length: Math.ceil(facilities.length / ITEMS_PER_PAGE) }, (_, index) => {
              const start = index * ITEMS_PER_PAGE;
              const chunk = facilities.slice(start, start + ITEMS_PER_PAGE);

              return [...chunk, ...Array(ITEMS_PER_PAGE - chunk.length).fill({} as CommonFacilityListModel)];
            });
          };

          const chunkedFacilities = createChunks(filteredFacilityList);
          setAllFacilityChunks(chunkedFacilities);
          setTotalPages(chunkedFacilities.length || 1);
          if (page > chunkedFacilities.length) {
            setCurrentPage(1);
            if (swiper) {
              swiper.slideTo(0, 0);
            }
          }
        } else {
          setAllFacilityChunks([Array(6).fill({} as CommonFacilityListModel)]);
          setTotalPages(1);
        }
      } catch (error) {
        setAllFacilityChunks([Array(6).fill({} as CommonFacilityListModel)]);
        setTotalPages(1);
      } finally {
        setIsLoading(false);
      }
    },
    [getReservationList, getContractList, facilityList],
  );

  // 데이터 새로고침 타이머
  const handleDataRefresh = useCallback(() => {
    if (isAutoplayEnabled) {
      if (autoplayTimer.countdown <= 2 || autoplayTimer.countdown >= 29) {
        // 1초 지연 후 데이터 호출 (타이밍 미세 조정)
        setTimeout(() => {
          fetchReservationList(currentPage, queryParams);
          setCurrentTime(new Date());
        }, 1000);
      } else {
        fetchReservationList(currentPage, queryParams);
        setCurrentTime(new Date());
      }
    } else {
      // 자동 슬라이드 모드가 아닐 때는 항상 데이터 호출
      fetchReservationList(currentPage, queryParams);
      setCurrentTime(new Date());
    }
  }, [currentPage, queryParams, fetchReservationList, isAutoplayEnabled]);

  // 현재 타임라인 위치로 스크롤하는 함수
  const scrollToCurrentTime = useCallback(() => {
    const activeIndex = swiper?.activeIndex || 0;
    const scrollContainer = scrollContainerRefs.current[activeIndex];

    if (scrollContainer) {
      const currentTimePos = getCurrentTimePosition;
      const containerHeight = scrollContainer.clientHeight;
      const scrollPosition = currentTimePos - containerHeight / 3;

      scrollContainer.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    }
  }, [swiper, getCurrentTimePosition]);

  // 자동 슬라이드 전환 함수 (다음 슬라이드로 이동)
  const moveToNextSlide = useCallback(() => {
    if (!swiper || isManualNavigation) return;

    const nextPage = currentPage + 1;
    const targetPage = totalPages && nextPage > totalPages ? 1 : nextPage;
    // setIsManualNavigation(true);
    setCurrentPage(targetPage);

    swiper.update();

    setTimeout(() => {
      swiper.slideTo(targetPage - 1, SLIDE_SPEED);

      setTimeout(() => {
        // setIsManualNavigation(false);
        scrollToCurrentTime();
        swiper.update();
        // setAllFacilityChunks([...allFacilityChunks]);
      }, SLIDE_SPEED + 100);
    }, 50); // 미세한 지연으로 렌더링 사이클 보장
  }, [swiper, isManualNavigation, currentPage, totalPages, scrollToCurrentTime]);

  const dataRefreshTimer = useCountdown(TIME_INTERVAL / 1000, handleDataRefresh, COUNTDOWN_INTERVAL);
  const autoplayTimer = useCountdown(AUTOPLAY_DELAY / 1000, moveToNextSlide, COUNTDOWN_INTERVAL);
  const scrollTimer = useCountdown(TIME_INTERVAL / 1000, scrollToCurrentTime, COUNTDOWN_INTERVAL);

  // 모달 헨들러
  const handleReservationDetailModal = (type: string, payload?: any) => {
    scrollTimer.reset();
    if (type === "open") {
      if (!isAutoplayEnabled) setReservationDetailModal({ isOpen: true, payload });
    }
    if (type === "close") setReservationDetailModal({ isOpen: false });
  };

  const handleCloseReservationSettingModal = () => {
    if (isAutoplayEnabled) {
      autoplayTimer.start();
    } else {
      scrollTimer.start();
    }
    setReservationSettingModal({ isOpen: false });
  };

  // 사용자 인터랙션 처리 (스로틀링 적용)
  const handleUserInteraction = useCallback(
    throttle(() => {
      if (isAutoplayEnabled) {
        autoplayTimer.reset();
      } else {
        scrollTimer.reset();
      }

      // dataRefreshTimer.reset();
    }, 100), // 100ms 스로틀링
    [isAutoplayEnabled],
  );

  //네비게이션 버튼 공통 로직
  const handleNavigation = useCallback(
    (direction: string) => {
      const newPage = direction === "prev" ? currentPage - 1 : currentPage + 1;
      if (newPage <= 0 || (totalPages && newPage > totalPages)) {
        return;
      }

      setCurrentPage(newPage);
      setIsManualNavigation(true);

      if (swiper) {
        swiper.update();
        swiper.slideTo(newPage - 1, SLIDE_SPEED);

        if (isAutoplayEnabled) {
          autoplayTimer.reset();
        }

        setTimeout(() => {
          setTimeout(() => {
            setIsManualNavigation(false);
            scrollToCurrentTime();
          }, 100);
        }, SLIDE_SPEED);
      }
    },
    [currentPage, totalPages, swiper, isAutoplayEnabled, autoplayTimer, scrollToCurrentTime],
  );

  // 네비게이션 버튼 핸들러
  const handlePrevSlide = useCallback(() => handleNavigation("prev"), [handleNavigation]);
  const handleNextSlide = useCallback(() => handleNavigation("next"), [handleNavigation]);

  // 최초 데이터 로드 및 타이머 설정
  useEffect(() => {
    fetchCommonfacility();
    scrollToCurrentTime();

    dataRefreshTimer.start();
    if (isAutoplayEnabled) {
      autoplayTimer.start();
    } else {
      scrollTimer.start();
    }
  }, []);

  // 30초 타이머가 지났을 때만 데이터를 새로 로드하도록 기존 useEffect에서 분리
  useEffect(() => {
    if (facilityList.length > 0) {
      // facilityList가 로드된 후 한 번만 초기 데이터 로드
      fetchReservationList(currentPage, queryParams);
    }
  }, [facilityList]);

  // URL 변경시 첫 페이지로 조회
  useEffect(() => {
    fetchReservationList(1, queryParams);
  }, [queryParams]);

  useEffect(() => {
    if (swiper) {
      swiper.update();
      scrollToCurrentTime();
    }
  }, [swiper, scrollToCurrentTime]);

  useEffect(() => {
    const userInteractionEvents = ["wheel"];

    const handleVisibilityChange = () => {
      if (!document.hidden) {
        scrollToCurrentTime();

        if (isAutoplayEnabled) {
          autoplayTimer.reset();
        } else {
          scrollTimer.reset();
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    userInteractionEvents.forEach((event) => {
      window.addEventListener(event, handleUserInteraction);
    });

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);

      userInteractionEvents.forEach((event) => {
        window.removeEventListener(event, handleUserInteraction);
      });
    };
  }, [scrollToCurrentTime, handleUserInteraction, isAutoplayEnabled, autoplayTimer, dataRefreshTimer, scrollTimer]);

  // queryParams 값에 따라 자동슬라이드여부 결정
  const swiperParams = {
    initialSlide: 0,
    threshold: 40, // (40px 이상 드래그해야 작동)
    onSwiper: (swiperInstance: SwiperType) => {
      if (swiperInstance) {
        setSwiper(swiperInstance);
        swiperInstance.update();

        // 이벤트 리스너 명시적 등록 (메모리 누수 방지를 위해 먼저 이벤트 제거)
        swiperInstance.off("slideChangeTransitionEnd");
        swiperInstance.off("slideChange");

        swiperInstance.on("slideChangeTransitionEnd", () => {
          scrollToCurrentTime();

          if (isAutoplayEnabled) {
            autoplayTimer.reset();
          } else {
            scrollTimer.reset();
          }
          swiperInstance.update();
        });
        // slideChange: 슬라이드가 변경될 때마다 발생하는 이벤트
        swiperInstance.on("slideChange", () => {
          const currentIndex = swiperInstance.activeIndex;
          const newPage = currentIndex + 1;

          if (!isManualNavigation) {
            setCurrentPage(newPage);
          }
          swiperInstance.update();
        });
      }
    },

    loop: false,
    autoplay: false,
    slidesPerView: 1,
    allowSlideNext: true,
    allowSlidePrev: true,
    simulateTouch: !isAutoplayEnabled,
    allowTouchMove: false, // 터치 이동 허용
    speed: SLIDE_SPEED, // 슬라이딩 애니메이션 속도 설정 (밀리초)
  };

  useEffect(() => {
    console.log("현재보여주는페이지", currentPage, allFacilityChunks[currentPage - 1]);
    console.log(allFacilityChunks);
  }, [allFacilityChunks, currentPage]);

  // 임시 하드코딩 로고 출력함수
  const getLogoClass = (partnerId: number | undefined) => {
    switch (process.env.REACT_APP_ENVIRONMENT) {
      case "prod":
        if (Number(partnerId) === 17) {
          return "fact";
        } else if (Number(partnerId) === 21) {
          return "gs";
        } else if (Number(partnerId) === 1) {
          return "pinpoint";
        } else return;
      case "dev":
        if (Number(partnerId) === 257) {
          return "pinpoint";
        } else if (Number(partnerId) === 307) {
          return "gs";
        }
    }
  };

  return (
    <div className="reservation-calendar-page">
      {isLoading && (
        <div className="reservation-calendar-loading">
          <div className="loader"></div>
        </div>
      )}
      <div className="logo-area">
        <div className="logo-area__left">
          <div className={`logo-area__left-logo ${getLogoClass(partner?.id)}`}></div>
          <h2>{t("reservation.display.body.title")}</h2>
        </div>
        <div className="logo-area__right">
          <p>{convertDisplayDate()}</p>
        </div>
      </div>
      <div className="reservation-calendar">
        <Swiper {...swiperParams} className="reservation-calendar-swiper">
          {Array.from({ length: totalPages }).map((_, index) => {
            // 현재 슬라이드가 가시 범위 내에 있는지 확인
            const pageIndex = currentPage - 1;
            const visibleRange = 2;
            const isVisible = Math.abs(index - pageIndex) <= visibleRange;
            const slideData = allFacilityChunks[index] || Array(6).fill({} as CommonFacilityListModel);
            return (
              <SwiperSlide key={`slide-${index}`}>
                {isVisible ? (
                  <>
                    <div className="reservation-calendar-header">
                      <div className="reservation-calendar-header-content">
                        <div className="reservation-calendar-header-qr">
                          <div className="qr-wrap">
                            <div className="qr">
                              <QRCode value={`${process.env.REACT_APP_COURT_BASSEURL}/front/taap/home` || ""} size={80} />
                              {/* <QRCode
                                value={`${process.env.REACT_APP_COURT_BASSEURL}/front/taap/reservation/room?facilityType=MEETING_ROOM` || ""}
                                size={80}
                              /> */}
                              <div className="qr-info">
                                <p>에서 예약</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="reservation-calendar-header-rooms">
                          {slideData.length > 0
                            ? slideData.map((room, index) => (
                                <div key={room.id + "-" + index} className="reservation-calendar-header-room">
                                  <div
                                    className={`reservation-calendar-header-room-box ${
                                      room.buildingName && !room.mediaList?.[0]?.url
                                        ? "empty-images"
                                        : !room.buildingName && !room.mediaList?.[0]?.url
                                        ? "empty-facility"
                                        : ""
                                    }`}
                                    {...(room.mediaList?.[0]?.url
                                      ? { style: { backgroundImage: `url(${resizedImageUrl(room.mediaList?.[0]?.url)})` } }
                                      : {})}
                                  >
                                    <div className="reservation-calendar-header-room-capacity">
                                      {(() => {
                                        const maxPeople = room?.facilityMaxPeopleNums || 0;
                                        return (
                                          maxPeople > 0 && (
                                            <div className="capacity-chip">
                                              <p className="number">{maxPeople}</p>
                                            </div>
                                          )
                                        );
                                      })()}
                                    </div>
                                    <div className="reservation-calendar-header-room-name">{room?.facilityName || ""}</div>
                                    <div className="reservation-calendar-header-room-building">{room?.buildingName}</div>
                                  </div>
                                </div>
                              ))
                            : Array.from({ length: 6 }).map((_, index) => (
                                <div className="reservation-calendar-header-room" key={`empty-${index}${moment()}`}>
                                  <div className="reservation-calendar-header-room-box empty-facility"></div>
                                </div>
                              ))}
                        </div>
                      </div>
                    </div>

                    <div className="reservation-calendar-content">
                      <div
                        className="reservation-calendar-content-scroll"
                        ref={(el) => (scrollContainerRefs.current[index] = el)}
                        style={isAutoplayEnabled ? { overflowY: "hidden", marginRight: "28px" } : {}}
                      >
                        <div className="reservation-calendar-time">
                          {timeSlots.map((time) => (
                            <div key={time} className="reservation-calendar-time-slot">
                              <p>{time !== "24:30" ? time : ""}</p>
                            </div>
                          ))}
                        </div>
                        <div className="reservation-calendar-grid">
                          {slideData.length > 0
                            ? slideData.map((facility, index) => (
                                <div
                                  key={facility.id + "-" + index}
                                  className={`reservation-calendar-grid-column ${facility.id ? "" : "empty-column"}`}
                                >
                                  {timeSlots.map((time, index) => {
                                    return (
                                      <div key={`${facility.id}-${time}`} className="reservation-calendar-grid-column-cell">
                                        <ReservationBox
                                          timeSlotsTime={time}
                                          facility={facility}
                                          currentPage={currentPage}
                                          handleReservationDetailModal={handleReservationDetailModal}
                                          getCurrentTimePosition={getCurrentTimePosition}
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                              ))
                            : Array.from({ length: 6 }).map((_, index) => (
                                <div key={`empty-${index}`} className="reservation-calendar-grid-column">
                                  {timeSlots.map((time, tIndex) => (
                                    <div key={`empty-${index}-${time}`} className="reservation-calendar-grid-column-cell"></div>
                                  ))}
                                </div>
                              ))}
                        </div>
                        <div className="reservation-calendar-current-time" style={{ top: `${getCurrentTimePosition}px` }}>
                          <div className="reservation-calendar-current-time-line" />
                          <div className="reservation-calendar-current-time-label">{moment(currentTime).format(ViewYmdFormat.HH_MM)}</div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </SwiperSlide>
            );
          })}
        </Swiper>

        <div className="reservation-calendar-navigation">
          {!isAutoplayEnabled && (
            <button className="reservation-calendar-navigation-button" disabled={currentPage === 1} onClick={handlePrevSlide}></button>
          )}
          <div className="reservation-calendar-navigation-page">
            <b>{`${currentPage}`}</b>
            <span className="mx2">/</span>
            {totalPages ? `${totalPages}` : "1"}
          </div>
          {!isAutoplayEnabled && (
            <button className="reservation-calendar-navigation-button" disabled={currentPage === totalPages} onClick={handleNextSlide}></button>
          )}
        </div>

        {process.env.REACT_APP_ENVIRONMENT === "dev" && (
          <div className="reservation-calendar-extra-left">
            <div className="reservation-calendar-countdowns font20 flex-center gap10">
              <div className="reservation-calendar-countdown ">
                <span className="countdown-label">데이터자동갱신:</span>
                <span className="countdown-time"> {dataRefreshTimer.countdown.toString().padStart(2, "0")}초</span>
              </div>
              {isAutoplayEnabled && (
                <div className="reservation-calendar-countdown">
                  <span className="countdown-label">자동슬라이드:</span>
                  <span className="countdown-time">{autoplayTimer.countdown.toString().padStart(2, "0")}초</span>
                </div>
              )}
              {!isAutoplayEnabled && (
                <div className="reservation-calendar-countdown">
                  <span className="countdown-label">자동스크롤:</span>
                  <span className="countdown-time">{scrollTimer.countdown.toString().padStart(2, "0")}초</span>
                </div>
              )}
            </div>
          </div>
        )}

        <div className="reservation-calendar-extra">
          <p className="extra-notice">{t("reservation.display.toast.title")}</p>
          <a href={window.location.href} aria-label="페이지 새로고침" className="reservation-calendar-extra-reload">
            {""}
          </a>
          <button
            className="reservation-calendar-extra-setting"
            onClick={() => {
              setReservationSettingModal({ isOpen: true });
              if (isAutoplayEnabled) {
                autoplayTimer.stop();
              } else {
                scrollTimer.stop();
              }
            }}
          ></button>
          <button
            className="reservation-calendar-extra-language"
            onClick={() => {
              const passParams = { ...queryParams };
              if (browserLanguage === "en") {
                return;
              } else if (queryParams.lang === "en") {
                passParams.lang = "kr";
              } else if (queryParams.lang === "kr") {
                passParams.lang = "en";
              } else {
                passParams.lang = "en";
              }
              navigateWithQueryParams(passParams);
              window.location.reload();
            }}
          >
            {browserLanguage === "en" ? "EN" : queryParams.lang === "en" ? "EN" : "KR"}
          </button>
        </div>
      </div>
      {reservationDetailModal.isOpen && (
        <ReservationDetailModal reservation={reservationDetailModal.payload} onClose={() => handleReservationDetailModal("close")} />
      )}
      {reservationSettingModal.isOpen && (
        <ReservationSettingModal
          data={facilityList}
          selectedBuildings={(value) => {
            const newPage = 1;
            navigateWithQueryParams({ ...queryParams, buildingIdList: value, floor: "" });
            setCurrentPage(newPage);
            if (swiper) {
              swiper.slideTo(newPage - 1, 0);
              swiper.update();
            }
            // fetchCommonfacility().then(() => {
            //   fetchReservationList(newPage, { ...queryParams, buildingIdList: value, floor: "" });
            // });
          }}
          selectedFloor={(value) => {
            const newPage = 1;
            navigateWithQueryParams({ ...queryParams, floor: value });
            setCurrentPage(newPage);
            if (swiper) {
              swiper.slideTo(newPage - 1, 0);
              swiper.update();
            }
            // fetchCommonfacility().then(() => {
            //   fetchReservationList(newPage, { ...queryParams, buildingIdList: value, floor: "" });
            // });
          }}
          selectedFacilityType={(value) => {
            const newPage = 1;
            navigateWithQueryParams({ ...queryParams, commonFacilityTypeList: value });
            setCurrentPage(newPage);
            if (swiper) {
              swiper.slideTo(newPage - 1, 0);
              swiper.update();
            }
            // fetchCommonfacility().then(() => {
            //   fetchReservationList(newPage, { ...queryParams, buildingIdList: value, floor: "" });
            // });
          }}
          onClose={handleCloseReservationSettingModal}
        />
      )}
    </div>
  );
};

export default ReservationCalendar;
