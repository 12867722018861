import { useCallback, useEffect, useMemo, useState } from "react";
import { Cell, useBlockLayout, useTable } from "react-table";
import { Ac2AccessGroupModel } from "src/api/access/ac-group-types";
import { deleteProductAccessAsync, getAccessGroupListAsync, getProductAccessGroupAsync, postProductAccessAsync } from "src/api/access/productac-api";
import { Ac2AccessDeviceModel, ProductAccessGroupModel, ProductAccessUpdateData } from "src/api/access/productac-types";
import { useApiOperation } from "src/api/hooks";
import { BaseButton, BaseConfirmModal, BaseModal } from "src/components";
import { getStatusMessage } from "src/components/ErrorModal";
import GoToListButton from "src/components/GoToListButton";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import { visitorSettings } from "src/pages/accessGroup/const";
import { Modal } from "src/pages/building/building-types";
import AccessGroupDetailModal from "../../components/AccessGroupDetailModal";
import SelectAcGroupModal from "./modals/SelectAcGroupModal";
import UserAndVisitorAccess from "./UserAndVisitorAccess";

type ErrorData = {
  error: { errorCode: string; errorMessage: string; status: number };
};

const ProductAccessGroup = ({ product }: any) => {
  const { isAuthority } = usePartnerAuthority();

  // 로딩바
  const columns: any[] = useMemo(() => {
    const _columns: any[] = [
      {
        Header: "출입그룹 id",
        accessor: "accessGroupId",
        width: 80,
      },
      {
        Header: "출입 그룹명",
        accessor: "accessGroupName",
        width: 360,
        Cell: ({ row, value }: Cell<ProductAccessGroupModel & ErrorData>) => {
          const error = row?.original?.error;
          const errorMessage = error?.status && getStatusMessage(error?.status);
          const errorCode = error?.errorCode;
          return (
            <div className="w-100 text-left">
              <p>{value}</p>
              {errorMessage && <p className="font12 text-red mt3">{`${errorMessage} ${errorCode && `[${errorCode}]`}`}</p>}
            </div>
          );
        },
      },
      {
        Header: "이용자 출입",
        accessor: "memberAccessYn",
        width: 100,
        Cell: ({ value }: Cell<Ac2AccessGroupModel>) => {
          return <span>{value ? "출입가능" : "출입불가"}</span>;
        },
      },
      {
        Header: "방문자 초대",
        accessor: "visitorAccessYn",
        width: 100,
        Cell: ({ value }: Cell<Ac2AccessGroupModel>) => {
          return <span>{value ? "초대가능" : "초대불가"}</span>;
        },
      },
      {
        Header: "Taap 노출",
        accessor: "visitorAccessGroupSelectableYn",
        width: 100,
        Cell: ({ value }: Cell<Ac2AccessGroupModel>) => {
          return (
            <span>
              {visitorSettings.find((item) => item.name === "visitorAccessGroupSelectableYn")?.options.find((option) => option.value === value)
                ?.label || "-"}
            </span>
          );
        },
      },
      {
        Header: "관리자 승인",
        accessor: "visitorInvitationApprovalYn",
        width: 100,
        Cell: ({ value }: Cell<Ac2AccessGroupModel>) => {
          return (
            <span>
              {visitorSettings.find((item) => item.name === "visitorInvitationApprovalYn")?.options.find((option) => option.value === value)?.label ||
                "-"}
            </span>
          );
        },
      },
      {
        Header: "QR 발급",
        accessor: "visitorAccessType",
        width: 100,
        Cell: ({ value }: Cell<Ac2AccessGroupModel>) => {
          return (
            <span>
              {visitorSettings.find((item) => item.name === "visitorAccessType")?.options.find((option) => option.value === value)?.label || "-"}
            </span>
          );
        },
      },
      // {
      //   Header: "방문자 허용",
      //   accessor: "visitorAccessYn",
      //   width: 140,
      //   Cell: ({ value }: Cell<ProductAccessGroupModel>) => {
      //     if (value === true) {
      //       return "허용";
      //     } else {
      //       return "미허용";
      //     }
      //   },
      // },

      {
        Header: "출입장치",
        accessor: "",
        width: 80,
        Cell: ({ row }: Cell<ProductAccessGroupModel>) => {
          return (
            <div
              className="flex-center-center"
              onClick={() => {
                setAccessGroupId(Number(row.original.accessGroupId));
                setShowDetailModal({ isOpen: true, payload: row.original.accessGroupName });
              }}
            >
              <span className="cursor-pointer text-underline text-blue900">보기</span>
            </div>
          );
        },
      },
      {
        Header: "삭제",
        accessor: "",
        width: 50,
        Cell: ({ row }: Cell<ProductAccessGroupModel>) => {
          return row.original.id ? (
            <button
              className="base-trash-btn color-gray"
              onClick={(e) => {
                e.preventDefault();
                setConfirmModal({ isOpen: true, message: "삭제하시겠습니까?", type: "remove", payload: row.original.id });
              }}
            ></button>
          ) : (
            <span className="text-red">실패</span>
          );
        },
      },
    ];

    return _columns.filter((item, idx) => (!isAuthority("d") ? idx < 4 : item));
  }, [isAuthority]);
  // 공간상품 id

  // 공간상품 출입그룹 등록된 목록
  const [productAccess, setProductAccess] = useState<ProductAccessGroupModel[]>([]);

  const [accessList, setAccessList] = useState<Ac2AccessGroupModel[]>([]);

  // 출입그룹 선택 팝업
  const [showSelectModal, setShowSelectModal] = useState(false);

  // 상세정보 팝업
  const [showDetailModal, setShowDetailModal] = useState<Modal>({ isOpen: false });

  // productAccess 에 accessList에 있는 출입그룹명과 방문자 허용 데이터 추가
  const productList = useMemo(() => {
    return productAccess?.map((product) => {
      accessList.map((item) => {
        if (Number(product.accessGroupId) === Number(item.id)) {
          product = {
            ...product,
            accessGroupName: item.accessGroupName,
            visitorAccessYn: item.visitorAccessYn, //
            memberAccessYn: item.memberAccessYn,
            visitorAccessGroupSelectableYn: item.visitorAccessGroupSelectableYn,
            visitorInvitationApprovalYn: item.visitorInvitationApprovalYn,
            visitorAccessType: item.visitorAccessType,
          };
        }
        return product;
      });
      return product;
    });
  }, [accessList, productAccess]);

  //
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable({ data: productList || [], columns }, useBlockLayout);

  // 공간상품 출입그룹 목록
  const { executeAsync: getProductAccess } = useApiOperation(getProductAccessGroupAsync);

  //팝업 출입그룹 목록
  const { executeAsync: getAccessList } = useApiOperation(getAccessGroupListAsync);

  // 출입그룹 등록
  const { executeAsync: postProductAccess } = useApiOperation(postProductAccessAsync, {
    noHandleError: true,
  });

  // 출입그룹 삭제
  const { executeAsync: deleteProductAccess } = useApiOperation(deleteProductAccessAsync);

  // 공간상품 출입그룹 목록 api
  const fetchProductAccess = useCallback(
    async (id: number) => {
      const { data } = await getProductAccess({ productId: id });
      if (data.data) {
        setProductAccess(data.data.content);

        const ids = data.data.content.map((item) => item.accessGroupId) as string[];
        const accessResponse = await getAccessList({ ids: ids.join(",") });
        if (accessResponse.status >= 200 && accessResponse.status <= 299) {
          setAccessList(accessResponse.data.data.content);
        }
      }
    },
    [getAccessList, getProductAccess],
  );

  useEffect(() => {
    if (product) {
      fetchProductAccess(Number(product.id));
    }
  }, [fetchProductAccess, product]);

  // 확인버튼만 있는 alert 모달
  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
  });

  // 취소, 확인 버튼이 있는 confirm 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });

  // 출입그룹 등록완료 / 실패 확인 모달
  const openAccessCompletePopup = (list: any) => {
    // 100% 성공시
    const allCompleted = list.every((item: any) => !item.error);
    const allFailed = list.every((item: any) => item.error);

    if (allCompleted) {
      setAlertModal({ isOpen: true, title: "출입그룹이 적용되었습니다.", message: "추가된 출입그룹에 바로 엑세스 할 수 있습니다." });
    }

    if (allFailed) {
      setAlertModal({ isOpen: true, title: "출입그룹 추가에 실패하였습니다.", message: "목록에서 사유를 확인해 주세요" });
    }

    // 부분 성공 및 실패 일시
    if (!allCompleted && !allFailed) {
      setAlertModal({ isOpen: true, title: "출입그룹이 적용되었습니다.", message: "추가된 출입그룹에 바로 엑세스 할 수 있습니다", type: "some" });
    }
  };

  // 등록
  const onAddProductAccess = useCallback(
    async (accessGroup: Ac2AccessDeviceModel[]) => {
      const promises = accessGroup.map(async (access, idx) => {
        const postData: ProductAccessUpdateData = {
          accessGroupId: String(Number(access.id)) || "",
          productId: String(product.id) || "", //성공

          // 실패 케이스
          // productId: idx === 1 ? String(product.id + "dsf") : String(product.id) || "", //부분성공
          // productId: String(productId + "dsf") || "", //실패
        };

        let response;

        try {
          response = await postProductAccess(postData);
          const result = response.data.data.content;

          //  RAD21. 출입그룹 페이지 조회  api 호출 - 출입그룹명을 추가히기 위함 (accessGroupName)
          const accessResponse = await getAccessList({ ids: result.accessGroupId });

          if (accessResponse.data.data.content) {
            const findAccessGroup = accessResponse.data.data.content.find((item) => Number(item.id) === Number(result.accessGroupId));
            return {
              data: {
                ...result,
                accessGroupName: findAccessGroup?.accessGroupName,
                memberAccessYn: findAccessGroup?.memberAccessYn,
                visitorAccessYn: findAccessGroup?.visitorAccessYn,
                visitorAccessGroupSelectableYn: findAccessGroup?.visitorAccessGroupSelectableYn,
                visitorInvitationApprovalYn: findAccessGroup?.visitorInvitationApprovalYn,
                visitorAccessType: findAccessGroup?.visitorAccessType,
              },
              error: null,
            };
          }

          // return { data: result, error: null };
        } catch (error) {
          const errorData = {
            errorCode: response?.data?.meta?.errorCode,
            status: response?.status,
          };

          return { data: null, error: { ...access, errorData } };
        }
      });

      Promise.all(promises).then(async (results) => {
        const merged = results.map((item) => {
          let fileData;
          if (item?.error) {
            fileData = {
              accessGroupName: item?.error.accessGroupName || "",
              accessGroupId: String(item?.error.id),
              visitorAccessYn: item?.error.visitorAccessYn || false,
              error: item?.error.errorData,
            };
          }
          return { ...item?.data, ...fileData };
        });
        openAccessCompletePopup(merged);
        setProductAccess((prev) => [...prev, ...merged]);
      });
    },
    [getAccessList, postProductAccess, product],
  );

  // 출입그룹 선택시 (선택 시 저장)
  const onSelectedAccessGroups = useCallback(
    (selectedItems: Ac2AccessDeviceModel[]) => {
      console.log(`selectedItems`, selectedItems);
      if (selectedItems.length > 0) {
        onAddProductAccess(selectedItems);
      }
      setShowSelectModal(false);
    },
    [onAddProductAccess],
  );

  // 출입그룹 삭제시
  const onRemoveAccessGroup = useCallback(
    async (id: string) => {
      const response = await deleteProductAccess({ ids: [id] });
      if (response.status >= 200 && response.status <= 299) {
        await fetchProductAccess(Number(product.id));
      }
    },
    [deleteProductAccess, fetchProductAccess, product],
  );

  const clickConfirmModal = useCallback(() => {
    if (confirmModal.type === "remove") {
      onRemoveAccessGroup(confirmModal.payload);
    }
  }, [confirmModal, onRemoveAccessGroup]);

  // 출입그룹 상세 키값
  const [accessGroupId, setAccessGroupId] = useState(0);

  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <article className="contents-container__wrap-article">
            {/* 이용자 관리 / 방문자 관리 */}
            <UserAndVisitorAccess product={product} />
          </article>

          <div className="contents-container__wrap-article">
            <div className="flex-center-between w-100">
              <BaseSectionTitle title={"출입그룹 관리"} />
              <div className="mb10">
                {isAuthority("w") && (
                  <BaseButton
                    title="+ 출입그룹 추가"
                    onClick={() => {
                      setShowSelectModal(true);
                    }}
                  />
                )}

                {showSelectModal && (
                  <SelectAcGroupModal
                    setShowModal={setShowSelectModal}
                    onSelectedAccessGroups={onSelectedAccessGroups}
                    existingAccess={productAccess || []}
                  />
                  // <SelectProductAccessGroupModal
                  //   setShowModal={setShowSelectModal}
                  //   onSelectedAccessGroups={onSelectedAccessGroups}
                  //   productAccess={productAccess || []}
                  // />
                )}
              </div>
            </div>

            <div className="contents-container__grid-contents">
              <section className="inner-tab-table">
                <div {...getTableProps()} className="base-table sticky">
                  <div className="header">
                    {headerGroups.map((headerGroup) => (
                      <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                        {headerGroup.headers.map((header) => {
                          return (
                            <div {...header.getHeaderProps()} className="base-table__th">
                              {header.render("Header")}
                            </div>
                          );
                        })}
                      </div>
                    ))}
                  </div>
                  <div {...getTableBodyProps()} className="body">
                    {rows.map((row: any) => {
                      prepareRow(row);
                      return (
                        <div {...row.getRowProps()} className={`base-table__tr`}>
                          {row.cells.map((cell: any) => {
                            return (
                              <div {...cell.getCellProps()} className="base-table__td">
                                {cell.render("Cell")}
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                  {rows.length === 0 && (
                    <div className="base-table__tr table-cursor-unset">
                      <div className="base-table__td w-100 text-center">
                        <div className="w-100">
                          <span>데이터가 없습니다.</span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </section>
            </div>
          </div>

          {/* 취소, 확인 버튼이 있는 confirm 모달 */}
          {confirmModal.isOpen && (
            <BaseModal
              isOpen={true}
              btnLeftTitle="취소"
              btnRightTitle="확인"
              title="해당 출입그룹을 삭제합니다."
              onClose={() => setConfirmModal({ isOpen: false })}
              onClick={() => {
                clickConfirmModal();
                setConfirmModal({ isOpen: false });
              }}
              className="px30"
            >
              <p>삭제한 즉시 해당 출입그룹에 액세스 할 수 없습니다.</p>
              {/* <p>{confirmModal.message}</p> */}
            </BaseModal>
          )}

          {/* 확인버튼만 있는 alert 모달 */}
          {alertModal.isOpen && (
            <BaseModal isOpen={true} btnRightTitle="확인" onClick={() => setAlertModal({ isOpen: false })} title={alertModal.title} className="pa30">
              {/* <h2 className="mb30">{alertModal.title}</h2> */}
              <p className="lh-20  text-gray600">
                {alertModal.message}
                <br />
                {alertModal.type === "some" && (
                  <span className="text-red">
                    *일부 그룹 추가에 실패 하였습니다.
                    <br />
                    목록에서 사유를 확인해 주세요
                  </span>
                )}
              </p>
            </BaseModal>
          )}

          {showDetailModal.isOpen && (
            <AccessGroupDetailModal showModal={showDetailModal} setShowModal={setShowDetailModal} accessGroupId={accessGroupId} />
          )}
        </div>
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <GoToListButton />
        </div>
        <div className="right-area"></div>
      </div>
    </>
  );
};

export default ProductAccessGroup;
