import { useCallback, useEffect, useMemo, useState } from "react";
import { useBlockLayout, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Ac2AccessGroupModel } from "src/api/access/ac-group-types";
import { getAccessGroupListAsync } from "src/api/access/productac-api";
import { ProductAccessGroupModel } from "src/api/access/productac-types";
import { useApiOperation } from "src/api/hooks";
import { PageMeta } from "src/api/public-types";
import { BaseButton, BaseCheckbox, BaseInput, BasePagination } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import { SelectProductAccessGroupColumn } from "src/pages/product/forms/accessLevelForm/columns/SelectProductAccessGroupColumn";
import { modalSelectItem, modalSelectedOptions } from "src/pages/product/hooks/atom";

type Props = {
  setShowModal: (value: boolean) => void;
  onSelectedAccessGroups: (selected: any[]) => void;
  existingAccess: (ProductAccessGroupModel | Ac2AccessGroupModel)[];
  contractManageId?: string; // 계약 관리 아이디(방문자 초대에서 사용)
  visitorAccessYn?: boolean; // 방문자 출입그룹 여부(방문자 초대에서 사용)
};

// 타입 가드 함수를 컴포넌트 외부에 정의
const isProductAccessGroup = (item: ProductAccessGroupModel | Ac2AccessGroupModel): item is ProductAccessGroupModel => {
  return "accessGroupId" in item;
};

const SelectAcGroupModal = ({ setShowModal, onSelectedAccessGroups, existingAccess, contractManageId, visitorAccessYn }: Props) => {
  const [accessGroups, setAccessGroups] = useState<Ac2AccessGroupModel[]>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const [params, setParams] = useState<any>({
    sort: {
      orders: [{ property: "name", direction: "ASC" }],
    },
    page: 0,
    size: 20,
  });

  const selectedOptions = useRecoilValue(modalSelectedOptions);
  const setAccessSelect = useSetRecoilState(modalSelectItem);

  const columns: any = useMemo(() => SelectProductAccessGroupColumn, []);

  const data = useMemo(
    () =>
      accessGroups.map((item) => ({
        checked: selectedOptions.map((select: any) => Number(select.id)).includes(Number(item.id)),
        ...item,
      })),
    [accessGroups, selectedOptions],
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns,
      data,
    },
    useBlockLayout,
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns: Array<any>) => [
        {
          width: 80,
          Header: "선택",
          Cell: ({ row }: any) => {
            const isDisabled = existingAccess.some((item) =>
              isProductAccessGroup(item) ? Number(item.accessGroupId) === Number(row.original.id) : Number(item.id) === Number(row.original.id),
            );

            return (
              <div>
                <BaseCheckbox
                  id={`${row.original.id}`}
                  name={`${row.original.id}`}
                  disabled={isDisabled}
                  checked={row.original.checked}
                  onChange={(checked) => {
                    row.original.checked = checked;
                    if (checked) {
                      setAccessSelect((prev: any) => [...prev, row.original]);
                    } else {
                      setAccessSelect((prev: any) => prev.filter((item: any) => item.id !== row.original.id));
                    }
                  }}
                />
              </div>
            );
          },
        },
        ...columns,
      ]);
    },
  );

  const { executeAsync: getAccessList } = useApiOperation(getAccessGroupListAsync);

  const getAccessGroupsList = useCallback(
    async (queryParams: any) => {
      const { data } = await getAccessList({
        searchValue: queryParams.searchValue,
        page: queryParams.page,
        size: queryParams.size || 20,
        contractManageId: contractManageId,
        visitorAccessYn: visitorAccessYn,
      });
      setAccessGroups(data?.data.content || null);
      setPageMeta(data?.meta.pageMeta);
    },
    [contractManageId, getAccessList, visitorAccessYn],
  );

  useEffect(() => {
    getAccessGroupsList(params);
  }, [getAccessGroupsList]);

  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);

  return (
    <div>
      <BaseAbstractModal className="dialog-modal" isOpen={true}>
        <section className="base-abstract-modal__title flex-center-between">
          <h1>{"출입그룹 추가"}</h1>
          <div className="right-area">
            <div className="minmax260">
              <BaseInput
                name="keyword"
                type="text"
                placeholder="검색어를 입력해주세요"
                value={params.searchValue}
                onChange={(value: string) => {
                  setParams({ ...params, searchValue: value });
                }}
                onKeyUp={() => {
                  getAccessGroupsList({ ...params, page: 0 });
                }}
                onSearchClick={() => {
                  getAccessGroupsList({ ...params, page: 0 });
                }}
              />
            </div>
          </div>
        </section>

        <section className="base-abstract-modal__contents pt0">
          <div className="contents-container__table px30">
            <div {...getTableProps()} className="base-table view-data-table sticky">
              <div className="header">
                {headerGroups.map((headerGroup) => (
                  <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                    {headerGroup.headers.map((header) => (
                      <div {...header.getHeaderProps()} className="base-table__th">
                        {header.render("Header")}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
              <div {...getTableBodyProps()} className="body">
                {rows.length > 0 ? (
                  rows.map((row) => {
                    prepareRow(row);
                    return (
                      <div {...row.getRowProps()} className="base-table__tr">
                        {row.cells.map((cell) => (
                          <div {...cell.getCellProps()} className="base-table__td">
                            {cell.render("Cell")}
                          </div>
                        ))}
                      </div>
                    );
                  })
                ) : (
                  <div className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
                    <div className="base-table__td w-100 text-center">
                      <div className="w-100">
                        <span>데이터가 없습니다.</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>

        <section className="px30">
          <BasePagination
            sizeOption={(size) => {
              setParams({ ...params, page: 0, size });
              getAccessGroupsList({ ...params, page: 0, size });
            }}
            currentSize={Number(params.size) || 20}
            pageIndex={pageMeta?.pageRequest.page || 0}
            totalElements={pageMeta?.totalElements}
            totalPages={pageMeta?.totalPages || 0}
            goPage={(page: number) => {
              setParams({ ...params, page });
              getAccessGroupsList({ ...params, page });
            }}
            children={
              <>
                <BaseButton
                  title="취소"
                  onClick={() => {
                    setShowModal(false);
                    setAccessSelect([]);
                  }}
                  className="color-white mr10"
                />
                <BaseButton
                  title="선택"
                  onClick={() => {
                    setShowModal(false);
                    onSelectedAccessGroups(selectedOptions);
                    setAccessSelect([]);
                  }}
                />
              </>
            }
          />
        </section>
      </BaseAbstractModal>
    </div>
  );
};

export default SelectAcGroupModal;
