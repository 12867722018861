import qs from "qs";
import { useCallback, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import useNavigate from "src/hooks/usePartnerNavigate";
import { useModal } from "src/recoil/modalState/hook";

const useActiceTab = (tabs: { value: string; label: string }[]) => {
  const location = useLocation();

  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  const activeTab = queryParams?.tab ? tabs.find((tab) => tab.value === queryParams?.tab) : tabs[0];

  return { activeTab };
};

export default useActiceTab;
