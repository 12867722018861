import useActiceTab from "src/hooks/useActiceTab";
import { accessGroupTabs } from "../const";
import { ContentsTitle } from "src/components";
import BasicInfoForm from "./BasicInfoForm";
import { useQueryParams } from "src/hooks/useQueryParams";

const AccessGroupForm = () => {
  const { activeTab } = useActiceTab(accessGroupTabs);
  const {
    queryParams: { id },
  } = useQueryParams();

  return (
    <div>
      {/* <ContentsTitle title="출입그룹 관리" tabs={accessGroupTabs} activeTab={activeTab} /> */}
      <ContentsTitle
        title="출입장치 관리"
        tabs={accessGroupTabs}
        activeTab={activeTab} //
        detailInfo={[{ title: "id", value: id || "" }]}
      />
      {activeTab?.value === "basicInfo" && ( //
        <BasicInfoForm />
      )}
    </div>
  );
};

export default AccessGroupForm;
