import moment from "moment";
import qs from "qs";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { getContractDetailBasicInfo, getContractManageByContractApplyNumber } from "src/api/contract/contract-api";
import { ContractStep } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import { Modal, Order, PageMeta, Select } from "src/api/public-types";
import { getUsers } from "src/api/user/user-api";
import { MemberStatus, UserListModel } from "src/api/user/user-types";
import { BaseButton, BaseInput, BaseInputWithSearch, BaseModal, BaseSelect, BaseTable } from "src/components";
import ContractSelectModal from "src/components/contract/ContractSelectModal";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import useNavigate from "src/hooks/usePartnerNavigate";
import useContractApi from "src/pages/contract/hooks/useContractApi";
import { PagePath } from "src/pages/product/details";
import { downloadExcel, qsParse } from "src/utils";
import { INVITEABLE_CONTRACT_STEP, USER_STATUS_TYPE_MAP } from "../constants";
import { columns } from "./columns/Columns";

import { formatVisitorMobileNumber } from "src/pages/visitor/utils";
import ExcelDownloadButton from "./ExcelDownloadButton";
import _ from "lodash";

//정렬기능 숨김
const disabledSortHeaders = [
  // "contractMemberId",
  "contractApplyNumber",
  "contractManageId",
  "memberNo",
  "email",
  "signUpTime",
  "memberStatus",
];
const memberStatusOptions: Select[] = [
  { label: "전체", value: "" },
  { label: "초대", value: MemberStatus.MEMBER_INVITE },
  { label: "사용", value: MemberStatus.MEMBER_USE },
  { label: "삭제", value: MemberStatus.MEMBER_DELETE },
  { label: "이용종료", value: MemberStatus.MEMBER_CLOSE },
  { label: "초대만료", value: MemberStatus.MEMBER_EXPIRE },
];

const calendarOptions = [
  { label: "전체", value: "" },
  { label: "연동", value: "true" },
  { label: "미연동", value: "false" },
];

const searchTypeUserOption: Select[] = [
  { label: "전체", value: "" },
  { label: "회원번호", value: "MEMBER_NO" },
  { label: "휴대폰번호", value: "MEMBER_MOBILE_NUMBER" },
];

const DEFAULT_ORDER: Order[] = [
  {
    property: "inviteDate",
    direction: "DESC",
  },
];
interface FormData {
  contractApplyNumber: string;
  contractId?: number;
  contractStep?: ContractStep;
  memberStatus?: string;
  search: {
    type: string;
    value: string;
  };
  watchingCalendar?: string;
}

const UserList = () => {
  const { isAuthority } = usePartnerAuthority();
  const navigate = useNavigate();
  const location = useLocation();
  const [modal, setModal] = useState({
    errorModal: {
      isVisible: false,
      message: "",
    },
    contractSelectModal: {
      visible: false,
    },
  });
  const [searchTrigger, setSearchTrigger] = useState(0); // 검색 트리거용 state 추가
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const [orders, setOrders] = useState<Order[]>();
  const [users, setUsers] = useState<UserListModel[]>();

  const { control, getValues, setValue, reset } = useForm<FormData>();

  // API
  const { callDetails } = useContractApi();
  const { executeAsync: getUsersAsync } = useApiOperation(getUsers);
  const { executeAsync: getContractDetailBasicInfoAsync } = useApiOperation(getContractDetailBasicInfo);
  const { executeAsync: getContractManage } = useApiOperation(getContractManageByContractApplyNumber);

  const search = ({ data, orders, page = 0, size = 20 }: { data: FormData; page?: number; size?: number; orders?: Order[] }): void => {
    navigate({
      search: qs.stringify({
        size,
        page,
        orders,
        ...data,
        searchType: data.search.type,
        searchValue: data.search.value,
        search: undefined,
      }),
    });
    setSearchTrigger((prev) => prev + 1); // 검색
  };

  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
  });

  const handleGoPage = (page: number) => search({ data: getValues(), page, size: pageMeta?.pageRequest?.size, orders });
  const handleViewSize = (sizeValue: number) => search({ data: getValues(), page: 0, size: sizeValue, orders });
  const handleSort = (orders: Order[]) => search({ data: getValues(), page: pageMeta?.pageRequest.page, orders });
  const closeContractSelectModal = () => setModal({ ...modal, contractSelectModal: { visible: false } });
  const openContractSelectModal = () => setModal({ ...modal, contractSelectModal: { visible: true } });
  const onCanceldContractSelectModal = () => closeContractSelectModal();

  const openErrorModal = (message: string) => {
    setModal({
      ...modal,
      errorModal: {
        isVisible: true,
        message,
      },
    });
  };

  const closeErrorModal = () => {
    setModal({
      ...modal,
      errorModal: {
        isVisible: false,
        message: "",
      },
    });
  };

  const handleClickGoToInviteButton = async () => {
    const contractApplyNumber = getValues("contractApplyNumber");
    const contractStep = getValues("contractStep")!;

    if (!contractApplyNumber) {
      openErrorModal("신청번호를 선택해주세요.");
      return;
    }

    if (INVITEABLE_CONTRACT_STEP.indexOf(contractStep) === -1) {
      openErrorModal("초대할 수 없는 계약 상태입니다.");
      return;
    }

    let pathName = PagePath.user.form.replace(":contractManageId", String(getValues("contractId")));
    pathName += "?contractApplyNumber=" + contractApplyNumber;

    navigate(pathName);
  };

  const handleOnClearClickContractInput = () => {
    setValue("contractId", undefined);
    setValue("contractApplyNumber", "");
    setValue("contractStep", "" as any);
    search({ data: { ...getValues() } });
  };

  const callList = useCallback(
    async (excelCall?: boolean) => {
      const parsedParams = qsParse(location.search);

      //쿼리스트링 기본값 객체로 세팅
      const newQueryParams = {
        page: parsedParams.page ? Number(parsedParams.page) : 0,
        size: parsedParams.size ? Number(parsedParams.size) : 20,

        memberStatus: parsedParams.memberStatus ? String(parsedParams.memberStatus) : "",
        searchType: parsedParams.searchType ? String(parsedParams.searchType) : "",
        searchValue: parsedParams.searchValue ? String(parsedParams.searchValue) : "",
        watchingCalendar: parsedParams.watchingCalendar ? String(parsedParams.watchingCalendar) : "",
        orders: parsedParams.orders ? (parsedParams.orders as unknown as Order[]) : DEFAULT_ORDER,
        contractApplyNumber: parsedParams.contractApplyNumber ? String(parsedParams.contractApplyNumber) : "",
        contractId: parsedParams.contractId ? Number(parsedParams.contractId) : undefined,
        contractStep: parsedParams.contractStep ?? "",
      };

      if (newQueryParams.contractApplyNumber && !newQueryParams.contractId) {
        //contractApplyNumber 가 있고 contractId가 없을때
        const manage = await getContractManage({ contractApplyNumber: newQueryParams.contractApplyNumber });

        if (manage.status === 200) {
          const contractData = await callDetails(String(manage.data.data.contractId));
          newQueryParams.contractId = Number(contractData.contract?.contractId);
          newQueryParams.contractStep = contractData.contract?.contractStep as ContractStep;
        }
      }

      if (newQueryParams.contractId && !newQueryParams.contractApplyNumber) {
        //contractId가 가 있고 contractApplyNumber 없을때

        const contractResult = await getContractDetailBasicInfoAsync({ id: newQueryParams.contractId });

        if (contractResult.status === 200) {
          newQueryParams.contractApplyNumber = contractResult.data.data.contractApplyNumber || "";
          newQueryParams.contractStep = contractResult.data.data.contractStep as ContractStep;
        }
      }

      if (newQueryParams.contractId && newQueryParams.contractApplyNumber && !newQueryParams.contractStep) {
        //[contractApplyNumber ,contractId]가 있고 contractStep이 없을 때

        const contractResult = await getContractDetailBasicInfoAsync({ id: newQueryParams.contractId });

        if (contractResult.status === 200) {
          newQueryParams.contractStep = contractResult.data.data.contractStep as ContractStep;
        }
      }

      //[contractApplyNumber ,contractId] 모두 없거나 모두 있을 때 실행
      if (excelCall) {
        newQueryParams.page = 0;
        newQueryParams.size = 100;
      }

      // if (excelCall && newQueryParams.contractApplyNumber === "") {
      //   setAlertModal({ isOpen: true, message: "엑셀 조회 시 신청번호를 선택해야합니다." });
      //   return;
      // }
      const params: any = {
        page: newQueryParams.page,
        size: newQueryParams.size,
        sort: {
          orders: newQueryParams.orders,
        },
        contractApplyNumber: newQueryParams.contractApplyNumber,
        memberStatus: newQueryParams.memberStatus,
        searchType: newQueryParams.searchType,
        searchValue: newQueryParams.searchValue,
        watchingCalendar: newQueryParams.watchingCalendar === "true" ? true : newQueryParams.watchingCalendar === "false" ? false : undefined,
      };
      let arrData: any = [];
      if (excelCall) {
        const newCall = _.cloneDeep(params);
        newCall.page = 0;
        newCall.size = 1;
        const check: any = await getUsersAsync(newCall);
        const { meta } = check.data;
        const fixedCallSize = 100;
        if (meta?.pageMeta?.totalElements > 0) {
          let callSize = Math.floor(meta?.pageMeta?.totalElements / fixedCallSize);
          const dvSize = meta?.pageMeta?.totalElements % fixedCallSize;
          // console.log("dv", callSize, dvSize);
          if (dvSize > 0) {
            callSize = callSize + 1;
          }
          // console.log("callSize", callSize, dvSize);

          let callList: any = [];
          for (let i = 0; i <= callSize; i++) {
            newCall.page = i;
            newCall.size = fixedCallSize;
            callList.push(
              new Promise((resolve, reject) => {
                resolve(getUsersAsync(newCall));
              }),
            );
          }
          const result2: any = await Promise.all(callList);
          result2.forEach((response: any) => {
            arrData = [...arrData, ...response.data.data];
          });
        }
        if (arrData.length === 0) {
          setAlertModal({ isOpen: true, message: "조회된 데이터가 없습니다." });
          return;
        }

        return arrData;
      } else {
        const result = await getUsersAsync(params);
        const { data, meta } = result.data;
        if (result.status !== 200) return;
        setUsers(data);
        setPageMeta(meta.pageMeta);
        setOrders(orders);
        reset({
          contractApplyNumber: newQueryParams.contractApplyNumber,
          contractId: newQueryParams.contractId,
          contractStep: newQueryParams.contractStep as any,
          memberStatus: newQueryParams.memberStatus,
          search: { type: newQueryParams.searchType, value: newQueryParams.searchValue },
          watchingCalendar: newQueryParams.watchingCalendar,
        });
      }
    },
    [location.search],
  );

  useEffect(() => {
    (async () => {
      callList();
    })();
  }, [callList, searchTrigger]);

  const handleClickExcelDownloadButton = async () => {
    const result: any = await callList(true);
    console.log("excel", result);
    const data = result;

    if (!data || data.length === 0) {
      setAlertModal({ isOpen: true, message: "조회된 데이터가 없습니다." });
      return;
    }
    const mappedData = data.map((item: any) => ({
      id: item.contractMemberId ?? "-",
      contractApplyNumber: item.contractApplyNumber ? item.contractApplyNumber : "-",
      memberStatus: item.memberStatus ? USER_STATUS_TYPE_MAP[item.memberStatus as keyof typeof USER_STATUS_TYPE_MAP] : "-",
      memberNo: item.memberNo ? item.memberNo : "-",
      phoneNumber: formatVisitorMobileNumber(item.phoneNumber) ?? "-", // 방문자 번호
      email: item.email ? item.email : "-",
      isEmailVerified: item.isEmailVerified ? "인증" : "미인증", // 시작
      nickname: item.nickname ? item.nickname : "-", // 종료
      inviteDate: moment(item.inviteDate).format("YYYY-MM-DD HH:mm") ?? "-",
      signUpTime: moment(item.signUpTime).format("YYYY-MM-DD HH:mm") ?? "-",
      isWatchingCalendar: item.isWatchingCalendar ? "연동" : "미연동", // 공간번호
      role:
        item.isContractor && item.isAdmin
          ? "계약자, 마스터"
          : item.isContractor && !item.isAdmin
          ? "계약자"
          : !item.isContractor && item.isAdmin
          ? "마스터"
          : "-", // 생성일
    }));

    try {
      const fileName = `ctrl.room-이용자목록-전체_${moment().format("YYYYMMDDHHmmss")}`;

      console.log("mappedData :>> ", mappedData);

      await downloadExcel({
        data: mappedData,
        fileName,
        header: columns.map((column) => column.Header),
      });

      setAlertModal({ isOpen: true, message: "엑셀 다운로드가 완료되었습니다." });
    } catch (error) {
      setAlertModal({ isOpen: true, message: "엑셀 다운로드에 실패하였습니다." });
    }
  };

  return (
    <div className="page-user-list">
      <div className="contents-container__table">
        <div className="contents-container__search-wrap">
          <div className="left-area">
            <section>
              <div className="left-area__index">
                <span>지정검색</span>
              </div>
              <div className="left-area__contents">
                <div className="minmax140 mr8">
                  <Controller
                    control={control}
                    name="contractApplyNumber"
                    render={({ field }) => (
                      <BaseInput
                        type="text"
                        value={field.value}
                        placeholder="신청번호"
                        onClick={openContractSelectModal}
                        onClearClick={handleOnClearClickContractInput}
                        readonly
                      />
                    )}
                  />
                </div>
              </div>
            </section>
            <section>
              <div className="left-area__index">
                <span>조건검색</span>
              </div>
              <div className="left-area__contents">
                <div className="minmax120 mr8">
                  <Controller
                    control={control}
                    name="memberStatus"
                    render={({ field }) => (
                      <BaseSelect
                        value={field.value}
                        placeholder="상태"
                        stateOptions={memberStatusOptions}
                        setStateValue={(memberStatus: string) => {
                          search({ data: { ...getValues(), memberStatus } });
                        }}
                      />
                    )}
                  />
                </div>
                <div className="minmax140 mr8">
                  <Controller
                    control={control}
                    name="watchingCalendar"
                    render={({ field }) => (
                      <BaseSelect
                        value={field.value}
                        placeholder="캘린더 연동"
                        stateOptions={calendarOptions}
                        setStateValue={(watchingCalendar: string) => {
                          search({ data: { ...getValues(), watchingCalendar } });
                        }}
                      />
                    )}
                  />
                </div>
                <div className="minmax320 mr16">
                  <Controller
                    control={control}
                    render={({ field }) => {
                      return (
                        <BaseInputWithSearch
                          type="text"
                          selectValue={field.value?.type}
                          inputValue={field.value?.value}
                          stateOptions={searchTypeUserOption}
                          setStateValue={(searchType: string) => {
                            setValue("search", {
                              ...field.value,
                              type: searchType,
                            });
                          }}
                          onChange={(searchValue: string, e: React.ChangeEvent<HTMLInputElement>) =>
                            setValue("search", {
                              ...field.value,
                              value: searchValue,
                            })
                          }
                          onKeyUp={() => {
                            search({
                              data: _.cloneDeep({ ...getValues() }),
                            });
                          }}
                          onSearchClick={() =>
                            search({
                              data: _.cloneDeep({ ...getValues() }),
                            })
                          }
                          onClearClick={() => {
                            search({
                              data: _.cloneDeep({
                                ...getValues(),
                                search: {
                                  type: "",
                                  value: "",
                                },
                              }),
                            });
                          }}
                        />
                      );
                    }}
                    name={"search"}
                  />
                </div>
              </div>
            </section>
          </div>
          <div className="right-area">{isAuthority("w") && <BaseButton title="+ 이용자 초대" onClick={handleClickGoToInviteButton} />}</div>
        </div>
        {/* 샘플 */}
        {users && (
          <BaseTable
            data={users}
            columns={columns}
            pageIndex={pageMeta?.pageRequest.page || 0}
            totalPages={pageMeta?.totalPages || 0}
            currentSize={Number(pageMeta?.pageRequest?.size) || 20}
            sizeOption={handleViewSize}
            totalElements={pageMeta?.totalElements || 0}
            goPage={handleGoPage}
            disabledSortHeaders={disabledSortHeaders}
            orders={orders}
            setOrders={handleSort}
            children={<ExcelDownloadButton onClick={handleClickExcelDownloadButton}>엑셀받기</ExcelDownloadButton>}
          />
        )}
      </div>
      {modal.errorModal.isVisible && (
        <BaseModal isOpen={true} btnRightTitle={"확인"} onClick={closeErrorModal} onClose={closeErrorModal} title={modal.errorModal.message} />
      )}
      {/* 확인버튼만 있는 alert 모달 */}
      {alertModal.isOpen && (
        <BaseModal
          isOpen={true}
          btnRightTitle="확인"
          onClick={() => setAlertModal({ isOpen: false })}
          onClose={() => setAlertModal({ isOpen: false })}
          title={alertModal.message}
        />
      )}

      {modal.contractSelectModal.visible && (
        <ContractSelectModal
          onCanceled={onCanceldContractSelectModal}
          onAdded={(data) => {
            closeContractSelectModal();
            search({
              data: {
                ...getValues(),
                contractApplyNumber: data[0].contractApplyNumber,
                contractId: data[0].contractId,
                contractStep: data[0].contractStep,
              },
            });
          }}
          defaultCheckedContractList={[
            {
              contractId: getValues("contractId")!,
              contractApplyNumber: getValues("contractApplyNumber")!,
            },
          ]}
          contractStepFilter={INVITEABLE_CONTRACT_STEP}
        />
      )}
    </div>
  );
};

export default UserList;
