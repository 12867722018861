import moment from "moment";
import qs from "qs";
import React from "react";
import { useLocation } from "react-router-dom";
import { ReservationListModel } from "src/api/reservation/reservation-types";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import { maskEmail, maskMiddleChars, maskString } from "src/utils";
import { getWeekToEN } from "../../reservation-types";
import { useI18n } from "src/hooks/useI18n";
type Props = {
  reservation: ReservationListModel & { mbOrganizationName: string };
  onClose: () => void;
};
const ReservationDetailModal = ({ reservation, onClose }: Props) => {
  const endMoment = moment(reservation?.end);
  const formattedStartTime = moment(reservation?.start).format("M월 D일 (ddd) HH:mm");
  const formattedEndTime = endMoment.format(endMoment.isSame(moment(), "day") ? "HH:mm" : "M월 D일 (ddd) HH:mm");

  const formattedStartTimeEN = getWeekToEN(reservation?.start);
  const formattedEndTimeEN = endMoment.isSame(moment(), "day") ? endMoment.format("HH:mm") : getWeekToEN(reservation.end);

  const location = useLocation();
  const { t } = useI18n();
  const queryParams: any = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    allowDots: true,
    decoder: (c) => c,
  });

  const renderTitle = (reservation: ReservationListModel) => {
    if (queryParams.reservationName === "public") {
      return reservation?.summary ? reservation?.summary : " ";
    } else if (queryParams.reservationName === "restricted") {
      return reservation?.summary ? maskString(reservation?.summary, 3) : "-";
    } else {
      return t("reservation.display.body.private");
    }
  };

  return (
    <BaseAbstractModal opacity={0} isOpen={true} className="display-reservation-detail-modal">
      <h2>{renderTitle(reservation)}</h2>
      <ul>
        <li className="display-modal__date">
          {queryParams.lang && queryParams.lang === "en"
            ? `${formattedStartTimeEN} - ${formattedEndTimeEN}`
            : `${formattedStartTime} - ${formattedEndTime}`}
        </li>

        <li className="display-modal__building">
          {queryParams.companyName === "public"
            ? reservation.mbOrganizationName || "-"
            : queryParams.companyName === "restricted"
            ? maskString(reservation.mbOrganizationName, 1) || "-"
            : t("reservation.display.detail.private")}
        </li>

        <li className="display-modal__organizer">
          {queryParams.attendeeInfo === "public"
            ? `${reservation.organizer.displayName}(${reservation.organizer.userEmail})`
            : queryParams.attendeeInfo === "restricted"
            ? `${maskMiddleChars(reservation.organizer.displayName)}(${maskEmail(reservation.organizer.userEmail)})`
            : t("reservation.display.detail.private")}
        </li>
        {reservation?.attendees && reservation?.attendees.length > 0 && (
          <li className="display-modal__guest">
            {queryParams.attendeeInfo === "private" ? (
              <p>{t("reservation.display.detail.private")}</p>
            ) : (
              <>
                {reservation.attendees && reservation.attendees.length > 0
                  ? reservation.attendees.map((attendee) => (
                      <p key={attendee.userEmail}>
                        {queryParams.attendeeInfo === "public"
                          ? `${attendee.displayName}(${attendee.userEmail})`
                          : queryParams.attendeeInfo === "restricted"
                          ? `${maskMiddleChars(attendee.displayName)}(${maskEmail(attendee.userEmail)})`
                          : "-"}
                      </p>
                    ))
                  : "-"}
              </>
            )}
          </li>
        )}
      </ul>
      <button className="close-btn" onClick={onClose}></button>
    </BaseAbstractModal>
  );
};

export default React.memo(ReservationDetailModal);
