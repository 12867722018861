import { useParams } from "react-router-dom";
import { ContentsTitle } from "src/components";
import MetaTag from "src/components/layout/MetaTag";
import useActiceTab from "src/hooks/useActiceTab";
import { accessGroupTabs } from "../const";
import BasicInfoDetail from "./BasicInfoDetail";
import { useApiOperation } from "src/api/hooks";
import { getAcGroupAsync } from "src/api/access/ac-group-api";
import { useEffect, useState, useCallback } from "react";
import { Ac2AccessGroupModel } from "src/api/access/ac-group-types";
import { getAccessDevicesAsync } from "src/api/access/productac-api";

const AccessGroupDetail = () => {
  const { id: accessGroupId } = useParams();
  const { activeTab } = useActiceTab(accessGroupTabs);

  const { executeAsync: getAcGroup } = useApiOperation(getAcGroupAsync);
  const { executeAsync: getAcDevices } = useApiOperation(getAccessDevicesAsync);

  const [accessGroup, setAccessGroup] = useState<Ac2AccessGroupModel>();

  const getAccessGroupDetail = useCallback(async () => {
    const { data } = await getAcGroup({ id: Number(accessGroupId) });

    // 출입그룹에 등록된 디바이스 조회 > front 용 데이터로 추가
    const devicesIds = data.data.accessGroup.deviceIds;

    if (devicesIds.length > 0) {
      const devices = await getAcDevices({ ids: devicesIds.join(",") });
      setAccessGroup({ ...data.data.accessGroup, acDevices: devices.data.data.content || [] });
    } else {
      setAccessGroup(data.data.accessGroup);
    }
  }, [accessGroupId, getAcGroup, getAcDevices]);

  useEffect(() => {
    if (accessGroupId) {
      getAccessGroupDetail();
    }
  }, [accessGroupId]);

  return (
    <div>
      <MetaTag subTitle={accessGroupId} />
      <ContentsTitle
        title="출입장치 관리"
        tabs={accessGroupTabs}
        activeTab={activeTab} //
        detailInfo={[{ title: "id", value: accessGroupId || "" }]}
      />

      {/* 기본정보 */}
      {accessGroup && <>{activeTab?.value === "basicInfo" && <BasicInfoDetail accessGroup={accessGroup} getAcGroup={getAccessGroupDetail} />}</>}
    </div>
  );
};

export default AccessGroupDetail;
