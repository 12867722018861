import React from "react";
import { Control, Controller } from "react-hook-form";
import { OrganizerProtectionSetting, ReservationConfigRequest } from "src/api/reservation/reservation-types";
import { BaseRadio, BaseTooltip } from "src/components";
import { ArticleSection } from "src/components/layout/ArticleSection";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";

const permissionRadio = [
  {
    label: "비허용",
    value: OrganizerProtectionSetting.DENIED, //
    message: "관리자가 설정한 정보 공개 범위를 사용자가 변경할 수 없습니다.",
  },
  {
    label: "허용",
    value: OrganizerProtectionSetting.ALLOWED,
    message: "관리자가 설정한 정보 공개 범위를 기본값으로 하고 사용자가 직접 설정할 수 있습니다.",
  },
];

type Props = {
  control: Control<ReservationConfigRequest>;
  isEditable: boolean;
  isAvailableMasking: boolean;
  fetchAuthorityReadonly: (checkReadonly?: boolean, global?: boolean) => void;
};

const ControlPermissionSetting = ({ control, isEditable, fetchAuthorityReadonly, isAvailableMasking }: Props) => {
  return (
    // <ArticleSection title="제어 권한 설정">
    <article className="contents-container__wrap-article">
      <BaseSectionTitle title="제어 권한 설정" className="pb10" />
      <p className="font14 text-primary3 mb20">
        Taap 회의실 예약 화면에서 사용자의 예약정보 공개 설정을 허용하거나 관리자 설정으로 제한할 수 있습니다.
      </p>

      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>사용자 제어 허용</p>
        </div>
        <div className="contents-container__grid-contents">
          <Controller
            control={control}
            name={"organizerProtectionSetting"}
            render={({ field: { onChange, value } }) => (
              <div className="flex-center-start flex-row">
                {permissionRadio.map((item) => (
                  <div key={item.value} className="flex-row flex-center-start">
                    <BaseRadio
                      id={`organizerProtectionSetting-${item.value}`}
                      name={"organizerProtectionSetting"}
                      label={item.label}
                      checked={value === item.value}
                      onChange={() => {
                        if (isEditable) {
                          onChange(item.value);
                        } else {
                          fetchAuthorityReadonly(true);
                        }
                      }}
                      disabled={!isAvailableMasking}
                    />
                    <BaseTooltip className="mr10" contents={item.message} touchIcon="QUESTION" />
                  </div>
                ))}
              </div>
            )}
          />
        </div>
      </section>
    </article>
  );
};

export default ControlPermissionSetting;
