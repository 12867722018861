import moment from "moment";
import { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { getContractDetailBasicInfo } from "src/api/contract/contract-api";
import { ContractStep } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import { postVisitorAsync } from "src/api/visitor/visitor-api";
import { BaseButton, ContentsTitle } from "src/components";
import GoToListButton from "src/components/GoToListButton";
import MetaTag from "src/components/layout/MetaTag";
import useNavigate from "src/hooks/usePartnerNavigate";
import { useQueryParams } from "src/hooks/useQueryParams";
import { useModal } from "src/recoil/modalState/hook";
import { useToast } from "src/recoil/toast/hook";
import { parsedPhoneNumber } from "src/utils";
import { getVisitorListPath, getVistorDetailPath } from "src/utils/route-util";

import InvitationInfo from "../components/InvitationInfo";

import { VisitorFormData } from "src/api/visitor/visitor-types";
import PermissionInfo from "../components/PermissionInfo";
import VisitInfo from "../components/VisitInfo";
import VisitorAddAccessGroup from "../components/VisitorAddAccessGroup";
import VisitorInfo from "../components/VisitorInfo";
import { INVITEABLE_CONTRACT_STEP } from "../constants";
import useVisitor from "../hooks/useVisitor";
import useGetPermissionAcGroup from "../hooks/useGetPermissionAcGroup";
import VisitorOnlyViewAccessGroup from "../components/VisitorOnlyViewAccessGroup";
import { getReservationListAsync } from "src/api/reservation/reservation-api";

const tabs = [
  {
    label: "상세정보",
    value: "basic",
    disabled: false,
  },
];

function VisitorAdd() {
  const { setBaseModal } = useModal();
  const { contractApplyNumber, contractId } = useParams();
  const { queryParams } = useQueryParams<{ tab?: string; reservationId?: string }>();
  const navigate = useNavigate();

  // 현재 활성화 되어야하는 tab
  const activeTab = tabs.find((tab) => tab.value === queryParams?.tab) ?? tabs[0];

  const { buildingList, contractManage } = useVisitor({ contractApplyNumber: contractApplyNumber });

  // 계약 관리 출입 설정 상세 조회(커스텀 권한 조회)
  // visitorDefaultAccessGroupYn 값이 false 일 때 커스텀 권한으로 커스텀 출입그룹 등록가능
  // visitorDefaultAccessGroupYn 값이 true 일 때 기본 권한으로 신청계약 출입그룹을 보여주기만함 (수정불가)
  const { visitorDefaultAccessGroupYn } = useGetPermissionAcGroup(Number(contractManage?.contractManageId));

  //공용공간 예약에서 방문자 등록 페이지로 보낼때 예약 정보 조회
  //queryParams.reservationId 값이 있을 때 예약 정보 조회
  const { executeAsync: getReservationList } = useApiOperation(getReservationListAsync);

  const useFormReturn = useForm<VisitorFormData>({
    defaultValues: {
      invitationInfo: {
        //초대정보
        inviteeMemberId: "",
        inviteeMemberNo: "",
        //방문정보
        buildingId: "",
        visitStartTime: "",
        visitEndTime: "",
        meetingPlace: "", //만남장소
        visitorGuideMemo: "", //방문자 메모
        //승인정보
        approvalRequestMemo: "", //승인요청 메모
        //출입그룹
        accessGroupAddIdsList: [],
      },
      //방문자 정보
      visitorInfoList: [{ contractVisitorId: 0, invitationType: "phone", visitorEmailLang: "en" }],
    },
  });
  const { control, setValue, handleSubmit, trigger, getValues, reset } = useFormReturn;

  const {
    fields: formVisitorInfoList,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "visitorInfoList",
  });
  const { openToast } = useToast();

  const { executeAsync: postVisitor } = useApiOperation(postVisitorAsync);

  //방문자 초대 가능한 신청계약 상태인지 확인용
  const { executeAsync: getContractDetailBasicInfoAsync } = useApiOperation(getContractDetailBasicInfo);

  const onSubmit = async (data: VisitorFormData) => {
    if (data.visitorInfoList.length === 0) {
      setBaseModal({
        isOpen: true,
        title: "1명 이상의 방문자 등록이 필요합니다.",
        btnLeftTitle: "취소",
        btnRightTitle: "확인",
        onClick: () => {
          setBaseModal({ isOpen: false });
        },
      });
      return;
    }

    const contractVisitorList = data.visitorInfoList.map((visitorInfo) => {
      return {
        visitorName: visitorInfo.visitorName || "",
        visitorEmail: visitorInfo.visitorEmail || undefined,
        visitorEmailLang: visitorInfo.invitationType === "email" ? visitorInfo.visitorEmailLang || "" : "",
        visitorMobileNumber: visitorInfo.visitorMobileNumber ? parsedPhoneNumber(visitorInfo.visitorMobileNumber!) : "",
        visitorCarNumber: visitorInfo.visitorCarNumber,
      };
    });

    const postData = {
      contractManageId: Number(contractManage?.contractManageId),
      contractVisitorGroup: {
        contractManageId: Number(contractManage?.contractManageId),
        visitStartTime: moment(data.invitationInfo.visitStartTime).format(),
        visitEndTime: moment(data.invitationInfo.visitEndTime).format(),
        buildingId: Number(data.invitationInfo.buildingId),
        meetingPlace: data.invitationInfo.meetingPlace,
        inviteeMemberId: data.invitationInfo.inviteeMemberId,
        inviteeMemberNo: data.invitationInfo.inviteeMemberNo,
        visitorGuideMemo: data.invitationInfo.visitorGuideMemo,
        reservationId: queryParams.reservationId ? Number(queryParams.reservationId) : undefined,
        approvalRequestMemo: data.invitationInfo.approvalRequestMemo,
        contractVisitorList: contractVisitorList,
        accessGroupAddIdsList: data.invitationInfo?.accessGroupAddIdsList,
      },
    };

    console.log("contractVisitorGroup postData", postData);

    const result = await postVisitor(postData);

    console.log("contractVisitorGroup result", result);

    if (result.status >= 200 && result.status <= 299) {
      openToast({
        type: "SUCCESS",
        content: "방문자 초대가 완료되었습니다.",
      });
      navigate(
        getVistorDetailPath({
          visitApplyNumber: result?.data?.data?.contractVisitorGroup?.visitApplyNumber || "",
          contractApplyNumber: contractManage?.contractApplyNumber || "",
        }),
      );
    } else {
      openToast({
        type: "FAIL",
        content: "방문자 초대에 실패하였습니다.",
      });
    }
  };

  useEffect(() => {
    if (buildingList?.find((building) => building.isPrimary)?.buildingId) {
      //대표건물을 찾아서 id set
      const buildingId = String(buildingList?.find((building) => building.isPrimary)?.buildingId);
      setValue("invitationInfo.buildingId", buildingId);
    }
  }, [buildingList, contractManage, setValue]);

  useEffect(() => {
    if (queryParams.reservationId) {
      (async () => {
        const result = await getReservationList({ id: queryParams.reservationId });
        if (result.status === 200) {
          const reservationList = result.data.data.content;
          const reservation = reservationList.find((reservation) => {
            return String(reservation.id) === String(queryParams.reservationId);
          });

          if (!reservation) {
            return openToast({
              type: "FAIL",
              content: "예약 정보를 불러오는데 실패하였습니다.",
            });
          }

          reset({
            ...getValues(),
            invitationInfo: {
              //초대정보
              inviteeMemberId: reservation.organizer?.userEmail || "",
              inviteeMemberNo: reservation.organizer?.memberNo || "",
              // //방문정보
              buildingId: reservation.facility?.building?.id?.toString() || "",
              visitStartTime: reservation.start,
              visitEndTime: reservation.end,
              meetingPlace: reservation.facility?.name || "", //만남장소
              visitorGuideMemo: reservation.summary || "", //방문자 메모
            },
          });
        }
      })();
    }
  }, [queryParams.reservationId]);

  useEffect(() => {
    // 외부에서 접근시 계약 상태에 따라 확인 후 초대 가능한 계약인지 확인
    (async () => {
      try {
        const result = await getContractDetailBasicInfoAsync({ id: Number(contractId) });

        const _contractApplyNumber = result.data.data.contractApplyNumber;
        const contractStep = result.data.data.contractStep as ContractStep;

        if (_contractApplyNumber !== contractApplyNumber) {
          openToast({
            type: "FAIL",
            content: "잘못된 접근입니다.",
          });

          navigate(getVisitorListPath());
        }

        if (!INVITEABLE_CONTRACT_STEP.includes(contractStep)) {
          openToast({
            type: "FAIL",
            content: "초대할 수 없는 계약 상태입니다.",
          });

          navigate(getVisitorListPath() + `?contractApplyNumber=${contractApplyNumber}&contractId=${contractId}`);
        }
      } catch (error) {
        openToast({
          type: "FAIL",
          content: "계약 정보를 불러오는데 실패하였습니다.",
        });

        navigate(getVisitorListPath());
      }
    })();
  }, [contractId]);

  return (
    <div>
      <MetaTag isCreatePage />
      <ContentsTitle title="방문자 초대" tabs={tabs} activeTab={activeTab} />
      <div className="contents-container__scroll">
        <form className="" onSubmit={handleSubmit(onSubmit)}>
          <div className="contents-container__wrap page-visitor">
            <InvitationInfo useFormReturn={useFormReturn} pageType="add" />
            <VisitInfo useFormReturn={useFormReturn} pageType="add" />

            <VisitorInfo
              pageType="add"
              append={append}
              remove={remove}
              control={control}
              formVisitorInfoList={formVisitorInfoList}
              useFormReturn={useFormReturn}
            />

            <PermissionInfo useFormReturn={useFormReturn} pageType="add" />

            {visitorDefaultAccessGroupYn === false && contractManage?.contractManageId && (
              <VisitorAddAccessGroup useFormReturn={useFormReturn} contractManageId={contractManage?.contractManageId} />
            )}

            {visitorDefaultAccessGroupYn === true && <VisitorOnlyViewAccessGroup pageType="add" />}
          </div>
        </form>
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <GoToListButton />
        </div>
        <div className="right-area">
          <BaseButton
            title="저장"
            className="size-large"
            onClick={async () => {
              (await trigger()) &&
                setBaseModal({
                  isOpen: true,
                  title: "저장하시겠습니까?",
                  btnLeftTitle: "취소",
                  btnRightTitle: "확인",
                  onClick: () => {
                    handleSubmit(onSubmit)();
                    setBaseModal({ isOpen: false });
                  },
                });
            }}
          />
        </div>
      </div>
    </div>
  );
}
export default VisitorAdd;
