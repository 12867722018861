import { Control, Controller } from "react-hook-form";
import { Ac2AccessGroupModel, Ac2AccessGroupRequest } from "src/api/access/ac-group-types";
import { BaseButton, BaseInput } from "src/components";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";

type Props = {
  accessGroup?: Ac2AccessGroupModel;
  control?: Control<Ac2AccessGroupRequest>;
  onChangeEdit?: (type: "basicInfo" | "setting") => void;
};

const AcGroupBasicInfoForm = ({ accessGroup, control, onChangeEdit }: Props) => {
  return (
    <article className="contents-container__wrap-article">
      <div className="flex-center-between">
        <BaseSectionTitle title={"기본 정보"} />
        {accessGroup && (
          <div className="flex-center">
            {onChangeEdit && <BaseButton title="취소" className="size-small color-white mr10" onClick={() => onChangeEdit("basicInfo")} />}
            <BaseButton title="저장" className="size-small" type="submit" />
          </div>
        )}
      </div>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className={"required"}>출입그룹명</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax240">
            <Controller
              control={control}
              name="accessGroupName"
              rules={{ required: { value: true, message: "출입그룹명을 입력해주세요." } }}
              render={({ field: { onChange, value }, formState: { errors } }) => (
                <BaseInput
                  onChange={onChange} //
                  value={value}
                  placeholder="출입그룹명을 입력해주세요."
                  errorText={errors.accessGroupName?.message}
                />
              )}
            />
          </div>
        </div>
      </section>
    </article>
  );
};

export default AcGroupBasicInfoForm;
