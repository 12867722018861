import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useApiOperation } from "src/api/hooks";
import { addProviderAccountAsync, confirmAccountHolderAsync, editProviderAccountAsync, getBankCodeListAsync } from "src/api/provider/provider-api";
import { ProviderAccountAddModel, ProviderAccountList, ProviderAccountModel, ProviderDetailModel } from "src/api/provider/provider-types";
import { BaseButton, BaseInput, BaseModal, BaseSelect } from "src/components";
import { Modal } from "src/pages/product/product-types";
import { useErrorModal } from "src/recoil/errorModal/hook";
import { useToast } from "src/recoil/toast/hook";
import { onlyNumber } from "src/utils";
import { AccountTableHeaderWidth, accoutTypeOptions, PopbillErrorCodes } from "../../provider-constants";

type Props = {
  providerDetail?: ProviderDetailModel;
  providerId: number;
  providerDetailApi: (id: string) => Promise<void>;
  onEdit: () => void;
};

const ProviderTypeAccountForm = ({ providerId, providerDetail, providerDetailApi, onEdit }: Props) => {
  // 토스트
  const { openToast } = useToast();

  const { openErrorModal } = useErrorModal();

  // 경고 모달
  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
    payload: true,
  });

  // 저장 컨펌 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });

  const defaultValues = useMemo(() => {
    const providerPostData: ProviderAccountAddModel = {
      providerAccountList: [
        {
          providerId,
          bankCode: "",
          accountId: 0,
          accountNumber: "",
          accountHolder: "",
          accountType: "DEPOSIT_ACCOUNT",
          isDeleted: false,
        },
        {
          providerId,
          accountId: 0,
          bankCode: "",
          accountNumber: "",
          accountHolder: "",
          accountType: "RENT_ACCOUNT",
          isDeleted: false,
        },
      ],
    };

    return providerPostData;
  }, [providerId]);

  const {
    control,
    setValue,
    handleSubmit,
    watch,
    register,
    formState: { errors },
  } = useForm<ProviderAccountAddModel>({
    defaultValues,
  });

  // 은행리스트 select
  const [bankList, setBankList] = useState<Array<{ value: string; label: string }>>([]);

  // 팝빌 은행코드 api
  const { executeAsync: getBankCodeList } = useApiOperation(getBankCodeListAsync, { noLoading: true });

  // 팝빌 예금주확인 api
  const { executeAsync: confirmAccountHolder } = useApiOperation(confirmAccountHolderAsync, {
    noHandleError: true,
  });

  /**프로바이더 계좌 등록 api */
  const { executeAsync: postProviderAccount } = useApiOperation(addProviderAccountAsync, {
    noHandleError: true,
  });

  /**프로바이더 계좌 수정 api */
  const { executeAsync: editProviderAccount } = useApiOperation(editProviderAccountAsync, {
    noHandleError: true,
  });

  const {
    fields: accountList,
    append,
    remove,
    update,
  } = useFieldArray({
    control,
    name: "providerAccountList",
  });

  // 프로바이더 계좌 detail 정보를 setValue
  useEffect(() => {
    if (providerDetail) {
      const providerAccountList = providerDetail.providerAccountList;

      // 프로바이더 상세에 providerAccountList 가 있으면 setValue
      if (providerAccountList && providerAccountList?.length > 0) {
        setValue("providerAccountList", providerAccountList);
      }
    }
  }, [providerDetail, setValue]);

  //
  //
  // 은행코드 리스트
  const getBankCodeListApi = useCallback(async () => {
    const response = await getBankCodeList({ codeGroup: "POPBILL_BANK_CODE" });
    if (response.status >= 200 || response.status <= 200) {
      const result = response.data.data.content;

      const convertList = result.map((item) => {
        return { value: item.code, label: item.desc };
      });
      convertList.sort((a, b) => (a.label < b.label ? -1 : 1));
      setBankList(convertList);
    }
  }, [getBankCodeList]);

  //
  //
  // 예금주 조회 전 팝빌 인증 유효성검사
  const accountHolderValidation = (accountNumber: string, bankCode: string) => {
    if (!accountNumber && !bankCode) {
      // 은행 / 계좌번호 미입력 시
      setAlertModal({
        isOpen: true,
        message: "은행과 계좌번호를 모두 입력한 후 조회 버튼을 눌러주세요.",
        payload: false,
      });
      return false;
    } //
    else if (accountNumber.length < 8 || accountNumber.length > 14) {
      setAlertModal({
        isOpen: true,
        message: "8자 ~ 14자 숫자를 입력해주세요",
        payload: false,
      });
      return false;
    }

    return true;
  };

  //
  //
  //  예금주 정보 확인 api
  const confirmAccountHolderApi = async (accountNumber: string, bankCode: string, accountIdx: number) => {
    const check = accountHolderValidation(accountNumber, bankCode);

    if (check === true) {
      const response = await confirmAccountHolder({
        bankCode,
        accountNumber,
        providerId,
      });

      // 정상 200 코드
      if (response && response.status >= 200 && response.status <= 299) {
        const data = response.data.data;
        if (data.result && data.result === "100") {
          setValue(`providerAccountList.${accountIdx}.accountHolder`, data.accountName, { shouldValidate: true });
        } else {
          setAlertModal({
            isOpen: true,
            message: PopbillErrorCodes[data.result as keyof typeof PopbillErrorCodes] || "알 수 없는 오류가 발생했습니다.",
          });
          setValue(`providerAccountList.${accountIdx}.accountHolder`, "");
        }
      } else {
        const errors = response.data.meta;

        const errorMessage = errors.errorMessage;
        const errorCode = errors.errorCode;

        let message = "";

        if (errorMessage?.includes("-99004021")) {
          message = "링크아이디가 존재하지 않습니다.";
        } else if (errorMessage?.includes("-99003008")) {
          message = "고유번호의 회원이 존재하지 않습니다.";
        }

        openErrorModal({ errorMessage: message, errorCode, statusCode: response.status });
      }
    }
  };

  useEffect(() => {
    getBankCodeListApi();
  }, [getBankCodeListApi]);

  //

  //
  // 계좌 입력 유효성 확인
  const formValidation = useCallback(() => {
    accountList?.forEach((_, idx: number) => {
      register(`providerAccountList.${idx}.bankCode`, {
        required: { value: true, message: "등록할 계좌의 은행을 선택해주세요." },
      });

      register(`providerAccountList.${idx}.accountNumber`, {
        required: { value: true, message: "계좌번호를 입력해주세요." },
        minLength: { value: 8, message: "8자 ~ 14자 숫자를 입력해주세요" },
        maxLength: { value: 14, message: "8자 ~ 14자 숫자를 입력해주세요" },
      });
      register(`providerAccountList.${idx}.accountHolder`, {
        validate: {
          required: (value) => {
            let result = true;
            let message = "";

            if (!value) {
              result = false;
              message = "예금주 정보 확인은 필수사항입니다.";
              // setAlertModal({ isOpen: true, message });
            }

            return result || message;
          },
        },
      });
    });
  }, [accountList, register]);

  useEffect(() => {
    formValidation();
  }, [formValidation]);

  //
  //
  // 계좌정보 등록/수정 api
  const updateProviderAccountApi = async (data: ProviderAccountList) => {
    const providerAccountList = data.providerAccountList || [];
    providerAccountList.map((item) => (item.providerId = providerId));

    // 계좌의 accountId 가 0 이면 신규등록 / 아니면 계좌 수정
    const isAccountId = providerAccountList[0].accountId !== 0;
    const response = isAccountId
      ? await editProviderAccount({ providerAccountList: providerAccountList }) // 수정
      : await postProviderAccount({ providerAccountList }); //등록

    if (response.status >= 200 && response.status <= 200) {
      openToast({ content: `정상적으로 저장되었습니다.` });
      setConfirmModal({ isOpen: false });
      await providerDetailApi(String(response.data.data.providerId));
      onEdit();
    }
    // 에러메세지 노출
    else {
      const errorCode = response.data?.meta?.errorCode;
      const errorData = Object.keys(response.data?.meta?.errorData).length > 0 ? JSON.parse(response.data?.meta?.errorData) : null;
      let errorMessage = "";

      const detailMessage = errorData
        ? `(구분: ${errorData.supplyType === "RENTAL" ? "프로바이더" : "관리처"}, 파트너 ID: ${errorData.partnerId || "-"}, 프로바이더 ID: ${
            errorData.providerId || "-"
          }, 은행 코드: ${errorData.bankCode}, 계좌번호: ${errorData.accountNumber})`
        : "";

      if (errorCode === "eCT110") {
        errorMessage = `다른 파트너에 사용중인 계좌는 등록할 수 없습니다.${detailMessage ? `\n\n${detailMessage}` : ""}`;
      }

      if (errorCode === "eCT113") {
        errorMessage = `다른 프로바이더/관리처에 사용 중인 계좌는 등록할 수 없습니다.${detailMessage ? `\n\n${detailMessage}` : ""}`;
      }

      if (errorCode === "eCT114") {
        errorMessage = `다른 용도(보증금, 임대료, 관리비)에 사용 중인 계좌는 등록할 수 없습니다.${detailMessage ? `\n\n${detailMessage}` : ""}`;
      }

      if (errorCode) {
        openErrorModal({ errorMessage, errorCode, statusCode: response.status });
      }
    }
  };

  const onSubmit = (data?: ProviderAccountAddModel, e?: any) => {
    e.preventDefault();

    const depositAccounts = data?.providerAccountList?.filter((item) => item.accountType === "DEPOSIT_ACCOUNT")?.map((item) => item.accountNumber);

    const retalAccounts = data?.providerAccountList?.filter((item) => item.accountType === "RENT_ACCOUNT")?.map((item) => item.accountNumber);

    //저장시 목록의 중복계좌 체크
    // 보증금 계좌 중복
    const depositDuplicated = depositAccounts?.some((item) => depositAccounts.indexOf(item) !== depositAccounts.lastIndexOf(item));

    // 임대료 계좌 중복
    const rentalDuplicated = retalAccounts?.some((item) => retalAccounts.indexOf(item) !== retalAccounts.lastIndexOf(item));

    if (depositDuplicated) {
      setAlertModal({ isOpen: true, message: `보증금 계좌에 이미 등록된 계좌번호 입니다.` });
      return;
    } else if (rentalDuplicated) {
      setAlertModal({ isOpen: true, message: `임대료 계좌에 이미 등록된 계좌번호 입니다.` });
      return;
    }

    const isDepositAccount = data?.providerAccountList?.some((item) => item.accountType === "DEPOSIT_ACCOUNT");
    const isRentAccount = data?.providerAccountList?.some((item) => item.accountType === "RENT_ACCOUNT");

    if (!isDepositAccount || !isRentAccount) {
      setAlertModal({
        isOpen: true,
        message: `${(!isDepositAccount && "보증금") || (!isRentAccount && "임대료")} 계좌 정보는 필수입니다.`,
      });
      return;
    }

    setConfirmModal({ isOpen: true, message: "저장하시겠습니까?", payload: data });
  };

  const onError = useCallback((errors: any, e?: any) => {
    console.log("onError errors", errors);
    return false;
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="contents-container__sub-title">
          <div className="w-100 flex-center-between">
            <h2>계좌 정보</h2>
            <div className="flex-center-end ">
              <BaseButton title="취소" className="color-white mr10" onClick={onEdit} />
              <BaseButton title="저장" type="submit" />
            </div>
          </div>
        </div>
        <section className="contents-container__1200">
          <section className="contents-container__1200 account-table">
            <table className="inner-table" width="840">
              <thead>
                <tr className="border-bottom border-top">
                  <th className={`minmax${AccountTableHeaderWidth.minmax160}`}>
                    <span>용도</span>
                  </th>
                  <th className={`minmax${AccountTableHeaderWidth.minmax160}`}>
                    <span className="required">은행</span>
                  </th>
                  <th className={`minmax${AccountTableHeaderWidth.minmax200}`}>
                    <span className="required">계좌번호</span>
                  </th>
                  <th className={`minmax${AccountTableHeaderWidth.minmax250}`}>
                    <span>예금주</span>
                  </th>
                  <th className={`minmax${AccountTableHeaderWidth.minmax40}`}>
                    <button
                      className="base-add-btn"
                      onClick={(e) => {
                        e.preventDefault();
                        append({
                          providerId,
                          accountId: 0,
                          bankCode: "",
                          accountNumber: "",
                          accountHolder: "",
                          accountType: "RENT_ACCOUNT",
                          isDeleted: false,
                        });
                      }}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {accountList?.map((account: ProviderAccountModel & { id: string }, accountIdx: number) => {
                  return (
                    <Fragment key={account.id}>
                      <tr className={` ${account.isDeleted === true && "d-none"}`}>
                        {providerDetail?.provider?.supplyType === "RENTAL" && (
                          <td width={`${AccountTableHeaderWidth.minmax160}`}>
                            <Controller
                              control={control}
                              name={`providerAccountList.${accountIdx}.accountType`}
                              render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                                return (
                                  <BaseSelect
                                    stateOptions={accoutTypeOptions}
                                    isDisabled={account.accountId !== 0 && true}
                                    setStateValue={(value: string) => {
                                      onChange(value);
                                    }}
                                    value={value}
                                  />
                                );
                              }}
                            ></Controller>
                          </td>
                        )}

                        <td width={`${AccountTableHeaderWidth.minmax160}`} className="px10">
                          <Controller
                            control={control}
                            name={`providerAccountList.${accountIdx}.bankCode`}
                            render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                              return (
                                <BaseSelect
                                  stateOptions={bankList}
                                  isDisabled={account.accountId !== 0 && true}
                                  placeholder="은행 선택"
                                  setStateValue={(value: string) => {
                                    // 프로바이더 상세에 저장된 bankCode
                                    const accountNumberDetail = providerDetail?.providerAccountList?.[accountIdx]?.bankCode;

                                    // 기존 데이터 변경되면  예금주 정보 재확인 필요
                                    if (accountNumberDetail !== value) {
                                      setValue(`providerAccountList.${accountIdx}.accountHolder`, "");
                                    }
                                    onChange(value);
                                  }}
                                  value={value}
                                />
                              );
                            }}
                          ></Controller>
                        </td>

                        <td width={`${AccountTableHeaderWidth.minmax200}`}>
                          <Controller
                            control={control}
                            name={`providerAccountList.${accountIdx}.accountNumber`}
                            render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                              return (
                                <BaseInput
                                  onChange={(accountNumber: string) => {
                                    // 프로바이더 상세에 저장된 계좌번호
                                    const accountNumberDetail = providerDetail?.providerAccountList?.[accountIdx]?.accountNumber;

                                    // 기존 데이터 변경되면  예금주 정보 재확인 필요

                                    if (accountNumberDetail !== accountNumber) {
                                      setValue(`providerAccountList.${accountIdx}.accountHolder`, "");
                                    }
                                    onChange(accountNumber);
                                  }}
                                  value={value ? onlyNumber(value) : ""}
                                  name={name}
                                  placeholder="8자 ~ 14자 숫자를 입력"
                                  readonly={account.accountId !== 0 && true}
                                />
                              );
                            }}
                          ></Controller>
                        </td>

                        <td width={`${AccountTableHeaderWidth.minmax200}`} className="px10">
                          <div className="flex-center">
                            <Controller
                              control={control}
                              name={`providerAccountList.${accountIdx}.accountHolder`}
                              render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                                return <BaseInput onChange={onChange} value={value} name={name} readonly className="mr10" />;
                              }}
                            ></Controller>
                            <BaseButton
                              title={"예금주 조회"}
                              className=""
                              onClick={() => {
                                let accountNumber = watch(`providerAccountList.${accountIdx}.accountNumber`);
                                let bankCode = watch(`providerAccountList.${accountIdx}.bankCode`);

                                confirmAccountHolderApi(String(accountNumber), String(bankCode), accountIdx);
                              }}
                            />
                          </div>
                        </td>

                        <td width={`${AccountTableHeaderWidth.minmax40}`} className="text-center">
                          <button
                            className="base-erase-btn"
                            onClick={(e) => {
                              e.preventDefault();
                              if (!account.accountId) {
                                remove(accountIdx);
                              } else {
                                const deletedArr = accountList
                                  .filter((item) => item.accountId !== 0)
                                  .map((item) => {
                                    if (item.accountId === account.accountId) {
                                      item = { ...item, isDeleted: true };
                                    }
                                    return item;
                                  });

                                const depositAccounts = deletedArr?.filter((item) => item.accountType === "DEPOSIT_ACCOUNT");
                                const rentAccounts = deletedArr?.filter((item) => item.accountType === "RENT_ACCOUNT");

                                if (depositAccounts?.length < 2 && depositAccounts[0].isDeleted === true) {
                                  setAlertModal({
                                    isOpen: true,
                                    message: "1개 이상의 보증금 계좌정보가 필요합니다.",
                                  });
                                  return;
                                }

                                if (rentAccounts?.length < 2 && rentAccounts[0].isDeleted === true) {
                                  setAlertModal({
                                    isOpen: true,
                                    message: "1개 이상의 임대료 계좌정보가 필요합니다.",
                                  });
                                  return;
                                }

                                setConfirmModal({
                                  isOpen: true,
                                  message: "삭제하시겠습니까?",
                                  payload: { providerAccountList: deletedArr },
                                });
                                // update(accountIdx, { ...account, isDeleted: true });
                              }
                            }}
                          ></button>
                        </td>
                      </tr>
                      {Object.keys(errors).length > 0 && errors.providerAccountList?.[accountIdx] && (
                        <tr>
                          <td colSpan={5} className="validation-text td-validation" style={{ height: "20px" }}>
                            {errors.providerAccountList[accountIdx]?.bankCode?.message ||
                              errors.providerAccountList[accountIdx]?.accountNumber?.message ||
                              errors.providerAccountList[accountIdx]?.accountHolder?.message}
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  );
                })}
              </tbody>
            </table>
          </section>
        </section>
      </form>
      <>
        {confirmModal.isOpen && (
          <BaseModal
            isOpen={true}
            btnRightTitle="확인"
            btnLeftTitle="취소"
            title={confirmModal.message}
            onClose={() => setConfirmModal({ isOpen: false })}
            onClick={() => {
              updateProviderAccountApi(confirmModal.payload);
              setConfirmModal({ isOpen: false });
            }}
          ></BaseModal>
        )}

        {alertModal.isOpen && (
          <BaseModal
            isOpen={true}
            btnRightTitle="확인"
            title={alertModal.message}
            onClick={() => {
              setAlertModal({ isOpen: false });
            }}
          ></BaseModal>
        )}
      </>
    </>
  );
};

export default ProviderTypeAccountForm;
