import { useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { useParams } from "react-router-dom";
import { ApprovalStatusMap, PageType, VisitorFormData } from "src/api/visitor/visitor-types";
import { BaseButton, BaseInput, BaseSelect } from "src/components";
import usePatchVisitorGroup from "../hooks/usePatchVisitorGroup";
import useVisitor from "../hooks/useVisitor";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";

// 승인 정보 컴포넌트
// pageType을 컴포넌트 내부에서 추기로 관리함
const PermissionInfo = ({ useFormReturn, pageType: defaultPageType }: { pageType: PageType; useFormReturn: UseFormReturn<VisitorFormData> }) => {
  const { control, getValues } = useFormReturn;

  const { isAuthority } = usePartnerAuthority();

  const { contractApplyNumber, visitApplyNumber } = useParams();

  const [pageType, setPageType] = useState(defaultPageType);

  const { visitorGroup } = useVisitor({ contractApplyNumber: contractApplyNumber, visitApplyNumber: visitApplyNumber });

  const { mutate: patchVisitorGroup } = usePatchVisitorGroup();

  const patchVisitorGroupHandler = () => {
    patchVisitorGroup(
      {
        visitApplyNumber: visitApplyNumber!,
        contractVisitorGroup: {
          approvalStatus: getValues("invitationInfo.approvalStatus") || undefined,
          approvalAuthMemo: getValues("invitationInfo.approvalAuthMemo") || undefined,
        },
      },
      {
        onSuccess() {
          setPageType(defaultPageType);
        },
      },
    );
  };

  return (
    <article className="contents-container__wrap-article">
      <BaseSectionTitle
        title={"승인 정보"}
        noHashLink={pageType === "add"}
        rightArea={
          isAuthority("w") && (
            <>
              {/* 방문상태가 종료,삭제일때 수정불가 */}
              {visitorGroup?.visitStatus !== "VISIT_CLOSE" && visitorGroup?.visitStatus !== "VISIT_DELETE" && (
                <>
                  {visitorGroup?.approvalStatus === "APPROVAL_PENDING" && (
                    // 서버에서 내려온 승인상태가 대기 상태의 경우에만 수정모드 진입
                    <>
                      {pageType === "detail" && <BaseButton className="size-small" title="수정" onClick={() => setPageType("edit")} />}
                      {pageType === "edit" && (
                        <div className="flex-start-start gap10">
                          <BaseButton className="size-small color-white" title="취소" onClick={() => setPageType(defaultPageType)} />
                          <BaseButton className="size-small" title="저장" onClick={patchVisitorGroupHandler} />
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )
        }
      />
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>승인상태</p>
        </div>
        <div className="contents-container__grid-contents">
          {pageType === "add" && <p>-</p>}
          {pageType === "detail" && (
            <p>{(visitorGroup?.approvalStatus && ApprovalStatusMap[visitorGroup?.approvalStatus as keyof typeof ApprovalStatusMap]) || "-"}</p>
          )}
          {pageType === "edit" && (
            <Controller
              name="invitationInfo.approvalStatus"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <BaseSelect
                  //자동승인 상태일때 수정불가
                  className="minmax200"
                  value={field.value}
                  stateOptions={[
                    {
                      label: "승인대기",
                      value: "APPROVAL_PENDING",
                    },
                    {
                      label: "승인완료",
                      value: "APPROVAL_COMPLETE",
                    },
                    {
                      label: "반려",
                      value: "APPROVAL_REJECT",
                    },
                  ]}
                  setStateValue={(value: string) => {
                    field.onChange(value);
                  }}
                />
              )}
            />
          )}
        </div>
      </section>

      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>승인자</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="">
            <p>{visitorGroup?.approverMemberId || "-"}</p>
          </div>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>승인요청 메모</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="">
            {pageType === "detail" && <p>{visitorGroup?.approvalRequestMemo || "-"}</p>}
            {pageType === "edit" && <p>{visitorGroup?.approvalRequestMemo || "-"}</p>}
            {pageType === "add" && (
              <Controller
                name="invitationInfo.approvalRequestMemo"
                control={control}
                render={({ field }) => <BaseInput value={field.value} onChange={field.onChange} placeholder="승인요청 메모를 입력해 주세요" />}
              />
            )}
          </div>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>승인/반려 사유</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="">
            {(pageType === "detail" || pageType === "add") && <p>{visitorGroup?.approvalAuthMemo || "-"}</p>}
            {pageType === "edit" && (
              <Controller
                name="invitationInfo.approvalAuthMemo"
                control={control}
                render={({ field }) => (
                  <BaseInput
                    disabled={pageType !== "edit"}
                    value={field.value}
                    onChange={field.onChange}
                    placeholder="승인/반려 사유를 입력해 주세요"
                  />
                )}
              />
            )}
          </div>
        </div>
      </section>
    </article>
  );
};

export default PermissionInfo;
