import { useAuth0 } from "@auth0/auth0-react";
import moment from "moment";
import { Controller, UseFormReturn } from "react-hook-form";
import { useParams } from "react-router-dom";
import { PageType, VisitorFormData } from "src/api/visitor/visitor-types";
import { BaseButton } from "src/components";
import BaseNewTabLink from "src/components/BaseNewTabLink";
import RvMembersModal from "src/pages/reservation/form/components/RvMembersModal";
import { useModal } from "src/recoil/modalState/hook";
import { getContractDetailPath, getProductDetailPath } from "src/utils/route-util";
import useVisitor from "../hooks/useVisitor";
import { getFormErrorText } from "../utils";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";

// 초대 정보 컴포넌트
const InvitationInfo = ({ useFormReturn, pageType }: { pageType: PageType; useFormReturn: UseFormReturn<VisitorFormData> }) => {
  const {
    watch,
    control,
    setValue,
    formState: { errors },
  } = useFormReturn;
  const { setAbstractModalZ1 } = useModal();
  const { user } = useAuth0();
  const { contractApplyNumber, visitApplyNumber } = useParams();

  const { visitorGroup, contractManage, contractId } = useVisitor({ contractApplyNumber: contractApplyNumber, visitApplyNumber: visitApplyNumber });

  return (
    <article className="contents-container__wrap-article">
      <BaseSectionTitle title={"초대 정보"} noHashLink={pageType === "add"} />

      <div className="flex-start">
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className={pageType !== "detail" ? "required" : ""}>초대자</p>
          </div>
          <div className="contents-container__grid-contents">
            {pageType === "add" ? (
              <div className="">
                <Controller
                  name="invitationInfo.inviteeMemberId"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <div className="flex-center-start flex-row gap10">
                      <BaseButton
                        className="color-white"
                        title="관리자"
                        onClick={() => {
                          setValue("invitationInfo.inviteeMemberId", user?.email || "");
                          setValue("invitationInfo.inviteeMemberNo", "");
                        }}
                      ></BaseButton>
                      <BaseButton
                        title="이용자"
                        className="color-white"
                        onClick={() => {
                          setAbstractModalZ1({
                            isOpen: true,
                            children: (
                              <RvMembersModal
                                addedItems={[watch("invitationInfo.inviteeMemberId") ?? ""]}
                                title="이용자"
                                checkboxType="radio"
                                selectKey="email"
                                onClick={(members) => {
                                  const member = members[0];

                                  field.onChange(member.email);
                                  setValue("invitationInfo.inviteeMemberNo", member.memberNo);
                                }}
                                onClose={() => setAbstractModalZ1({ isOpen: false })}
                                applyNumber={contractManage?.contractApplyNumber ?? ""}
                                contractName={contractManage?.contractName ? contractManage?.contractName : contractManage?.spaceProductName ?? ""}
                              />
                            ),
                          });
                        }}
                      ></BaseButton>
                      <span>{field.value}</span>
                    </div>
                  )}
                />
                {errors.invitationInfo?.inviteeMemberId && (
                  <p className="validation-text">{getFormErrorText(errors.invitationInfo?.inviteeMemberId?.type!)}</p>
                )}
              </div>
            ) : (
              <p>{watch("invitationInfo.inviteeMemberId") || "-"}</p>
            )}
          </div>
        </section>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>회원번호</p>
          </div>
          <div className="contents-container__grid-contents">
            <p>{watch("invitationInfo.inviteeMemberNo") || "-"}</p>
          </div>
        </section>
      </div>
      <div className="flex-start">
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>신청/계약번호</p>
          </div>
          <div className="contents-container__grid-contents">
            <p>
              <BaseNewTabLink
                path={getContractDetailPath({
                  contractId: contractId?.toString() ?? "",
                })}
                value={contractManage?.contractApplyNumber || "-"}
              />
            </p>
          </div>
        </section>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>신청/계약명</p>
          </div>
          <div className="contents-container__grid-contents">
            <p>{contractManage?.contractName ? contractManage?.contractName : contractManage?.spaceProductName || "-"}</p>
          </div>
        </section>
      </div>
      <div className="flex-start">
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>초대일시</p>
          </div>
          <div className="contents-container__grid-contents">
            <p>{visitorGroup?.invitationDate ? moment(visitorGroup?.invitationDate).format("YYYY.MM.DD HH:mm:ss") : "-"}</p>
          </div>
        </section>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>초대경로</p>
          </div>
          <div className="contents-container__grid-contents">
            <p>{visitorGroup?.invitationChannel || "-"}</p>
          </div>
        </section>
      </div>
    </article>
  );
};

export default InvitationInfo;
