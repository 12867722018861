import { Ac2AccessGroupModel } from "src/api/access/ac-group-types";
import { BaseButton } from "src/components";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";

type Props = {
  accessGroup: Ac2AccessGroupModel;
  onChangeEdit: (type: "basicInfo" | "setting") => void;
  isAuthority: boolean;
};

const AcGroupBasicInfo = ({ accessGroup, onChangeEdit, isAuthority }: Props) => {
  const { id, accessGroupName } = accessGroup;

  return (
    <article className="contents-container__wrap-article">
      <div className="flex-center-between">
        <BaseSectionTitle title={"기본 정보"} />
        {isAuthority && <BaseButton title="수정" className="size-small" onClick={() => onChangeEdit("basicInfo")} />}
      </div>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>ID</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span>{id || "-"}</span>
          </div>
        </div>
      </section>

      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="">출입그룹명</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax240">
            <span>{accessGroupName || "-"}</span>
          </div>
        </div>
      </section>
    </article>
  );
};

export default AcGroupBasicInfo;
