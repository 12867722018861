import { AxiosInstance, AxiosResponse } from "axios";
import _ from "lodash";
import qs from "qs";
import ApiPath from "src/api/apiPath.json";
import { ContractUseRequestList } from "../contract/contract-types";
import { ApiResponse } from "../public-types";
import { GetIssueCountCalendar, GetIssueCountCalendarResponse, GetUsersParams, UserListModel } from "./user-types";

/**
 * 이용자 목록 조회
 * @param axios
 * @param params
 * @returns
 */

//
//  CCA19. 계약(이용) 중인 신청/계약 목록 조회
const getContractUseRequests = (axios: AxiosInstance, params?: any): Promise<AxiosResponse<ApiResponse<{ content: ContractUseRequestList[] }>>> => {
  let path = `/api/central/ct/member/${params?.memberNo}/contract`;

  // return axios.get<ApiResponse<UserListModel[]>>(path, { params });
  return axios.get<ApiResponse<{ content: ContractUseRequestList[] }>>(path, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
};

// CCA40. 이용자 목록 페이지 조회
const getUsers = async (axios: AxiosInstance, params?: GetUsersParams): Promise<AxiosResponse<ApiResponse<UserListModel[]>>> => {
  let path = ApiPath.user.list;

  return await axios.get<ApiResponse<UserListModel[]>>(path, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
};

const addUsers = (axios: AxiosInstance, body?: any): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.user.add.replace("{contractManageId}", body.contractManageId ? body.contractManageId : body.contractId.toString());
  const send = { contractMemberList: body.contractMemberList, partnerId: Number(body.partnerId) };
  return axios.post<ApiResponse<any>>(path, send);
};

/**
 * 방문자 목록 조회
 * @param axios
 * @param params
 * @returns
 */
const getUser = (axios: AxiosInstance, params?: any): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.user.detail.replace("{contractMemberId}", params!.contractMemberId!);
  return axios.get<ApiResponse<any>>(path, { params });
};

/**
 * 방문자 목록 조회
 * @param axios
 * @param params
 * @returns
 */
const delUser = (
  axios: AxiosInstance,
  params?: {
    contractMemberId?: number;
    contractManageId?: number;
    isAdmin?: boolean;
    delete?: boolean;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  // console.log('params', params);
  const send: any = {
    contractMember: {
      contractMemberId: params?.contractMemberId,
      isDeleted: params?.delete,
    },
  };
  if (params?.delete) {
    send.contractMember.isDeleted = params?.delete;
  }
  if (params?.isAdmin !== undefined) {
    send.contractMember.isAdmin = params?.isAdmin;
  }
  const path = ApiPath.user.delete.replace("{contractManageId}", String(params?.contractManageId));
  return axios.patch<ApiResponse<any>>(path, send);
};

/**
 * 캘린더 연동 상태 조회
 * @param axios
 * @param params
 * @returns
 */
const calendarStatus = (
  axios: AxiosInstance,
  params?: {
    contractId: number;
    memberNo: string;
    userEmail: string;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  // console.log('params', params);
  const send = {
    contractId: params?.contractId,
    memberNo: params?.memberNo,
    userEmail: params?.userEmail,
  };
  const path = ApiPath.user.calendarStatus.replace("{memberNo}", String(params?.memberNo));
  return axios.patch<ApiResponse<any>>(path, send);
};

/**
 * 캘린더 연동 상태 조회
 * @param axios
 * @param params
 * @returns
 */
const watchCalendar = (
  axios: AxiosInstance,
  params?: {
    contractId: number;
    memberNo: string;
    userEmail: string;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  // console.log('params', params);
  const send = {
    contractId: params?.contractId,
    memberNo: params?.memberNo,
    userEmail: params?.userEmail,
  };
  const path = ApiPath.user.watchCalendar.replace("{memberNo}", String(params?.memberNo));
  return axios.post<ApiResponse<any>>(path, send);
};
/**
 * 캘린더 연동 상태 조회
 * @param axios
 * @param params
 * @returns
 */
const unWatchCalendar = (
  axios: AxiosInstance,
  params?: {
    contractId: number;
    memberNo: string;
    userEmail: string;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  // console.log('params', params);
  const send = {
    contractId: params?.contractId,
    memberNo: params?.memberNo,
    userEmail: params?.userEmail,
  };
  const path = ApiPath.user.unWatchCalendar.replace("{memberNo}", String(params?.memberNo));
  return axios.post<ApiResponse<any>>(path, send);
};

/**
 * RRV33. 이용자 캘린더 연동 처리

 * @param axios
 * @param params
 * @returns
 */
export const subscribeCalendar = (
  axios: AxiosInstance,
  params?: {
    contractId: number;
    memberNo: string;
    active: boolean;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.user.subscribeCalendar.replace("{contractId}", String(params?.contractId)).replace("{memberNo}", String(params?.memberNo));
  return axios.put<ApiResponse<any>>(path, { active: params?.active });
};

/**
 * 캘린더 연동 재시도 처리
 * @param axios
 * @param params
 * @returns
 */
const retryWatchCalendar = (
  axios: AxiosInstance,
  params?: {
    contractId: number;
    memberNo: string;
    userEmail: string;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  // console.log('params', params);
  const send = {
    contractId: params?.contractId,
    memberNo: params?.memberNo,
    userEmail: params?.userEmail,
  };
  const path = ApiPath.user.retryWatchCalendar.replace("{memberNo}", String(params?.memberNo));
  return axios.post<ApiResponse<any>>(path, send);
};

/**
 * 캘린더 연동 이슈해결 처리
 * @param axios
 * @param params
 * @returns
 */
const resolveWatchCalendar = (
  axios: AxiosInstance,
  params?: {
    contractId: number;
    memberNo: string;
    userEmail: string;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  // console.log('params', params);
  const send = {
    contractId: params?.contractId,
    memberNo: params?.memberNo,
    userEmail: params?.userEmail,
  };
  const path = ApiPath.user.resolveCalendar.replace("{memberNo}", String(params?.memberNo));
  return axios.post<ApiResponse<any>>(path, send);
};

// CCA47c. 캘린더동기화 실패 카운트 조회
const getIssueCountCalendar = (
  axios: AxiosInstance,
  params?: GetIssueCountCalendar,
): Promise<AxiosResponse<ApiResponse<GetIssueCountCalendarResponse>>> => {
  const path = ApiPath.user.issueCountCalendar.replace("{contractManageId}", String(params?.contractManageId));
  return axios.get<ApiResponse<GetIssueCountCalendarResponse>>(path);
};

const getIssueCountUser = (axios: AxiosInstance, params?: any): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.user.issueCalendarUsers.replace("{contractManageId}", params?.contractManageId!);
  return axios.get<ApiResponse<any>>(path, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
};

const getIssueUserDetail = (axios: AxiosInstance, params?: any): Promise<AxiosResponse<ApiResponse<any>>> => {
  let path = ApiPath.user.issueCalendarUserDetail.replace("{contractManageId}", params?.contractManageId!).replace("{memberNo}", params?.memberNo!);
  return axios.get<ApiResponse<any>>(path);
};

// 재초대 - 더이상 사용 X
const retryInviteUser = (
  axios: AxiosInstance,
  params?: {
    contractMemberId?: number;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  // console.log('params', params);

  const path = ApiPath.user.retryInvite.replace("{contractMemberId}", String(params?.contractMemberId));
  return axios.patch<ApiResponse<any>>(path);
};

export {
  getUsers,
  addUsers,
  getUser,
  delUser,
  getContractUseRequests,
  calendarStatus,
  watchCalendar,
  unWatchCalendar,
  retryInviteUser,
  retryWatchCalendar,
  resolveWatchCalendar,
  getIssueCountCalendar,
  getIssueCountUser,
  getIssueUserDetail,
};
