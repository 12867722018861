import { I18n } from "i18n-js";
import { localesKo } from "./locales/ko";
import { localesEn } from "./locales/en";
import _ from "lodash";

// 브라우저에서 사용중인 언어 확인
const browserLanguage = navigator.language.split("-")[0];

// URL에서 lang 쿼리 파라미터 확인
const getUrlLangParam = (): string | null => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("lang");
};

// 다국어 설정 JSON 형태로 변환
const flatToNestedObject = (flat: { [props: string]: any }) => {
  const nested: { [props: string]: any } = {};
  Object.keys(flat).forEach((path) => {
    _.set(nested, path, flat[path]);
  });

  return nested;
};

const determineLocale = (): string => {
  // 브라우저가 영어면 무조건 영어 반환
  if (browserLanguage === "en") return "en";

  // 브라우저가 한국어인 경우에만 URL 파라미터 확인
  if (browserLanguage === "ko") {
    const urlLang = getUrlLangParam();
    // URL 파라미터 값에 따라 언어 결정
    return urlLang === "en" ? "en" : "ko";
  }

  // 다른 모든 언어는 한국어로 기본 설정
  return "ko";
};

// i18n 인스턴스 생성
const i18n = new I18n(
  {
    en: flatToNestedObject(localesEn),
    ko: flatToNestedObject(localesKo),
  },
  {
    defaultLocale: "ko",
    locale: determineLocale(),
    enableFallback: true,
  },
);

export default i18n;

// ==============
