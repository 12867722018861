import qs from "qs";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Ac2AccessGroupModel } from "src/api/access/ac-group-types";
import { getAccessGroupListAsync } from "src/api/access/productac-api";
import { AccessPageQueryParams } from "src/api/access/productac-types";
import { useApiOperation } from "src/api/hooks";
import { Order, PageMeta } from "src/api/public-types";
import { BaseButton, BaseInputWithSearch } from "src/components";
import { BaseTable } from "src/components/BaseTable";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import useNavigate from "src/hooks/usePartnerNavigate";
import { PagePath } from "src/pages/product/details";
import { accessGroupSearchType } from "../const";
import AccessGroupColumns from "./AccessGroupColumns";

const AccessGroupList = () => {
  const location = useLocation();

  const { isAuthority } = usePartnerAuthority();
  const navigate = useNavigate();
  const [pageMeta, setPageMeta] = useState<PageMeta>();

  const queryParams = useMemo(() => {
    const query: AccessPageQueryParams = qs.parse(location.search, { allowDots: true, ignoreQueryPrefix: true });
    if (!query.page) {
      query.page = 0;
    }
    if (!query.size) {
      query.size = 20;
    }
    if (!query?.sort) {
      query.sort = {
        orders: [{ property: "id", direction: "DESC" }],
      };
    }

    return query;
  }, [location]);

  // 액세스그룹
  const [accessGroups, setAccessGroups] = useState<Ac2AccessGroupModel[]>([]);

  const [searchParams, setSearchParams] = useState<AccessPageQueryParams>(queryParams);

  const { executeAsync: getAccessList } = useApiOperation(getAccessGroupListAsync);

  const getAccessGroupsList = async (passParams?: AccessPageQueryParams) => {
    // 조회
    const { data } = await getAccessList(passParams);
    setAccessGroups(data?.data.content || null);
    setPageMeta(data?.meta.pageMeta);
  };

  useEffect(() => {
    setSearchParams(queryParams);
    getAccessGroupsList(queryParams);
  }, [location]);

  // URL 쿼리 파라미터 업데이트 함수
  const updateQueryParams = (newParams: Partial<AccessPageQueryParams>) => {
    const currentValues = searchParams;
    const updatedParams = { ...currentValues, ...newParams };

    // 빈 검색어는 파라미터에서 제거
    if (updatedParams.searchValue === "") {
      delete updatedParams.searchValue;
    }
    console.log("newParams", newParams);
    const queryString = qs.stringify(updatedParams, { allowDots: true });
    navigate(location.pathname + (queryString ? `?${decodeURIComponent(queryString)}` : ""));
  };

  // 검색 실행 함수
  const handleSearch = () => {
    const { searchType, searchValue } = searchParams;
    updateQueryParams({ ...searchParams, page: 0, searchType, searchValue });
  };

  // 검색 초기화 함수
  const clearSearch = () => {
    setSearchParams({ ...searchParams, page: 0, searchValue: "", searchType: "" });
  };

  // 페이지 변경 함수
  const handleGoPage = (page: number) => {
    updateQueryParams({ ...searchParams, page });
  };

  // 페이지 크기 변경 함수
  const handleViewSize = (size: number) => {
    updateQueryParams({ ...searchParams, page: 0, size });
  };

  // 정렬 변경 함수
  const handleSort = (orders: Order[]) => {
    updateQueryParams({ ...searchParams, sort: { orders } });
  };

  return (
    <div className="page-product-list">
      <div className="contents-container__table">
        <div className="contents-container__search-wrap">
          <div className="left-area">
            <section>
              <div className="left-area__index">
                <span>조건검색</span>
              </div>
              <div className="left-area__contents">
                <BaseInputWithSearch
                  type="text"
                  selectValue={searchParams.searchType}
                  inputValue={searchParams.searchValue}
                  stateOptions={accessGroupSearchType}
                  setStateValue={(value: string) => setSearchParams({ ...searchParams, searchType: value })}
                  onChange={(value: string) => setSearchParams({ ...searchParams, searchValue: value })}
                  onClearClick={clearSearch}
                  onKeyUp={() => handleSearch()}
                  onSearchClick={handleSearch}
                />
              </div>
            </section>
          </div>
          <div className="right-area">
            {isAuthority("w") && <BaseButton className="ml20" title="+ 출입그룹 등록" onClick={() => navigate(PagePath.accessGroup.form)} />}
          </div>
        </div>
        <BaseTable
          data={accessGroups}
          currentSize={Number(queryParams.size) || 20}
          sizeOption={handleViewSize}
          pageIndex={Number(queryParams?.page || 0)}
          totalElements={pageMeta?.totalElements}
          totalPages={pageMeta?.totalPages || 0}
          goPage={handleGoPage}
          orders={queryParams?.sort?.orders}
          setOrders={handleSort}
          disabledSortHeaders={AccessGroupColumns.map((column) => column.accessor)}
          columns={AccessGroupColumns}
        />
      </div>
    </div>
  );
};

export default AccessGroupList;
