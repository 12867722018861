import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { providerDetailAsync } from "src/api/provider/provider-api";
import { ProviderDetailModel } from "src/api/provider/provider-types";
import { BaseModal, ContentsTitle } from "src/components";
import MetaTag from "src/components/layout/MetaTag";
import useNavigate from "src/hooks/usePartnerNavigate";
import { PagePath } from "src/pages/product/details";
import { Modal } from "src/pages/product/product-types";
import ProviderAccountInfo from "./accountInfo/ProviderAccountInfo";
import BasicInfoForm from "./basicInfoForm/BasicInfoForm";
import ContactInfoForm from "./contactInfoForm/ContactInfoForm";
import HistoryDetail from "src/components/histroy/HistoryDetail";

const tabs = [
  { value: "basicInfo", label: "기본정보" },
  { value: "contactInfo", label: "연락처 정보" },
  { value: "accountInfo", label: "결제 연동 정보" },
  { value: "history", label: "변경내역" },
];

const ProviderForm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );
  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (queryParams?.tab) {
      return tabs.find((tab) => tab.value === queryParams?.tab);
    }
    return tabs[0];
  }, [queryParams]);

  // 탭 클릭시 callback 함수
  const clickTab = useCallback(
    (tab: { value: string; label: string }) => {
      // 기본정보 가 작성되지 않은 상태에서, 다른 탭으로 이동하려고 할 경우.
      if (tab.value !== "basicInfo" && !queryParams.id) {
        setModal({
          isOpen: true,
          message: "기본정보가 저장되지 않았습니다.",
        });
        return;
      }
      const newQueryParams = { ...queryParams };
      if (newQueryParams?.tab) {
        delete newQueryParams.tab;
      }

      newQueryParams.tab = tab.value;

      // query parameter 에 tab 추가
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location, queryParams],
  );

  // breadCrumb 클릭시 callback 함수
  const clickBreadCrumb = useCallback(
    (crumb: { value: string; label: string }) => {
      if (crumb.value === "provider") {
        navigate(PagePath.provider.list);
      }
    },
    [navigate],
  );

  const breadCrumbs = [{ value: "provider", label: "프로바이더" }];
  if (activeTab) {
    // 마지막 bread crumb 는 현재 선택된 탭
    breadCrumbs.push({
      value: activeTab.value,
      label: activeTab.label,
    });
  }

  const [modal, setModal] = useState<Modal>({
    isOpen: false,
  });

  const providerId: number | undefined = queryParams?.id ? Number(queryParams.id) : undefined;
  const [providerDetail, setProviderDetail] = useState<ProviderDetailModel>();
  // 프로바이더 상세
  const { executeAsync: getProviderDetail } = useApiOperation(providerDetailAsync);

  // 프로바이더 상세 조회 후 setValue 처리
  const providerDetailApi = useCallback(
    async (id: string) => {
      const response = await getProviderDetail({ providerId: id });
      if (response.status >= 200 || response.status <= 299) {
        const detail: ProviderDetailModel = response.data.data;
        setProviderDetail(detail);
      }
    },
    [getProviderDetail],
  );

  useEffect(() => {
    if (providerId) {
      providerDetailApi(String(providerId));
    }
  }, [providerDetailApi, providerId]);

  return (
    <div className="page-product-access">
      <MetaTag subTitle={providerDetail?.provider?.providerName} isCreatePage={providerId === undefined} />
      <ContentsTitle
        title="정산정보"
        tabs={tabs}
        activeTab={activeTab}
        clickTab={clickTab}
        breadCrumbs={breadCrumbs}
        detailInfo={[{ title: "id", value: queryParams.id ? String(queryParams.id) : "" }]}
        clickBreadCrumb={clickBreadCrumb}
      />
      {/* 탭 */}
      {/* 기본정보 */}
      {activeTab?.value === "basicInfo" && <BasicInfoForm providerDetail={providerDetail!} />}

      {/* 연락처 정보 */}
      {activeTab?.value === "contactInfo" && <ContactInfoForm />}

      {/* 연동 정보 */}

      {activeTab?.value === "accountInfo" && <ProviderAccountInfo />}

      {/* 변경내역 */}
      {activeTab?.value === "history" && providerId && <HistoryDetail identifier={{ providerId: String(providerId) }} />}

      {modal.isOpen && (
        <BaseModal
          isOpen={modal.isOpen}
          btnRightTitle={"확인"}
          onClick={() => setModal({ ...modal, isOpen: false })}
          title={modal.message}
        ></BaseModal>
      )}
    </div>
  );
};

export default ProviderForm;
