import { useCallback, useEffect, useMemo, useState } from "react";
import { Cell, useBlockLayout, useTable } from "react-table";
import { editContractAcGroupManageAsync, getContractAcGroupManageAsync } from "src/api/access/ac-group-api";
import { Ac2AccessGroupModel, AcGroupContractManageModel } from "src/api/access/ac-group-types";
import { getAccessGroupListAsync } from "src/api/access/productac-api";
import { Ac2AccessDeviceModel } from "src/api/access/productac-types";
import { DetailTotalData } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import { BaseButton } from "src/components";
import { getStatusMessage } from "src/components/ErrorModal";
import GoToListButton from "src/components/GoToListButton";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import { visitorSettings } from "src/pages/accessGroup/const";
import { Modal } from "src/pages/building/building-types";
import SearchAccessLog from "src/pages/commonPopup/SearchAccessLog";
import AccessGroupDetailModal from "src/pages/product/components/AccessGroupDetailModal";
import SelectAcGroupModal from "src/pages/product/forms/accessLevelForm/modals/SelectAcGroupModal";
import { useModal } from "src/recoil/modalState/hook";
import UserAndVisitorAccess from "./components/UserAndVisitorAccess";

interface ContractBasicProp {
  detailData: DetailTotalData; //상품 상세 조회 정보
}

type ErrorData = {
  error: { errorCode: string; errorMessage: string; status: number };
};

const ContractAccessGroup = ({ detailData }: ContractBasicProp) => {
  const { isAuthority } = usePartnerAuthority();

  // 출입그룹 관리 테이블 컬럼
  const acGroupColumns: any[] = useMemo(
    () => [
      {
        Header: "출입그룹 id",
        accessor: "id",
        width: 80,
      },
      {
        Header: "출입 그룹명",
        accessor: "accessGroupName",
        width: 360,
        Cell: ({ row, value }: Cell<Ac2AccessGroupModel & ErrorData>) => {
          const error = row?.original?.error;
          const errorMessage = error?.status && getStatusMessage(error?.status);
          const errorCode = error?.errorCode;
          return (
            <div className="w-100 text-left">
              <p>{value}</p>
              {errorMessage && <p className="font12 text-red mt3">{`${errorMessage} ${errorCode && `[${errorCode}]`}`}</p>}
            </div>
          );
        },
      },
      {
        Header: "이용자 출입",
        accessor: "memberAccessYn",
        width: 100,
        Cell: ({ value }: Cell<Ac2AccessGroupModel>) => {
          return <span>{value ? "출입가능" : "출입불가"}</span>;
        },
      },
      {
        Header: "방문자 초대",
        accessor: "visitorAccessYn",
        width: 100,
        Cell: ({ value }: Cell<Ac2AccessGroupModel>) => {
          return <span>{value ? "초대가능" : "초대불가"}</span>;
        },
      },
      {
        Header: "Taap 노출",
        accessor: "visitorAccessGroupSelectableYn",
        width: 100,
        Cell: ({ value }: Cell<Ac2AccessGroupModel>) => {
          return (
            <span>
              {visitorSettings.find((item) => item.name === "visitorAccessGroupSelectableYn")?.options.find((option) => option.value === value)
                ?.label || "-"}
            </span>
          );
        },
      },
      {
        Header: "관리자 승인",
        accessor: "visitorInvitationApprovalYn",
        width: 100,
        Cell: ({ value }: Cell<Ac2AccessGroupModel>) => {
          return (
            <span>
              {visitorSettings.find((item) => item.name === "visitorInvitationApprovalYn")?.options.find((option) => option.value === value)?.label ||
                "-"}
            </span>
          );
        },
      },
      {
        Header: "QR 발급",
        accessor: "visitorAccessType",
        width: 100,
        Cell: ({ value }: Cell<Ac2AccessGroupModel>) => {
          return (
            <span>
              {visitorSettings.find((item) => item.name === "visitorAccessType")?.options.find((option) => option.value === value)?.label || "-"}
            </span>
          );
        },
      },

      {
        Header: "출입장치",
        accessor: "accessDeviceView",
        width: 80,
        Cell: ({ row }: Cell<Ac2AccessGroupModel>) => {
          return (
            <div
              className="flex-center-center"
              onClick={() => {
                setAccessGroupId(Number(row.original.id));
                setShowDetailModal({ isOpen: true, payload: row.original.accessGroupName });
              }}
            >
              <span className="cursor-pointer text-underline text-blue900">보기</span>
            </div>
          );
        },
      },
    ],
    [],
  );
  // 삭제 컬럼
  const etc: any[] = useMemo(
    () => [
      {
        Header: "삭제",
        accessor: "",
        width: 50,
        Cell: ({ row }: Cell<Ac2AccessGroupModel>) => {
          return (
            <button
              className="base-trash-btn color-gray"
              onClick={(e) => {
                e.preventDefault();
                setBaseModal({
                  isOpen: true,
                  title: "해당 출입그룹을 삭제합니다",
                  children: (
                    <div>
                      <p className=" text-gray600">삭제한 즉시 해당 출입그룹에 액세스 할 수 없습니다</p>
                    </div>
                  ),
                  onClick: () => {
                    onRemoveAccessGroup(row.original.id);
                  },
                  onClose: () => {
                    setBaseModal({ isOpen: false });
                  },
                  btnLeftTitle: "취소",
                  btnRightTitle: "확인",
                });
              }}
            ></button>
          );
        },
      },
    ],

    [detailData],
  );

  // 계약 출입그룹 등록된 목록
  const [contractManage, setContractManage] = useState<AcGroupContractManageModel>();

  // 출입그룹 관리 목록
  const [contractAcGroupList, setContractAcGroupList] = useState<Ac2AccessGroupModel[]>([]);

  const { setBaseModal } = useModal();
  // 출입그룹 선택 팝업
  const [showSelectModal, setShowSelectModal] = useState(false);

  // 출입기록 조회 팝업
  const [accessRecordModal, setAccessRecordModal] = useState(false);

  // 상세정보 팝업
  const [showDetailModal, setShowDetailModal] = useState<Modal>({ isOpen: false });

  const contractManageId = useMemo(() => Number(detailData?.contract?.contractManageId), [detailData]);

  const contractApplyNumber = useMemo(() => detailData?.contractManage?.contractApplyNumber, [detailData]);
  //
  const checkAuth = useMemo(() => {
    let cols: any[] = acGroupColumns;
    // 출입그룹 관리 > 테이블 > 삭제 아이콘 처리 > "d" 권한이 있으면 노출
    if (isAuthority("d")) {
      cols[cols.length - 1].width = 80;
      cols = cols.concat(etc);
    }
    return cols;
  }, [acGroupColumns, etc, isAuthority]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    { data: contractAcGroupList || [], columns: checkAuth },
    useBlockLayout,
  );

  //팝업 출입그룹 목록
  const { executeAsync: getAccessList } = useApiOperation(getAccessGroupListAsync);

  // 계약 관리 출입 설정 상세 조회
  const { executeAsync: getContractAcGroup } = useApiOperation(getContractAcGroupManageAsync);

  // 계약 관리 출입 설정 수정
  const { executeAsync: editContractAcGroup } = useApiOperation(editContractAcGroupManageAsync);

  useEffect(() => {
    if (contractManageId) {
      fetchContractAccess(Number(contractManageId));
    }
  }, [contractManageId]);

  // 계약 출입그룹 상세 및 목록 api
  const fetchContractAccess = async (id: number) => {
    console.log("id", id);
    const { data } = await getContractAcGroup({ contractManageId: Number(id) });
    if (data.data) {
      setContractManage(data.data.contractManage);
      const ids = data.data.contractManage.accessGroupIds;
      console.log("ids", ids);
      if (ids.length > 0) {
        const accessResponse = await getAccessList({ ids: ids.join(",") });
        if (accessResponse.status >= 200 && accessResponse.status <= 299) {
          setContractAcGroupList(accessResponse.data.data.content);
        }
      } else {
        setContractAcGroupList([]);
      }
    }
  };

  // 방문자 출입 권한 수정 api
  const onChangeVisitorApprove = async (visitorDefaultAccessGroupYn: boolean) => {
    const { status } = await editContractAcGroup({
      contractManageId: Number(contractManageId),
      contractManage: { visitorDefaultAccessGroupYn },
    });
    if (status >= 200 && status <= 299) {
      await fetchContractAccess(Number(contractManageId));
    }
  };

  // 출입그룹 선택시 (선택 시 저장)
  const onAddAccessGroups = async (selectedItems: Ac2AccessDeviceModel[]) => {
    if (selectedItems.length > 0) {
      const { status } = await editContractAcGroup({
        contractManageId: Number(contractManageId),
        contractManage: { accessGroupAddIds: selectedItems.map((item) => Number(item.id)) },
      });
      if (status >= 200 && status <= 299) {
        await fetchContractAccess(Number(contractManageId));

        setBaseModal({
          isOpen: true,
          title: "출입그룹이 적용되었습니다.",
          children: "추가된 출입그룹에 바로 엑세스 할 수 있습니다",
          onClick: () => {
            setBaseModal({ isOpen: false });
          },
          btnRightTitle: "확인",
        });
      }
    }
    setShowSelectModal(false);
  };

  // 출입그룹 삭제시
  const onRemoveAccessGroup = async (deleteId: number) => {
    const response = await editContractAcGroup({
      contractManageId: Number(contractManageId),
      contractManage: { accessGroupDeleteIds: [Number(deleteId)] },
    });
    if (response.status >= 200 && response.status <= 299) {
      await fetchContractAccess(Number(contractManageId));
      setBaseModal({ isOpen: false });
    }
  };

  // 출입그룹 상세 키값
  const [accessGroupId, setAccessGroupId] = useState(0);

  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap detail-usage-restriction">
          {/* 이용자 관리 / 방문자 관리 */}
          <UserAndVisitorAccess
            contractApplyNumber={String(contractApplyNumber)}
            contractManage={contractManage}
            onChangeVisitorApprove={onChangeVisitorApprove}
          />

          <article className="contents-container__wrap-article">
            <div className="contents-container__sub-title mb0">
              <div className="flex-center-between w-100">
                <BaseSectionTitle title={"출입그룹 관리"} className="pb0" />
                <div className="contents-container__grid-contents flex-center">
                  <div className=" mr10">
                    <BaseButton
                      title="출입기록 조회"
                      className="color-white"
                      onClick={() => {
                        setAccessRecordModal(true);
                      }}
                    />
                    {accessRecordModal && (
                      <SearchAccessLog //
                        isOpen={true}
                        contractManageId={String(contractManageId)}
                        contractApplyNumber={String(contractApplyNumber)}
                        onClose={() => setAccessRecordModal(false)}
                      />
                    )}
                  </div>
                  <div className="">
                    {isAuthority("w") && (
                      <BaseButton
                        title="+ 출입그룹 추가"
                        onClick={() => {
                          setShowSelectModal(true);
                        }}
                      />
                    )}
                    {showSelectModal && (
                      // v.1.24 에서 공통 컴포넌트로 테스트
                      <SelectAcGroupModal
                        setShowModal={setShowSelectModal}
                        onSelectedAccessGroups={onAddAccessGroups}
                        // onSelectedAccessGroups={() => {}}
                        existingAccess={contractAcGroupList || []}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <section>
              <section className="inner-tab-table">
                <div className="my20">
                  <div {...getTableProps()} className="base-table sticky">
                    <div className="header">
                      {headerGroups.map((headerGroup) => (
                        <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                          {headerGroup.headers.map((header) => {
                            return (
                              <div {...header.getHeaderProps()} className="base-table__th">
                                {header.render("Header")}
                              </div>
                            );
                          })}
                        </div>
                      ))}
                    </div>
                    <div {...getTableBodyProps()} className="body">
                      {rows.map((row: any) => {
                        prepareRow(row);
                        return (
                          <div {...row.getRowProps()} className={`base-table__tr`}>
                            {row.cells.map((cell: any) => {
                              return (
                                <div {...cell.getCellProps()} className="base-table__td">
                                  {cell.render("Cell")}
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                    {rows.length === 0 && (
                      <div className="base-table__tr table-cursor-unset">
                        <div className="base-table__td w-100 text-center">
                          <div className="w-100">
                            <span>데이터가 없습니다.</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </section>
            </section>
          </article>

          {showDetailModal.isOpen && (
            <AccessGroupDetailModal showModal={showDetailModal} setShowModal={setShowDetailModal} accessGroupId={accessGroupId} />
          )}
        </div>
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <GoToListButton />
        </div>
        <div className="right-area"></div>
      </div>
    </>
  );
};

export default ContractAccessGroup;
