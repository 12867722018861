import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { getContractDetailBasicInfo } from "src/api/contract/contract-api";
import { ContractStep } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import { Order, PageMeta, Select } from "src/api/public-types";
import { getVisitorsAsync } from "src/api/visitor/visitor-api";
import { Ac2AccessCode, ApprovalStatusMap, ContractVisitorListModel, VisitStatusMap } from "src/api/visitor/visitor-types";
import { BaseButton, BaseInput, BaseInputWithSearch, BaseModal, BaseSelect, BaseTable } from "src/components";
import RangeDatepicker from "src/components/RangeDatepicker";
import ContractSelectModal from "src/components/contract/ContractSelectModal";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import useNavigate from "src/hooks/usePartnerNavigate";
import { useQueryParams } from "src/hooks/useQueryParams";
import PagePath from "src/pagePath.json";
import { downloadExcelV2 } from "src/utils/common-util";
import { INVITEABLE_CONTRACT_STEP } from "../constants";
import useCsnkey from "../hooks/useCsnkey";
import { formatVisitorMobileNumber } from "../utils";
import { Modal } from "../visitor-types";
import ExcelDownloadButton from "./ExcelDownloadButton";
import { columns } from "./columns/Columns";

// url query parameter 타입
type QueryParams = {
  page?: number;
  size?: number;
  sort?: {
    orders?: Array<Order>;
  };
  visitStatus?: string;
  searchType?: string;
  searchValue?: string;
  partnerId?: string;
  searchStartTime?: string;
  searchEndTime?: string;
  visitType?: string;
  visitPurpose?: string;
  contractId?: string;
  contractApplyNumber?: string;
  contractStep?: ContractStep;
  approvalStatus?: string;
  invitationChannel?: string;
};

// 방문상태 options
const visitStatusOptions: Select[] = [
  { label: "전체", value: "" },
  { label: "대기", value: "VISIT_PENDING" },
  { label: "사용", value: "VISIT_USE" },
  { label: "삭제", value: "VISIT_DELETE" },
  { label: "종료", value: "VISIT_CLOSE" },
];

// 방문자 승인상태 options
const approvalStatusOptions: Select[] = [
  { label: "전체", value: "" },
  { label: "자동승인", value: "APPROVAL_AUTO" },
  { label: "승인대기", value: "APPROVAL_PENDING" },
  { label: "승인완료", value: "APPROVAL_COMPLETE" },
  { label: "반려", value: "APPROVAL_REJECT" },
];

// 접근 경로 options
const invitationChannelOptions: Select[] = [
  { label: "전체", value: "" },
  { label: "TaapSpace", value: "TaapSpace" },
  { label: "Taap", value: "Taap" },
  { label: "Ctrl.room", value: "Ctrl.room" },
  { label: "SelfVisit", value: "SelfVisit" },
];

// 검색대상 options
const searchTypeOptions: Select[] = [
  { label: "전체", value: "" },
  { label: "방문신청번호", value: "VISIT_APPLY_NUMBER" },
  { label: "방문자 명", value: "VISITOR_NAME" },
  { label: "방문자 휴대폰", value: "VISITOR_MOBILE_NUMBER" },
  { label: "방문자 이메일", value: "VISITOR_EMAIL" },
  { label: "방문자 차량번호", value: "VISITOR_CAR_NUMBER" },
  { label: "회원번호", value: "INVITEE_MEMBER_NO" },
  //1.24 추가
  { label: "초대자", value: "INVITEE_MEMBER_ID" },
  { label: "초대경로", value: "invitationChannel" },
];

// 정렬기능 숨김
const disabledSortHeaders: string[] = [
  "contractVisitorId", // 방문자 ID
  "visitorName", // 방문자 명
  "visitorMobileNumber", // 방문자 휴대폰
  "visitorKeyCardButton", // 키카드 할당
  "visitorKeyCard", // 키카드 번호

  "inviteeMemberId", // 초대자
  "inviteeMemberNo", // 초대자 회원번호
  "invitationChannel", // 초대경로
  "modifiedDate", // 수정일
];

/* 
  방문자 > 목록화면
*/

const VisitorList = () => {
  const navigate = useNavigate();

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const { isAuthority } = usePartnerAuthority();

  const { queryParams, queryParamsNavigate } = useQueryParams<QueryParams>({
    page: 0,
    size: 20,
    sort: {
      orders: [{ property: "createdDate", direction: "DESC" }],
    },
  });

  const [searchInput, setSearchInput] = useState({
    searchType: "",
    searchValue: "",
  });

  const { getFixedCsnMutationAsync } = useCsnkey();
  const { executeAsync: getVisitors } = useApiOperation(getVisitorsAsync);
  const { executeAsync: getContractDetailBasicInfoAsync } = useApiOperation(getContractDetailBasicInfo);

  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
  });

  const [contractSelectModal, setContractSelectModal] = useState<Modal>({
    isOpen: false,
  });

  const [visitors, setVisitors] = useState<ContractVisitorListModel[]>();
  const [pageMeta, setPageMeta] = useState<PageMeta>();

  useEffect(() => {
    // queryParams 기준 방문자 리스트 호출
    (async () => {
      const result = await getVisitors(queryParams);

      if (result.status !== 200) return;

      setVisitors(result.data.data.contractVisitor);
      setPageMeta(result.data.meta.pageMeta);
    })();
  }, [queryParams]);

  useEffect(() => {
    // queryParams 검색 옵션, 검색어 세팅
    setSearchInput({ searchType: queryParams.searchType ?? "", searchValue: queryParams.searchValue ?? "" });
  }, [queryParams.searchType, queryParams.searchValue]);

  useEffect(() => {
    // queryParams 기준 날짜 세팅
    const dump = _.cloneDeep(dateRange);
    if (queryParams?.searchStartTime !== undefined && queryParams?.searchStartTime !== "") {
      dump[0] = moment(queryParams?.searchStartTime).toDate();
    } else {
      dump[0] = null;
    }
    if (queryParams?.searchEndTime !== undefined && queryParams?.searchEndTime !== "") {
      dump[1] = moment(queryParams?.searchEndTime).toDate();
    } else {
      dump[1] = null;
    }
    setDateRange(dump);
  }, [queryParams.searchEndTime, queryParams.searchStartTime]);

  // 날짜 검색
  const handleOnDateRangeChange = (dateRange: [Date | null, Date | null]) => {
    setDateRange(dateRange);
    let start = "";
    let end = "";

    if (dateRange[0] !== null) {
      start = moment(dateRange[0]).format("YYYY-MM-DD") + "T00:00:00.000+09:00";
    }
    if (dateRange[1] !== null) {
      end = moment(dateRange[1]).format("YYYY-MM-DD") + "T23:59:59.999+09:00";

      queryParamsNavigate({ ...queryParams, searchStartTime: start, searchEndTime: end, page: 0 });
    }
  };

  const goFormPage = (contractApplyNumber: string) =>
    navigate(
      PagePath.visitor.add.replace(":contractApplyNumber", contractApplyNumber).replace(":contractId", queryParams?.contractId?.toString() || ""),
    );

  const clickVisitorInvitation = async () => {
    const contractApplyNumber = queryParams?.contractApplyNumber;
    let contractStep = queryParams?.contractStep;

    if (!contractApplyNumber) {
      setAlertModal({ isOpen: true, message: "신청번호를 선택해주세요." });
      return;
    }

    if (!contractStep) {
      // 신청계약을 통해 접근한 경우 API 요청 후 계약 상태 체크
      const contract = await getContractDetailBasicInfoAsync({ id: Number(queryParams?.contractId) }).then((res) => res?.data?.data);
      contractStep = contract.contractStep as ContractStep;
    }

    if (!contractStep || !INVITEABLE_CONTRACT_STEP.includes(contractStep as ContractStep)) {
      setAlertModal({ isOpen: true, message: "초대할 수 없는 계약 상태입니다." });
      return;
    }

    goFormPage(contractApplyNumber);
  };

  /**
   * 방문자 목록을 엑셀 파일로 다운로드하는 함수
   * 데이터가 많은 경우 분할하여 가져오는 방식 사용
   */
  const handleClickExcelDownloadButton = async () => {
    try {
      // 1. 데이터 존재 여부 확인
      const sizeCheckResult = await getVisitors({ ...queryParams, page: 0, size: 1 });
      const totalCount = sizeCheckResult.data.meta?.pageMeta?.totalElements || 0;

      if (totalCount <= 0) {
        setAlertModal({ isOpen: true, message: "조회된 데이터가 없습니다." });
        return;
      }

      // 2. 데이터 수집 준비
      const chunkSize = 100; // 한 번에 조회할 데이터 수
      const chunkCount = Math.ceil(totalCount / chunkSize);

      // 3. 데이터 수집을 위한 API 호출 준비
      const apiCalls = [];
      for (let i = 0; i < chunkCount; i++) {
        apiCalls.push(
          getVisitors({
            ...queryParams,
            page: i,
            size: chunkSize,
          }),
        );
      }

      // 4. 병렬로 데이터 수집
      const responses = await Promise.all(apiCalls);

      // 5. 수집된 데이터 병합 (배열 함수 사용)
      const allData: (ContractVisitorListModel & { visitorKeyCard?: string })[] = responses.flatMap(
        (response) => response.data.data.contractVisitor || [],
      );

      const csnkeyListResult = await getFixedCsnMutationAsync({
        contractVisitorIds: allData.map((item) => item.contractVisitorId?.toString() || ""),
      });
      //만약 csnkeyListResult가 undefined 일 경우 에러가 발생할까?
      const csnkeyList = csnkeyListResult?.data?.data?.content;

      // csnkeyList에서 contractVisitorId를 키로 하고 accessToken을 값으로 하는 매핑 객체 생성
      const visitorIdToAccessTokenMap = csnkeyList?.reduce((acc: Record<string, string>, item: Ac2AccessCode) => {
        if (item.contractVisitorId && item.accessToken) {
          acc[item.contractVisitorId.toString()] = item.accessToken;
        }
        return acc;
      }, {});
      // 6. 데이터 가공 - 엑셀 형식에 맞게 변환
      const excelData = allData.map((item) => ({
        visitApplyNumber: item.visitApplyNumber || "-", // 신청번호
        contractVisitorId: item.contractVisitorId || "-", // 방문자ID
        visitStatus: item.isDeleted ? "삭제" : item.visitStatus ? VisitStatusMap[item.visitStatus as keyof typeof VisitStatusMap] : "-", // 방문 상태
        approvalStatus: item.approvalStatus ? ApprovalStatusMap[item.approvalStatus as keyof typeof ApprovalStatusMap] : "-", // 승인 상태
        visitorName: item.visitorName || "-", // 방문자
        visitorMobileNumber: formatVisitorMobileNumber(item.visitorMobileNumber || "") || "-", // 번호
        visitorEmail: item.visitorEmail || "-", // 이메일
        visitStartTime: moment(item.visitStartTime).format("YYYY-MM-DD HH:mm") || "-", // 시작
        visitEndTime: moment(item.visitEndTime).format("YYYY-MM-DD HH:mm") || "-", // 종료
        visitorKeyCard: visitorIdToAccessTokenMap?.[item.contractVisitorId?.toString() || ""] || "-", // 키카드
        invitationDate: item.invitationDate || "-", // 초대일
        inviteeMemberId: item.inviteeMemberId || "-", // 초대자
        inviteeMemberNo: item.inviteeMemberNo || "-", // 초대자 회원번호
        contractApplyNumber: item.contractApplyNumber || "-", // 계약신청번호
        invitationChannel: item.invitationChannel || "-", // 초대경로
        modifiedDate: moment(item.modifiedDate).format("YYYY-MM-DD HH:mm") || "-", // 수정일
      }));

      // 7. 파일명 생성
      const timestamp = moment().format("YYYYMMDDHHmmss");
      let fileName = `ctrl.room-방문자목록-전체_${timestamp}`;

      if (queryParams.searchStartTime && queryParams.searchEndTime) {
        const startDate = moment(queryParams.searchStartTime).format("YYYYMMDD");
        const endDate = moment(queryParams.searchEndTime).format("YYYYMMDD");
        fileName = `ctrl.room_방문자목록_${startDate}~${endDate}_${timestamp}`;
      }

      // 8. 엑셀 다운로드 실행
      await downloadExcelV2({
        data: excelData,
        fileName,
        //키카드 버튼 컬럼 제거
        columns: columns.filter((column) => column.accessor !== "visitorKeyCardButton"),
      });

      setAlertModal({ isOpen: true, message: "엑셀 다운로드가 완료되었습니다." });
    } catch (error) {
      console.error("엑셀 다운로드 오류:", error);
      setAlertModal({ isOpen: true, message: "엑셀 다운로드에 실패하였습니다." });
    }
  };

  return (
    <div className="page-product-list">
      <div className="contents-container__table">
        <div className="contents-container__search-wrap">
          <div className="left-area">
            <section>
              <div className="left-area__index">
                <span>지정검색</span>
              </div>
              <div className="left-area__contents">
                <div className="minmax140">
                  <BaseInput
                    type="text"
                    value={queryParams?.contractApplyNumber}
                    placeholder="신청번호"
                    onClick={() => setContractSelectModal({ isOpen: true })}
                    onClearClick={() =>
                      queryParamsNavigate({ ...queryParams, contractId: "", contractApplyNumber: "", contractStep: "" as any, page: 0 })
                    }
                    readonly
                  />
                </div>
              </div>
            </section>
            <section>
              <div className="left-area__index">
                <span>조건검색</span>
              </div>
              <div className="left-area__contents">
                <div className="minmax201">
                  <RangeDatepicker dateRange={dateRange} onChange={handleOnDateRangeChange} />
                </div>
              </div>
              <div className="left-area__contents">
                <div className="minmax120 mr8">
                  <BaseSelect
                    placeholder="상태"
                    value={queryParams.visitStatus}
                    stateOptions={visitStatusOptions}
                    setStateValue={(visitStatus: string) => {
                      queryParamsNavigate({
                        ...queryParams,
                        visitStatus: visitStatus,
                        page: 0,
                      });
                    }}
                  />
                </div>

                <div className="minmax120 mr8">
                  <BaseSelect
                    placeholder="승인상태"
                    value={queryParams.approvalStatus}
                    stateOptions={approvalStatusOptions}
                    setStateValue={(approvalStatus: string) => {
                      queryParamsNavigate({
                        ...queryParams,
                        approvalStatus: approvalStatus,
                        page: 0,
                      });
                    }}
                  />
                </div>

                <div className="minmax120 mr8">
                  <BaseSelect
                    placeholder="초대경로"
                    value={queryParams.invitationChannel}
                    stateOptions={invitationChannelOptions}
                    setStateValue={(approvalStatus: string) => {
                      queryParamsNavigate({
                        ...queryParams,
                        invitationChannel: approvalStatus,
                        page: 0,
                      });
                    }}
                  />
                </div>

                <div className="minmax320 mr16">
                  <BaseInputWithSearch
                    type="text"
                    selectValue={searchInput.searchType}
                    inputValue={searchInput.searchValue}
                    stateOptions={searchTypeOptions}
                    setStateValue={(searchType: string) => {
                      setSearchInput((prev) => ({ ...prev, searchType: searchType }));
                    }}
                    onChange={(searchValue: string, e: React.ChangeEvent<HTMLInputElement>) =>
                      setSearchInput((prev) => ({ ...prev, searchValue: searchValue }))
                    }
                    onKeyUp={() =>
                      queryParamsNavigate({ ...queryParams, searchType: searchInput.searchType, searchValue: searchInput.searchValue, page: 0 })
                    }
                    onSearchClick={() =>
                      queryParamsNavigate({ ...queryParams, searchType: searchInput.searchType, searchValue: searchInput.searchValue, page: 0 })
                    }
                    onClearClick={() => queryParamsNavigate({ ...queryParams, searchType: "", searchValue: "", page: 0 })}
                  />
                </div>
              </div>
            </section>
          </div>
          <div className="right-area">{isAuthority("w") && <BaseButton title="+ 방문자 초대" onClick={clickVisitorInvitation} />}</div>
        </div>

        {visitors && (
          <BaseTable
            data={visitors}
            columns={columns}
            pageIndex={pageMeta?.pageRequest.page || 0}
            totalPages={pageMeta?.totalPages || 0}
            totalElements={pageMeta?.totalElements || 0}
            currentSize={Number(pageMeta?.pageRequest?.size) || 20}
            sizeOption={(size) => queryParamsNavigate({ ...queryParams, page: 0, size: size })}
            goPage={(page: number) => queryParamsNavigate({ ...queryParams, page: page, size: pageMeta?.pageRequest?.size })}
            disabledSortHeaders={disabledSortHeaders}
            orders={pageMeta?.pageRequest.sort?.orders}
            setOrders={(orders: Order[]) => queryParamsNavigate({ ...queryParams, sort: { orders: orders } })}
            children={<ExcelDownloadButton onClick={handleClickExcelDownloadButton}>엑셀받기</ExcelDownloadButton>}
          />
        )}
      </div>

      {/* 확인버튼만 있는 alert 모달 */}
      {alertModal.isOpen && (
        <BaseModal
          isOpen={true}
          btnRightTitle="확인"
          onClick={() => setAlertModal({ isOpen: false })}
          onClose={() => setAlertModal({ isOpen: false })}
          title={alertModal.message}
        />
      )}

      {contractSelectModal.isOpen && (
        <ContractSelectModal
          onCanceled={() => setContractSelectModal({ isOpen: false })}
          onAdded={(data) => {
            console.log("data :>> ", data);
            queryParamsNavigate({
              ...queryParams,
              contractId: data[0].contractId.toString(),
              contractApplyNumber: data[0].contractApplyNumber,
              contractStep: data[0].contractStep,
              page: 0,
            });
            setContractSelectModal({ isOpen: false });
          }}
          defaultCheckedContractList={[{ contractApplyNumber: queryParams?.contractApplyNumber!, contractId: Number(queryParams.contractId) }]}
          contractStepFilter={INVITEABLE_CONTRACT_STEP}
        />
      )}
    </div>
  );
};

export default VisitorList;
