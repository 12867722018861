import { useQuery } from "@tanstack/react-query";
import { getBuildingAsync } from "src/api/building/building-api";
import { getContractManage } from "src/api/contract/contract-api";
import { getManageBuildingAsync } from "src/api/contract/contract-facility-api";
import { useApiOperation } from "src/api/hooks";
import { getContractVisitorGroupAsync, getContractVisitorsAsync } from "src/api/visitor/visitor-api";

function useVisitor({ visitApplyNumber, contractApplyNumber }: { visitApplyNumber?: string; contractApplyNumber?: string }) {
  const { executeAsync: getContractVisitorGroup } = useApiOperation(getContractVisitorGroupAsync);
  const { executeAsync: getContractManageAsync } = useApiOperation(getContractManage);
  const { executeAsync: getManageBuilding } = useApiOperation(getManageBuildingAsync);
  const { executeAsync: getBuilding } = useApiOperation(getBuildingAsync);

  // RCV51b. 계약 방문자 조회(v1.24 이후)
  // 방문자 상세페이지에서 방문자 그룹 조회와 함께 방문자 목록 조회
  // 방문자 그룹안에 방문자 목록을 사용하는것이 아니라 별도로 조회해야함
  // 방문자 그룹안에 있는 방문자 목록은 개별 방문자 삭제시 조회할 수 없어서 별도로 조회해야함
  const { executeAsync: getContractVisitors } = useApiOperation(getContractVisitorsAsync);

  const { data: visitorGroup } = useQuery({
    queryKey: ["getContractVisitorsAsync", visitApplyNumber],
    queryFn: async () => {
      if (visitApplyNumber) {
        //방문자 그룹을 조회
        const visitorGroupResponse = await getContractVisitorGroup({ visitApplyNumber: visitApplyNumber! }).then(
          (res) => res.data?.data?.contractVisitorGroup,
        );

        if (visitorGroupResponse.contractVisitorGroupId) {
          //방문자 그룹id로 방문자 목록을 조회함
          const visitorList = await getContractVisitors({ contractVisitorGroupId: visitorGroupResponse.contractVisitorGroupId.toString() }).then(
            (res) => res.data?.data.contractVisitorList,
          );

          //방문자 그룹안에 방문자 목록 overwrite
          const newVisitorGroup = { ...visitorGroupResponse, contractVisitorList: visitorList };

          return newVisitorGroup;
        }
      }
    },
    enabled: !!visitApplyNumber,
  });

  const { data: contractManageResponse } = useQuery({
    queryKey: ["getContractManage", contractApplyNumber],
    queryFn: () => getContractManageAsync({ contractApplyNumber: contractApplyNumber! }).then((res) => res.data?.data),
    enabled: !!contractApplyNumber,
  });

  /** 계약에 등록된 건물 관리 목록 조회 */
  const { data: buildingManageList = [] } = useQuery({
    queryKey: ["getBuildingManageList", contractManageResponse?.contractManage?.contractManageId],
    queryFn: async () => {
      if (!contractManageResponse?.contractManage?.contractManageId) return [];

      const response = await getManageBuilding({ contractManageId: contractManageResponse?.contractManage?.contractManageId });
      return response.data?.data?.buildingManageList || [];
    },
    enabled: !!contractManageResponse?.contractManage?.contractManageId,
  });

  /** 각 건물의 상세 정보 조회 */
  const { data: buildingList = [] } = useQuery({
    queryKey: ["getBuildingDetails", buildingManageList],
    queryFn: async () => {
      const buildings = await Promise.all(
        buildingManageList.map(async (item) => {
          const building = await getBuilding({ buildingId: item.buildingId }).then((res) => res.data?.data?.content?.building);

          const newBuilding = {
            isPrimary: item.isPrimary,
            buildingId: building?.id,
            buildingName: building?.buildingName,
            address: `(${building?.addressList?.[0]?.zonecode}) ${building?.addressList?.[0]?.address} ${building?.addressList?.[0]?.addressDetail}`,
          };

          return newBuilding;
        }),
      );
      return buildings;
    },
    enabled: buildingManageList.length > 0,
  });

  return {
    buildingList,
    visitorGroup,
    contractManage: contractManageResponse?.contractManage,
    contractId: contractManageResponse?.contractId,
  };
}
export default useVisitor;
