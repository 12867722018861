import { Toast, toastState } from "src/recoil/toast/atom";
import { useRecoilState } from "recoil";

const getUniqueID = () => {
  return `${Date.now()}-${Math.random().toString(36).substring(2, 9)}`;
};
/**
 * 토스트 hook
 */

export function useToast() {
  const [toasts, setToasts] = useRecoilState(toastState);

  const removeToast = (toastID: Toast["id"]) => setToasts((prev) => prev.filter((toast) => toast.id !== toastID));

  const openToast = (toast: Omit<Toast, "id">) => {
    const id = getUniqueID();
    const newToast = { ...toast, id };
    setToasts((prev) => [...prev, newToast]);
    setTimeout(() => removeToast(id), 60 + (toast.duration ?? 1000));
  };

  return { toasts, openToast };
}
