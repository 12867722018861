import React from "react";
import { ServiceTypes } from "src/api/adminMemo/adminmemo-types";
import { BaseButton } from "src/components";
import AdminMemoV3 from "src/components/AdminMemoV3/AdminMemoV3";
import GoToListButton from "src/components/GoToListButton";
import { ArticleSection } from "src/components/layout/ArticleSection";
import RegistrationInformation from "src/components/RegistrationInformation";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import { PagePath } from "src/pages/product/details";
import { Ac2AccessDeviceModel } from "src/api/access/productac-types";

type Props = {
  accessDevice: Ac2AccessDeviceModel;
};
const BasicInfoDetail = ({ accessDevice }: Props) => {
  const { isAuthority } = usePartnerAuthority();
  const { id, externalDeviceId, deviceName, deviceLabels } = accessDevice;

  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <ArticleSection title="기본 정보">
            <div className="flex-center">
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>ID</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400">
                    <span>{id || "-"}</span>
                  </div>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>장치 ID</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400">
                    <span>{externalDeviceId || "-"}</span>
                  </div>
                </div>
              </section>
            </div>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>장치명</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <span>{deviceName || "-"}</span>
                </div>
              </div>
            </section>
          </ArticleSection>
          {/* <ArticleSection title="설정">
            <div className="flex-center">
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>건물</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400">
                    <span>{deviceDetail || "-"}</span>
                  </div>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>층</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400">
                    <span>{deviceDetail || "-"}</span>
                  </div>
                </div>
              </section>
            </div>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>장치명 (노출)</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <span>{deviceDetail || "-"}</span>
                </div>
              </div>
            </section>
          </ArticleSection> */}
          <RegistrationInformation data={accessDevice} />
          <AdminMemoV3 serviceId={Number(accessDevice.id)} serviceType={ServiceTypes.SERVICE_ACCESS_DEVICE} />
        </div>
        {/* 버튼영역 */}
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <GoToListButton />
        </div>
      </div>
    </>
  );
};

export default BasicInfoDetail;
