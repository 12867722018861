import { useCallback, useEffect, useMemo, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { Ac2AccessGroupModel } from "src/api/access/ac-group-types";
import { VisitorFormData } from "src/api/visitor/visitor-types";
import { BaseButton } from "src/components";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import SelectAcGroupModal from "src/pages/product/forms/accessLevelForm/modals/SelectAcGroupModal";
import SpaceBaseTable from "src/pages/product/forms/commonSpaceForm/components/SpaceBaseTable";
import { acGroupColumns, addTypeDeleteCol } from "./acColumns";

/**
 * 방문자 출입그룹 등록시에만 사용하는 컴포넌트
 */
const VisitorAddAccessGroup = ({ useFormReturn, contractManageId }: { useFormReturn: UseFormReturn<VisitorFormData>; contractManageId: string }) => {
  const { isAuthority } = usePartnerAuthority();

  const { setValue } = useFormReturn;

  const [showSelectModal, setShowSelectModal] = useState(false);

  const [selectAccessGroupList, setSelectAccessGroupList] = useState<Ac2AccessGroupModel[]>([]);

  // 출입그룹 삭제 핸들러
  const handleDeleteAccessGroup = useCallback((accessGroupId: number) => {
    setSelectAccessGroupList((prev) => prev.filter((group) => group.id !== accessGroupId));
  }, []);

  const newColumns = useMemo(() => {
    return [...acGroupColumns, ...addTypeDeleteCol];
  }, []);

  // 출입그룹 추가 핸들러
  const handleAddAccessGroups = useCallback(
    (selectedGroups: Ac2AccessGroupModel[]) => {
      // 이미 존재하는 그룹은 제외하고 새로운 그룹만 추가
      const newGroups = selectedGroups.filter((group) => !selectAccessGroupList.some((existingGroup) => existingGroup.id === group.id));

      setSelectAccessGroupList((prev) => [...prev, ...newGroups]);
      setShowSelectModal(false);
    },
    [selectAccessGroupList],
  );

  //출입그룹이 추가되거나 삭제 될때 유즈이팩트 안에서 setValue 로 출입그룹 리스트 id 를 string[] 로 set 해주는 유즈이팩트 함수
  useEffect(() => {
    setValue(
      "invitationInfo.accessGroupAddIdsList",
      selectAccessGroupList.map((group) => group.id.toString()),
    );
  }, [selectAccessGroupList, setValue]);

  return (
    <article className="contents-container__wrap-article">
      <div className="contents-container__sub-title mb0">
        <div className="flex-center-between w-100">
          <BaseSectionTitle title={"출입그룹 관리"} className="pb0" noHashLink={true} />
          <div className="contents-container__grid-contents flex-center">
            <div className="">
              {isAuthority("w") && (
                <BaseButton
                  title="+ 출입그룹 추가"
                  onClick={() => {
                    setShowSelectModal(true);
                  }}
                />
              )}
              {showSelectModal && (
                <SelectAcGroupModal
                  setShowModal={setShowSelectModal}
                  onSelectedAccessGroups={handleAddAccessGroups}
                  existingAccess={selectAccessGroupList}
                  contractManageId={contractManageId}
                  visitorAccessYn={true}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <section>
        <SpaceBaseTable columns={newColumns} data={selectAccessGroupList} handleDeleteAccessGroup={handleDeleteAccessGroup} />
      </section>
    </article>
  );
};

export default VisitorAddAccessGroup;
