export const localesEn = {
  "lnb.sideBar.body.main.link": "Dashaboard",
  "lnb.sideBar.body.monitoring.link": "Space Monitoring",
  "lnb.sideBar.body.building.link": "Building",
  "lnb.sideBar.body.product.link": "Product",
  "lnb.sideBar.body.workorder.link": "Work Order",
  "lnb.sideBar.body.cs.link": "Customer Service",
  "lnb.sideBar.body.contract.link": "Contract",
  "lnb.sideBar.body.provider.link": "Settlement Information",
  "lnb.sideBar.body.reservation.link": "Shared Space reservation",
  "lnb.sideBar.body.user.link": "Member Invitation",
  "lnb.sideBar.body.visitor.link": "Visitor Invitation",
  "lnb.sideBar.body.notice.link": "Notice",
  "lnb.sideBar.body.org.link": "Business information",
  "lnb.sideBar.body.billing.link": "Billing Schedule",
  "lnb.sideBar.body.option.link": "Settings",
  "lnb.sideBar.body.guide.link": "Services Guide",
  "lnb.sideBar.body.voc.link": "VoC Categories",
  "lnb.sideBar.body.price.link": "Shared Spaces Pricing",
  "lnb.sideBar.body.cctv.link": "CCTV Devices",
  "lnb.sideBar.body.login": "Last login",
  "lnb.sideBar.body.accessDevice.link": "Access Devices",
  "lnb.sideBar.body.accessGroup.link": "Access Groups",
  "lnb.sideBar.body.reservationConfig.link": "Reservation Config",

  // 공용공간 예약현황
  "reservation.display.body.title": "Space reservation Schedule",
  "reservation.display.toast.title": "The space reservation policies vary by company. Please check the Taap app for the exact availability.",
  "reservation.display.body.private": "Private",
  "reservation.display.body.attendeeInfo.private": "Private",
  "reservation.display.detail.private": "Private",
  "reservation.display.setting.building": "Building",
  "reservation.display.setting.floor": "Floor",
  "reservation.display.setting.type": "Space type",
  "reservation.display.setting.room": "Meeting Room",
  "reservation.display.setting.desk": "Desk",
  "reservation.display.setting.amenity": "Amenity",
  "reservation.display.setting.all": "ALL",
  "reservation.display.setting.title": "Please select the search conditions",
};
