import _ from "lodash";
import moment from "moment";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { CommonFacilityType } from "src/api/building/building-types";
import { Order } from "src/api/public-types";
import { ReservationListParams } from "src/api/reservation/reservation-types";
import { BaseButton, BaseInputWithSearch, BaseModal, BaseSelect, BaseTable } from "src/components";
import RangeDatepicker from "src/components/RangeDatepicker";
import useNavigate from "src/hooks/usePartnerNavigate";
import { PagePath } from "src/pages/product/details";
import { YmdFormat } from "src/utils/common-util";
import useRv from "../hooks/useRv";
import {
  Modal,
  ReservationExternalTypes,
  reservationFacilityType,
  reservationInboundChannel,
  reservationSearchTypeKey,
  reservationSearchTypes,
  reservationStatuses,
} from "../reservation-types";
import { reservationColumns } from "./columns";
import CalendarModal from "./components/CalendarModal";
import { useRecoilValue } from "recoil";
import { globalPartnerState } from "src/recoil/partners/atom";
import { PaPartner } from "src/types/partner";

/**
 * 공용공간 예약 > 목록 화면
 */
const ReservationList = () => {
  // 로딩바

  const navigate = useNavigate();
  const location = useLocation();
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const partner = useRecoilValue(globalPartnerState);

  const isAccessFeature =
    partner?.features?.some((feature: PaPartner) => feature.name === "CTRLROOM_RESERVATION_CALENDAR_DISPLAY" && feature.active) || false;

  // 공용공간예약 커스텀 훅
  const {
    reservationQueryParam, //
    reservationList,
    pageMeta,
    onDownloadReservationUsages,
    onDownloadReservations,
  } = useRv();

  // location search (url query parameter) 를 읽어서 object 로 변환

  // 받아온 query parameter 로 해당 컴포넌트에서 변경가능하기 위한 state 선언
  const [params, setParams] = useState<ReservationListParams & { reservationStatus?: string }>({ ...reservationQueryParam });

  useEffect(() => {
    setParams(reservationQueryParam);
  }, [reservationQueryParam]);

  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });
  const [calendarModal, setCalendarModal] = useState<Modal>({ isOpen: false });

  useEffect(() => {
    const newDateRange = _.cloneDeep(dateRange);
    if (reservationQueryParam?.start) {
      newDateRange[0] = moment(reservationQueryParam.start).toDate();
    } else {
      newDateRange[0] = null;
    }
    if (reservationQueryParam?.end) {
      newDateRange[1] = moment(reservationQueryParam.end).toDate();
    } else {
      newDateRange[1] = null;
    }
    setDateRange(newDateRange);
  }, [reservationQueryParam?.start, reservationQueryParam?.end]);

  const handleOnDateRangeChange = (dateRange: [Date | null, Date | null]) => {
    const [startDate, endDate] = dateRange;
    setDateRange(dateRange);
    if (!startDate || !endDate) return;

    const start = moment(startDate).startOf("days").format(YmdFormat.WITH_TIME_ZONE);
    const end = moment(endDate).endOf("days").format(YmdFormat.WITH_TIME_ZONE);
    setParams({ ...params, start, end });
    navigateWithQueryParams({ ...reservationQueryParam, start: String(start), end: String(end), page: 0 });
  };

  const navigateWithQueryParams = useCallback(
    (passParams?: ReservationListParams & { ALL?: string; reservationStatus?: string }, type?: "search" | "pagination") => {
      let data;
      if (type) {
        type === "search" ? (data = { ...params }) : (data = { ...reservationQueryParam });
      }
      let newQueryParams = { ...data, ...(passParams || {}) };
      // delete newQueryParams.keyword;
      if (includeSearchType() !== "") {
        // delete newQueryParams.search001;
      } else {
        newQueryParams = { ...newQueryParams, search001: newQueryParams.search001 };
      }
      delete newQueryParams.ALL;

      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location.pathname, params, reservationQueryParam],
  );

  // 소진시간 목록 엑셀 다운로드
  // const onDownloadReservationUsages = async (passParams: ReservationListParams) => {
  //   if (!reservationList) return;
  //   const { sort, page, size, ...rest } = passParams;
  //   let excelData: any = [];
  //   const defaultSize = 1000;
  //   const reservationUsageParams: ReservationUsageParams = {
  //     reservation: {
  //       ...rest,
  //     },
  //     sort: {
  //       orders: [
  //         { property: "reservationId", direction: "DESC", nullHandling: "NULLS_LAST" },
  //         { property: "order", direction: "ASC", nullHandling: "NULLS_LAST" },
  //       ],
  //     },
  //     page: 0,
  //     size: defaultSize,
  //   };

  //   const totalPages = Math.ceil(pageMeta?.totalElements! / defaultSize);

  //   for (let i = 0; i < totalPages; i++) {
  //     const { data } = await getReservationUsageList(reservationUsageParams as ReservationListParams);
  //     reservationUsageParams.page = i + 1;
  //     excelData = [...excelData, ...data.data.content];
  //   }

  //   // 목록이 나오면 다운받을 엑셀 형태를 수정
  //   if (excelData.length > 0) {
  //     // 한글 데이터로 변경
  //     const newData = excelData?.map((item: any) => {
  //       if (item.endDate && item.startDate) {
  //       }
  //       //reservationUsageHeaders
  //       item = {
  //         reservationId: item.reservationId, // id
  //         contractApplyNumber: item.contract.applyNumber, // 신청/계약 번호
  //         facilityBuildingName: item.facility.building.name, //건물명
  //         facilityType: item.facility.type, //공간타입
  //         facilityId: item.facility.id, // 공간id
  //         facilityName: item.facility.name, //공간명
  //         start: moment(item.start).format(YmdFormat.YYYY_MM_DD_HH_MM), //예약기간 (시작일시),
  //         end: moment(item.end).format(YmdFormat.YYYY_MM_DD_HH_MM), //예약기간 (시작일시),
  //         duration: item.duration, // 소진시간
  //         organizerMemberNo: item.organizer.memberNo, // 예약자 (회원번호)
  //         organizerPhoneNumber: item.organizer.phoneNumber, //예약자 (휴대폰번호)
  //         organizerUserEmail: item.organizer.userEmail, //예약자 (이메일)
  //       };

  //       return item;
  //     });

  //     try {
  //       const fileName =
  //         startDate && endDate
  //           ? `ctrl.room_공용공간예약_소진시간_${moment(startDate).format("YYYYMMDD")}~${moment(endDate).format("YYYYMMDD")}_${moment().format(
  //               "YYYYMMDDHHmm",
  //             )}`
  //           : `ctrl.room_공용공간예약_소진시간_전체_${moment().format("YYYYMMDDHHmm")}`;
  //       await downloadExcel({
  //         data: newData,
  //         fileName,
  //         header: reservationUsageHeaders.map((header: any) => header.label),
  //       });
  //       setAlertModal({ isOpen: true, title: "엑셀 다운로드가 완료되었습니다." });
  //     } catch (error) {
  //       setAlertModal({ isOpen: true, title: "엑셀 다운로드에 실패하였습니다.", message: String(error) });
  //     }
  //   }
  // };

  const returnStatusCodes = useMemo(() => {
    let result: any = [];
    if (params.statusCode !== undefined && params.statusCode !== "") {
      result = params.statusCode.split(",");
    }

    return result;
  }, [params.statusCode]);

  const includeSearchType = () => {
    return reservationSearchTypeKey.find((item) => item in params) || "";
  };

  const removeOtherSearchType = (searchType: string) => {
    const removedOtherSearchTypeArray = reservationSearchTypeKey.filter((item) => item !== searchType);
    let newParams: any = { ...params };
    for (const key in params) {
      if (removedOtherSearchTypeArray.includes(key)) {
        delete newParams[key];
        delete newParams.ALL;
      }
    }
    return newParams;
  };

  const handleCalendarModalClose = () => {
    setCalendarModal({ isOpen: false });
  };

  return (
    <div className="page-product-list">
      <div className="contents-container__table">
        <div className="contents-container__search-wrap">
          <div className="left-area">
            {/* section 태그가 지정검색, 조건검색, 영역이고 반드시 필요함 */}
            <section>
              <div className="left-area__index">
                <span>조건검색</span>
              </div>
              <div className="left-area__contents">
                <div>
                  <RangeDatepicker dateRange={dateRange} onChange={handleOnDateRangeChange} />
                </div>
                <div className="minmax120">
                  <BaseSelect
                    placeholder="공용공간 유형"
                    value={params.facilityType}
                    className="pre-wrap"
                    stateOptions={reservationFacilityType}
                    setStateValue={(facilityType: CommonFacilityType) => {
                      setParams({ ...params, facilityType });
                      navigateWithQueryParams({ ...params, page: 0, facilityType }, "search");
                    }}
                  />
                </div>
                <div className="minmax170">
                  <BaseSelect
                    placeholder="예약 경로"
                    value={params.inboundChannel}
                    stateOptions={reservationInboundChannel}
                    setStateValue={(inboundChannel: ReservationExternalTypes) => {
                      setParams({ ...params, inboundChannel });
                      navigateWithQueryParams({ ...params, page: 0, inboundChannel }, "search");
                    }}
                  />
                </div>
                <div className="minmax160">
                  {/* <BaseMultiSelect
                    placeholder="예약 상태"
                    stateOptions={reservationStatuses}
                    value={returnStatusCodes || []}
                    setStateValue={(options: Array<{ value: string; label: string }>) => {
                      const statusCode = options.map((obj: { value: string; label: string }) => obj.value).join(",");
                      setParams({ ...params, statusCode });
                      navigateWithQueryParams({ ...params, statusCode, page: 0 });
                    }}
                  /> */}
                  <BaseSelect
                    placeholder="예약 상태"
                    value={params.reservationStatus}
                    stateOptions={reservationStatuses}
                    setStateValue={(value: string) => {
                      setParams({ ...params, reservationStatus: value });
                      navigateWithQueryParams({ ...params, reservationStatus: value, page: 0 });
                    }}
                  />
                </div>
                <BaseInputWithSearch
                  type="text"
                  selectValue={includeSearchType()}
                  inputValue={decodeURIComponent(String(params?.search001 || ""))}
                  stateOptions={reservationSearchTypes}
                  setStateValue={(searchType: string) => {
                    if (searchType !== "") {
                      const type = { [searchType]: params?.search001 };
                      const newParams = removeOtherSearchType(searchType);
                      setParams({ ...newParams, ...type });
                    } else {
                      setParams({ ...params, search001: params?.search001 });
                    }
                  }}
                  onClearClick={() => {
                    const findOptionName = Object.keys(params).find((key) => reservationSearchTypeKey.includes(key));
                    if (findOptionName) {
                      navigateWithQueryParams({ page: 0, search001: "", [findOptionName]: "" }, "search");
                    } else {
                      navigateWithQueryParams({ page: 0, search001: "" }, "search");
                    }
                  }}
                  onChange={(search001: string) => {
                    if (includeSearchType() !== "" && includeSearchType() !== "ALL") {
                      const type = { [includeSearchType()]: decodeURIComponent(search001), search001: decodeURIComponent(search001) };
                      setParams({ ...params, ...type });
                    } else {
                      setParams({ ...params, search001: decodeURIComponent(search001) });
                    }
                  }}
                  onKeyUp={() => navigateWithQueryParams({ page: 0 }, "search")}
                  onSearchClick={() => navigateWithQueryParams({ page: 0 }, "search")}
                />
              </div>
            </section>
          </div>
          <div className="right-area flex-row">
            {isAccessFeature && <BaseButton title="공간 예약 현황" className="mr10 color-blue" onClick={() => setCalendarModal({ isOpen: true })} />}
            <BaseButton title="+ 예약 생성" onClick={() => navigate(PagePath.reservation.form)} />
          </div>
        </div>

        {reservationList && (
          <BaseTable
            data={reservationList}
            columns={reservationColumns}
            pageIndex={pageMeta?.pageRequest.page || 0}
            totalPages={pageMeta?.totalPages || 0}
            goPage={(page: number) => {
              navigateWithQueryParams({ page }, "pagination");
            }}
            sizeOption={(number: number) => {
              setParams({ ...params, size: number });
              navigateWithQueryParams({ ...params, page: 0, size: number }, "search");
            }}
            currentSize={Number(params.size)}
            totalElements={Number(pageMeta?.totalElements) || 0}
            orders={reservationQueryParam?.sort?.orders}
            children={
              <>
                <button
                  className="base-btn color-white icon-excel"
                  onClick={() => {
                    onDownloadReservations(params);
                  }}
                >
                  예약내역 엑셀받기
                </button>
                <button
                  className="base-btn color-white icon-excel"
                  onClick={() => {
                    onDownloadReservationUsages(params);
                  }}
                >
                  소진시간내역 엑셀받기
                </button>
              </>
            }
            disabledSortHeaders={[
              "memberNo",
              "memberEmail",
              "end",
              "inboundChannel",
              "facility",
              "facility.floor",
              "organizer.memberNo",
              "organizer.userEmail",
              "organizer.phoneNumber",
              "organizer.displayName",
              "summary",
              "contract.mbOrganizationName",
              "rvType",
              "contract.contractName",
              "usingTime",
            ]}
            setOrders={(orders?: Array<Order>) => {
              if (orders) {
                navigateWithQueryParams({ ...params, sort: { orders } });
              }
            }}
          />
        )}
      </div>
      {/* 확인버튼만 있는 alert 모달 */}
      {alertModal.isOpen && (
        <BaseModal
          isOpen={true}
          btnRightTitle="확인"
          onClick={() => setAlertModal({ isOpen: false })}
          onClose={() => setAlertModal({ isOpen: false })}
          title={alertModal.title}
        />
      )}
      {calendarModal.isOpen && <CalendarModal onClose={handleCalendarModalClose} />}
    </div>
  );
};
export default ReservationList;
