import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApiOperation } from "src/api/hooks";
import { patchContractVisitorAsync } from "src/api/visitor/visitor-api";

// RCA53b. 방문자 수정
const usePatchVisitor = () => {
  const { executeAsync } = useApiOperation(patchContractVisitorAsync);
  const queryClient = useQueryClient();

  const query = useMutation({
    mutationFn: executeAsync,
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ["getContractVisitorsAsync"] });
    },
  });

  return { ...query };
};

export default usePatchVisitor;
