import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useApiOperation } from "src/api/hooks";
import { getReservationConfigAsync, updateReservationConfigAsync } from "src/api/reservation/reservation-api";
import { ReservationConfigRequest } from "src/api/reservation/reservation-types";
import { BaseButton, ContentsTitle } from "src/components";
import ControlPermissionSetting from "./components/ControlPermissionSetting";
import ReservationPublicSetting from "./components/ReservationPublicSetting";
import { useModal } from "src/recoil/modalState/hook";
import { useRecoilValue } from "recoil";
import { globalPartnerState } from "src/recoil/partners/atom";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";

const activeTab = { label: "기본정보", value: "basicInfo" };

const ReservationConfigForm = () => {
  const { isAuthority, fetchAuthorityReadonly } = usePartnerAuthority();

  const partner = useRecoilValue(globalPartnerState);
  const isAvailableMasking = useMemo(
    () => partner?.features?.find((feature) => feature.name === "CTRLROOM_RESERVATION_ESL_MASKING")?.active,
    [partner],
  );
  // 파트너 예약 설정 정보 조회
  const { executeAsync: getReservationConfig } = useApiOperation(getReservationConfigAsync);

  // 파트너 예약 설정 정보 저장
  const { executeAsync: updateReservationConfig } = useApiOperation(updateReservationConfigAsync);

  const { setBaseModal } = useModal();

  const { handleSubmit, setValue, control } = useForm<ReservationConfigRequest>({
    defaultValues: {
      summaryProtectionStrategy: "NONE", // 주최자 닉네임 공개 설정
      organizerDisplayNameProtectionStrategy: "NONE", // 예약명 공개 설정
      organizationNameProtectionStrategy: "NONE", // 소속 회사 공개 설정
      organizerProtectionSetting: "DENIED", // 예약 정보 Taap 사용자 공개 설정
    },
  });

  // 파트너 예약 설정 저장
  const onSaveReservationConfig = async (data: ReservationConfigRequest) => {
    const { status } = await updateReservationConfig(data);
    if (status >= 200 && status < 300) {
      setBaseModal({ isOpen: false });
    }
  };

  const onSubmit = (data: ReservationConfigRequest) => {
    if (isAuthority("w")) {
      setBaseModal({
        isOpen: true,
        title: "저장하시겠습니까?",
        onClick: () => onSaveReservationConfig(data),
        onClose: () => setBaseModal({ isOpen: false }),
        btnLeftTitle: "취소",
        btnRightTitle: "저장",
      });
    } else {
      fetchAuthorityReadonly(true);
    }
  };

  const onError = (errors: any) => {
    console.log(errors);
  };

  useEffect(() => {
    (async () => {
      const { data } = await getReservationConfig();
      const { config } = data.data;
      if (config) {
        setValue("summaryProtectionStrategy", config.summaryProtectionStrategy);
        setValue("organizerDisplayNameProtectionStrategy", config.organizerDisplayNameProtectionStrategy);
        setValue("organizationNameProtectionStrategy", config.organizationNameProtectionStrategy);
        setValue("organizerProtectionSetting", config.organizerProtectionSetting);
      }
    })();
  }, []);
  return (
    <div>
      <ContentsTitle title="예약정보 관리" tabs={[activeTab]} activeTab={activeTab} />

      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="contents-container__scroll">
          <div className="contents-container__wrap">
            <ReservationPublicSetting
              control={control}
              isEditable={isAuthority("w")}
              fetchAuthorityReadonly={fetchAuthorityReadonly}
              isAvailableMasking={isAvailableMasking || false}
            />
            <ControlPermissionSetting
              control={control}
              isEditable={isAuthority("w")}
              fetchAuthorityReadonly={fetchAuthorityReadonly}
              isAvailableMasking={isAvailableMasking || false}
            />
          </div>
        </div>
        <div className="contents-container__btn-wrap">
          <div className="left-area"></div>
          <div className="right-area">
            <BaseButton type="submit" title="저장" className="size-large" />
          </div>
        </div>
      </form>
    </div>
  );
};

export default ReservationConfigForm;
