import { useMemo } from "react";
import { useBlockLayout, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { Order } from "src/api/public-types";

type Props = {
  data: any;
  setOrders?: Function;
  orders?: Array<Order>;
  disabledSortHeaders?: Array<string>; // 정렬이 불가능한 컬럼이 있을 경우 header accesor 값 배열로 전달
  columns: any;
  className?: string;
};

const InnerTable = ({ data, setOrders, orders, disabledSortHeaders, columns, className }: Props) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns: columns as any,
      data,
    },
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  // columns width 의 총 합 (table witdh 를 구하기 위함)
  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);

  return (
    <section className="inner-tab-table mb20 contents-container__1200">
      <div {...getTableProps()} className={`base-table sticky ${className ? className : ""}`}>
        <div
          className="header" //
          // style={{ borderBottom: "1px solid #E0E0E0" }}
        >
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
              {headerGroup.headers.map((header) => {
                return (
                  <div
                    {...header.getHeaderProps(header.id !== "rowChecked" ? header.getSortByToggleProps() : undefined)}
                    className={`base-table__th cursor-unset`}
                  >
                    {header.render("Header")}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        <div {...getTableBodyProps()} className="body max-height500">
          {rows.map((row: any) => {
            prepareRow(row);
            return (
              <div {...row.getRowProps()} className={`base-table__tr`}>
                {row.cells.map((cell: any) => {
                  return (
                    <div {...cell.getCellProps()} className={`base-table__td`}>
                      {cell.render("Cell")}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        {rows.length === 0 && (
          <div className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
            <div className="base-table__td w-100 text-center">
              <div className="w-100">
                <span>등록된 데이터가 없습니다.</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default InnerTable;
