import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApiOperation } from "src/api/hooks";
import { patchVisitorGroupAsync } from "src/api/visitor/visitor-api";

// RCA53a. 방문자 그룹 수정
const usePatchVisitorGroup = () => {
  const { executeAsync } = useApiOperation(patchVisitorGroupAsync);
  const queryClient = useQueryClient();

  const query = useMutation({
    mutationFn: executeAsync,
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ["getContractVisitorsAsync"] });
    },
  });

  return { ...query };
};

export default usePatchVisitorGroup;
