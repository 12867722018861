import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApiOperation } from "src/api/hooks";
import { deleteFixedCsnAsync, getFixedCsnAsync, postFixedCsnAsync } from "src/api/visitor/visitor-api";

const useCsnkey = (contractVisitorIds?: string[]) => {
  const queryClient = useQueryClient();
  // 키카드 조회, 저장, 삭제 함수들
  const { executeAsync: getFixedCsn } = useApiOperation(getFixedCsnAsync);
  const { executeAsync: postFixedCsn } = useApiOperation(postFixedCsnAsync, { noHandleError: true });
  const { executeAsync: deleteFixedCsn } = useApiOperation(deleteFixedCsnAsync);

  const { data: csnkeyList, isLoading: isCsnkeyListLoading } = useQuery({
    queryKey: ["csnkeyList", contractVisitorIds],
    queryFn: () => (contractVisitorIds?.length ? getFixedCsn({ contractVisitorIds: contractVisitorIds || [] }) : null),
    enabled: !!contractVisitorIds?.length,
  });

  //getFixedCsn 를 내가 원하는 타이밍에 요청 하고 바로 응답 받을 수 있는 리액트 쿼리 함수
  const { mutateAsync: getFixedCsnMutationAsync } = useMutation({
    mutationFn: (params: { contractVisitorIds: string[] }) => getFixedCsn({ contractVisitorIds: params.contractVisitorIds }),
  });

  const { mutate: saveCardNumber } = useMutation({
    mutationFn: (params: { contractVisitorId: string; accessToken: string }) => postFixedCsn(params),
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ["csnkeyList"] });
    },
  });

  const { mutate: deleteCardNumber } = useMutation({
    mutationFn: (params: { contractVisitorId: string }) => deleteFixedCsn(params),
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ["csnkeyList"] });
    },
  });

  return { csnkeyList, isCsnkeyListLoading, saveCardNumber, deleteCardNumber, getFixedCsnMutationAsync };
};

export default useCsnkey;
