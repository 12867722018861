export const BuildingParkingDataMap = {
  // id: "ID",
  selfPropelledParkingLotNums: "자주식주차장수",
  selfPropelledParkingLotTotalNums: "자주식주차장대수",
  mechanicalParkingLotNums: "기계식주차장수",
  mechanicalParkingLotTotalNums: "기계식주차장대수",
  description: "설명",
} as const;

export const BuildingRoomDataMap = {
  // id: "건물_층_호실_ID",
  roomNum: "호실",
  roomName: "호실 명칭",
  leasableAreaNet: "전용면적",
  leasableArea: "임대면적",
  rent: "임대료",
  isPrimary: "대표여부",
  ownerId: "소유주 ID",
  ownerName: "소유주 명",
  managementUsingCount: "관리비상품 사용건수",
  isDeleted: "삭제여부",
} as const;

export const BuildingFloorDataMap = {
  // id: "건물_층_ID",
  floorNum: "층",
  floorName: "층 명칭",
  buildingRoomList: "호실",
  isDeleted: "삭제여부",
} as const;

export const BuildingDataMap = {
  // id: "건물_ID",
  partnerId: "파트너ID",
  // serviceId: "공간상품-건물 매핑 ID",
  buildingName: "건물이름",
  locationCode: "LocationCode",
  description: "설명",
  feature: "지리적특징",
  floorNums: "지상층수",
  undergroundFloorNums: "지하층수",
  completionYmd: "사용승인일",
  remodelingYmd: "리모델링 년도",
  buildingStructureType: "건축물 용도",
  entryStartTime: "출입시작시간",
  entryEndTime: "출입종료시간",
  isHoliday: "공휴일여부",
  leasableArea: "임대면적",
  leasableAreaNet: "전용면적",
  exitDirection: "주출입구방향",
  buildingToilet: "화장실",
  passengerElev: "승객엘리베이터",
  freightElev: "화물엘리베이터",
  heatingType: "냉난방타입",
  heatingTypeDesc: "냉난방타입설명",
  isHeating: "냉난방장비제공여부",
  heatingFacilityType: "냉난방장비타입",
  heatingFacilityTypeDesc: "냉난방장비타입설명",
  heatingFacilityTypeAddDesc: "냉난방방식추가설명",
  addressList: "다음 지도에서 돌려받은 주소정보",
  buildingFloorList: "건물 층/호실",
  buildingCommonFacility: "주차/회의실/좌석/편의시설",
  productNums: "해당 건물을 사용중인 상품 수",
  mgmtOfficeId: "관리처 ID",
  mgmtProviderId: "정산정보_관리처_ID",
  mapUrlNaver: "네이버맵 URL",
  mapUrlKakako: "카카오맵 URL",
  mapUrlT: "TMAP URL",
  mapUrlGoogle: "구글맵 URL",
} as const;

export const AddressDataMap = {
  // id: "주소 id",
  zonecode: "우편번호",
  address: "기본주소",
  addressDetail: "상세주소",
  addressEnglish: "기본영문주소",
  addressType: "기본주소타입",
  userSelectedType: "사용자선택주소타입",
  noSelected: "연관주소선택여부",
  userLanguageType: "사용자선택언어타입",
  roadAddress: "도로명주소",
  roadAddressEnglish: "도로명영문주소",
  jibunAddress: "지번주소",
  jibunAddressEnglish: "영문지번주소",
  autoRoadAddress: "자동매핑도로명주소",
  autoRoadAddressEnglish: "자동매핑도로명영문주소",
  autoJibunAddress: "자동매핑지번주소",
  autoJibunAddressEnglish: "자동매핑영문지번주소",
  buildingCode: "건물관리번호",
  buildingName: "건물명",
  apartment: "공동주택여부",
  sido: "도시이름",
  sidoEnglish: "도시영문이름",
  sigungu: "시군구이름",
  sigunguEnglish: "시군구영문이름",
  sigunguCode: "시군구코드",
  roadnameCode: "도로명코드",
  bcode: "법정동코드",
  roadname: "도로명값",
  roadnameEnglish: "도로명영문값",
  bname: "법정동이름",
} as const;

export const BuildingCommonFacilityDeskGroupDataMap = {
  // id: "그룹 ID",
  groupName: "그룹명",
  isGround: "지상여부",
  floorNm: "층",
  description: "설명",
  deskChartDescription: "좌석배치도 설명",
  buildingId: "건물 ID",
  deskList: "좌석 목록",
  desk: "좌석 목록 (삭제 예정)",
  mediaList: "좌석배치도 이미지",
} as const;

export const BuildingCommonFacilityDataMap = {
  parkingLotList: "건물 공용공간 주차장",
  mettingRoomList: "건물 공용공간 회의실",
  deskSpace: "건물 공용공간 좌석",
  deskGroupList: "좌석그룹",
  mediaList: "좌석 이미지",
  refreshRoomList: "건물 공용공간 편의시설",
} as const;

export const ProductDataMap = {
  // id: "공간상품_ID",
  partnerId: "파트너ID",
  buildingList: "공간상품에 등록된 건물정보",
  productType: "상품타입",
  productBuildingList: "상품 건물 리스트",
  productName: "공간상품이름",
  introduce: "한줄소개",
  status: "공개상태",
  description: "설명",
  productIconTypeList: "상품 아이콘 타입",
  minLeasePeriod: "최소계약기간",
  maxLeasePeriod: "최대계약기간",
  isRightAwayMoveIn: "즉시입주가능여부",
  moveInAvailDate: "입주가능일자",
  vocUrl: "vocurl",
  colorName: "색상명",
  productNotice: "상품 유의사항",
  deposit: "보증금",
  earnest: "계약금",
  balanceFullPaymentDate: "잔금일자",
  costType: "요금타입",
  productionCost: "원가",
  rentalCostList: "이용료",
  productOptionalFacilityList: "추가 시설/서비스",
  etcService: "기타시설서비스",
  parkingLotFreeDescription: "무료 주차 설명",
  parkingLotPayDescription: "유료 주차 설명",
  meetingRoomMaxReservTimeMonth: "회의실 한달 최대 예약가능 시간",
  meetingRoomMaxReservTimeDay: "회의실 하루 최대 예약가능 시간",
  isUnlimitedMeetingRoom: "회의실 무제한 여부",
  isAllowedMeetingRoomOverReservation: "회의실 초과예약 허용 여부",
  isLimitedMeetingRoomMonthlyReservation: "회의실 월별 예약제한 사용 여부",
  isLimitedMeetingRoomDailyReservation: "회의실 일별 예약제한 사용 여부",
  meetingRoomDescription: "회의실 추가 설명",
  deskMaxReservTimeMonth: "좌석 한달 최대 예약가능 시간",
  deskMaxReservTimeDay: "좌석 하루 최대 예약가능 시간",
  isUnlimitedDesk: "좌석 무제한 여부",
  isAllowedDeskOverReservation: "좌석 초과예약 허용 여부",
  isLimitedDeskMonthlyReservation: "좌석 월별 예약제한 사용 여부",
  isLimitedDeskDailyReservation: "좌석 일별 예약제한 사용 여부",
  deskDescription: "좌석 추가 설명",
  refreshRoomMaxReservTimeMonth: "편의시설 한달 최대 예약가능 시간",
  refreshRoomMaxReservTimeDay: "편의시설 하루 최대 예약가능 시간",
  isUnlimitedRefreshRoom: "편의시설 무제한 여부",
  isAllowedRefreshRoomOverReservation: "편의시설 초과예약 허용 여부",
  isLimitedRefreshRoomMonthlyReservation: "편의시설 월별 예약제한 사용 여부",
  isLimitedRefreshRoomDailyReservation: "편의시설 일별 예약제한 사용 여부",
  refreshRoomDescription: "편의시설 추가 설명",
  isParkingLot: "주차 사용 여부",
  isMeetingRoom: "회의실 사용 여부",
  isRefreshRoom: "편의시설 사용 여부",
  isDesk: "좌석 사용 여부",
  refundInfo: "결제 및 취소 안내",
  isDeleted: "삭제여부",
  createdDate: "생성일",
  modifiedDate: "수정일",
  modifiedBy: "수정자",
  createdBy: "생성자",
  isUsed: "현재 사용중인지 여부",
  buildingCommonFacilityList: "상품-건물 공용공간 목록",
} as const;

export const CommonFacilityDataMap = {
  // id: "건물_공용시설_ID",
  buildingId: "건물_ID",
  commonFacilityType: "공용시설타입",
  isGround: "지상여부",
  floorNum: "층",
  facilityName: "시설명",
  facilityList: "시설 목록",
  facilityMaxPeopleNums: "시설정원(수)",
  eslAddress: "ESL 매핑주소",
  locationCode: "LocationCode",
  description: "설명",
  spaceStyle: "공간유형",
  spaceStyleNum: "공간유형번호",
  mediaList: "시설 이미지",
  centerX: "좌석배치도 X 값",
  centerY: "좌석배치도 Y 값",
  BuildingCommonFacilityDeskGroupId: "좌석 그룹 ID",
  buildingCommonFacilityDeskGroupName: "좌석 그룹명",
  productBuilcingCommonfacilityId: "상품-건물 공용공간ID",
  productId: "상품ID",
  isUnlimitedReservation: "예약 무제한 여부",
  isAllowedOverReservation: "초과예약 허용 여부",
  isLimitedMonthlyReservation: "월별 예약제한 사용 여부",
  monthlyServiceMinutes: "월별 제공시간(분)",
  isLimitedDailyReservation: "일별 예약제한 사용 여부",
  dailyServiceMinutes: "일별 예약제한 시간(분)",
  isDeleted: "삭제여부",
} as const;

export const MediaFileDataMap = {
  // id: "PK, 수정시 필요.",
  // serviceId: "서비스ID",
  mediaServiceType: "서비스타입",
  category1: "카테고리 1",
  description: "설명",
  url: "이미지 전체 URL",
  fileStorageType: "파일 저장소 타입",
  key: "물리 파일 키",
  filename: "저장 파일 이름",
  contentType: "저장 파일 타입",
  fileSize: "저장 파일 사이즈",
  isPrimary: "대표미디어여부",
  orderNums: "정렬순서",
  cmdType: "요청타입",
} as const;

export const MetaItemDataMap = {
  // id: "메타아이템 id",
  metaGroup: "그룹타입",
  metaItem: "아이템타입",
  orderNums: "정렬순서",
  value1: "기본값1",
  value2: "기본값2",
  value3: "기본값3",
} as const;

export const ProductBuildingDataMap = {
  // id: "건물_ID",
  productBuildingFloorList: "건물 층",
  productBuildingCommonFacility: "공용공간",
  isDeleted: "삭제여부",
} as const;

export const ProductBuildingFloorDataMap = {
  // id: "건물_층_ID",
  productBuildingRoomList: "건물 호실",
} as const;

export const ProductBuildingRoomDataMap = {
  // id: "건물_호실_ID",
  isPrimary: "대표 건물/호실 여부",
} as const;

export const PrinterDataMap = {
  isPrinterProvided: "복합기 제공여부",
  printerDescription: "복합기 추가 설명",
  printerOptionList: "복합기 옵션",
} as const;

export const FreeInternetDataMap = {
  isFreeInternetProvided: "무료인터넷 제공여부",
  freeInternetDescription: "무료인터넷 추가 설명",
  freeInternetOptionList: "무료인터넷 옵션",
} as const;

export const AirConditionerDataMap = {
  isAirConditionerProvided: "공조기제공여부",
  airConditionerDescription: "공조기 추가 설명",
  airConditionerInfoList: "공조기 기기정보",
} as const;

export const ProductBuildingCommonFacility = {
  meetingRoomList: "회의실 목록",
  refreshRoomList: "편의시설 목록",
  deskList: "좌석 목록",
};

export const ProductAccessGroup = {
  // id: "상품 출입그룹 ID",
  productId: "상품 ID",
  accessGroupId: "접근그룹 ID",
};

export const ContractManageDataMap = {
  contractManageId: "계약 관리 ID",
  providerId: "프로바이더 ID",
  partnerId: "파트너 ID",
  contractApplyNumber: "이용신청 번호",
  mbOrganizationId: "조직(회사) id",
  spaceProductId: "공간상품 id (공간상품 관리)",
  spaceProductType: "공간상품타입",
  spaceProductName: "공간상품이름 (스냅샷)",
  contractName: "계약명",
  isUseVisitorInvite: "방문자 초대 사용여부",
  isUseCalendar: "캘린더연동 사용여부",
  dummyUserEmail: "더미 유저 이메일",
  calendarType: "캘린더 종류",
  tenantId: "테넌트 ID",
  clientId: "클라이언트 ID",
  clientSecretValue: "클라이언트 암호값",
} as const;

export const ContractDataMap = {
  contractId: "계약정보 id",
  contractManageId: "계약 관리 id",
  contractNumber: "계약번호",
  contractStep: "이용신청 진행 단계",
  useStartTime: "(신청)이용 시작 일시",
  useEndTime: "(신청)이용 종료 일시",
  applicantName: "(신청)이용신청자 이름",
  applicantCorporation: "(신청)이용신청자 회사명",
  isAgreeTerms: "(신청)이용신청 약관동의 여부",
  contractDate: "계약일시",
  cancelDate: "취소일시",
  contractTerm: "계약기간",
  contractStartTime: "계약 시작 일시",
  contractEndTime: "계약 종료 일시",
  depositAmount: "보증금",
  earnestAmount: "보증금 (계약금)",
  earnestPayStatus: "보증금 (계약금) 결제상태",
  balanceAmount: "보증금 (잔금)",
  balancePayStatus: "보증금 (잔금) 결제상태",
  payAmount: "이용료 결제 금액",
  costType: "납부 유형",
  isMntContain: "관리비 포함 여부",
  zoneId: "표준 시간대 ID",
} as const;

export const BillContactDataMap = {
  billContactId: "청구 연락처 ID",
  contractId: "계약정보 id",
  receiveNotiType: "수신알림 타입",
  contact: "연락처",
  email: "이메일",
  description: "비고",
} as const;

export const ContractEsignDataMap = {
  esignId: "계약 전자서명 ID",
  contractId: "계약 ID",
  workflowId: "이싸인온 문서ID",
  status: "문서 상태",
  createdDate: "생성일",
  createdBy: "생성자",
  modifiedDate: "수정일",
  modifiedBy: "수정자",
  workflowName: "이싸인온 문서명",
  expiryDate: "이싸인온 문서 만료일",
} as const;

export const BillingScheduleDataMap = {
  scheduleId: "스케줄 id",
  contractId: "계약정보 id",
  providerId: "프로바이더 id",
  supplyType: "공급 유형",
  providerName: "프로바이더명",
  billIssueDate: "스케줄 최근 청구일",
  billPayStatus: "스케줄 최근 결제상태",
  scheduleStartTime: "빌링 시작일시",
  scheduleEndTime: "빌링 종료일시",
  regularPgcode: "정기 결제수단 코드",
  payRegularMonth: "정기 결제월",
  payRegularDay: "정기결제일",
  payDueDays: "정기결제 마감일수 (청구일자 대비)",
  taxInvoiceIssueMonth: "세금계산서 정기발행월",
  taxInvoiceIssueDay: "세금계산서 정기발행일",
  isTaxInvoiceIssue: "세금계산서 발행여부",
  accountInName: "입금자명",
  overdueChargeStandard: "연체료 부과 기준",
  isAutoBilling: "자동 빌링 여부",
  isAutoSend: "공문 자동발송 여부",
  billkey: "payletter 빌링키(자동결제 재결제용 키)",
  isContractCancel: "빌링스케줄 중지 여부",
  contractCancelDate: "빌링스케줄 중지 일자",
  contractCancelStartOrder: "빌링스케줄 중지 시작회차",
  contractCancelReason: "빌링스케줄 중지 사유",
  messageList: "빌링스케줄 공문/알림톡 리스트",
  overdueList: "빌링스케줄 연체율 리스트",
} as const;

export const ScheduleMessageDataMap = {
  messageId: "공문 ID",
  scheduleId: "스케줄 ID",
  messageType: "공문 타입",
  messageMedia: "공문 미디어",
  isSend: "발송 여부",
  isDeleted: "삭제 여부",
  createdDate: "생성일",
  createdBy: "생성자",
  modifiedDate: "수정일",
  modifiedBy: "수정자",
} as const;

export const ScheduleOverdueDataMap = {
  overdueId: "연체율 ID",
  scheduleId: "스케줄 ID",
  overdueChargeStandard: "연체율 부과 기준",
  overdueDays: "연체율 기준일",
  overdueRates: "연체율 (%)",
  isDeleted: "삭제 여부",
  createdDate: "생성일",
  createdBy: "생성자",
  modifiedDate: "수정일",
  modifiedBy: "수정자",
} as const;

export const ScheduleAttributeDataMap = {
  attrId: "속성 ID",
  scheduleId: "스케줄 ID",
  attrName: "속성명",
  attrValue: "속성값",
  isDeleted: "삭제 여부",
  createdDate: "생성일",
  createdBy: "생성자",
  modifiedDate: "수정일",
  modifiedBy: "수정자",
} as const;

export const ProviderDataMap = {
  providerId: "프로바이더 ID",
  providerName: "프로바이더 명",
  partnerId: "파트너 ID",
  representative: "대표자",
  businessRegistrationNumber: "사업자 등록번호",
  address: "주소",
  bizType: "업태",
  bizClass: "종목",
  description: "비고",
  supplyType: "공급 유형",
  workStartTime: "업무 시작시간",
  workEndTime: "업무 종료시간",

  isPopbillBiz: "팝빌 사업자 등록여부",
  isVerifiedTaxInvoice: "세금계산서 API 검증 여부",
  isVerifiedBankAccount: "계좌조회 API 검증 여부",
  isVerifiedCashbill: "현금영수증 API 검증 여부",
  popbillVerifiedDate: "팝빌 API 검증일시",
} as const;

export const ProviderContactDataMap = {
  contactId: "연락처 ID",
  providerId: "프로바이더 ID",
  assignedWorkType: "담당업무 타입",
  name: "이름",
  contact: "연락처",
  email: "이메일",
  description: "비고",
  isDeleted: "삭제 여부",
  bankName: "은행명",
} as const;

export const ProviderAccountDataMap = {
  accountId: "계좌 ID",
  providerId: "프로바이더 ID",
  accountType: "계좌 타입",
  bankCode: "은행코드",
  bankName: "은행명",
  accountNumber: "계좌번호",
  accountHolder: "예금주",
  isPopbillAcct: "팝빌 계좌 등록(할당)여부",
  syncCode: "계좌 연동코드",
  syncMessage: "계좌 연동 메시지",
  syncDate: "계좌 연동일시",
  errorCount: "계좌 연동오류횟수",
} as const;

export const PayletterDataMap = {
  payletterId: "페이레터 ID",
  clientType: "가맹점 타입",
  clientId: "가맹점 ID",
  paymentKey: "결제 API Key",
  searchKey: "조회 API Key",
  verifiedDate: "검증일시",
  isVerifiedPaymentKey: "결제 Key API 검증 상태",
  isVerifiedSearchKey: "조회 Key API 검증 상태",
  isDeleted: "삭제 여부",
} as const;

const CommonKeyNamesMap = {
  serviceId: "서비스ID",
  id: "id",
  createdDate: "생성일",
  createdBy: "생성자",
  modifiedDate: "수정일",
  modifiedBy: "수정자",
  isDeleted: "삭제여부",
  contract: "계약",
  provider: "프로바이더",
  partner: "파트너",
  schedule: "빌링스케줄",
  product: "상품",
  building: "건물",
  contractManage: "계약관리",
} as const;

export const KeyNamesMap = {
  // 건물 감사 로그 종류 코드
  AL201: {
    // 공통 키
    ...CommonKeyNamesMap,
    // Address 관련 키
    ...AddressDataMap,
    // Building 관련 키
    ...BuildingDataMap,
    // BuildingFloor 관련 키
    ...BuildingFloorDataMap,
    // BuildingRoom 관련 키
    ...BuildingRoomDataMap,
    // BuildingParking 관련 키
    ...BuildingParkingDataMap,
    // BuildingCommonFacility 관련 키
    ...BuildingCommonFacilityDataMap,
    // BuildingCommonFacilityDeskGroup 관련 키
    ...BuildingCommonFacilityDeskGroupDataMap,
    // CommonFacility 관련 키
    ...CommonFacilityDataMap,

    // ProductBuildingCommonFacility 관련 키
    ...ProductBuildingCommonFacility,
  },

  //상품 감사 로그 종류 코드
  AL202: {
    // 공통 키
    ...CommonKeyNamesMap,
    maxNums: "최대 이용자",
    productionPrice: "판매가",
    productionPriceType: "판매가 유형",
    // Product 관련 키
    ...ProductDataMap,
    // CommonFacility 관련 키
    ...CommonFacilityDataMap,
    // MediaFile 관련 키
    ...MediaFileDataMap,
    // MetaItem 관련 키
    ...MetaItemDataMap,
    // ProductBuilding 관련 키
    ...ProductBuildingDataMap,
    // ProductBuildingFloor 관련 키
    ...ProductBuildingFloorDataMap,
    // ProductBuildingRoom 관련 키
    ...ProductBuildingRoomDataMap,
    // Printer 관련 키
    ...PrinterDataMap,
    // FreeInternet 관련 키
    ...FreeInternetDataMap,
    // AirConditioner 관련 키
    ...AirConditionerDataMap,
    //ProductBuildingCommonFacility 공용공간
    ...ProductBuildingCommonFacility,
    //ProductAccessGroup 상품 엑세스 그룹
    ...ProductAccessGroup,
  },

  // -------- 아래부터 신청계약 관련 객체들 --------- //
  // 신청계약 감사 로그 종류 코드
  AL131: {
    // 공통 키
    ...CommonKeyNamesMap,
    ...{
      //커스텀 키
      contract: "계약",
      provider: "프로바이더",
      partner: "파트너",
      schedule: "빌링스케줄",
    },
    // ContractManage 관련 키
    ...ContractManageDataMap,

    // Contract 관련 키
    ...ContractDataMap,

    // BillContact 관련 키
    ...BillContactDataMap,

    // ContractEsign 관련 키
    ...ContractEsignDataMap,
  },

  // -------- 아래부터 신청계약 > 빌링스케줄 관련 객체들 --------- //
  // 계약/빌링스케줄 감사 로그 종류 코드
  AL141: {
    // 공통 키
    ...CommonKeyNamesMap,
    ...{
      //커스텀 키
      contract: "계약",
      provider: "프로바이더",
      partner: "파트너",
      schedule: "빌링스케줄",
    },
    ...BillingScheduleDataMap,
    ...ScheduleMessageDataMap,
    ...ScheduleOverdueDataMap,
    ...ScheduleAttributeDataMap,
  },

  // -------- 아래부터 정산정보(프로바이더) 관련 객체들 --------- //
  // 정산정보(프로바이더) 감사 로그 종류 코드
  AL101: {
    // 공통 키
    ...CommonKeyNamesMap,
    ...{
      //커스텀 키
      contract: "계약",
      provider: "프로바이더",
      partner: "파트너",
      schedule: "빌링스케줄",
    },
    ...ProviderDataMap,
    ...ProviderContactDataMap,
    ...ProviderAccountDataMap,
    ...PayletterDataMap,
  },
} as const;

export type KeyNamesMapKey = keyof typeof KeyNamesMap;
export type KeyNamesMapValue = (typeof KeyNamesMap)[KeyNamesMapKey];
