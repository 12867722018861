import { useEffect, useMemo, useState } from "react";
import { useBlockLayout, useFilters, useGlobalFilter, useRowSelect, useSortBy, useTable } from "react-table";
import { useApiOperation } from "src/api/hooks";
import { PageMeta } from "src/api/public-types";
import { getUsers } from "src/api/user/user-api";
import { GetUsersParams, UserListModel } from "src/api/user/user-types";
import { BaseCheckbox, BaseInputWithSearch, BasePagination, BaseRadio } from "src/components";
import { rvMemberColumns } from "../columns/RvMemberColumns";
import { useModal } from "src/recoil/modalState/hook";

type Props = {
  onClick?: (data: UserListModel[]) => void;
  onClose?: () => void;
  applyNumber: string;
  title: "주최자" | "참석자" | "이용자";
  checkboxType?: "checkbox" | "radio";
  selectKey: string;
  organizerMemberNo?: string;
  addedItems?: string[]; //등록된 | 추가된 목록
  contractName: string;
};

const searchOptions = [
  // { value: "ALL", label: "전체" },
  { value: "MEMBER_MOBILE_NUMBER", label: "휴대폰번호" },
];

const RvMembersModal = ({
  onClick, //
  onClose,
  applyNumber,
  title,
  checkboxType = "radio",
  selectKey,
  organizerMemberNo,
  addedItems,
  contractName,
}: Props) => {
  const requestParams: GetUsersParams = {
    page: 0,
    size: 20,
    searchType: "MEMBER_MOBILE_NUMBER",
    searchValue: "",
    contractApplyNumber: applyNumber,
    sort: {
      orders: [
        {
          property: "contractMemberId",
          direction: "DESC",
        },
      ],
    },
  };
  const [members, setMembers] = useState<UserListModel[]>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();

  const [isAvailable, setIsAvailable] = useState(true);
  const { executeAsync: getUsersAsync } = useApiOperation(getUsers);

  const [params, setParams] = useState<GetUsersParams>(requestParams);
  const [selectedItems, setSelectedItems] = useState<UserListModel[]>([]);

  const newList = useMemo(() => {
    return members.map((item: UserListModel) => {
      const selects = selectedItems.map((select: UserListModel) => Number(select.memberNo));
      return {
        checked:
          selects.includes(Number(item.memberNo)) || // 선택한 item 목록에 데이터가 있을 때 (paging시 필요)
          item.memberNo === organizerMemberNo, // 주최자 id와 동일할경우 checked
        ...item,
      };
    });
  }, [checkboxType, members, selectedItems]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns: rvMemberColumns,
      data: newList,
      addedItems,
      organizerMemberNo,
      selectedItems,
    },
    useBlockLayout,
    useGlobalFilter,
    useFilters,
    useSortBy,
    useGlobalFilter,
    useRowSelect,
    (hooks) => {
      checkboxType &&
        hooks.visibleColumns.push((columns: Array<any>) => [
          {
            width: 50,
            Header: "선택",
            accessor: "checked",
            Cell: ({ row, addedItems, organizerMemberNo }: any) => {
              let member: UserListModel & { checked: boolean } = row.original;

              return (
                <>
                  {checkboxType === "checkbox" ? (
                    <BaseCheckbox
                      id={row.id}
                      name={row.id}
                      checked={member.checked}
                      onChange={(checked: boolean) => {
                        member.checked = checked;
                        setSelectedItems((prev) =>
                          checked
                            ? [...prev, row.original]
                            : prev.filter((item) => {
                                return item.memberNo !== row.original.memberNo;
                              }),
                        );
                      }}
                      disabled={
                        addedItems?.includes(String(row.original[selectKey])) ||
                        row.original.memberNo === organizerMemberNo ||
                        member.memberStatus !== "MEMBER_USE"
                      }
                    />
                  ) : (
                    <BaseRadio
                      id={`member-${row.id}`}
                      name={`member-selector`}
                      checked={row.original.checked || false}
                      onChange={() => {
                        setSelectedItems([row.original]);
                      }}
                    />
                  )}
                </>
              );
            },
          },
          ...columns,
        ]);
    },
  );

  useEffect(() => {
    console.log("selectedItems updated:", selectedItems);
  }, [selectedItems]);

  const fetchApi = async (request: GetUsersParams) => {
    const { data, status } = await getUsersAsync(request);
    const result = data.data;
    if (status >= 200 && status <= 299) {
      setMembers(result);
      setPageMeta(data?.meta?.pageMeta);
    }
  };
  useEffect(() => {
    let request = requestParams;
    if (isAvailable) {
      request = {
        ...params, //
        page: 0,
        memberStatus: "MEMBER_USE",
      };
    }
    setParams(request);

    fetchApi(request);
  }, [isAvailable]);

  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);

  return (
    <>
      <section className="base-abstract-modal__title flex-center-between ">
        <div className="flex-center">
          <h1>{title} 선택</h1>
          <div className="sub-title-banner ml10">
            <p>{contractName}</p>
          </div>
        </div>
        <div className=" right-area">
          <div className="flex-center">
            <BaseCheckbox
              id={"rv_member"}
              name={"rv_member"}
              label="선택 가능"
              className="mr10"
              onChange={(checked: boolean) => {
                setIsAvailable(checked);
              }}
              checked={isAvailable}
            />
            <BaseInputWithSearch
              inputValue={params.searchValue}
              selectValue={params.searchType}
              stateOptions={searchOptions}
              onKeyUp={() => fetchApi({ ...params, page: 0 })}
              onChange={(searchValue: string) => {
                setParams({ ...params, searchValue });
              }}
              setStateValue={(searchType: string) => {
                setParams({ ...params, searchType });
              }}
              onSearchClick={() => fetchApi({ ...params, page: 0 })}
            />
          </div>
        </div>
      </section>

      <section className="base-abstract-modal__contents">
        <div className="contents-container__table px30">
          <div {...getTableProps()} className="base-table view-data-table sticky">
            <div className="header">
              {headerGroups.map((headerGroup) => (
                <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                  {headerGroup.headers.map((header) => {
                    return (
                      <div {...header.getHeaderProps(header.getSortByToggleProps())} className="base-table__th">
                        {header.render("Header")}
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
            {rows.length && rows.length > 0 ? (
              <div {...getTableBodyProps()} className="body">
                {rows.map((row, idx: number) => {
                  prepareRow(row);
                  return (
                    <div {...row.getRowProps()} className="base-table__tr">
                      {row.cells.map((cell) => {
                        return (
                          <div {...cell.getCellProps()} className="base-table__td">
                            {cell.render("Cell")}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
                <div className="base-table__td w-100 text-center">
                  <div className="w-100">
                    <span>데이터가 없습니다.</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      <section className="px30">
        <BasePagination
          pageIndex={params.page || 0}
          totalPages={pageMeta?.totalPages || 0}
          currentSize={Number(params.size) || 20}
          sizeOption={(size) => {
            setParams({ ...params, size, page: 0 });
            fetchApi({ ...params, size, page: 0 });
          }}
          goPage={(page: number) => {
            setParams({ ...params, page });
            fetchApi({ ...params, page });
          }}
          totalElements={pageMeta?.totalElements || 0}
          children={
            <div className="flex-center">
              <button className="base-btn color-white mr10" onClick={() => onClose && onClose()}>
                취소
              </button>
              <button
                className="base-btn"
                onClick={() => {
                  // const selected = selectedFlatRows[0].original;
                  // onClick && onClick(selected);
                  onClick && onClick(selectedItems);
                  onClose && onClose();
                }}
              >
                선택
              </button>
            </div>
          }
        />
      </section>
    </>
  );
};

export default RvMembersModal;
