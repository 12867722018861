import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useBlockLayout, useTable } from "react-table";
import { UnionServiceType } from "src/api/adminMemo/adminmemo-types";
import { CommonFacilityListModel } from "src/api/building/building-types";
import { useApiOperation } from "src/api/hooks";
import { getBuildingCommonFacilityPriceRulesAsync, getPriceRulesListAsync, postBuildingCommonFacilityPriceRulesAsync } from "src/api/price/price-api";
import { BuildingCommonFacilityPriceRulesModel, PriceRulesModel } from "src/api/price/price-types";
import { BaseButton, BaseCheckbox } from "src/components";
import Link from "src/components/PartnerLink";
import useGetProduct from "src/hooks/product/useGetProduct";
import { PagePath } from "src/pages/product/details";
import { SpaceTypeLabel, SpaceTypeT } from "src/pages/product/product-types";
import { useModal } from "src/recoil/modalState/hook";
import { hasDuplicateValue } from "src/utils";
import { base_cols, id_selector, id_viewer, price_cols, price_name_cols, priority_cols, specific_cols, cost_cols } from "../columns/PriceColumns";
import { useToast } from "src/recoil/toast/hook";
import _ from "lodash";
import useDirtyState from "src/recoil/dirty/hooks";

type Props = {
  space: CommonFacilityListModel;
  isEditable?: boolean; //보내지마세요 error 방지로 남겨둠
  productId?: string;
  serviceId: string;
  serviceType: UnionServiceType;
};

export interface SpecificTableData extends PriceRulesModel {
  isSelected: boolean;
  priority: string;
  buildingCommonFacilityPriceRulesId?: number;
}

const Price = ({
  space,
  productId,
  serviceId,
  serviceType, //
  isEditable,
}: Props) => {
  // const isEditable = true; //
  const spaceName = space.facilityName;
  const spaceType = space.commonFacilityType as SpaceTypeT;
  const [hiddenPrice, setHiddenPrice] = useState(true);
  const { openToast } = useToast();
  const title = `${SpaceTypeLabel[spaceType]} ${spaceName}`;

  const { setAbstractModalZ1, setBaseModal } = useModal();
  const { executeAsync: getPriceRulesList } = useApiOperation(getPriceRulesListAsync);
  const { executeAsync: getBuildingCommonFacilityPriceRules } = useApiOperation(getBuildingCommonFacilityPriceRulesAsync);
  const { executeAsync: postBuildingCommonFacilityPriceRules } = useApiOperation(postBuildingCommonFacilityPriceRulesAsync);

  const { data: product } = useGetProduct({ productId: productId });

  const [basePriceTableData, setBasePriceTableData] = useState<PriceRulesModel[]>([]); // 기본요금
  const [specificTableData, setSpecificTableData] = useState<SpecificTableData[]>([]); // 개별요금
  const [originSpecificTableData, setOriginSpecificTableData] = useState<SpecificTableData[]>([]); // 개별요금 원본

  const [trigger, setTrigger] = useState(true);

  /** 개별 요금 컬럼 */
  const specificTableColumns = useMemo(() => {
    if (isEditable) {
      return id_selector
        .concat(id_viewer)
        .concat({ ...price_name_cols, width: 105 })
        .concat({ ...cost_cols, width: 100 })
        .concat({ ...price_cols, width: 110 })
        .concat(specific_cols)
        .concat(priority_cols);
    } else {
      return id_viewer
        .concat({ ...price_name_cols, width: 185 })
        .concat({ ...cost_cols, width: 100 })
        .concat({ ...price_cols, width: 110 })
        .concat(specific_cols)
        .concat(priority_cols);
    }
  }, [isEditable]);

  /** 기본 요금 컬림 */
  const basePriceColumns = useMemo(() => {
    return base_cols.concat(price_name_cols).concat(cost_cols).concat(price_cols);
  }, []);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns: basePriceColumns as [],
      data: basePriceTableData,
      setSpecificTableData,
      isEditable,
      space,
    },
    useBlockLayout,
  );

  const {
    getTableProps: getTableProps2,
    getTableBodyProps: getTableBodyProps2,
    headerGroups: headerGroups2,
    prepareRow: prepareRow2,
    rows: rows2,
  } = useTable(
    {
      columns: specificTableColumns as [],
      data: specificTableData as [],
      setSpecificTableData,
      isEditable,
      space,
    },
    useBlockLayout,
  );

  /** 모든 테이블 데이터 생성 */
  const findUsageData = useCallback(async () => {
    const {
      data: {
        data: { content: priceRules },
      },
    } = await getPriceRulesList({ providerId: product?.providerId, page: 0, size: 999 });
    const {
      data: {
        data: { content: buildingCommonFacilityPriceRules },
      },
    } = await getBuildingCommonFacilityPriceRules({ serviceId: serviceId, serviceType: serviceType });

    // priceRules에서 공용공간, 프로바이더 조건만 추출 후 set
    const basePriceRules = priceRules.filter((rules) => rules.buildingCommonFacilityId === space.id || rules.priceRuleType === "PRICE_RULE_PROVIDER");
    setBasePriceTableData(basePriceRules);

    //==================================하단 부터 개별요금===============================//

    // priceRules에서 개별요금 조건만 추출
    const specificPriceRules = priceRules.filter((rules) => rules.priceRuleType === "PRICE_RULE_SPECIFIC");

    /** 사용중인 공용공간 룰 리스트 */
    const usedFacilityRules = buildingCommonFacilityPriceRules.filter(
      (facilityPriceRules) => Number(facilityPriceRules.buildingCommonFacilityId) === Number(space.id),
    );

    // 상세,편집 모드에서 사용할 테이블데이터 리소스 생성
    const allSpecificTableData: SpecificTableData[] = specificPriceRules.map((rule) => {
      const find = buildingCommonFacilityPriceRules.find(
        (buildingCommonFacilityPriceRule) =>
          Number(buildingCommonFacilityPriceRule.priceRuleId) === Number(rule.id) && // 같은 요금 아이디
          Number(buildingCommonFacilityPriceRule.buildingCommonFacilityId) === Number(space.id), // 같은 공용공간
      );

      const returnData: SpecificTableData = {
        ...rule,
        isSelected: find ? true : false,
        priority: find?.priority?.toString() ?? "",
        buildingCommonFacilityPriceRulesId: find?.id,
      };

      return returnData;
    });

    // 적용중인 개별요금 테이블 생성
    let usedSpecificTableData: SpecificTableData[] = [];

    usedFacilityRules.forEach((usedFacilityRule) => {
      //적용중인 공용공간 요금룰 id와 모든 개별요금 리스트에 있는 priceRuleId 를 대조해서 사용중인 공용공간 요금룰 정렬순으로 테이블 데이터 리턴
      const findSpecificTableData = allSpecificTableData.find((data) => String(data.id) === String(usedFacilityRule.priceRuleId));

      if (findSpecificTableData) {
        usedSpecificTableData.push(findSpecificTableData);
      }
    });

    const filteredSpecificTableData = allSpecificTableData.filter(
      (data) => !usedFacilityRules.find((usedFacilityRule) => String(usedFacilityRule.priceRuleId) === String(data.id)),
    );

    setSpecificTableData(usedSpecificTableData.concat(filteredSpecificTableData));

    //탭 이동시 원본데이터 비교용
    setOriginSpecificTableData(usedSpecificTableData.concat(filteredSpecificTableData));
  }, [product, setBasePriceTableData, setSpecificTableData, getBuildingCommonFacilityPriceRules, getPriceRulesList, space.id]);

  useEffect(() => {
    findUsageData();
  }, [findUsageData, trigger]);

  const { setIsDirtyState, isDirtyState } = useDirtyState();

  useEffect(() => {
    if (!_.isEqual(originSpecificTableData, specificTableData)) {
      return setIsDirtyState(true);
    } else {
      setIsDirtyState(false);
    }
  }, [setIsDirtyState, originSpecificTableData, specificTableData]);

  const onCloseTabModal = () => {
    if (isDirtyState) {
      setBaseModal({
        isOpen: true,
        title: "취소하시겠습니까?",
        children: "저장하지 않고 취소하면 입력한 내용이 모두 삭제됩니다.",
        btnLeftTitle: "취소",
        btnRightTitle: "확인",
        onClick: () => {
          setIsDirtyState(false);
          setBaseModal({ isOpen: false });
          setAbstractModalZ1({ isOpen: false });
        },
        onClose: () => {
          setBaseModal({ isOpen: false });
        },
      });
    } else {
      setIsDirtyState(false);
      setAbstractModalZ1({ isOpen: false });
    }
  };

  const handleSubmit = async ({
    specificTableData,
    serviceType,
    spaceId,
  }: {
    specificTableData: SpecificTableData[];
    serviceId: string;
    serviceType: string;
    spaceId: number;
  }) => {
    const selectedSpecificTableData = specificTableData.filter(
      // 날짜가 안 지났고 체크된거만
      (data) => data.isSelected === true && moment(data.endDate).isBefore(moment()) === false,
    );

    for (let i = 0; i < selectedSpecificTableData.length; i++) {
      if (!selectedSpecificTableData[i].priority) {
        return setBaseModal({ isOpen: true, btnRightTitle: "확인", title: "가중치를 입력해주세요." });
      }

      if (moment(selectedSpecificTableData[i].endDate).isBefore(moment())) {
        return setBaseModal({
          isOpen: true,
          btnRightTitle: "확인",
          title: "적용기간을 확인해 주세요",
          children: "적용 기간이 과거인 정책을 선택하셨습니다.",
        });
      }
    }

    if (hasDuplicateValue(selectedSpecificTableData, "priority")) {
      return setBaseModal({ isOpen: true, btnRightTitle: "확인", title: "중복되는 가중치 입력값이 있습니다." });
    }

    const sendData: BuildingCommonFacilityPriceRulesModel[] = [];

    selectedSpecificTableData.forEach((value) => {
      const data: BuildingCommonFacilityPriceRulesModel = {
        id: value.buildingCommonFacilityPriceRulesId,
        serviceId: +serviceId,
        serviceType: serviceType,
        buildingCommonFacilityId: spaceId,
        priceRuleId: value.id,
        priority: Number(value.priority),
      };

      if (data.id && value.isSelected) {
        data.cmdType = "U";
      }
      if (!data.id && value.isSelected) {
        data.cmdType = "C";
      }
      if (value.isSelected === false) {
        data.cmdType = "D";
      }
      sendData.push(data);
    });

    const result = await postBuildingCommonFacilityPriceRules(sendData);
    setBaseModal({ isOpen: false });
    setTrigger((prev) => !prev);
    openToast({ content: "저장 되었습니다.", isSystemToast: true });
    setIsDirtyState(false); //isDirtyState 초기화

    console.log("result :>> ", result);
  };

  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);
  return (
    <article className="base-abstract-modal__contents d-flex flex-column">
      <ul className="base-list pt10 px30">
        <li className="font14">요금 정책은 개별 요금 → 기본 요금 순으로 적용되며, 개별 요금은 복수로 적용이 가능합니다.</li>
        <li className="font14">특정 시점에 복수의 개별 요금과 기본 요금이 있는 경우, 가중치가 높은 개별 요금의 정책이 적용됩니다.</li>
        <li className="font14 d-block">
          기본 요금과 개별 요금의 생성은{" "}
          <Link to={PagePath.price.list} target="_blank" className={"text-underline ml5"}>
            {"공용공간 요금 정책"} <span className="ic-target-blank"></span>
          </Link>
          메뉴에서 가능합니다. 적용할 요금 정책이 없을 경우{" "}
          <Link to={PagePath.price.list} target="_blank" className={"text-underline ml5"}>
            {"공용공간 요금 정책"} <span className="ic-target-blank"></span>
          </Link>
          메뉴에서 요금을 등록해주세요.
        </li>
      </ul>

      <section className="px30">
        <section className="base-abstract-modal__contents-subtitle">
          <h2>기본 요금</h2>
        </section>
      </section>
      <section className="base-abstract-modal__contents-table-wrap px30">
        <table {...getTableProps()} className="base-table overflow-visible sticky">
          <thead className="header">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                {headerGroup.headers.map((header) => {
                  return (
                    <td {...header.getHeaderProps()} className="base-table__th">
                      {header.render("Header")}
                    </td>
                  );
                })}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()} className="body">
            {rows.length > 0 &&
              rows.map((row: any, i: number) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className={`base-table__tr`}>
                    {row.cells.map((cell: any, index: number) => {
                      return (
                        <td {...cell.getCellProps()} className="base-table__td">
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            {rows.length === 0 && (
              <tr className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
                <td className="base-table__td w-100 text-center">
                  <div className="w-100">
                    <span>데이터가 없습니다.</span>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </section>
      <section className="px30">
        <section className="base-abstract-modal__contents-subtitle">
          <h2>개별 요금</h2>
          <BaseCheckbox checked={hiddenPrice} id="box" name="box" label="지난 날짜 요금 숨기기" onChange={(e) => setHiddenPrice(e)}></BaseCheckbox>
        </section>
      </section>
      <section className="base-abstract-modal__contents-table-wrap px30 mb20">
        <table {...getTableProps2()} className="base-table overflow-visible sticky">
          <thead className="header">
            {headerGroups2.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                {headerGroup.headers.map((header) => {
                  return (
                    <td {...header.getHeaderProps()} className="base-table__th">
                      {header.render("Header")}
                    </td>
                  );
                })}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps2()} className="body">
            {rows2.length > 0 &&
              rows2.map((row: any, i: number) => {
                prepareRow2(row);
                return (
                  <tr {...row.getRowProps()} className={`base-table__tr`}>
                    {row.cells.map((cell: any, index: number) => {
                      const isBeforeDate = moment(row.original.endDate).isBefore(moment());
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={`base-table__td ${isBeforeDate ? "beforeDate" : ""} ${hiddenPrice && isBeforeDate ? "hiddenPrice" : ""} `}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            {rows2.length === 0 && (
              <tr className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
                <td className="base-table__td w-100 text-center">
                  <div className="w-100">
                    <span>데이터가 없습니다.</span>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </section>
      <section className="base-abstract-modal__btn-wrap">
        <div className="flex-center">
          {/* {checkChange && <span className="font14 mr10 text-red">*수정된 사항이 있으니 다시 한번 확인해주세요.</span>} */}

          <BaseButton
            title={"취소"}
            className="color-white mr10"
            onClick={() => {
              onCloseTabModal();
            }}
          />

          {isEditable && (
            <BaseButton
              title={"저장"}
              onClick={() => {
                setBaseModal({
                  isOpen: true,
                  btnRightTitle: "확인",
                  btnLeftTitle: "취소",
                  title: "저장하시겠습니까?",
                  children: "저장 후에는 변경 사항이 즉시 적용됩니다.",
                  onClick: () => {
                    handleSubmit({ serviceId: serviceId, specificTableData, serviceType: serviceType, spaceId: space.id! });
                  },
                });
              }}
            />
          )}
        </div>
      </section>
    </article>
  );
};

export default Price;
