// 방문자 Table Columns
import moment from "moment";
import { Cell, Row } from "react-table";
import { ContractVisitorListModel } from "src/api/visitor/visitor-types";
import { BaseButton, BaseTooltip } from "src/components";
import { useModal } from "src/recoil/modalState/hook";
import { ViewYmdFormat } from "src/utils";
import CsnkeyModal from "../../components/CsnkeyModal";
import useCsnkey from "../../hooks/useCsnkey";
import { formatVisitorMobileNumber } from "../../utils";
import ApprovalStatusTag from "./ApprovalStatusTag";
import { VisitorDateCell } from "./VisitorDateCell";
import { VisitorLinkCell } from "./VisitorLinkCell";
import VisitStatusTag from "./VisitStatusTag";

export const columns = [
  {
    Header: "방문신청번호",
    accessor: "visitApplyNumber",
    width: 120,
    sticky: "left",
    Cell: (props: Cell<ContractVisitorListModel>) => <VisitorLinkCell {...props} />,
  },
  {
    Header: "방문자 Id",
    accessor: "contractVisitorId",
    sticky: "left",
    width: 120,
    Cell: (props: Cell<ContractVisitorListModel>) => {
      return <div className="">{props.value ?? "-"}</div>;
    },
  },
  {
    Header: "방문상태",
    accessor: "visitStatus",
    sticky: "left",
    width: 80,
    Cell: ({ value, row }: Cell<ContractVisitorListModel>) => {
      if (row.original.isDeleted) return <VisitStatusTag value="VISIT_DELETE" />;
      return <VisitStatusTag value={value} />;
    },
  },
  {
    Header: "승인상태",
    accessor: "approvalStatus",
    sticky: "left",
    width: 80,
    Cell: ({ value }: Cell<ContractVisitorListModel>) => {
      return value ? <ApprovalStatusTag value={value} /> : "-";
    },
  },

  {
    Header: "방문자명",
    accessor: "visitorName",
    width: 200,
    Cell: (props: Cell<ContractVisitorListModel>) => {
      return (
        <div className="flex-center-start w-100">
          <BaseTooltip contents={props.value} isSingleLine={true} />
        </div>
      );
    },
  },
  {
    Header: "방문자 휴대폰",
    accessor: "visitorMobileNumber",
    width: 120,
    Cell: ({ value }: Cell<ContractVisitorListModel>) => {
      const phoneNumber = formatVisitorMobileNumber(value);
      return <div className="flex-center-start w-100">{phoneNumber}</div>;
    },
  },
  {
    Header: "방문자 이메일",
    accessor: "visitorEmail",
    width: 160,
    Cell: ({ row }: Cell<ContractVisitorListModel>) => {
      if (row.original.visitorEmail) {
        return (
          <div className="flex-center-start w-100">
            {row.original.visitorEmail} {row.original.visitorEmailLang === "en" ? "(영문)" : "(국문)"}
          </div>
        );
      }

      return <div className="flex-center-start w-100">{"-"}</div>;
    },
  },
  {
    Header: "방문시작일시",
    accessor: "visitStartTime",
    width: 160,
    Cell: (props: Cell<ContractVisitorListModel>) => <span>{props.value ? moment(props.value).format(ViewYmdFormat.YYYY_MM_DD_HH_MM) : "-"}</span>,
  },
  {
    Header: "방문종료일시",
    accessor: "visitEndTime",
    width: 160,
    Cell: (props: Cell<ContractVisitorListModel>) => <span>{props.value ? moment(props.value).format(ViewYmdFormat.YYYY_MM_DD_HH_MM) : "-"}</span>,
  },

  {
    // 상세 페이지 키카드할당
    Header: "키카드 할당",
    accessor: "visitorKeyCardButton",
    width: 105,

    Cell: ({ row }: Cell<ContractVisitorListModel>) => {
      const { setAbstractModalZ1 } = useModal();

      const isDeleted = row?.original?.isDeleted;
      const visitStatus = row?.original.visitStatus;

      // 방문자가 삭제되었거나 방문상태가 삭제/종료 경우 할당 불가
      const isDisabled = isDeleted || visitStatus === "VISIT_DELETE" || visitStatus === "VISIT_CLOSE";

      return (
        <div>
          <BaseButton
            disabled={isDisabled}
            title="할당"
            className="color-white"
            onClick={() =>
              setAbstractModalZ1({
                isOpen: true,
                children: <CsnkeyModal contractVisitor={row?.original as any} />,
              })
            }
          />
        </div>
      );
    },
  },
  {
    Header: "키카드 번호",
    accessor: "visitorKeyCard",
    width: 164,
    Cell: ({ row, data }: { row: Row<ContractVisitorListModel>; data: ContractVisitorListModel[] }) => {
      const { setBaseModal } = useModal();
      //목록에 모든 키번호로 조회
      const contractVisitorIds = data.map((item) => item.contractVisitorId?.toString() || "");

      const contractVisitorId = row.original?.contractVisitorId?.toString() || "";

      const { deleteCardNumber, csnkeyList } = useCsnkey(contractVisitorIds);

      const cardNumber = csnkeyList?.data?.data?.content?.find((item) => String(item.contractVisitorId) === String(contractVisitorId))?.accessToken;

      return (
        <>
          {cardNumber ? (
            <div className="w-100 flex-row flex-start">
              <BaseTooltip className="" contents={cardNumber} isSingleLine={true}></BaseTooltip>
              <div
                className="delete-btn-x shrink-0"
                onClick={() =>
                  setBaseModal({
                    isOpen: true,
                    title: "키카드 번호를 삭제하시겠습니까?",
                    children: "삭제하면 입력되어 있는 번호가 삭제됩니다.",
                    btnRightTitle: "확인",
                    btnLeftTitle: "취소",
                    onClick: () => {
                      deleteCardNumber(
                        { contractVisitorId: contractVisitorId },
                        {
                          onSuccess: () => {
                            setBaseModal({ isOpen: false });
                          },
                        },
                      );
                    },
                  })
                }
              ></div>
            </div>
          ) : (
            <div className="w-100 flex-row flex-start">
              <span>-</span>
            </div>
          )}
        </>
      );
    },
  },

  {
    Header: "초대일시",
    accessor: "invitationDate",
    width: 160,
    Cell: (props: Cell<ContractVisitorListModel>) => <span>{props.value ? moment(props.value).format(ViewYmdFormat.YYYY_MM_DD_HH_MM) : "-"}</span>,
  },
  {
    Header: "초대자",
    accessor: "inviteeMemberId",
    width: 160,
    Cell: (props: Cell<ContractVisitorListModel>) => {
      return (
        <div className="flex-center-start w-100">
          <BaseTooltip contents={props.value || "-"} />
        </div>
      );
    },
  },
  {
    Header: "회원번호",
    accessor: "inviteeMemberNo",
    width: 120,
    Cell: (props: Cell<ContractVisitorListModel>) => {
      return (
        <div className="flex-center-center w-100">
          <BaseTooltip contents={props.value || "-"} />
        </div>
      );
    },
  },
  {
    Header: "신청/계약번호",
    accessor: "contractApplyNumber",
    width: 120,
    Cell: (props: Cell<ContractVisitorListModel>) => {
      return (
        <div className="flex-center-center w-100">
          <BaseTooltip contents={props.value || "-"} />
        </div>
      );
    },
  },
  {
    Header: "초대경로",
    accessor: "invitationChannel",
    width: 80,
    Cell: (props: Cell<ContractVisitorListModel>) => {
      return (
        <div className="flex-center-center w-100">
          <BaseTooltip contents={props.value || "-"} />
        </div>
      );
    },
  },
  {
    Header: "최종 수정일",
    accessor: "modifiedDate",
    width: 120,
    Cell: (props: Cell<ContractVisitorListModel>) => <VisitorDateCell {...props} />,
  },
];
