import { clipboard } from "@toss/utils";
import React, { useEffect, useLayoutEffect, useRef } from "react";
import { useToast } from "src/recoil/toast/hook";
import { BaseTooltip } from "../BaseTooltip";

type Props = {
  title: string;
  noHashLink?: boolean;
  className?: string;
  rightArea?: React.ReactNode;
};
const BaseSectionTitle = ({ title, noHashLink, className, rightArea }: Props) => {
  const { openToast } = useToast();
  const titleRef = useRef<null | HTMLDivElement>(null);
  const hashLinkCopy = async (id: string) => {
    const href = window.location.href;
    let result = false;
    if (href.includes("#")) {
      result = await clipboard.writeText(window.location.href.substring(0, window.location.href.indexOf("#")) + "#" + id);
    } else {
      result = await clipboard.writeText(window.location.href + "#" + id);
    }
    if (result) {
      openToast({ content: "링크를 복사했습니다", isSystemToast: true });
    } else {
      openToast({ content: "링크 복사를 실패했습니다", isSystemToast: true });
    }
  };

  useEffect(() => {
    const url = window.location.href;
    const hasHash = url.indexOf("#") !== -1;
    let hashPart = "";
    if (hasHash) {
      hashPart = url.split("#")[1];
    }
    if (titleRef.current && hashPart && title === decodeURIComponent(hashPart)) {
      titleRef.current?.scrollIntoView();
      const timeoutId = setTimeout(() => {
        titleRef.current?.scrollIntoView();
      }, 200); // 1000ms = 1초

      return () => clearTimeout(timeoutId); // 클린업 함수
    }
  }, [title]);

  return (
    <div className={`contents-container__sub-title ${className}`} ref={titleRef}>
      <h2 className={!noHashLink ? "cursor-pointer" : ""}>
        {title}
        {!noHashLink && (
          <BaseTooltip
            tooltip="타이틀 링크 복사"
            className="ml10"
            touchIcon={(<div onClick={() => hashLinkCopy(String(title))} className="ic-link cursor-pointer"></div>) as any as string}
          />
        )}
      </h2>
      {rightArea && rightArea}
    </div>
  );
};

export default BaseSectionTitle;
