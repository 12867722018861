import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { qsParse } from "src/utils";
import useNavigate from "./usePartnerNavigate";
import qs from "qs";

/**
 * useQueryParams hook
 * queryParams 리턴에 useParams().id 를 같이 리턴합니다.
 */
export function useQueryParams<T extends { [key: string]: any }>(defaultQueryParams?: { [key: string]: any }) {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [queryParams, setQueryParams] = useState<T>({ ...defaultQueryParams, ...qsParse(location.search) } as T);

  /** queryParams을 인자로 보내면 네이게이트 실행 */
  const queryParamsNavigate = (queryParams: T) => {
    // list 파라미터를 별도 처리
    const { list, ...restParams } = queryParams;

    // 나머지 파라미터들은 정상적으로 stringify
    const queryString = qs.stringify(restParams, { allowDots: true });

    // list가 있으면 인코딩해서 추가
    const listParam = list ? `&list=${encodeURIComponent(list)}` : "";

    // 최종 URL 생성 (list 제외한 나머지는 디코딩)
    navigate(`${location.pathname}?${decodeURIComponent(queryString)}${listParam}`);
  };

  useEffect(() => {
    setQueryParams((prev) => ({ ...prev, ...(params.id && { id: params.id }), ...qsParse(location.search) }));
  }, [location, params.id]);

  // setQueryParams 리턴해서 사용x

  return { queryParams, location, queryParamsNavigate };
}
