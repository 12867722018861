import { AxiosInstance, AxiosResponse } from "axios";

import { ApiPath } from "..";
import { ApiResponse } from "../public-types";
import { Ac2AccessGroupModel, Ac2AccessGroupRequest, AcGroupContractManageModel, AcGroupContractManageRequest } from "./ac-group-types";

//RAD22. 출입그룹 상세 조회
export async function getAcGroupAsync(
  axios: AxiosInstance,
  params?: {
    id: number;
  },
): Promise<AxiosResponse<ApiResponse<{ accessGroup: Ac2AccessGroupModel }>>> {
  return axios.get(ApiPath.accessGroup.detail.replace("{id}", String(params?.id)));
}

//RAD23. 출입그룹 생성
export async function postAcGroupAsync(
  axios: AxiosInstance,
  params?: {
    accessGroup: Ac2AccessGroupRequest;
  },
): Promise<AxiosResponse<ApiResponse<{ accessGroup: { id: number } }>>> {
  return axios.post(ApiPath.accessGroup.add, params?.accessGroup);
}

//RAD24. 출입그룹 수정
export async function editAcGroupAsync(
  axios: AxiosInstance,
  params?: {
    id: number;
    accessGroup: Ac2AccessGroupRequest;
  },
): Promise<AxiosResponse<ApiResponse<{ accessGroup: { id: number } }>>> {
  return axios.patch(ApiPath.accessGroup.edit.replace("{id}", String(params?.id)), params?.accessGroup);
}

// RAD25. 출입그룹 삭제
export async function deleteAcGroupDeviceAsync(
  axios: AxiosInstance,
  params?: {
    id: number;
  },
): Promise<AxiosResponse<ApiResponse<{ accessGroup: { id: number } }>>> {
  return axios.delete(ApiPath.accessGroup.delete.replace("{id}", String(params?.id)));
}

// RAD82. 계약 관리 출입 설정 상세 조회

export async function getContractAcGroupManageAsync(
  axios: AxiosInstance,
  params?: {
    contractManageId: number;
  },
): Promise<AxiosResponse<ApiResponse<{ contractManage: AcGroupContractManageModel }>>> {
  return axios.get(ApiPath.accessGroup.contract.replace("{contractManageId}", String(params?.contractManageId)));
}

// RAD84. 계약 관리 출입 설정 수정
export async function editContractAcGroupManageAsync(
  axios: AxiosInstance,
  params?: {
    contractManageId: number;
    contractManage: AcGroupContractManageRequest;
  },
): Promise<AxiosResponse<ApiResponse<{ contractManage: { contractManageId: number } }>>> {
  return axios.patch(ApiPath.accessGroup.contract.replace("{contractManageId}", String(params?.contractManageId)), params?.contractManage);
}

// RAD94. 계약방문자그룹 출입 설정 수정
export async function editContractVisitorAcGroupManageAsync(
  axios: AxiosInstance,
  params?: {
    contractVisitorGroupId: string;
    accessGroupAddIds?: number[];
    accessGroupDeleteIds?: number[];
  },
): Promise<AxiosResponse<ApiResponse<{ contractVisitorGroup: { contractVisitorGroupId: number } }>>> {
  return axios.patch(ApiPath.accessGroup.contractVisitor.replace("{contractVisitorGroupId}", String(params?.contractVisitorGroupId)), {
    accessGroupAddIds: params?.accessGroupAddIds,
    accessGroupDeleteIds: params?.accessGroupDeleteIds,
  });
}
