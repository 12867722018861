import { Fragment } from "react";
import { Ac2AccessGroupModel } from "src/api/access/ac-group-types";
import { BaseButton, BaseTooltip } from "src/components";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";
import { visitorSettings } from "../../const";
type Props = {
  accessGroup: Ac2AccessGroupModel;
  onChangeEdit: (type: "basicInfo" | "setting") => void;
  isAuthority: boolean;
};

const AcGroupSetting = ({ accessGroup, onChangeEdit, isAuthority }: Props) => {
  const {
    memberAccessYn, // 이용자 출입
    visitorAccessYn, // 방문자 출입
    visitorAccessGroupSelectableYn, // Taap 노출 여부
    visitorInvitationApprovalYn, // 방문자 초대 관리자 승인 필요
    visitorAccessType, // QR 발급 타입
  } = accessGroup;

  return (
    <article className="contents-container__wrap-article">
      <div className="flex-center-between">
        <BaseSectionTitle title="설정" />
        {isAuthority && <BaseButton title="수정" className="size-small" onClick={() => onChangeEdit("setting")} />}
      </div>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <div className="flex-center">
            <p>이용자 출입</p>
            <BaseTooltip contents="비활성화 하는 경우, 해당 출입그룹 - 출입장치에 이용자가 출입할 수 없습니다." touchIcon="QUESTION" />
          </div>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span>{memberAccessYn ? "ON" : "OFF"}</span>
          </div>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <div className="flex-center">
            <p>방문자 초대</p>
            <BaseTooltip contents="비활성화 하는 경우, 해당 출입그룹에 방문자를  초대할 수 없습니다." touchIcon="QUESTION" />
          </div>
        </div>
        <div className="contents-container__grid-contents">
          <div>
            <span>{visitorAccessYn ? "ON" : "OFF"}</span>
          </div>
        </div>
      </section>

      <section className="contents-container__grid">
        <div className="contents-container__grid-index"></div>
        <div className="contents-container__grid-contents">
          <section className="contents-container__grid gray-box mt10 mb30" style={{ height: 180 }}>
            {visitorSettings.map((group) => (
              <Fragment key={group.name}>
                <div className="contents-container__grid-index">
                  <div className="flex-center">
                    <p>{group.title}</p>
                    <BaseTooltip contents={group.tooltip} touchIcon="QUESTION" />
                  </div>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="flex-center-start flex-row">
                    {group.options.map((option) => (
                      <span key={option.label}>
                        {(group.name === "visitorAccessGroupSelectableYn" && option.value === visitorAccessGroupSelectableYn && option.label) ||
                          (group.name === "visitorInvitationApprovalYn" && option.value === visitorInvitationApprovalYn && option.label) ||
                          (group.name === "visitorAccessType" && option.value === visitorAccessType && option.label)}
                      </span>
                    ))}
                  </div>
                </div>
              </Fragment>
            ))}
          </section>
        </div>
      </section>
    </article>
  );
};

export default AcGroupSetting;
