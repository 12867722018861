import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getBuildingsAsync } from "src/api/building/building-api";
import { getContractApply } from "src/api/contract/contract-api";
import { useApiOperation } from "src/api/hooks";
import { deleteNoticeAsync, getNoticeDetailAsync, postAppPushNotificationAsync, postNoticeAppPush } from "src/api/notice/notice-api";
import { NoticeModel } from "src/api/notice/notice-types";
import { BaseButton, BaseModal } from "src/components";
import GoToListButton from "src/components/GoToListButton";
import MarkdownViewer from "src/components/MarkdownViewer";
import useNavigate from "src/hooks/usePartnerNavigate";
import { PagePath } from "src/pages/product/details";
import { useToast } from "src/recoil/toast/hook";
import { ViewYmdFormat, YmdFormat } from "src/utils";
import { appPushStatusTypeToText, BatchStatus, Modal, noticeCategoryToText, noticeTypeToText } from "../../notice-types";
import MetaTag from "src/components/layout/MetaTag";
import { ArticleSection } from "src/components/layout/ArticleSection";
import BaseNewTabLink from "src/components/BaseNewTabLink";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import { FullDatePicker } from "src/components/FullDatePicker";
import { cancelScheduledMessagesAsync, getScheduledMessagesAsync } from "src/api/messages/messages-api";
import { Messages } from "src/api/messages/messages-type";

/* 
  공지사항 상세
 */
const BasicInfoDetail = () => {
  const navigate = useNavigate();
  const { openToast } = useToast();
  const location = useLocation();
  const { id } = useParams();
  // const { openToast } = useToast();
  const { isAuthority } = usePartnerAuthority();
  const [noticeDetail, setNoticeDetail] = useState<
    (NoticeModel & { contractSpaceProductNames: { name: string; id: string }[]; buildingNames: { name: string; id: string }[] }) | null
  >(null);
  const [appPushData, setAppPushData] = useState<Messages[]>([]);
  // 취소, 확인 버튼이 있는 confirm 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({ isOpen: false });
  const [appPushModal, setAppPushModal] = useState<Modal>({ isOpen: false });
  const [sendDate, setSendDate] = useState<string>("");
  // 확인버튼만 있는 alert 모달
  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });

  const { executeAsync: getNoticeDetail } = useApiOperation(getNoticeDetailAsync);
  const { executeAsync: deleteNoticeDetail } = useApiOperation(deleteNoticeAsync);
  const { executeAsync: getContractList } = useApiOperation(getContractApply);
  const { executeAsync: getBuildingList } = useApiOperation(getBuildingsAsync);
  const { executeAsync: getScheduledMessages } = useApiOperation(getScheduledMessagesAsync);
  const { executeAsync: postAppNotification } = useApiOperation(postNoticeAppPush, { noHandleError: true });
  const { executeAsync: cancelScheduledMessages } = useApiOperation(cancelScheduledMessagesAsync);

  const getPushMessages = useCallback(async () => {
    const { data: appPushData } = await getScheduledMessages({
      contentsList: [{ serviceType: "SERVICE_NOTICE", serviceId: Number(id) }],
      page: 0,
      size: 10,
      sort: {
        orders: [
          {
            property: "id",
            direction: "DESC",
          },
        ],
      },
    });
    setAppPushData(appPushData.data.content);
    return appPushData.data.content;
  }, []);

  const cancelPushMessages = useCallback(async (id: string) => {
    const { status } = await cancelScheduledMessages({
      ids: id,
    });
    if (status >= 200 && status < 300) {
      getPushMessages();
    }
  }, []);
  useEffect(() => {
    const fetchApi = async (id: string) => {
      const { data } = await getNoticeDetail({ id });
      if (data.data) {
        let tempNoticeDetail = data.data.notice;
        let contractSpaceProductNames: { name: string; id: string }[] = [];
        let buildingNames: { name: string; id: string }[] = [];
        if (tempNoticeDetail.contracts && tempNoticeDetail.contracts.length > 0) {
          const contractList = tempNoticeDetail.contracts.map((item) => item.contractId);
          const { data: contractData } = await getContractList({ page: 0, size: 99, contractIds: contractList?.join(",") });
          contractSpaceProductNames = contractData.data.content.map((contract) => {
            return { name: contract.spaceProductName, id: String(contract.contractId) };
          });
        }
        if (tempNoticeDetail.buildings && tempNoticeDetail.buildings.length > 0) {
          const buildingList = tempNoticeDetail.buildings.map((item) => item.buildingId);
          const { data: buildingData } = await getBuildingList({ page: 0, size: 99, id: buildingList?.join(",") });
          buildingNames = buildingData.data.content.map((building) => {
            return { name: building.buildingName, id: String(building.id) };
          });
        }
        const newData = { ...tempNoticeDetail, contractSpaceProductNames, buildingNames };
        setNoticeDetail(newData);
        const pushMessages = await getPushMessages();
        const batchStartMessage = pushMessages?.find((message) => message.status === BatchStatus.BATCH_START);
        if (batchStartMessage && newData) {
          // 공개 off인데 발송예약건이 있으면 취소
          if (!newData?.isDisplayed) {
            cancelPushMessages(batchStartMessage.id);
          }
          const startDate = moment(newData?.displayStartDate);
          const endDate = moment(newData?.displayEndDate);
          // 공개 on인데 발송예약일시가 공개기간을 벗어나면 취소
          if (!moment(batchStartMessage.reservationTime).isBetween(startDate, endDate, null, "[]")) {
            cancelPushMessages(batchStartMessage.id);
          }
        }
      }
    };
    if (id) {
      fetchApi(id);
    }
  }, [id, getContractList, getBuildingList, getNoticeDetail, getPushMessages, cancelPushMessages]);

  // 공지사항 삭제
  const fetchDeleteNoticeDetail = async () => {
    if (id) {
      const { data } = await deleteNoticeDetail({ id });
      if (data.data) {
        openToast({ content: "정상적으로 삭제 되었습니다" });
        navigate(`${PagePath.notice.list}`);
      }
    }
    setConfirmModal({ isOpen: false });
  };

  const fetchPushAlarm = async () => {
    if (!id) return;

    const ERROR_MESSAGES = {
      eET103: "공지사항이 비공개입니다",
      eET104: "공지사항을 볼 수 있는 회원이 없습니다",
      eET105: "공개기간 내에서만 앱 푸시 알림 예약이 가능합니다",
    } as const;

    type ErrorCode = keyof typeof ERROR_MESSAGES;

    const response = await postAppNotification({ id, sendDate });

    if (response.status >= 200 && response.status <= 299) {
      setAlertModal({ isOpen: true, title: "Push 알림 발송이 예약 되었습니다", type: "successPush" });
      setAppPushModal({ isOpen: false });
      return;
    }

    const errorCode = response?.data?.meta?.errorCode as ErrorCode | undefined;
    const errorMessage = errorCode && ERROR_MESSAGES[errorCode];

    setAlertModal({
      isOpen: true,
      title: errorMessage || "잘못된 요청입니다",
    });
  };

  const checkPushAlarmEnabled = () => {
    if (noticeDetail?.displayStartDate && noticeDetail?.displayEndDate) {
      const today = moment();
      const startDate = moment(noticeDetail.displayStartDate);
      const endDate = moment(noticeDetail.displayEndDate);

      // 발송예정 예약건이 있는지 확인
      const isExistReservationMessage = appPushData?.filter((message) => message.status === BatchStatus.BATCH_START)?.length > 0;

      if (noticeDetail?.isDisplayed && !isExistReservationMessage) {
        return false;
      } else return true;
    }
  };
  return (
    <>
      <MetaTag subTitle={noticeDetail?.title || ""} />
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <ArticleSection title={"기본 정보"}>
            {/* 기본정보 */}
            <div className="flex-center">
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="">공지유형</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div>
                    <p className="font14">{noticeDetail?.type ? noticeTypeToText(noticeDetail?.type) : "-"}</p>
                  </div>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="">카테고리</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div>
                    <p className="font14">{noticeCategoryToText(noticeDetail?.noticeCategory || "-")}</p>
                  </div>
                </div>
              </section>
            </div>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">대상 설정</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>
                  <div className="flex-files">
                    {noticeDetail?.buildingNames.map((building, index) => {
                      const detailPath = PagePath.building.detail.replace(":id", building.id);
                      return (
                        <div className="flex-files__wrap" key={detailPath + index}>
                          <BaseNewTabLink path={detailPath} value={building.name} className="w-100 text-left" />
                          {index < noticeDetail?.buildingNames.length - 1 && <span>,</span>}
                        </div>
                      );
                    })}
                    {noticeDetail?.contractSpaceProductNames.map((contractSpaceProduct, index) => {
                      const detailPath = PagePath.contract.detail.replace(":id", contractSpaceProduct.id);
                      return (
                        <div className="flex-files__wrap" key={detailPath + index}>
                          <BaseNewTabLink path={detailPath} value={contractSpaceProduct.name} className="w-100 text-left" />
                          {index < noticeDetail?.contractSpaceProductNames.length - 1 && <span>,</span>}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </section>

            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">제목</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>
                  <p className="font14">{noticeDetail?.title || "-"}</p>
                </div>
              </div>
            </section>
            <section className="contents-container__grid contents-container__1200">
              <div className="contents-container__grid-index">
                <p className="pt4">내용</p>
              </div>
              <div className="contents-container__grid-contents">
                {noticeDetail?.content ? (
                  <MarkdownViewer value={noticeDetail.content} />
                ) : (
                  <div className="flex-row flex-center-start">
                    <p>-</p>
                  </div>
                )}
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">공개여부</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>
                  <div className="font14 pre-formatted">
                    {noticeDetail?.isDisplayed !== undefined ? (
                      noticeDetail?.isDisplayed ? (
                        <div className="status B">공개</div>
                      ) : (
                        <div className="status D">비공개</div>
                      )
                    ) : (
                      <span>-</span>
                    )}
                  </div>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">공개기간</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>
                  <p className="font14 pre-formatted">
                    {noticeDetail?.displayStartDate && moment(noticeDetail?.displayStartDate).format(ViewYmdFormat.FULL)}
                    <span className={noticeDetail?.displayStartDate && noticeDetail?.displayEndDate ? "px4" : ""}>-</span>
                    {noticeDetail?.displayEndDate && moment(noticeDetail?.displayEndDate).format(ViewYmdFormat.FULL)}
                  </p>
                </div>
              </div>
            </section>
            {isAuthority("w") && (
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="">앱 푸시알림</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div>
                    <BaseButton
                      disabled={checkPushAlarmEnabled()}
                      title={"예약 추가"}
                      className="color-white"
                      onClick={() => setAppPushModal({ isOpen: true })}
                    />
                    <section className="message-history">
                      {appPushData.map((message) => (
                        <div key={message.id}>
                          <div className="flex-center-start">
                            <p>발송 예약 일시 : {moment(message.reservationTime).format(ViewYmdFormat.YYYY_MM_DD_HH_MM)}</p>
                            <button
                              className="color-white base-btn ml10"
                              onClick={() => {
                                cancelPushMessages(message.id);
                              }}
                              disabled={message.status !== BatchStatus.BATCH_START}
                            >
                              취소
                            </button>
                          </div>
                          <ul className="base-list">
                            <li>
                              {appPushStatusTypeToText(message.status)}{" "}
                              {message.status !== BatchStatus.BATCH_START ? ` : ${moment(message.modifiedDate).format(ViewYmdFormat.FULL)}` : ""}
                            </li>
                          </ul>
                        </div>
                      ))}
                    </section>
                    {appPushModal.isOpen && (
                      <BaseAbstractModal isOpen={true} size={"medium"}>
                        <section className="base-abstract-modal__title border-bottom flex-column flex-start">
                          <h1>앱 푸시알림 예약</h1>
                          <ul className="base-list mt10">
                            <li>알림은 설정된 시간에 발송됩니다.</li>
                            <li>다만, 시스템 상태에 따라 최대 10분의 오차가 생길 수 있습니다.</li>
                          </ul>
                        </section>
                        <section className="base-abstract-modal__contents px30">
                          <section className="base-abstract-modal__contents-subtitle">
                            <h2 className="required">
                              <p>발송 일시</p>
                            </h2>
                          </section>
                          <FullDatePicker
                            selectedDate={sendDate ? moment(sendDate).toDate() : null}
                            minDate={moment().toDate()}
                            minTime={
                              moment(sendDate || new Date()).isSame(moment(), "day")
                                ? moment()
                                    .startOf("hour")
                                    .minutes(Math.floor(moment().minutes() / 5) * 5)
                                    .toDate()
                                : moment().startOf("day").toDate()
                            }
                            maxTime={moment().endOf("day").toDate()}
                            setDate={(selectedDate: Date) => {
                              const date = moment(selectedDate).format(YmdFormat.WITH_TIME_ZONE);
                              setSendDate(date);
                            }}
                            className="mr10 minmax180"
                            timeIntervals={5}
                          />
                        </section>
                        <section className="base-abstract-modal__btn-wrap">
                          <BaseButton title={"취소"} className="color-white" onClick={() => setAppPushModal({ isOpen: false })} />
                          <BaseButton
                            title={"확인"}
                            onClick={() => {
                              fetchPushAlarm();
                            }}
                          />
                        </section>
                      </BaseAbstractModal>
                    )}
                  </div>
                </div>
              </section>
            )}
          </ArticleSection>
          <ArticleSection title={"등록 정보"}>
            <div className="flex-center">
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="">생성일</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div>
                    <p className="font14 pre-formatted">
                      {noticeDetail?.createdDate ? moment(noticeDetail?.createdDate).format(ViewYmdFormat.FULL) : "-"}
                    </p>
                  </div>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="">최초 등록자</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div>
                    <p className="font14 pre-formatted">{noticeDetail?.createdBy ? noticeDetail?.createdBy : "-"}</p>
                  </div>
                </div>
              </section>
            </div>
            <div className="flex-center">
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="">최종 수정일</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div>
                    <p className="font14 pre-formatted">
                      {noticeDetail?.lastModifiedDate ? moment(noticeDetail?.lastModifiedDate).format(ViewYmdFormat.FULL) : "-"}
                    </p>
                  </div>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="">최종 작성자</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div>
                    <p className="font14 pre-formatted">{noticeDetail?.lastModifiedBy || "-"}</p>
                  </div>
                </div>
              </section>
            </div>
          </ArticleSection>

          {/* 취소, 확인 버튼이 있는 confirm 모달 */}
          <BaseModal
            isOpen={confirmModal.isOpen}
            btnLeftTitle={confirmModal.type === "ALARM" ? "아니오" : "취소"}
            btnRightTitle={confirmModal.type === "ALARM" ? "네" : "확인"}
            onClose={() => setConfirmModal({ isOpen: false })}
            onClick={() => {
              confirmModal.type === "DELETE" && fetchDeleteNoticeDetail();
              confirmModal.type === "ALARM" && fetchPushAlarm();
            }}
            title={confirmModal.message}
          >
            {confirmModal.type === "ALARM" && <p>Taap을 통해 신규 공지사항 알림이 발송됩니다.</p>}
          </BaseModal>

          {/* 확인버튼만 있는 alert 모달 */}
          <BaseModal
            title={alertModal.title}
            isOpen={alertModal.isOpen}
            btnRightTitle="확인"
            onClick={() => {
              if (alertModal.type === "successPush") {
                setAlertModal({ isOpen: false });
                getPushMessages();
              } else {
                setAlertModal({ isOpen: false });
              }
            }}
          >
            {alertModal.message && alertModal.message}
          </BaseModal>
        </div>
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area d-flex">
          <GoToListButton />
        </div>
        <div className="left-area d-flex">
          {isAuthority("d") && (
            <BaseButton
              title="삭제"
              className="color-white size-large mr10"
              onClick={() => {
                setConfirmModal({ isOpen: true, message: "삭제 하시겠습니까?", type: "DELETE" });
              }}
            />
          )}
          {isAuthority("w") && (
            <BaseButton
              title="수정"
              className="size-large"
              onClick={() => {
                const formPath = `${PagePath.notice.form}?id=${id}`;
                navigate(formPath);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default BasicInfoDetail;

//여기
