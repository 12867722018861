import { ReservationExternalTypes, UserType } from "src/pages/reservation/reservation-types";
import { CommonFacilityType } from "../building/building-types";
import { Sort } from "../public-types";

// 회의실 예약 참석자
export interface ReservationInvitee {
  id: string;
  reservationId: string;
  memberNo?: string;
  memberEmail: string;
  status: string;
  isOrganizer?: boolean;
  createdDate?: string;
  createdBy?: string;
  modifiedDate?: string;
  modifiedBy?: string;
}

export interface ReservationListParams {
  page?: number;
  size?: number;
  start?: string;
  end?: string;
  searchType?: string;
  search001?: string;
  statusCode?: string;
  inboundChannel?: ReservationExternalTypes;
  facilityType?: CommonFacilityType;
  sort?: Sort;
  parentId?: string;
  partnerId?: string;
  partnerName?: string;
  id?: string;
  containsFacilityName?: string;
  containsBuildingName?: string;
  containsOrganizerMemberNo?: string;
  containsContractApplyNumber?: string;
  greaterThanEqualsEnd?: string;
  contractId?: string;
  facilityId?: string;
  [key: string]: string | number | ReservationExternalTypes | CommonFacilityType | Sort | undefined | unknown; // 문자열 인덱스 시그니처 추가
}

export interface ReservationUsageParams {
  reservation?: ReservationListParams;
  sort?: Sort;
  page?: number;
  size?: number;
}

export type RecurrenceType = "RECURRENCE_UNRECOGNIZED" | "SINGLE" | "DAILY" | "WEEKLY";

/**
 * . 공용공간 예약목록 api response 타입
 */

export interface ReservationContract {
  id: number; // 계약아이디
  manageId: number; // 계약관리아이디
  applyNumber: string; // 계약신청번호
}
export interface ReservationOrganizer {
  memberNo: string; // 회원번호
  userEmail: string; // 회원이메일
  displayName: string; // 출력용 이름
  phoneNumber: string; // 회원 휴대폰번호
}
export interface ReservationFacility {
  id: number; // 공용공간 id
  type: "MEETING_ROOM" | "DESK" | "REFRESH_ROOM"; // 공용공간 타입
  locationCode: string; // 로케이션코드
  name: string; //공용공간 이름
  floor: number; //위치(층) 음수 지하 양수 지상
  building: { id: number; name: string };
  buildingCommonFacilityDeskGroupName?: string; // 좌석그룹명
}

export interface ReservationAttendee {
  memberNo: string; // 참석자 회원번호
  userEmail: string; // 참석자 이메일
  displayName: string; //참석자 출력용 이름
  responseStatus: string; // 참석자 응답 상태
}
export interface ReservationStatus {
  code: string;
  time: string;
}

export type RvReservationUser = {
  name: string; //사용자 이름
  type: UserType; // 사용자 타입
  remoteAddress: string; // 클라이언트 주소
};

export interface ReservationListModel {
  facility: ReservationFacility; // 공용공간 정보
  organizer: ReservationOrganizer; // 주최자 정보
  contract: ReservationContract & {
    contractName: string;
    mbOrganizationName: string;
  };
  attendees: ReservationAttendee[]; // 참석자 정보
  id: string;
  parentId?: string; // 그룹id
  inboundChannel: string; // 예약경로
  start: string; // 예약시작일시
  end: string; // 예약종료일시
  description: string; // 예약 설명
  status: ReservationStatus; // 예약상태
  pending: boolean; //예약 처리 중 여부
  summary: string; // 예약명
  createdBy: RvReservationUser; // 생성자
  createdDate: string;
  lastModifiedBy: RvReservationUser;
  lastModifiedDate: string;
  incompleteReasons?: string[]; //불완전 예약 사유 목록
  cancelledRemarks?: string[]; //예약 취소 추가 정보
  calendarEvent?: {
    //연동 캘린더 이벤트 정보
    id: string;
    service: "GOOGLE" | "OUTLOOK";
  };
  partnerId: string | number; //파트너 아이디
}

// 공용공간 예약 목록
export interface ReservationListResponse {
  content: Array<ReservationListModel>;
}
// 공용공간 예약 소진시간
export interface ReservationUsageResponse {
  reservationId: string | number; // 예약아이디
  duration: string | number; //해당 예약의 총 소진시간(분)
  usages: any; //해당 예약의 소진 시간 기록 목록
}

// 공용공간 업데이트 response data
export interface ReservationUpdateResponseData {
  reservation: ReservationListModel;
}

// 변경내역 response data
export interface ReservationHistoryResponse {
  id?: string | number; //아이디
  reservationId?: string | number; //예약아이디
  createdDate?: string; //일시
  eventType?: string; //구분
  statusCode?: string; //예약 상태코드
  description?: string; //설명
  operator?: string; //주체
  targetResource?: string; //대상 리소스
  start?: string; //예약 시작 시간(소진시작시간)
  end?: string; // 예약 종료 시간(소진종료시간)
}

type RvAttendeeRequest = {
  member?: {
    memberNo: string; // 회원번호
    memberEmail?: string; // 멤버 이메일
  };
  //아웃룩 얘약 등 수정 상태에서 필요
  external?: {
    userEmail: string; //사용자 이메일 주소
    displayName: string; //사용자 출력용 이름
  };
};

//예약 생성 Form data
export interface ReservationRequest {
  reservationId?: string; // 예약 id
  summary: string; // 예약명, 최대 200자
  description?: string; // 예약 내용
  start: string; // 예약 시작 시간
  end: string; // 예약 종료 시간
  contractId: string | number; // 계약 아이디
  facilityId: string | number; // 공용 공간 아이디
  organizerMemberNo: string; // 예약 주최자 회원 번호
  attendees?: RvAttendeeRequest[]; // 참석자 목록
}

// 예약 정보 관리 Request
export const ReservationProtectionStrategy = {
  NONE: "NONE", // 전체공개
  PARTIAL_MASKING: "PARTIAL_MASKING", // 일부 공개
  PRIVATE: "PRIVATE", // 비공개
} as const;

export const OrganizerProtectionSetting = {
  ALLOWED: "ALLOWED", // 허용
  DENIED: "DENIED", // 거절
} as const;

export type UnitonReservationProtectionStrategy = (typeof ReservationProtectionStrategy)[keyof typeof ReservationProtectionStrategy];
export type UnitonOrganizerProtectionSetting = (typeof OrganizerProtectionSetting)[keyof typeof OrganizerProtectionSetting];

export interface ReservationConfigRequest {
  summaryProtectionStrategy: UnitonReservationProtectionStrategy; // 주최자 닉네임 공개 설정 (RvReservationProtectionStrategy 타입 참고, 기본값: NONE)
  organizerDisplayNameProtectionStrategy: UnitonReservationProtectionStrategy; // 예약명 공개 설정 (RvReservationProtectionStrategy 타입 참고, 기본값: NONE)
  organizationNameProtectionStrategy: UnitonReservationProtectionStrategy; // 소속 회사 공개 설정 (RvReservationProtectionStrategy 타입 참고, 기본값: NONE)
  organizerProtectionSetting: UnitonOrganizerProtectionSetting; // 예약 정보 Taap 사용자 공개 설정 (RvReservationOrganizerProtectionSetting 타입 참고, 기본값: DENIED)
}

export type ReservationConfigModel = {
  partnerId: number; // 파트너 아이디
  summaryProtectionStrategy: UnitonReservationProtectionStrategy; // 주최자 닉네임 공개 설정
  organizerDisplayNameProtectionStrategy: UnitonReservationProtectionStrategy; // 예약명 공개 설정
  organizationNameProtectionStrategy: UnitonReservationProtectionStrategy; // 소속 회사 공개 설정
  organizerProtectionSetting: UnitonOrganizerProtectionSetting; // 예약 정보 Taap 사용자 공개 설정
};
