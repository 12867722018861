import moment from "moment";
import { Cell } from "react-table";
import { Ac2AccessDeviceModel } from "src/api/access/productac-types";
import { BaseTooltip } from "src/components";
import DetailLink from "src/components/DetailLink";
import { PagePath } from "src/pages/product/details";
import { YmdFormat } from "src/utils";

export const AccessDeviceColumns = [
  {
    Header: "ID",
    accessor: "id",
    width: 80,
    sticky: "left",
    Cell: ({ value }: Cell<Ac2AccessDeviceModel>) => {
      const detailPath = `${PagePath.accessDevice.detail.replace(":id", value)}`;
      return (
        <DetailLink to={detailPath} className="text-underline">
          {value}
        </DetailLink>
      );
    },
  },
  {
    Header: "장치ID",
    accessor: "externalDeviceId",
    width: 120,
    sticky: "left",
    Cell: ({ value }: Cell<Ac2AccessDeviceModel>) => {
      return <BaseTooltip contents={value || "-"} isSingleLine className="text-start w-100" />;
    },
  },
  {
    Header: "장치명",
    accessor: "deviceName",
    width: 760,
    Cell: ({ value }: Cell<Ac2AccessDeviceModel>) => {
      return <BaseTooltip contents={value || "-"} isSingleLine className="text-start w-100" />;
    },
  },

  {
    Header: "최종 수정일",
    accessor: "lastModifiedDate",
    width: 120,
    Cell: ({ value }: Cell<Ac2AccessDeviceModel>) => {
      return <BaseTooltip contents={moment(value).format(YmdFormat.YYYY_MM_DD_HH_MM)} type="date" />;
    },
  },
  {
    Header: "최종 수정자",
    accessor: "lastModifiedBy",
    width: 120,
    Cell: ({ value }: Cell<Ac2AccessDeviceModel>) => <BaseTooltip contents={value || "-"} isSingleLine className="text-start w-100" />,
  },
];
