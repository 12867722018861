import React from "react";
import { Control, Controller } from "react-hook-form";
import { ReservationConfigRequest, ReservationProtectionStrategy } from "src/api/reservation/reservation-types";
import { BaseRadio, BaseTooltip } from "src/components";
import { ArticleSection } from "src/components/layout/ArticleSection";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";

const permissionRadio = [
  { label: "전체", value: ReservationProtectionStrategy.NONE },
  { label: "일부", value: ReservationProtectionStrategy.PARTIAL_MASKING },
  { label: "비공개", value: ReservationProtectionStrategy.PRIVATE },
];

type Props = {
  control: Control<ReservationConfigRequest>;
  isEditable: boolean;
  isAvailableMasking: boolean;
  fetchAuthorityReadonly: (checkReadonly?: boolean, global?: boolean) => void;
};

const ReservationPublicSetting = ({ control, isEditable, fetchAuthorityReadonly, isAvailableMasking }: Props) => {
  return (
    <article className="contents-container__wrap-article">
      <BaseSectionTitle title="예약정보 공개 설정" className="pb10" />
      <p className="font14 text-primary3 mb20">회의실, 좌석, 편의시설에 설치된 전자 디스플레이에 표시되는 정보의 공개 범위를 설정합니다.</p>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>주최자 닉네임</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="flex-center-start flex-row">
            <Controller
              control={control}
              name={"summaryProtectionStrategy"}
              render={({ field: { onChange, value } }) => (
                <>
                  {permissionRadio.map((item) => (
                    <div key={item.value} className="flex-row flex-center-start">
                      <BaseRadio
                        id={`nickname-${item.value}`}
                        name={"nickname"}
                        label={item.label}
                        className={item.label !== "일부" ? "mr10" : ""}
                        checked={value === item.value}
                        onChange={() => {
                          if (isEditable) {
                            onChange(item.value);
                          } else {
                            fetchAuthorityReadonly(true);
                          }
                        }}
                        disabled={!isAvailableMasking}
                      />
                      {item.label === "일부" && (
                        <BaseTooltip className="mr10" contents="닉네임의 일부분이 가려집니다. 예) 김수미 → 김*미" touchIcon="QUESTION" />
                      )}
                    </div>
                  ))}
                </>
              )}
            />
          </div>
        </div>
      </section>

      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>예약명</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="flex-center-start flex-row">
            <Controller
              control={control}
              name={"organizerDisplayNameProtectionStrategy"}
              render={({ field: { onChange, value } }) => (
                <>
                  {permissionRadio.map((item) => (
                    <div key={item.value} className="flex-row flex-center-start">
                      <BaseRadio
                        id={`reservationName-${item.value}`}
                        name={"reservationName"}
                        label={item.label}
                        className={item.label !== "일부" ? "mr10" : ""}
                        checked={value === item.value}
                        onChange={() => {
                          if (isEditable) {
                            onChange(item.value);
                          } else {
                            fetchAuthorityReadonly(true);
                          }
                        }}
                        disabled={!isAvailableMasking}
                      />
                      {item.label === "일부" && (
                        <BaseTooltip
                          className="mr10"
                          contents="첫 세 글자를 제외하고 모두 가려집니다. 예) 고객사 미팅 → 고객사****"
                          touchIcon="QUESTION"
                        />
                      )}
                    </div>
                  ))}
                </>
              )}
            />
          </div>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>소속회사</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="flex-center-start flex-row">
            <Controller
              control={control}
              name={"organizationNameProtectionStrategy"}
              render={({ field: { onChange, value } }) => (
                <>
                  {permissionRadio.map((item) => (
                    <div key={item.value} className="flex-row flex-center-start">
                      <BaseRadio
                        id={`company-${item.value}`}
                        name={"company"}
                        label={item.label}
                        className={item.label !== "일부" ? "mr10" : ""}
                        checked={value === item.value}
                        onChange={() => {
                          if (isEditable) {
                            onChange(item.value);
                          } else {
                            fetchAuthorityReadonly(true);
                          }
                        }}
                        disabled={!isAvailableMasking}
                      />
                      {item.label === "일부" && (
                        <BaseTooltip
                          className="mr10"
                          contents="회사명의 첫 글자를 제외한 모든 글자가 가려집니다. 예) 핀포인트 → 핀***"
                          touchIcon="QUESTION"
                        />
                      )}
                    </div>
                  ))}
                </>
              )}
            />
          </div>
        </div>
      </section>
    </article>
  );
};

export default ReservationPublicSetting;
