import { AxiosInstance, AxiosResponse } from "axios";
import qs from "qs";
import ApiPath from "src/api/apiPath.json";
import { ApiResponse } from "../public-types";
import {
  Ac2AccessCode,
  ContractVisitor,
  ContractVisitorGroup,
  DeleteVisitorGroupParams,
  DeleteVisitorGroupResponse,
  ExternalQrResponse,
  PatchContractVisitorsParams,
  PatchVisitorResponseData,
  PostVisitorParams,
  PreDiscountResponse,
  ContractVisitorListModel,
  VisitorListQueryParams,
} from "./visitor-types";

/**
 * RCV50. 방문자 목록 페이지 조회
 * 1.24 이후 변경됨
 * https://pnpt.atlassian.net/wiki/x/ioC4H
 * @param axios
 * @param params
 * @returns
 */
export async function getVisitorsAsync(
  axios: AxiosInstance,
  params?: VisitorListQueryParams,
): Promise<AxiosResponse<ApiResponse<{ contractVisitor?: ContractVisitorListModel[] }>>> {
  return await axios.get<ApiResponse<{ contractVisitor?: ContractVisitorListModel[] }>>(ApiPath.visitor.list, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
}

// RCV51a. 계약 방문자 그룹 조회(v1.24 이후)
// https://pnpt.atlassian.net/wiki/x/AYCrOQ
export async function getContractVisitorGroupAsync(
  axios: AxiosInstance,
  params?: {
    visitApplyNumber: string;
  },
): Promise<AxiosResponse<ApiResponse<{ contractVisitorGroup: ContractVisitorGroup }>>> {
  const visitApplyNumber = params?.visitApplyNumber;
  const path = ApiPath.visitor.detail.replace("{visitApplyNumber}", visitApplyNumber!);
  return axios.get<ApiResponse<{ contractVisitorGroup: ContractVisitorGroup }>>(path);
}

/**
 * RCA52. 방문자 그룹 등록
 * @param axios
 * @param params
 * @returns
 */
export async function postVisitorAsync(
  axios: AxiosInstance,
  params?: PostVisitorParams,
): Promise<AxiosResponse<ApiResponse<{ contractVisitorGroup: ContractVisitorGroup }>>> {
  const contractManageId = params?.contractManageId;

  const path = ApiPath.visitor.add.replace("{contractManageId}", String(contractManageId));
  return await axios.post(path, {
    contractVisitorGroup: params?.contractVisitorGroup,
  });
}

/**
 * RCA53b. 방문자 그룹 수정
 * @param axios
 * @param params
 * @returns
 */
export async function patchVisitorGroupAsync(
  axios: AxiosInstance,
  params?: PatchContractVisitorsParams,
): Promise<AxiosResponse<ApiResponse<PatchVisitorResponseData>>> {
  const visitApplyNumber = params?.visitApplyNumber;
  const path = ApiPath.visitor.edit.replace("{visitApplyNumber}", visitApplyNumber!);

  return await axios.patch(path, {
    contractVisitorGroup: params?.contractVisitorGroup,
    contractVisitorList: params?.contractVisitorList,
  });
}

//RCV51b. 계약 방문자 조회(v1.24 이후)
// https://pnpt.atlassian.net/wiki/x/FICkOQ
export async function getContractVisitorsAsync(
  axios: AxiosInstance,
  params?: {
    contractVisitorGroupId: string;
  },
): Promise<AxiosResponse<ApiResponse<{ contractVisitorList: ContractVisitor[] }>>> {
  const contractVisitorGroupId = params?.contractVisitorGroupId;
  const path = ApiPath.visitor.detail_RCV51b.replace("{contractVisitorGroupId}", contractVisitorGroupId!);
  return axios.get<ApiResponse<{ contractVisitorList: ContractVisitor[] }>>(path);
}

// RCV53b. 계약 방문자 수정(v1.24 이후)
// https://pnpt.atlassian.net/wiki/x/wgDLOQ
export async function patchContractVisitorAsync(
  axios: AxiosInstance,
  params?: {
    contractVisitorList: ContractVisitor[];
    contractVisitorGroupId: string;
  },
): Promise<AxiosResponse<ApiResponse<any>>> {
  const contractVisitorGroupId = params?.contractVisitorGroupId;
  const path = ApiPath.visitor.edit_RCV53a.replace("{contractVisitorGroupId}", contractVisitorGroupId!);

  return await axios.patch(path, {
    contractVisitorList: params?.contractVisitorList,
  });
}
export async function deleteVisitorGroupAsync(
  axios: AxiosInstance,
  params?: DeleteVisitorGroupParams,
): Promise<AxiosResponse<ApiResponse<DeleteVisitorGroupResponse>>> {
  const visitApplyNumber = params?.visitApplyNumber;
  const path = ApiPath.visitor.delete.replace("{visitApplyNumber}", visitApplyNumber!);

  return await axios.patch(path);
}

/**
 * RAD52. 방문자에 대한 출입로그 조회
 * @param axios
 * @param params
 * @returns
 */
export async function getAccessLog(axios: AxiosInstance, params?: any): Promise<AxiosResponse<ApiResponse<any>>> {
  const visitorId = params?.visitorId;
  const path = ApiPath.visitor.ac.getAccesslog.replace("{contractVisitorId}", visitorId!);

  return await axios.get(path, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
}

// TAC11. 방문자 QR 조회 // 코트
export async function getExternalVisitorQrAsync(
  axios: AxiosInstance,
  params?: {
    visitorKey: string; // visitApplyNumber + contractVisitorId
  },
): Promise<AxiosResponse<ApiResponse<{ qr: ExternalQrResponse }>>> {
  if (!params?.visitorKey) {
    throw Error("visitorKey is not found");
  }
  const path = ApiPath.visitor.externalQr.replace("{visitorKey}", params.visitorKey);
  return axios.get(path);
}

//RCK20. 주차장 사전할인 리스트 조회 (KPS)
export async function getVisitorParkingPreDiscountListAsync(
  axios: AxiosInstance,
  params?: {
    contractId: string;
    preDiscountIds?: string; // "202201010131212, 202201010131132, 202201010131082"
  },
): Promise<AxiosResponse<ApiResponse<PreDiscountResponse>>> {
  if (!params?.contractId) {
    throw Error("contractId is not found");
  }
  const path = ApiPath.visitor.parking.list.replace("{contractId}", params.contractId);
  return axios.get(path, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
}
/**
 * RCV53. 방문자 삭제 (초대자)
 * @param axios
 * @param params
 * @returns
 */
export async function deleteVisitorByInviter(
  axios: AxiosInstance,
  params?: {
    inviteeMemberNo: string;
    contractVisitorIds: string; //문서에는 []형태지만 전송 데이터는 string 에 , 구분으로 전송
  },
): Promise<AxiosResponse<ApiResponse<any>>> {
  const path = ApiPath.visitor.deleteByInviter.replace("{inviteeMemberNo}", params!.inviteeMemberNo!);

  return await axios.patch(path, params!);
}

/**
 * RAD47. 고정 CSN 페이지 조회
 * https://pnpt.atlassian.net/wiki/x/QwBoOQ
 * @param axios
 * @param params
 * @returns
 */
export async function getFixedCsnAsync(
  axios: AxiosInstance,
  params?: { contractVisitorIds: string[] },
): Promise<
  AxiosResponse<
    ApiResponse<{
      content?: Ac2AccessCode[];
    }>
  >
> {
  return await axios.get(ApiPath.visitor.csn.get, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
}

/**
 * RAD48. 방문자 고정 CSN 저장
 * https://pnpt.atlassian.net/wiki/x/E4BoOQ
 * @param axios
 * @param params
 * @returns
 */
export async function postFixedCsnAsync(
  axios: AxiosInstance,
  params?: { contractVisitorId: string; accessToken: string },
): Promise<
  AxiosResponse<
    ApiResponse<{
      accessCode?: {
        id?: number;
      };
    }>
    // 고정 CSN 저장 시 반환되는 에러 409
    // 다른 방문자가 사용중 errorCode: eAC106
    // "errorData": {
    //   "contractApplyNumber": "1464581055",
    //   "contractVisitorId": 4031,
    //   "visitApplyNumber": "2011429183"
    // },
    // 다른 이용자가 사용중 errorCode: eAC107
    // "errorData": {
    //   "memberNo": "1008755197"
    // },
    // 다른 파트너가 사용중 errorCode: eAC105
    // "errorData": null,
  >
> {
  const path = ApiPath.visitor.csn.put.replace("{contractVisitorId}", params!.contractVisitorId!);
  return await axios.put(path, {
    accessToken: params!.accessToken,
  });
}

/**
 * RAD49. 방문자 고정 CSN 삭제
 * https://pnpt.atlassian.net/wiki/x/E4BoOQ
 * @param axios
 * @param params
 * @returns
 */
export async function deleteFixedCsnAsync(
  axios: AxiosInstance,
  params?: { contractVisitorId: string },
): Promise<AxiosResponse<ApiResponse<{ accessCode?: { id?: number } }>>> {
  const path = ApiPath.visitor.csn.delete.replace("{contractVisitorId}", params!.contractVisitorId!);
  return await axios.delete(path);
}
