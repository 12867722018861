import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { BaseModal, ContentsTitle } from "src/components";
import useNavigate from "src/hooks/usePartnerNavigate";
import { useValidRoutes } from "src/hooks/useValidRoutes";
import PagePath from "src/pagePath.json";
import { Modal } from "../building-types";
import CommonFacility from "../details/facilityDetail/CommonFacility";
import MgmtOfficeDetail from "../details/mgmtOfficeDetail/MgmtOfficeDetail";
import VocService from "../details/vocService/VocService";
import BasicInfoForm from "./basicInfoForm/BasicInfoForm";
import ImageForm from "./imageForm/ImageForm";
import BuildingIotDevice from "./iotDevice/BuildingIotDevice";
import PropertyForm from "./propertyForm/PropertyForm";
import SeatingChartForm from "./seatingChartForm/SeatingChartForm";
import HistoryDetail from "src/components/histroy/HistoryDetail";

const tabs = [
  { value: "basicInfo", label: "기본정보" },
  { value: "property", label: "프로퍼티" },
  { value: "facility", label: "공용공간" },
  { value: "seatingChart", label: "좌석 배치도" },
  { value: "image", label: "이미지" }, // TODO: MVP 이후
  { value: "mgmtOffice", label: "관리처" },
  { value: "csItem", label: "VoC 항목" },
  { value: "iotDevice", label: "IoT 기기" },
  ...(process.env.REACT_APP_ENVIRONMENT === "dev" ? [{ value: "history", label: "변경내역" }] : []),
];

/* 
  건물 > 등록 or 수정
 */
const BuildingForm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  const buildingId = queryParams?.id;

  const { isCsActive, goToPageNotFound } = useValidRoutes();

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (queryParams?.tab) {
      return tabs.find((tab) => tab.value === queryParams?.tab);
    }
    return tabs[0];
  }, [queryParams]);

  // 탭 클릭시 callback 함수
  const clickTab = useCallback(
    (tab: { value: string; label: string }) => {
      // 기본정보 가 작성되지 않은 상태에서, 다른 탭으로 이동하려고 할 경우.
      if (tab.value !== "basicInfo" && !queryParams.id) {
        setModal({
          isOpen: true,
        });
        return;
      }
      const newQueryParams = { ...queryParams };
      console.log("newQueryParams", newQueryParams);
      if (newQueryParams?.tab) {
        delete newQueryParams.tab;
      }

      // 공용공간 tab 이 아닐 때, facilityType 제거
      if (newQueryParams?.tab !== "facility" && newQueryParams?.facilityType) {
        delete newQueryParams.facilityType;
      }

      if (newQueryParams?.memoId) {
        delete newQueryParams?.memoId;
      }
      if (newQueryParams?.memoType) {
        delete newQueryParams?.memoType;
      }

      if (newQueryParams?.tabType) {
        delete newQueryParams?.tabType;
        delete newQueryParams?.tabId;
      }

      newQueryParams.tab = tab.value;

      // query parameter 에 tab 추가
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location, queryParams],
  );

  // breadCrumb 클릭시 callback 함수
  const clickBreadCrumb = useCallback(
    (crumb: { value: string; label: string }) => {
      if (crumb.value === "building") {
        // 건물 목록으로 이동
        navigate(PagePath.building.list);
      }
    },
    [navigate],
  );

  const breadCrumbs = [{ value: "building", label: "건물" }];
  if (activeTab) {
    // 마지막 bread crumb 는 현재 선택된 탭
    breadCrumbs.push({
      value: activeTab.value,
      label: activeTab.label,
    });
  }

  const [modal, setModal] = useState<Modal>({
    isOpen: false,
  });

  useEffect(() => {
    goToPageNotFound();
  }, [goToPageNotFound]);

  return (
    <div className="page-product-access">
      <ContentsTitle
        title="건물"
        tabs={tabs.filter((item) => (isCsActive ? item : item.value !== "csItem"))}
        activeTab={activeTab}
        clickTab={clickTab}
        detailInfo={[{ title: "id", value: queryParams.id ? String(queryParams.id) : "" }]}
        clickBreadCrumb={clickBreadCrumb}
      />

      {/* 탭 */}

      <>
        {/* 기본정보 */}
        {activeTab?.value === "basicInfo" && <BasicInfoForm />}
        {/* 프로퍼티 */}
        {activeTab?.value === "property" && <PropertyForm />}
        {/* 공용공간 */}
        {activeTab?.value === "facility" && <CommonFacility />}
        {/* 이미지 */}
        {activeTab?.value === "image" && <ImageForm />}
        {/* 좌석 배치도 */}
        {activeTab?.value === "seatingChart" && <SeatingChartForm />}
        {/* 관리처 */}
        {activeTab?.value === "mgmtOffice" && <MgmtOfficeDetail buildingId={String(buildingId || "")} />}
        {/* voc */}
        {activeTab?.value === "csItem" && <VocService serviceId={String(buildingId)} />}
        {/* IoT */}
        {activeTab?.value === "iotDevice" && <BuildingIotDevice />}
        {/* 변경내역 */}
        {activeTab?.value === "history" && buildingId && <HistoryDetail identifier={{ buildingId: String(buildingId) }} />}
      </>

      {modal.isOpen && (
        <BaseModal isOpen={true} btnRightTitle={"확인"} title="기본정보가 저장되지 않았습니다." onClick={() => setModal({ ...modal, isOpen: false })}>
          {/* <p>{modal.message}</p> */}
        </BaseModal>
      )}
    </div>
  );
};
export default BuildingForm;
