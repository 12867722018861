import { Sort } from "../public-types";
import { Ac2AccessDeviceModel } from "./productac-types";

// 출입그룹 조회 쿼리
export interface AccessGroupQuery {
  page: number;
  size: number;
  sort: Sort;
  searchType?: "id" | "accessGroupname" | "";
  searchValue?: string;
  ids: string;
}

export const visitorAccessType = {
  VISITOR_MUTABLE: "VISITOR_MUTABLE", // QR 표시
  VISITOR_NO_MUTABLE: "VISITOR_NO_MUTABLE", // QR 표시 안함
  VISITOR_ACCESS_UNRECOGNIZED: "VISITOR_ACCESS_UNRECOGNIZED", // 정의 X
} as const;

// 방문자 출입방식
export type VisitorAccessType = (typeof visitorAccessType)[keyof typeof visitorAccessType] | "";

// 출입그룹 상세 조회 응답
export type Ac2AccessGroupModel = {
  id: number; // Access Group ID
  accessGroupName: string; // 출입그룹명
  memberAccessYn: boolean; // 이용자 허용 여부
  visitorAccessYn: boolean; // 방문자 허용 여부
  visitorAccessGroupSelectableYn: boolean; // Taap 방문자 초대시 출입그룹 선택가능 여부
  visitorInvitationApprovalYn: boolean; // 방문자 초대 관리자 승인 필요 여부
  visitorAccessType: VisitorAccessType; // 방문자 출입방식
  deleteYn: boolean; // 삭제여부
  deviceIds: number[]; // Device ID
  partnerId: number; // Partner ID
  createdBy: string; // 생성자
  createdDate: string; // 생성 일시
  lastModifiedBy: string; // 수정자
  lastModifiedDate: string; // 수정 일시
  acDevices?: Ac2AccessDeviceModel[]; // 출입기록 조회 기기 목록 front 용
};

// 출입그룹 생성 요청
export interface Ac2AccessGroupRequest {
  accessGroupName?: string; // 출입그룹명
  memberAccessYn?: boolean; // 이용자 허용 여부
  visitorAccessYn?: boolean; // 방문자 허용 여부
  visitorAccessGroupSelectableYn?: boolean; // Taap 방문자 초대시 출입그룹 선택가능 여부
  visitorInvitationApprovalYn?: boolean; // 방문자 초대 관리자 승인 필요 여부
  visitorAccessType?: VisitorAccessType; // QR 발급 타입
  deviceAddIds?: number[]; // 출입기록 조회 기기 추가
  deviceDeleteIds?: number[]; // 출입기록 조회 기기 삭제
}

// 계약 관리 출입 설정 상세 조회 응답
export type AcGroupContractManageModel = {
  contractManageId: number; // 계약 관리 ID
  contractApplyNumber: string; //이용신청 번호
  visitorDefaultAccessGroupYn: boolean; //방문자 기본 출입 권한으로 사용
  accessGroupIds: number[]; //연결된 출입그룹 ID 목록
};

// 계약 관리 출입 설정 수정 요청
export interface AcGroupContractManageRequest {
  visitorDefaultAccessGroupYn?: boolean; //방문자 기본 출입 권한으로 사용
  accessGroupAddIds?: number[]; // 출입그룹 추가
  accessGroupDeleteIds?: number[]; // 출입그룹 삭제
}
