import moment from "moment";
import { reservationColumns } from "./list/columns";

// modal 공통
export type Modal = {
  isOpen: boolean;
  message?: string;
  type?: string;
  payload?: any;
  title?: string;
};

// 프론트에서 사용하는 값
// 예약접수 -> RESERVATION_RECEIVED
// 예약실패 -> RESERVATION_FAILED
// 이용대기 -> WAITING_FOR_USE
// 이용중 -> IN_USE
// 이용완료 -> USE_COMPLETED
// 이용취소 -> USE_CANCELLED

// 공용공간 예약상태 한글, css 반환
export const getStatusTextAndColor = (statusCode?: string, startDate?: string, endDate?: string) => {
  if (statusCode === ReservationState.INCOMPLETE) {
    return { label: "예약실패", color: "status C" };
  } else if (statusCode === ReservationState.ACKNOWLEDGED && moment().isBefore(moment(startDate))) {
    return { label: "이용대기", color: "status A" };
  } else if (statusCode === ReservationState.ACKNOWLEDGED && moment().isBetween(moment(startDate), moment(endDate), null, "[)")) {
    return { label: "이용중", color: "status B" };
  } else if (statusCode === ReservationState.ACKNOWLEDGED && moment(endDate).isSameOrBefore(moment())) {
    return { label: "이용완료", color: "status D" };
  } else if (statusCode === ReservationState.CANCELLED) {
    return { label: "이용취소", color: "status D" };
  } else return { label: "", color: "" };
};

// 공용공간 예약상태 프론트에서 사용하는 value 값 반환
export const getStatusValue = (statusCode?: string, startDate?: string, endDate?: string) => {
  if (statusCode === ReservationState.INCOMPLETE) {
    return "RESERVATION_FAILED";
  } else if (statusCode === ReservationState.ACKNOWLEDGED && moment().isBefore(moment(startDate))) {
    return "WAITING_FOR_USE";
  } else if (statusCode === ReservationState.ACKNOWLEDGED && moment().isBetween(moment(startDate), moment(endDate), null, "[)")) {
    return "IN_USE";
  } else if (statusCode === ReservationState.ACKNOWLEDGED && moment(endDate).isSameOrBefore(moment())) {
    return "USE_COMPLETED";
  } else if (statusCode === ReservationState.CANCELLED) {
    return "USE_CANCELLED";
  } else return "-";
};

// 공용공간 예약상태에 따른, 셀렉트 옵션 반환
export const getStatusTextOption = (statusCode?: string, startDate?: string, endDate?: string) => {
  if (statusCode === ReservationState.INCOMPLETE) {
    return [{ value: "RESERVATION_FAILED", label: "예약실패", color: "color-gray" }];
  } else if (statusCode === ReservationState.ACKNOWLEDGED && moment().isBefore(moment(startDate))) {
    return [
      { value: "WAITING_FOR_USE", label: "이용대기", color: "color-blue" },
      { value: "USE_CANCELLED", label: "이용취소", color: "color-gray" },
    ];
  } else if (statusCode === ReservationState.ACKNOWLEDGED && moment().isBetween(moment(startDate), moment(endDate), null, "[)")) {
    return [
      { value: "IN_USE", label: "이용중", color: "color-green" },
      { value: "USE_CANCELLED", label: "이용취소", color: "color-gray" },
    ];
  } else if (statusCode === ReservationState.ACKNOWLEDGED && moment(endDate).isSameOrBefore(moment())) {
    return [
      { value: "USE_COMPLETED", label: "이용완료", color: "color-gray" },
      { value: "USE_CANCELLED", label: "이용취소", color: "color-gray" },
    ];
  } else if (statusCode === ReservationState.CANCELLED) {
    return [{ value: "USE_CANCELLED", label: "이용취소", color: "color-gray" }];
  } else return [];
};

// 공용공간 예약 status 한글 변환
export const getStatusText = (status?: string, endDate?: Date) => {
  const accepted = ReservationState.ACCEPTED;
  const cancelled = ReservationState.CANCELLED;
  const acknowledged = ReservationState.ACKNOWLEDGED;
  const incomplete = ReservationState.INCOMPLETE;
  switch (status) {
    case accepted:
      return "접수";
    case acknowledged:
      return "점유";
    case incomplete:
      return "불완전";
    case cancelled:
      return "취소";
    default:
      return "-";
  }
};

// 공용공간 예약 status 한글 변환
export const getReservationStatusDetail = (status?: string, endDate?: Date) => {
  const accepted = ReservationState.ACCEPTED;
  const cancelled = ReservationState.CANCELLED;
  const acknowledged = ReservationState.ACKNOWLEDGED;
  const incomplete = ReservationState.INCOMPLETE;
  switch (status) {
    case accepted:
      return "접수";
    case acknowledged:
      return "점유";
    case incomplete:
      return "불완전";
    case cancelled:
      return "취소";
    default:
      return "-";
  }
};

// 예약 경로 타입
// export const ReservationStatus = {
//   ENABLED: "RESERVATION_ENABLED", // 공개
//   CANCELED: "RESERVATION_CANCELED", // 관리자가 취소
//   EXPIRED: "RESERVATION_EXPIRED", // 예약취소
//   INCOMPLETED_FACILITY: "RESERVATION_INCOMPLETED_FACILITY", // 불완전예약-회의실사용불가
//   INCOMPLETED_QUOTA_DAYLY: "RESERVATION_INCOMPLETED_QUOTA_DAYLY", //불완전예약-일사용시간초과
//   INCOMPLETED_QUOTA_MONTHLY: "RESERVATION_INCOMPLETED_QUOTA_MONTHLY", //불완전예약-월사용시간초과
// } as const;

export const ReservationState = {
  ACCEPTED: "ACCEPTED", // 접수
  ACKNOWLEDGED: "ACKNOWLEDGED", // 점유
  INCOMPLETE: "INCOMPLETE", // 불완전
  CANCELLED: "CANCELLED", // 취소
} as const;

export type UnionReservationStatus = (typeof ReservationState)[keyof typeof ReservationState];

// 공간예약 목록 조회하는 모든 상태
export const SEARCH_STATUSES = [ReservationState.ACCEPTED, ReservationState.ACKNOWLEDGED, ReservationState.INCOMPLETE, ReservationState.CANCELLED];

export type ReservationExternalTypes = "TAAP" | "GC" | "OC";

// 예약 경로 타입
export const ExternalServiceType = {
  EXTERNALSERVICE_UNRECOGNIZED: "EXTERNALSERVICE_UNRECOGNIZED", //정의되지 않은 타입
  TAAP: "TAAP",
  GC: "GC", // Google Calendar
  OC: "OC", //Outlook Calendar
  CTRL_ROOM: "CTRL_ROOM",
  TAAP_SPACE: "TAAP_SPACE",
} as const;

export type UnionExternalServiceType = (typeof ExternalServiceType)[keyof typeof ExternalServiceType];

const USER_TYPES = {
  SYSTEM: "SYSTEM",
  TAAP: "TAAP",
  CENTRAL: "CENTRAL",
  CTRL_ROOM: "CTRL_ROOM",
  CALENDAR: "CALENDAR",
  TAAP_SPACE: "TAAP_SPACE",
} as const;

// Union 타입
export type UserType = (typeof USER_TYPES)[keyof typeof USER_TYPES];

//  예약초대 상태
export const ReservationAttendeeResponseStatus = {
  NONE: "NONE", //응답없음
  DECLINED: "DECLINED", //거부
  TENTATIVE: "TENTATIVE", // 임시적 수락
  ACCEPTED: "ACCEPTED", //수락
};

// 공용공간예약 목록 엑셀 다운로드 헤더
export const reservationHeaders = [
  { label: "id", key: "id" },
  { label: "group id", key: "reservationGroupId" },
  { label: "상품 id", key: "productId" },
  { label: "신청/계약 번호", key: "contractApplyNumber" },
  { label: "예약경로", key: "externalServiceType" },
  { label: "건물명", key: "buildingName" },
  { label: "위치 (층)", key: "buildingCommonFacilityFloorNum" },
  { label: "위치 (지상여부)", key: "buildingCommonFacilityIsGround" },
  { label: "위치 (id)", key: "buildingCommonFacilityId" },
  { label: "위치 (LocationCode)", key: "buildingCommonFacilityLocationCode" },
  { label: "공간타입", key: "commonFacilityType" },
  { label: "공간명", key: "buildingCommonFacilityName" },
  { label: "예약기간 (시작일시)", key: "start" },
  { label: "예약기간 (종료일시)", key: "end" },
  { label: "예약자 (회원번호)", key: "memberNo" },
  { label: "예약자 (이메일)", key: "memberEmail" },
  { label: "예약명", key: "summary" },
  { label: "생성일", key: "createdDate" },
  { label: "상태", key: "status" },
];

// 공용공간예약 소진시간 엑셀 다운로드 헤더
export const reservationUsageHeaders = [
  { label: "id", key: "reservationId" },
  { label: "신청/계약 번호", key: "contract.applyNumber" },
  { label: "건물명", key: "facility.building.name" },
  { label: "공간타입", key: "facility.type" },
  { label: "공간id", key: "facility.id" },
  { label: "공간명", key: "facility.name" },
  { label: "예약기간 (시작일시)", key: "start" },
  { label: "예약기간 (종료일시)", key: "end" },
  { label: "소진시간", key: "duration" },
  { label: "예약자 (회원번호)", key: "organizer.memberNo" },
  { label: "예약자 (휴대폰번호)", key: "organizer.phoneNumber" },
  { label: "예약자 (이메일)", key: "organizer.userEmail" },
];

// 검색 대상
export const reservationSearchTypes = [
  { value: "ALL", label: "전체" },
  { value: "containsFacilityName", label: "공간 명" },
  { value: "containsBuildingName", label: "건물 명" },
  { value: "containsOrganizerMemberNo", label: "회원번호" },
  { value: "id", label: "예약 ID" },
  { value: "parentId", label: "그룹 ID" },
  { value: "startsWithOrganizerDisplayName", label: "주최자 닉네임" },
  { value: "startsWithOrganizerUserEmail", label: "주최자 이메일" },
  { value: "startsWithSummary", label: "예약명" },
];

export const reservationSearchTypeKey = [
  //검색조건
  "id",
  "parentId",
  "containsFacilityName",
  "containsBuildingName",
  "containsOrganizerMemberNo",
  "containsContractApplyNumber",
  "startsWithOrganizerDisplayName",
  "startsWithOrganizerUserEmail",
  "startsWithSummary",
];

// 공용공간 유형
export const reservationFacilityType = [
  { value: "", label: "전체" },
  { value: "MEETING_ROOM", label: "회의실" },
  { value: "DESK", label: "좌석" },
  { value: "REFRESH_ROOM", label: "편의시설" },
];

// 상태 - 예약구분이 정상일 경우
export const reservationStatuses = [
  { value: "", label: "전체" },
  { value: "RESERVATION_FAILED", label: "예약실패" },
  { value: "WAITING_FOR_USE", label: "이용대기" },
  { value: "IN_USE", label: "이용중" },
  { value: "USE_COMPLETED", label: "이용완료" },
  { value: "USE_CANCELLED", label: "이용취소" },
];

export const inboundChannelType = {
  [ExternalServiceType.TAAP]: "Taap",
  [ExternalServiceType.GC]: "Google Calendar",
  [ExternalServiceType.OC]: "Outlook Calendar",
  [ExternalServiceType.TAAP_SPACE]: "TaapSPACE",
  [ExternalServiceType.CTRL_ROOM]: "Ctrl.room",
} as const;

// 상태
export const reservationInboundChannel = [
  { value: "", label: "전체" },
  { value: ExternalServiceType.TAAP, label: inboundChannelType[ExternalServiceType.TAAP] },
  { value: ExternalServiceType.GC, label: inboundChannelType[ExternalServiceType.GC] },
  { value: ExternalServiceType.OC, label: inboundChannelType[ExternalServiceType.OC] },
  { value: ExternalServiceType.TAAP_SPACE, label: inboundChannelType[ExternalServiceType.TAAP_SPACE] },
  { value: ExternalServiceType.CTRL_ROOM, label: inboundChannelType[ExternalServiceType.CTRL_ROOM] },
];

export type CalendarSetting = {
  transportType: boolean; // on: 스와이퍼 롤링, off: 스크롤
  reservationName: "public" | "private" | string; // public: 공개, private: 비공개
  attendeeInfo: "public" | "restricted" | "private" | string; // public:공개, restricted: 일부공개, private:비공개
  companyName: "public" | "private" | string; // public: 공개, private: 비공개
};

export const FORM_NAMES = {
  RESERVATION_NAME: "reservationName", // 예약명
  ATTENDEE_INFO: "attendeeInfo", // 참석자정보
  COMPANY_NAME: "companyName", // 소속회사
} as const;

export const convertDisplayDate = () => {
  const ymd = moment(new Date()).format(`YYYY.MM.DD`);
  const week = moment(new Date()).format(`ddd`);
  const hhmm = moment(new Date()).format(`HH:mm`);
  const days = ["월", "화", "수", "목", "금", "토", "일"];
  const englishDays = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];

  const index = days.indexOf(week);
  const convertWeek = index !== -1 ? englishDays[index] : null;

  return `${ymd}(${convertWeek}) ${hhmm}`;
};

export const getWeekToEN = (date: string) => {
  const md = moment(date).format("M.D");
  const week = moment(date).format(`ddd`);
  const hhmm = moment(date).format(`HH:mm`);
  const days = ["월", "화", "수", "목", "금", "토", "일"];
  const englishDays = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];

  const index = days.indexOf(week);
  const convertWeek = index !== -1 ? englishDays[index] : null;

  return `${md} (${convertWeek}) ${hhmm}`;
};

export interface Room {
  id: string;
  title: string;
  buildingName: string;
}

// 빌딩의 갯수를 6분할하여 만약 6개 미만이라면 빈 객체를 추가
export const chunkRooms = (rooms: Room[], size: number = 6): Room[][] => {
  return Array.from({ length: Math.ceil(rooms.length / size) }, (_, i) => {
    const chunk = rooms.slice(i * size, (i + 1) * size);
    return [
      ...chunk,
      ...Array(size - chunk.length)
        .fill(null)
        .map((_, j) => ({
          id: `empty-${j}`,
          title: "",
          buildingName: "",
        })),
    ];
  });
};

// 모든 공용공간 더미데이터
export const dates = [
  { title: "CTRL.ROOM_123", buildingName: "ROOM_123", id: "0" },
  { title: "CTRL.ROOM_123", buildingName: "ROOM_123", id: "2" },
  { title: "룸이름3", buildingName: "빌딩이름3", id: "3" },
  { title: "룸이름4", buildingName: "빌딩이름4", id: "4" },
  { title: "룸이름5", buildingName: "빌딩이름5", id: "5" },
  { title: "룸이름6", buildingName: "빌딩이름6", id: "6" },
  { title: "룸이름7", buildingName: "빌딩이름7", id: "7" },
  { title: "룸이름8", buildingName: "빌딩이름8", id: "89" },
  { title: "룸이름8", buildingName: "빌딩이름8", id: "899" },
  { title: "룸이름8", buildingName: "빌딩이름8", id: "8000" },
  { title: "룸이름8", buildingName: "빌딩이름8", id: "8123" },
  { title: "룸이름8", buildingName: "빌딩이름8", id: "8112354" },
  { title: "룸이름8", buildingName: "빌딩이름8", id: "811231556" },
  { title: "룸이름8", buildingName: "빌딩이름8", id: "81121" },
  { title: "룸이름123", buildingName: "빌딩이름81", id: "811210" },
];

// timeSlots 생성 부분 수정
export const timeSlots: string[] = Array.from({ length: 50 }, (_, i) => {
  const hour = Math.floor(i / 2)
    .toString()
    .padStart(2, "0");
  const minute = i % 2 === 0 ? "00" : "30";
  return `${hour}:${minute}`;
});
