import { Ac2AccessDeviceModel } from "src/api/access/productac-types";
import { DeviceType, ExecuteResultType, IndoorUnitKeyTypes, IndoorUnitMode } from "./monitoring-types";
import { BULLET, roundFloat } from "src/utils/common-util";
import { alertIcon } from "./components/MonitoringTable";
import { CctvDevice } from "src/api/cctv/cctv-types";

export const getUnit = (type: IndoorUnitKeyTypes) => {
  return IndoorUnitMode[type];
};

const cautionMessage = "최신 정보가 아닐 수 있습니다. 상태를 확인하세요.";

type TooltipDeviceData = {
  iot?: ExecuteResultType;
  access?: Ac2AccessDeviceModel;
  cctv?: CctvDevice;
};

export const monitoringTooltipData = (deviceData: TooltipDeviceData, isCaution?: boolean) => {
  const { iot, access, cctv } = deviceData;

  const unknownMessage = "알수없음";
  const tooltipChildren = [
    {
      type: DeviceType.CCTV,
      value: (
        <div>
          <p className="popover-gray__title">{`Camera Group : ${cctv?.cameraGroup}`}</p>

          <p>{`${BULLET} Camera ID : ${cctv?.cameraId}`}</p>
          <p>{`${BULLET} 장치명 : ${cctv?.name}`}</p>
        </div>
      ),
    },
    {
      type: DeviceType.ACCESS,
      value: (
        <div>
          <p className="popover-gray__title">{access?.deviceName}</p>

          <p>{`${BULLET} 상태 : ${access?.deviceStatusInfo?.deviceStatus}`}</p>
          <p>{`${BULLET} 장치 id : ${access?.externalDeviceId}`}</p>
        </div>
      ),
    },
    {
      type: DeviceType.SENSOR,
      value: (
        <div>
          <p className="popover-gray__title">{iot?.internalDisplayName}</p>
          <div className="flex-center">
            <p>{`${BULLET} 상태 : ${(iot?.result?.motion && iot?.result?.motion.value) || unknownMessage}`}</p>
            {isCaution && <div className="ml4">{alertIcon}</div>}
          </div>
          {isCaution && (
            <div className="flex-center">
              <div className="mr3">{alertIcon}</div>
              <p className="font12">{cautionMessage}</p>
            </div>
          )}
        </div>
      ),
    },
    {
      type: DeviceType.INDOOR_UNIT,
      value: (
        <div>
          {iot?.platform === "ST" && (
            <>
              <p className="popover-gray__title">{iot?.internalDisplayName}</p>
              <div className="flex-center">
                <p>{`${BULLET} 상태 : ${(iot?.result?.switch && iot?.result?.switch.value) || unknownMessage}`}</p>
                {isCaution && <div className="ml4">{alertIcon}</div>}
              </div>
              <p>{`${BULLET} 모드 : ${(iot?.result?.airConditionerMode && getUnit(iot?.result?.airConditionerMode.value)) || unknownMessage}`}</p>
              <p>{`${BULLET} 희망 온도 : ${
                (iot?.result?.error ? unknownMessage : iot?.result?.coolingSetpoint && roundFloat(iot?.result?.coolingSetpoint.value) + "°C") ||
                unknownMessage
              }`}</p>
              <p>{`${BULLET} 측정 온도 : ${
                (iot?.result?.error ? unknownMessage : iot?.result?.temperature && roundFloat(iot?.result?.temperature.value) + "°C") ||
                unknownMessage
              }`}</p>
              {isCaution && (
                <div className="flex-center">
                  <div className="mr3">{alertIcon}</div>
                  <p className="font12">{cautionMessage}</p>
                </div>
              )}
            </>
          )}
          {iot?.platform === "B_IOT" && (
            <>
              {/* CES 대비 > FTU 일 때 */}
              {Array.isArray(iot.result) && iot.result.find((item: any) => item.controlPoint) ? (
                <>
                  <p className="popover-gray__title">{iot?.internalDisplayName}</p>
                  <div className="flex-center">
                    <p>{`${BULLET} 환기 : ${
                      iot?.result?.find((item: any) => item.controlPoint).label === "공조/환기" &&
                      iot?.result?.find((item: any) => item.controlPoint.label === "습도")?.controlPoint?.value === 0
                        ? " on"
                        : "off" || unknownMessage
                    }`}</p>
                    {isCaution && <div className="ml4">{alertIcon}</div>}
                  </div>
                  <p>{`${BULLET} 습도 : ${
                    (iot?.result?.find((item: any) => item.controlPoint.label === "습도") &&
                      roundFloat(iot?.result?.find((item: any) => item.controlPoint.label === "습도")?.controlPoint?.value) + "%") ||
                    unknownMessage
                  }`}</p>
                  <p>{`${BULLET} 측정 온도 : ${
                    (iot?.result?.find((item: any) => item.controlPoint.label === "측정온도") &&
                      roundFloat(iot?.result?.find((item: any) => item.controlPoint.label === "측정온도")?.controlPoint?.value) + "°C") ||
                    unknownMessage
                  }`}</p>
                  <p>{`${BULLET} 희망 온도 : ${
                    (iot?.result?.find((item: any) => item.controlPoint.label === "공조/희망온도") &&
                      roundFloat(iot?.result?.find((item: any) => item.controlPoint.label === "공조/희망온도")?.controlPoint?.value) + "°C") ||
                    unknownMessage
                  }`}</p>
                  <p>{`${BULLET} CO2 : ${
                    (iot?.result?.find((item: any) => item.controlPoint.label === "CO2") &&
                      roundFloat(iot?.result?.find((item: any) => item.controlPoint.label === "CO2")?.controlPoint?.value)) ||
                    unknownMessage
                  }`}</p>
                </>
              ) : (
                <>
                  <p className="popover-gray__title">{iot?.internalDisplayName}</p>
                  <div className="flex-center">
                    <p>{`${BULLET} 상태 : ${(iot?.result?.operation && iot?.result?.operation.power) || unknownMessage}`}</p>
                    {isCaution && <div className="ml4">{alertIcon}</div>}
                  </div>
                  <p>{`${BULLET} 모드 : ${(iot?.result?.mode && getUnit(iot?.result?.mode.mode)) || unknownMessage}`}</p>
                  <p>{`${BULLET} 희망 온도 : ${
                    (iot?.result?.error ? unknownMessage : iot?.result?.temperature && roundFloat(iot?.result?.temperature.desired) + "°C") ||
                    unknownMessage
                  }`}</p>
                  <p>{`${BULLET} 측정 온도 : ${
                    (iot?.result?.error ? unknownMessage : iot?.result?.temperature && roundFloat(iot?.result?.temperature.current) + "°C") ||
                    unknownMessage
                  }`}</p>
                  {isCaution && (
                    <div className="flex-center">
                      <div className="mr3">{alertIcon}</div>
                      <p className="font12">{cautionMessage}</p>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      ),
    },
    //  조명
    {
      type: DeviceType.LIGHT,
      value: (
        <div>
          <p className="popover-gray__title">{iot?.internalDisplayName}</p>
          <div className="flex-center">
            <div className="flex-center">
              {/* 스마트띵 일 때 */}
              {iot?.result?.switch && ( //
                <p>{`${BULLET} 상태 : ${(iot?.result?.switch && iot?.result?.switch.value) || unknownMessage}`}</p>
              )}
              {/* B_IOT 일 때 */}
              {iot?.result?.operation && ( //
                <p>{`${BULLET} 상태 : ${(iot?.result?.operation && iot?.result?.operation.power) || unknownMessage}`}</p>
              )}

              {isCaution && <div className="ml4">{alertIcon}</div>}
            </div>
            {isCaution && <div className="ml4">{alertIcon}</div>}
          </div>
          {/* 스마트띵 일때 */}
          {iot?.result?.level && <p>{`${BULLET} 밝기 : ${(iot?.result?.level && iot?.result.level.value) || unknownMessage}`}</p>}
          {/* B_IOT 일때 */}
          {iot?.result?.light && (
            <p>{`${BULLET} 밝기 : ${(iot?.result?.light.dimmingLevel && iot?.result?.light.dimmingLevel) || unknownMessage}`}</p>
          )}
          {isCaution && (
            <div className="flex-center">
              <div className="mr3">{alertIcon}</div>
              <p className="font12">{cautionMessage}</p>
            </div>
          )}
        </div>
      ),
    },
    {
      type: DeviceType.MENS_TOILET,
      value: (
        <div>
          <p className="popover-gray__title">{iot?.internalDisplayName}</p>
          {!iot?.result && <p>{`${BULLET} 상태 : ${unknownMessage}`}</p>}
          {iot?.result?.contact && (
            <div className="flex-center">
              <p>{`${BULLET} 상태 : ${iot?.result?.contact.value ? iot?.result?.contact.value : unknownMessage}`}</p>
              {isCaution && <div className="ml4">{alertIcon}</div>}
            </div>
          )}
          {iot?.result?.motion && (
            <div className="flex-center">
              <p>{`${BULLET} 상태 : ${iot?.result?.motion.value ? iot?.result?.motion.value : unknownMessage}`}</p>
              {isCaution && <div className="ml4">{alertIcon}</div>}
            </div>
          )}

          {isCaution && (
            <div className="flex-center">
              <div className="mr3">{alertIcon}</div>
              <p className="font12">{cautionMessage}</p>
            </div>
          )}
        </div>
      ),
    },
    {
      type: DeviceType.WOMEN_TOILET,
      value: (
        <div>
          <p className="popover-gray__title">{iot?.internalDisplayName}</p>
          {!iot?.result && <p>{`${BULLET} 상태 : ${unknownMessage}`}</p>}
          {iot?.result?.contact && (
            <div className="flex-center">
              <p>{`${BULLET} 상태 : ${iot?.result?.contact.value ? iot?.result?.contact.value : unknownMessage}`}</p>
              {isCaution && <div className="ml4">{alertIcon}</div>}
            </div>
          )}
          {iot?.result?.motion && (
            <div className="flex-center">
              <p>{`${BULLET} 상태 : ${iot?.result?.motion.value ? iot?.result?.motion.value : unknownMessage}`}</p>
              {isCaution && <div className="ml4">{alertIcon}</div>}
            </div>
          )}
          {isCaution && (
            <div className="flex-center">
              <div className="mr3">{alertIcon}</div>
              <p className="font12">{cautionMessage}</p>
            </div>
          )}
        </div>
      ),
    },
  ];

  const tooltipChild =
    (iot && tooltipChildren.find((item) => item.type === iot.labelType)) ||
    (access && tooltipChildren.find((item) => item.type === DeviceType.ACCESS)) ||
    (cctv && tooltipChildren.find((item) => item.type === DeviceType.CCTV));
  return tooltipChild?.value;
};
