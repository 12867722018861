import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { getAccessGroupListAsync } from "src/api/access/productac-api";
import { addFacilityAccessGroupAsync, getFacilityAccessGroupAsync } from "src/api/building/commonfacility-api";
import { FacilityAccessGroupModel, FacilityAccessGroupRequest } from "src/api/building/commonfacility-types";
import { useApiOperation } from "src/api/hooks";
import { Modal } from "src/api/public-types";
import { BaseModal, BaseSelect } from "src/components";
import { BaseButton } from "src/components/BaseButton";
import AccessGroupDetailModal from "src/pages/product/components/AccessGroupDetailModal";
import FacilityAccessGroupList from "./FacilityAccessGroupList";
import { Ac2AccessGroupModel } from "src/api/access/ac-group-types";

type Props = {
  onClose: () => void;
  readonly: boolean;
  onChageReadOnly: () => void;
  facilityId: string;
};

const timeOptions = [
  { value: 0, label: "0분" },
  { value: 5, label: "5분" },
  { value: 10, label: "10분" },
  { value: 15, label: "15분" },
  { value: 20, label: "20분" },
  { value: 25, label: "25분" },
  { value: 30, label: "30분" },
];

const FacilityAccessGroupForm = ({ facilityId, onClose, readonly, onChageReadOnly }: Props) => {
  const [facilityAccessGroup, setFacilityAccessGroup] = useState<FacilityAccessGroupModel>();
  const [selectedAccessGroups, setSelectedAccessGroups] = useState<Ac2AccessGroupModel[]>([]);
  const [confirmModal, setConfirmModal] = useState<Modal>({ isOpen: false });
  const [showModal, setShowModal] = useState<Modal & { accessGroupId?: number }>({ isOpen: false });

  // 건물 공용 시설 출입 설정 조회
  const { executeAsync: getFacilityAccessGroup } = useApiOperation(getFacilityAccessGroupAsync);

  // 건물 공용 시설 출입 설정 저장
  const { executeAsync: addFacilityAccessGroup } = useApiOperation(addFacilityAccessGroupAsync);

  const { executeAsync: getAccessGroupList } = useApiOperation(getAccessGroupListAsync);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FacilityAccessGroupRequest>({
    defaultValues: {
      facilityId,
      accessGroupAddIds: [],
      accessGroupDeleteIds: [],
      preAccessTime: 0,
      postAccessTime: 0,
    },
  });

  useEffect(() => {
    (async () => {
      const { data, status } = await getFacilityAccessGroup({ facilityId });

      if (status >= 200 && status <= 299 && data.data.facility) {
        const accessGroupIds = data.data.facility.accessGroupIds || [];

        if (accessGroupIds.length > 0) {
          const { data: accessGroupData } = await getAccessGroupList({ ids: accessGroupIds.join(",") });
          setSelectedAccessGroups(accessGroupData.data.content);
        }

        setFacilityAccessGroup(data.data.facility);
        setValue("preAccessTime", data.data.facility.preAccessTime);
        setValue("postAccessTime", data.data.facility.postAccessTime);
        setValue("accessGroupAddIds", data.data.facility.accessGroupIds || []);
      }
    })();
  }, [facilityId]);

  const onSubmit = (data: FacilityAccessGroupRequest) => {
    const newData = {
      ...data,
      accessGroupAddIds: facilityAccessGroup
        ? data.accessGroupAddIds?.filter((id: number) => !facilityAccessGroup?.accessGroupIds?.includes(id))
        : data.accessGroupAddIds,
    };

    setConfirmModal({ isOpen: true, payload: newData });
  };

  const onConfirm = async () => {
    const { data, status } = await addFacilityAccessGroup(confirmModal.payload as FacilityAccessGroupRequest);
    if (status >= 200 && status <= 299) {
      console.log(`data`, data);
      onClose();
    }
  };

  return (
    <div className="contents-container__wrap px30 pb30">
      <form onSubmit={handleSubmit(onSubmit)}>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>출입그룹</p>
          </div>

          <div className="contents-container__grid-contents flex-center">
            {readonly ? (
              <>
                {selectedAccessGroups.length > 0 ? (
                  <ul className="flex-center">
                    {selectedAccessGroups.map((item) => (
                      <li
                        key={item.id}
                        className="mr10 flex-center cursor-pointer"
                        onClick={() =>
                          setShowModal({ isOpen: true, type: "ACCESS_GROUP_DETAIL", accessGroupId: Number(item.id), payload: item.accessGroupName })
                        }
                      >
                        <span className="text-underline text-blue900">{item.accessGroupName}</span>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <span>-</span>
                )}
              </>
            ) : (
              <>
                <Controller
                  control={control}
                  name="accessGroupAddIds"
                  render={({ field: { onChange }, formState: { errors } }) => (
                    <>
                      <BaseButton title="선택" className="color-white" onClick={() => setShowModal({ isOpen: true, type: "ACCESS_GROUP_SELECT" })} />
                      {showModal.type === "ACCESS_GROUP_SELECT" && showModal.isOpen && (
                        <FacilityAccessGroupList
                          onClose={() => setShowModal({ isOpen: false })}
                          onSelectedAccessGroups={(selectedItems: Ac2AccessGroupModel[]) => {
                            const accessGroupAddIds = selectedItems.map((item) => item.id);
                            onChange([...(watch("accessGroupAddIds") || []), ...accessGroupAddIds]);
                            setSelectedAccessGroups([...selectedAccessGroups, ...selectedItems]);
                          }}
                          selectedAccessGroups={selectedAccessGroups || []}
                        />
                      )}
                      <ul className="flex-center  ml10" style={{ listStyle: "none" }}>
                        {selectedAccessGroups.map((item) => (
                          <li key={item.id} className="mr10 flex-center cursor-pointer">
                            <span
                              className="text-underline text-blue900"
                              onClick={() => {
                                setShowModal({
                                  isOpen: true,
                                  type: "ACCESS_GROUP_DETAIL",
                                  accessGroupId: Number(item.id),
                                  payload: item.accessGroupName,
                                });
                              }}
                            >
                              {item.accessGroupName}
                            </span>
                            <button
                              style={{ backgroundColor: "transparent" }}
                              className="base-remove ml5"
                              onClick={(e) => {
                                e.preventDefault();
                                const filteredAccessGroups = selectedAccessGroups.filter((accessItem) => accessItem.id !== item.id);
                                setSelectedAccessGroups(filteredAccessGroups);
                                onChange(filteredAccessGroups.map((item) => item.id));
                                const currentDeleteIds = watch("accessGroupDeleteIds") as number[];
                                setValue("accessGroupDeleteIds", [...currentDeleteIds, Number(item.id)]);
                              }}
                            ></button>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                ></Controller>
              </>
            )}
          </div>
        </section>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>출입 유효시간</p>
          </div>
          <div className="contents-container__grid-contents">
            {readonly ? (
              <div className="minmax240 pt10">
                <span>{`예약 시간 시작 전 : ${watch("preAccessTime") ? watch("preAccessTime") + "분 부터" : "-"}`}</span>
                <span>{`예약 시간 종료 후 : ${watch("postAccessTime") ? watch("postAccessTime") + "분 까지" : "-"}`}</span>
              </div>
            ) : (
              <div>
                <div className="flex-center mb10">
                  <p>예약 시간 시작 전</p>
                  <Controller
                    control={control}
                    name="preAccessTime"
                    render={({ field: { value, name, onChange }, formState: { errors } }) => (
                      <BaseSelect
                        menuPlacement="auto"
                        className="minmax240 mx10"
                        stateOptions={timeOptions}
                        value={String(value)}
                        name={name}
                        placeholder="선택해주세요"
                        setStateValue={onChange}
                      />
                    )}
                  ></Controller>
                  <p>부터</p>
                </div>

                <div className="flex-center">
                  <p>예약 시간 종료 후</p>
                  <Controller
                    control={control}
                    name="postAccessTime"
                    render={({ field: { value, name, onChange }, formState: { errors } }) => (
                      <BaseSelect
                        menuPlacement="auto"
                        className="minmax240 mx10"
                        stateOptions={timeOptions}
                        value={String(value)}
                        name={name}
                        placeholder="선택해주세요"
                        setStateValue={onChange}
                      />
                    )}
                  ></Controller>
                  <p>까지</p>
                </div>
              </div>
            )}
          </div>
        </section>
        <div className="flex-center-end ">
          <BaseButton type="button" title="취소" className="color-white mr5" onClick={onClose} />
          {readonly ? (
            <BaseButton type="button" title={"수정"} onClick={() => onChageReadOnly()} /> //
          ) : (
            <BaseButton type="submit" title={"저장"} />
          )}
        </div>
      </form>
      {confirmModal.isOpen && (
        <BaseModal
          title={"저장하시겠습니까?"} //
          onClick={onConfirm}
          onClose={() => setConfirmModal({ isOpen: false })}
          isOpen={true}
          btnRightTitle="저장"
          btnLeftTitle="취소"
        />
      )}

      {showModal.type === "ACCESS_GROUP_DETAIL" && showModal.isOpen && (
        <AccessGroupDetailModal showModal={showModal} setShowModal={setShowModal} accessGroupId={Number(showModal.accessGroupId)} />
      )}
    </div>
  );
};

export default FacilityAccessGroupForm;
