import { Fragment, useMemo } from "react";
import { Control, Controller, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { Ac2AccessGroupModel, Ac2AccessGroupRequest, visitorAccessType } from "src/api/access/ac-group-types";
import { BaseButton, BaseRadio, BaseToggle, BaseTooltip } from "src/components";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";
import { visitorSettings } from "../../const";
import { useRecoilValue } from "recoil";
import { globalPartnerState } from "src/recoil/partners/atom";
import { PaPartner } from "src/types/partner";

type Props = {
  accessGroup?: Ac2AccessGroupModel;
  control?: Control<Ac2AccessGroupRequest>;
  watch: UseFormWatch<Ac2AccessGroupRequest>;
  onChangeEdit?: (type: "setting") => void;
  setValue: UseFormSetValue<Ac2AccessGroupRequest>;
};

const AccessGroupSettingForm = ({ accessGroup, control, watch, onChangeEdit, setValue }: Props) => {
  const partner = useRecoilValue(globalPartnerState);

  // 센트럴 > 파트너 feature  > FEATURE_ACCESS_GROUP_VISITOR_APPROVAL 여부 판단
  const isVisitorApproval = useMemo(() => {
    return partner?.features?.some((feature: PaPartner) => feature.name === "FEATURE_ACCESS_GROUP_VISITOR_APPROVAL" && feature.active) || false;
  }, [partner]);

  return (
    <article className="contents-container__wrap-article">
      <div className="flex-center-between">
        <BaseSectionTitle title={"설정"} />
        {accessGroup && (
          <div className="flex-center">
            {onChangeEdit && <BaseButton title="취소" className="size-small color-white mr10" onClick={() => onChangeEdit("setting")} />}
            <BaseButton title="저장" className="size-small" type="submit" />
          </div>
        )}
      </div>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <div className="flex-center">
            <p>이용자 출입</p>
            <BaseTooltip contents="비활성화 하는 경우, 해당 출입그룹 - 출입장치에 이용자가 출입할 수 없습니다." touchIcon="QUESTION" />
          </div>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <Controller
              control={control}
              name="memberAccessYn"
              render={(
                { field: { onChange, value } }, //
              ) => <BaseToggle checked={value || false} onChange={() => onChange(!value)} />}
            />
          </div>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <div className="flex-center">
            <p>방문자 초대</p>
            <BaseTooltip contents="비활성화 하는 경우, 해당 출입그룹에 방문자를  초대할 수 없습니다." touchIcon="QUESTION" />
          </div>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <Controller
              control={control}
              name="visitorAccessYn"
              render={(
                { field: { onChange, value } }, //
              ) => (
                <BaseToggle
                  checked={value}
                  onChange={(checked: boolean) => {
                    onChange(checked);

                    if (checked) {
                      setValue?.("visitorAccessGroupSelectableYn", true);
                      setValue?.("visitorInvitationApprovalYn", false);
                      setValue?.("visitorAccessType", visitorAccessType.VISITOR_MUTABLE);
                    } else {
                      setValue?.("visitorAccessGroupSelectableYn", false);
                      setValue?.("visitorInvitationApprovalYn", false);
                      setValue?.("visitorAccessType", visitorAccessType.VISITOR_MUTABLE);
                    }
                  }}
                />
              )}
            />
          </div>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index"></div>
        <div className="contents-container__grid-contents">
          <section className="contents-container__grid gray-box " style={{ height: 180 }}>
            {visitorSettings.map((group) => (
              <Fragment key={group.name}>
                <div className="contents-container__grid-index">
                  <div className="flex-center">
                    <p>{group.title}</p>
                    <BaseTooltip contents={group.tooltip} touchIcon="QUESTION" />
                  </div>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="flex-center-start flex-row">
                    <Controller
                      control={control}
                      name={group.name as keyof Ac2AccessGroupRequest}
                      defaultValue={group.defaultValue}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <>
                            {group.options.map((option, index) => (
                              <BaseRadio
                                key={index}
                                id={`${group.name}-${index}`}
                                name={group.name}
                                label={option.label}
                                value={option.value as string}
                                checked={value === option.value}
                                className={index < group.options.length - 1 ? "mr10" : ""}
                                onChange={() => onChange(option.value)}
                                disabled={
                                  !watch("visitorAccessYn") || (watch("visitorAccessYn") && group.title === "관리자 승인" && !isVisitorApproval)
                                }
                              />
                            ))}
                          </>
                        );
                      }}
                    />
                  </div>
                </div>
              </Fragment>
            ))}
          </section>
        </div>
      </section>
    </article>
  );
};

export default AccessGroupSettingForm;
