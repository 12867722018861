import { useCallback, useEffect, useMemo, useState } from "react";
import { Cell } from "react-table";
import { CommonFacilityModel } from "src/api/building/building-types";
import { useApiOperation } from "src/api/hooks";
import { ReservationListModel } from "src/api/reservation/reservation-types";
import { getContractVisitorGroupAsync, getVisitorsAsync } from "src/api/visitor/visitor-api";
import { ContractVisitorGroup } from "src/api/visitor/visitor-types";
import { BaseButton } from "src/components";
import DetailLink from "src/components/DetailLink";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";
import useNavigate from "src/hooks/usePartnerNavigate";
import ViewDataTable from "src/pages/building/components/ViewDataTable";
import { PagePath } from "src/pages/product/details";
import { getApprovalStatusToText } from "src/pages/visitor/visitor-types";

type Props = {
  reservation: ReservationListModel | null;
  commonFacility: CommonFacilityModel | null;
};

/* 
  공용공간 예약 상세 > 방문자 컴포넌트
 */
const VisitorSection = ({ reservation, commonFacility }: Props) => {
  // 방문자 목록 페이지 조회
  const { executeAsync: getVisitors } = useApiOperation(getVisitorsAsync);
  const { executeAsync: getContractVisitorGroup } = useApiOperation(getContractVisitorGroupAsync);
  const [visitorGroupList, setVisitorGroupList] = useState<ContractVisitorGroup[]>([]);
  const navigate = useNavigate();
  const fetchVisitors = useCallback(async (reservationId: string) => {
    const { data } = await getVisitors({ reservationId });

    if (data.data.contractVisitor) {
      if (data.data.contractVisitor) {
        const visitApplyNumberList = data.data.contractVisitor.map((visitor: any) => visitor.visitApplyNumber);
        const uniqueVisitApplyNumbers = [...new Set(visitApplyNumberList)];

        const requests = uniqueVisitApplyNumbers?.map(async (visitApplyNumber) => {
          const res = await getContractVisitorGroup({ visitApplyNumber });
          return res.data.data.contractVisitorGroup;
        });

        const results = await Promise.all(requests);
        setVisitorGroupList(results);
      }
    }
  }, []);

  useEffect(() => {
    if (reservation?.id) {
      fetchVisitors(String(reservation.id));
    }
  }, [fetchVisitors]);

  const handleClickAddVisitor = () => {
    if (reservation) {
      navigate(
        PagePath.visitor.add
          .replace(":contractApplyNumber", reservation?.contract?.applyNumber)
          .replace(":contractId", String(reservation?.contract?.id)) + `?reservationId=${reservation.id}`,
      );
    }
  };

  const columns: Array<any> = useMemo(
    () => [
      {
        Header: "id",
        accessor: "contractVisitorGroupId",
        width: 120,
        Cell: ({ value, row }: Cell<any>) => {
          const changeVisitApplyNumber = `${PagePath.visitor.detail.replace(":visitApplyNumber", String(row.original.visitApplyNumber))}`;
          const detailPath = `${changeVisitApplyNumber.replace(":contractApplyNumber", String(reservation?.contract.applyNumber))}`;
          return (
            <DetailLink to={detailPath} className="text-underline">
              {value}
            </DetailLink>
          );
        },
      },
      {
        Header: "승인상태",
        accessor: "approvalStatus",
        width: 90,
        Cell: ({ value }: any) => {
          return <p>{getApprovalStatusToText(value)}</p>;
        },
      },

      {
        Header: "인원",
        accessor: "size",
        width: 80,
        Cell: ({ value, row }: any) => {
          console.log(row.original.contractVisitorList);
          const contractVisitorList = row.original.contractVisitorList;
          const avtiveContractVisitorList = contractVisitorList?.filter((visitor: any) => !visitor.isDeleted);
          return <span>{avtiveContractVisitorList.length}</span>;
        },
      },
      {
        Header: "방문자 정보",
        accessor: "contractVisitorList",
        width: 780,
        Cell: ({ value }: any) => {
          const values = value.map((visitor: any, index: number) => `${visitor.visitorName} (${visitor.visitorMobileNumber})`);
          return <div className=" w-100 ellipsis">{values.join(", ")}</div>;
        },
      },
    ],
    [],
  );

  return (
    <article className="contents-container__wrap-article">
      <BaseSectionTitle
        title={"방문자"}
        rightArea={<BaseButton width={"103px"} className="size-medi color-white" title={"방문자 추가"} onClick={handleClickAddVisitor} />}
      />

      <ViewDataTable columns={columns} data={visitorGroupList} />
    </article>
  );
};

export default VisitorSection;
