import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { deleteAcGroupDeviceAsync, editAcGroupAsync } from "src/api/access/ac-group-api";
import { Ac2AccessGroupModel, Ac2AccessGroupRequest } from "src/api/access/ac-group-types";
import { ServiceTypes } from "src/api/adminMemo/adminmemo-types";
import { useApiOperation } from "src/api/hooks";
import AdminMemoV3 from "src/components/AdminMemoV3/AdminMemoV3";
import GoToListButton from "src/components/GoToListButton";
import RegistrationInformation from "src/components/RegistrationInformation";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import { useModal } from "src/recoil/modalState/hook";
import AcDeviceSection from "../form/components/AcDeviceSection";
import AcGroupBasicInfoForm from "../form/components/AcGroupBasicInfoForm";
import AccessGroupSettingForm from "../form/components/AccessGroupSettingForm";
import AcGroupBasicInfo from "./components/AcGroupBasicInfo";
import AcGroupSetting from "./components/AcGroupSetting";
import { BaseButton } from "src/components";
import useNavigate from "src/hooks/usePartnerNavigate";
import { PagePath } from "src/pages/product/details";

// 각 폼 타입 정의
type BasicInfoFormData = Pick<Ac2AccessGroupRequest, "accessGroupName">;
type SettingFormData = Pick<
  Ac2AccessGroupRequest,
  "memberAccessYn" | "visitorAccessYn" | "visitorAccessGroupSelectableYn" | "visitorInvitationApprovalYn" | "visitorAccessType"
>;
type Props = {
  accessGroup: Ac2AccessGroupModel;
  getAcGroup: () => void;
};
const BasicInfoDetail = ({ accessGroup, getAcGroup }: Props) => {
  const navigate = useNavigate();
  const { isAuthority } = usePartnerAuthority();

  const [isEdit, setIsEdit] = useState({ basicInfo: false, setting: false });

  const onChangeEdit = (type: "basicInfo" | "setting") => {
    setIsEdit((prev) => ({ ...prev, [type]: !prev[type] }));
  };

  const { setBaseModal } = useModal();

  // 각 폼별로 별도의 useForm 사용
  const basicInfoForm = useForm<BasicInfoFormData>({
    defaultValues: {
      accessGroupName: accessGroup.accessGroupName,
    },
  });

  const settingForm = useForm<SettingFormData>({
    defaultValues: {
      memberAccessYn: accessGroup.memberAccessYn,
      visitorAccessYn: accessGroup.visitorAccessYn,
      visitorAccessGroupSelectableYn: accessGroup.visitorAccessGroupSelectableYn,
      visitorInvitationApprovalYn: accessGroup.visitorInvitationApprovalYn,
      visitorAccessType: accessGroup.visitorAccessType,
    },
  });

  useEffect(() => {
    basicInfoForm.setValue("accessGroupName", accessGroup.accessGroupName);
    settingForm.setValue("memberAccessYn", accessGroup.memberAccessYn);
    settingForm.setValue("visitorAccessYn", accessGroup.visitorAccessYn);
    settingForm.setValue("visitorAccessGroupSelectableYn", accessGroup.visitorAccessGroupSelectableYn);
    settingForm.setValue("visitorInvitationApprovalYn", accessGroup.visitorInvitationApprovalYn);
    settingForm.setValue("visitorAccessType", accessGroup.visitorAccessType);
  }, []);

  // 출입그룹 수정
  const { executeAsync: editAcGroup } = useApiOperation(editAcGroupAsync);

  const { executeAsync: deleteAcGroupDevice } = useApiOperation(deleteAcGroupDeviceAsync);

  // 출입그룹 삭제
  const deleteAcGroupDeviceHandler = async () => {
    const { status } = await deleteAcGroupDevice({ id: Number(accessGroup.id) });
    if (status >= 200 && status < 300) {
      navigate(PagePath.accessGroup.list);
    }
    setBaseModal({ isOpen: false });
  };

  const editAcGroupHandler = async (
    request: BasicInfoFormData | SettingFormData | { deviceDeleteIds?: number[] },
    type?: "basicInfo" | "setting",
  ) => {
    const { status } = await editAcGroup({ id: Number(accessGroup.id), accessGroup: request });
    if (status >= 200 && status < 300) {
      if (type) {
        setIsEdit((prev) => ({ ...prev, [type]: false }));
      }
      getAcGroup();
    }
    setBaseModal({ isOpen: false });
  };

  const onError = (error: any) => {
    console.log(error);
  };

  // 각 폼별 제출 핸들러
  const onBasicInfoSubmit = async (data: BasicInfoFormData) => {
    setBaseModal({
      isOpen: true,
      title: "저장하시겠습니까?",
      onClick: () => editAcGroupHandler(data, "basicInfo"),
      onClose: () => setBaseModal({ isOpen: false }),
      btnRightTitle: "확인",
      btnLeftTitle: "취소",
    });
  };

  const onSettingSubmit = async (data: SettingFormData) => {
    setBaseModal({
      isOpen: true,
      title: "저장하시겠습니까?",
      onClick: () => editAcGroupHandler(data, "setting"),
      onClose: () => setBaseModal({ isOpen: false }),
      btnRightTitle: "확인",
      btnLeftTitle: "취소",
    });
  };

  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          {/* 기본정보 */}
          {isEdit.basicInfo ? ( //
            <form onSubmit={basicInfoForm.handleSubmit(onBasicInfoSubmit, onError)}>
              <AcGroupBasicInfoForm control={basicInfoForm.control} accessGroup={accessGroup} onChangeEdit={onChangeEdit} />
            </form>
          ) : (
            <AcGroupBasicInfo accessGroup={accessGroup} onChangeEdit={onChangeEdit} isAuthority={isAuthority("w")} />
          )}

          {/* 설정 */}
          {isEdit.setting ? (
            <form onSubmit={settingForm.handleSubmit(onSettingSubmit, onError)}>
              <AccessGroupSettingForm
                control={settingForm.control}
                watch={settingForm.watch}
                setValue={settingForm.setValue}
                accessGroup={accessGroup}
                onChangeEdit={onChangeEdit}
              />
            </form>
          ) : (
            //세팅 상세
            <AcGroupSetting accessGroup={accessGroup} onChangeEdit={onChangeEdit} isAuthority={isAuthority("w")} />
          )}
          {/* 출입장치 */}
          <AcDeviceSection accessGroup={accessGroup} editAcGroupHandler={(request) => editAcGroupHandler(request)} isAuthority={isAuthority} />
          {/* 등록정보 */}
          <RegistrationInformation data={accessGroup} />
          {/* 메모 */}
          <AdminMemoV3 serviceId={Number(accessGroup.id)} serviceType={ServiceTypes.SERVICE_ACCESS_GROUP} />
        </div>
        {/* 버튼영역 */}
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <GoToListButton />
        </div>
        <div className="right-area">
          {isAuthority("d") && (
            <BaseButton
              title="삭제"
              className="color-white size-large"
              onClick={() =>
                setBaseModal({
                  isOpen: true,
                  title: "삭제하시겠습니까?",
                  onClick: deleteAcGroupDeviceHandler,
                  onClose: () => setBaseModal({ isOpen: false }),
                  btnRightTitle: "확인",
                  btnLeftTitle: "취소",
                })
              }
            />
          )}
        </div>
      </div>
    </>
  );
};

export default BasicInfoDetail;
