import moment from "moment";
import qs from "qs";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { getAccessDevicesAsync } from "src/api/access/productac-api";
import { Ac2AccessDeviceModel } from "src/api/access/productac-types";
import { getBuildingAsync, getBuildingsAsync } from "src/api/building/building-api";
import { BuildingListParams, BuildingModel, CommonFacilityModel } from "src/api/building/building-types";
import { useApiOperation } from "src/api/hooks";
import { deviceControlExcuteAsync, getBuildingDeviceAsync, getBuildingDeviceControlAsync } from "src/api/iot/iot-api";
import { BuildingLocation, UnionBuildingLocation } from "src/api/iot/iot-types";
import { BaseCheckbox } from "src/components";
import { YmdFormat } from "src/utils";
import { SelectOption } from "../building/building-types";
import BuildingSelect from "./components/BuildingSelect";
import MonitoringTable from "./components/MonitoringTable";
import {
  DeviceCategory,
  DeviceControlType,
  DeviceType,
  ExecuteResultType,
  IotMonitoringDevice,
  MonitoringType,
  UnionDeviceCategory,
} from "./monitoring-types";
import { getCctvDeviceListAsync } from "src/api/cctv/cctv-api";
import { CctvDevice } from "src/api/cctv/cctv-types";

type IotPassData = {
  devices: ExecuteResultType[];
  locationId: string;
  roomIds?: string[];
};

type AccessDevicePassData = {
  location: UnionBuildingLocation;
  value: string;
};

type CctvDevicePassData = {
  devices: CctvDevice[];
  locationId: string;
};

const defaultIotDevice = {
  floor: {
    access: [],
    sensor: [],
    indoorUnit: [],
    temperature: [],
    light: [],
    mensToilet: [],
    womensToilet: [],
    cctv: [],
  },
  facility: {
    access: [],
    sensor: [],
    indoorUnit: [],
    temperature: [],
    light: [],
    mensToilet: [],
    womensToilet: [],
  },
};

/**
 * 건물 상세
 * iot 목록 가져오기
 * control 호출
 * 실행
 */

export type CctvDeviceType = CctvDevice & { isLoading?: boolean };

const SpaceMonitoring = () => {
  const location = useLocation();

  // 건물 상세
  const [building, setBuilding] = useState<BuildingModel>();

  // 건물 선택 옵션
  const [buildingOptions, setBuildingOptions] = useState<SelectOption[]>([]);

  //iot 기기 데이터
  const [monitoringDevices, setMonitoringDevices] = useState<IotMonitoringDevice>(defaultIotDevice);

  // 출입기기 데이터
  const [accessGroup, setAccessGroup] = useState<Array<Ac2AccessDeviceModel & { isLoading?: boolean }>>();

  //  데이터 있는 공간 체크박스
  const [hasDevices, setHasDevices] = useState(true);

  const [cctvDevices, setCctvDevices] = useState<CctvDeviceType[]>([]);

  // buildingId - queryParams 적용
  const queryParams = useMemo(
    () => qs.parse(location.search, { ignoreQueryPrefix: true, allowDots: true, decoder: (value) => decodeURIComponent(value) }),
    [location.search],
  );

  // 출입기기 목록
  const { executeAsync: getAccessDevices } = useApiOperation(getAccessDevicesAsync);

  //건물 목록
  const { executeAsync: getBuildingList } = useApiOperation(getBuildingsAsync);

  //건물 상세
  const { executeAsync: getBuilding } = useApiOperation(getBuildingAsync);

  // 건물 IoT 기기 탭 목록
  const { executeAsync: getBuildingIotDevice } = useApiOperation(getBuildingDeviceAsync);

  // iot 디바이스 제어 목록
  const { executeAsync: getIotDeviceControl } = useApiOperation(getBuildingDeviceControlAsync, { noHandleError: true, noLoading: true });

  // iot 디바이스 제어 실행
  const { executeAsync: deviceControlExcute } = useApiOperation(deviceControlExcuteAsync, { noHandleError: true, noLoading: true });

  // cctv api
  const { executeAsync: getCctvDeviceList } = useApiOperation(getCctvDeviceListAsync);

  const [updateTime, setUpdateTime] = useState("");

  const intervalRef = useRef<NodeJS.Timer>();

  useEffect(() => {
    const fetchAndStartInterval = async () => {
      const requestParams: BuildingListParams = {
        page: 0,
        size: 1000,
        sort: { orders: [{ direction: "ASC", property: "buildingName" }] },
      };
      const { data, status } = await getBuildingList(requestParams);
      if (status >= 200 && status <= 299) {
        const result = data.data.content;

        setBuildingOptions(
          result.map((item) => ({
            value: String(item.id),
            label: item.buildingName,
          })),
        );

        const buildingId = queryParams.buildingId ? Number(queryParams.buildingId) : result[0].id;
        await getBuildingDetail(buildingId);

        startInterval(buildingId);
      }
    };

    fetchAndStartInterval();

    // Cleanup: 컴포넌트 언마운트 시 인터벌 제거
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      setUpdateTime("");
    };
  }, [queryParams.buildingId]);

  // cctv 목록 가져오기
  const fetchCctvDeviceList = async (buildingId: string) => {
    const { data } = await getCctvDeviceList({
      buildingId,
      sort: { orders: [{ direction: "ASC", property: "name" }] },
    });
    if (data.data.content) {
      // console.log("cctv", data.data.content);
      const parsedCctvDevices = data.data.content.map((device) => {
        // cctvEventDataList가 있는 경우에만 처리
        if (device.cctvEventDataList) {
          const parsedEventList = device.cctvEventDataList.map((eventData) => {
            try {
              if (eventData.eventData && typeof eventData.eventData === "string") {
                return {
                  ...eventData,
                  eventData: JSON.parse(eventData.eventData),
                };
              }
              return eventData;
            } catch (error) {
              return eventData;
            }
          });

          return {
            ...device,
            cctvEventDataList: parsedEventList,
            isLoading: true,
          };
        }

        return {
          ...device,
          isLoading: true,
        };
      });

      setCctvDevices(parsedCctvDevices);
    }
  };

  // 출입기기 목록 불러오기
  const getAccessGroupList = async () => {
    const { data, status } = await getAccessDevices({ includeDeviceStatusInfo: true, cacheable: true });

    if (status >= 200 && status <= 299) {
      const access = data.data.content.map((item) => ({ ...item, isLoading: true }));
      setAccessGroup(access);
    }
  };

  // 건물 상세
  const getBuildingDetail = async (buildingId: number) => {
    setUpdateTime("");
    const { data } = await getBuilding({ buildingId });
    const result = data.data.content.building;
    if (result) {
      setBuilding(result);
      getAccessGroupList();
      getBuildingIotList(result);
      fetchCctvDeviceList(String(result.id));
      // 등록된 기기의 제어목록 호출
      // 건물 > 등록된 iot기기 목록 호출
    }
  };

  // 건물에 등록된 iot 목록 가져오기 >
  const getBuildingIotList = async (buildingDetail: BuildingModel) => {
    const { data: deviceData } = await getBuildingIotDevice({
      buildingId: String(buildingDetail?.id),
      sort: { orders: [{ direction: "ASC", property: "internalDisplayName" }] },
    });
    if (deviceData.data) {
      if (deviceData.data.content.length === 0) {
        setUpdateTime("");
      }
      const devicesResult = deviceData.data.content;

      const monitoringFormat: ExecuteResultType[] = devicesResult.map((device) => ({
        buildingDeviceId: device.id,
        // control: null,
        location: device.building.location,
        labels: device.labels,
        platform: device.platformDevice.platformAccount.platform,
        labelType: null,
        internalDisplayName: device.internalDisplayName || "",
        result: null,
        isLoading: true,
      }));

      // 층 / 공용공간 별로 디바이스 나누기
      const floorAndFacilityDevices: ExecuteResultType[] = groupDeviceLocation(monitoringFormat);

      handleSetMonitoringData(floorAndFacilityDevices);

      // // 등록된 기기의 제어목록 호출
      // await getAccessGroupList();
      // 등록된 기기 제어목록 호출하기
      await getControlDevicesAndExecute(floorAndFacilityDevices);
    }
  };

  // 인터벌 시작 및 재시작 로직
  const startInterval = (buildingId?: number) => {
    let id = queryParams.buildingId ? Number(queryParams.buildingId) : buildingId || Number(building?.id);
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(() => {
      getBuildingDetail(id);
    }, 60000);
  };

  // 새로고침 버튼 클릭 시 호출
  const handleRefresh = async () => {
    if (building?.id) {
      await getBuildingDetail(building.id);
      startInterval(building.id); // 인터벌 재시작
    }
  };

  const spaceSorting = (list: CommonFacilityModel[]) => {
    return list.sort((a, b) => {
      if (a?.facilityName && b?.facilityName) {
        if (a!.facilityName > b!.facilityName) return 1;
        if (b!.facilityName > a!.facilityName) return -1;
      }
      return 0;
    });
  };

  // 층 / 공용공간 별로 디바이스 나눠서 그룹화하기
  const groupDeviceLocation = (passDevices: ExecuteResultType[]) => {
    //재실

    const floorSensor = passDevices
      .filter(
        (item) =>
          item.labels?.some((label) => label.name === "용도" && label.value === DeviceType.SENSOR) &&
          item.location.category !== BuildingLocation.FACILITY,
      )
      .map((item) => ({ ...item, labelType: DeviceType.SENSOR }));

    const facilitySensor = passDevices
      .filter(
        (item) =>
          item.labels?.some((label) => label.name === "용도" && label.value === DeviceType.SENSOR) &&
          item.location.category === BuildingLocation.FACILITY,
      )
      .map((item) => ({ ...item, labelType: DeviceType.SENSOR }));

    //실내기
    const floorIndoorUnit = passDevices
      .filter(
        (item) =>
          item.labels?.some((label) => label.name === "용도" && label.value === "공조") && item.location.category !== BuildingLocation.FACILITY,
      )
      .map((item) => ({ ...item, labelType: DeviceType.INDOOR_UNIT }));

    //실내기
    const facilityIndoorUnit = passDevices
      .filter(
        (item) =>
          item.labels?.some((label) => label.name === "용도" && label.value === "공조") && item.location.category === BuildingLocation.FACILITY,
      )
      .map((item) => ({ ...item, labelType: DeviceType.INDOOR_UNIT }));

    //온도
    const floorTemperature = passDevices
      .filter(
        (item) =>
          item.labels?.some((label) => label.name === "용도" && label.value === DeviceType.TEMPERATURE) &&
          item.location.category !== BuildingLocation.FACILITY,
      )
      .map((item) => ({ ...item, labelType: DeviceType.TEMPERATURE }));

    const facilityTemperature = passDevices
      .filter(
        (item) =>
          item.labels?.some((label) => label.name === "용도" && label.value === DeviceType.TEMPERATURE) &&
          item.location.category === BuildingLocation.FACILITY,
      )
      .map((item) => ({ ...item, labelType: DeviceType.TEMPERATURE }));
    //조명

    const floorLight = passDevices
      .filter(
        (item) =>
          item.labels?.some((label) => label.name === "용도" && label.value === DeviceType.LIGHT) &&
          item.location.category !== BuildingLocation.FACILITY,
      )
      .map((item) => ({ ...item, labelType: DeviceType.LIGHT }));

    const facilityLight = passDevices
      .filter(
        (item) =>
          item.labels?.some((label) => label.name === "용도" && label.value === DeviceType.LIGHT) &&
          item.location.category === BuildingLocation.FACILITY,
      )
      .map((item) => ({ ...item, labelType: DeviceType.LIGHT }));

    //남 화장실
    const floorMensToilet = passDevices
      .filter((item) => {
        return (
          item.labels?.some((label) => label.name === "용도" && label.value === "화장실") &&
          item.labels?.some((label) => label.name === "성별" && label.value === "남") &&
          item.location.category !== BuildingLocation.FACILITY
        );
      })
      .map((item) => ({ ...item, labelType: DeviceType.MENS_TOILET }));

    const facilityMensToilet = passDevices
      .filter((item) => {
        return (
          item.labels?.some((label) => label.name === "용도" && label.value === "화장실") &&
          item.labels?.some((label) => label.name === "성별" && label.value === "남") &&
          item.location.category === BuildingLocation.FACILITY
        );
      })
      .map((item) => ({ ...item, labelType: DeviceType.MENS_TOILET }));

    //여 화장실
    const floorWomensToilet = passDevices
      .filter((item) => {
        return (
          item.labels?.some((label) => label.name === "용도" && label.value === "화장실") &&
          item.labels?.some((label) => label.name === "성별" && label.value === "여") &&
          item.location.category !== BuildingLocation.FACILITY
        );
      })
      .map((item) => ({ ...item, labelType: DeviceType.WOMEN_TOILET }));

    const facilityWomensToilet = passDevices
      .filter((item) => {
        return (
          item.labels?.some((label) => label.name === "용도" && label.value === "화장실") &&
          item.labels?.some((label) => label.name === "성별" && label.value === "여") &&
          item.location.category === BuildingLocation.FACILITY
        );
      })
      .map((item) => ({ ...item, labelType: DeviceType.WOMEN_TOILET }));

    const returnData = [
      ...floorSensor,
      ...facilitySensor,
      ...floorIndoorUnit,
      ...facilityIndoorUnit,
      ...floorTemperature,
      ...facilityTemperature,
      ...floorLight,
      ...facilityLight,
      ...floorMensToilet,
      ...facilityMensToilet,
      ...floorWomensToilet,
      ...facilityWomensToilet,
    ];
    return returnData as ExecuteResultType[];
  };

  const filterDevices = (data: { passData: any; deviceType: UnionDeviceCategory }) => {
    const { passData, deviceType } = data;

    // 기기 타입이 iot 인 경우
    if (deviceType === DeviceCategory.IOT) {
      const iotData: IotPassData = passData;
      return iotData.devices.filter(
        (device: ExecuteResultType) =>
          //층 / 공용공간이면 위치 id 만 비교
          ((device.location.category === BuildingLocation.FLOOR || device.location.category === BuildingLocation.FACILITY) && //
            String(device.location.identifier) === String(iotData.locationId)) ||
          // 호실이면 호실 ids[] 에 포함 확인
          (device.location.category === BuildingLocation.ROOM && iotData.roomIds?.includes(String(device.location.identifier))),
      );
    }
    //기기 타입이 출입기기 경우
    else if (deviceType === DeviceCategory.ACCESS) {
      const accessData: AccessDevicePassData = passData;
      const locationCode = accessData.location === BuildingLocation.FLOOR ? `${building?.locationCode}.${accessData.value}` : accessData.value;
      const accessDevice = accessGroup?.filter((item) => item.deviceLabels?.some((label) => label.value === locationCode)) || [];
      return accessDevice;
    }
    //기기 타입이 CCTV 인 경
    else if (deviceType === DeviceCategory.CCTV) {
      const cctvData: CctvDevicePassData = passData;
      const cctvList = cctvData.devices?.filter(({ cctvDevicePartnerList }) =>
        cctvDevicePartnerList?.some((item) => String(item.buildingFloorId) === String(cctvData.locationId)),
      );
      return cctvList;
    }
  };

  //======================== 층 정보 ========================
  const floorList = useMemo(() => {
    // 층/호
    const _floorList =
      building?.buildingFloorList
        ?.sort((a, b) => Number(b?.floorNum) - Number(a?.floorNum))
        .map((floor) => {
          const roomIds = floor.buildingRoomList?.map((item) => String(item.id)) || [];
          const floorValue = String(floor.floorNum).includes("-") ? `${floor.floorNum?.toString().replace("-", "B")}` : `${floor.floorNum}F`;
          const floorId = String(floor.id);
          return {
            floorId,
            roomIds,
            floorNum: Number(floor.floorNum),
            floorValue,
            floorDevices: {
              access: filterDevices({
                deviceType: DeviceCategory.ACCESS,
                passData: { location: BuildingLocation.FLOOR, value: floorValue },
              }) as Ac2AccessDeviceModel[],
              sensor: filterDevices({
                deviceType: DeviceCategory.IOT,
                passData: { devices: monitoringDevices.floor.sensor, locationId: floorId, roomIds },
              }) as ExecuteResultType[],
              indoorUnit: filterDevices({
                deviceType: DeviceCategory.IOT,
                passData: { devices: monitoringDevices.floor.indoorUnit, locationId: floorId, roomIds },
              }) as ExecuteResultType[],
              temperature: filterDevices({
                deviceType: DeviceCategory.IOT,
                passData: { devices: monitoringDevices.floor.temperature, locationId: floorId, roomIds },
              }) as ExecuteResultType[],
              light: filterDevices({
                deviceType: DeviceCategory.IOT,
                passData: { devices: monitoringDevices.floor.light, locationId: floorId, roomIds },
              }) as ExecuteResultType[],
              mensToilet: filterDevices({
                deviceType: DeviceCategory.IOT,
                passData: { devices: monitoringDevices.floor.mensToilet, locationId: floorId, roomIds },
              }) as ExecuteResultType[],
              womensToilet: filterDevices({
                deviceType: DeviceCategory.IOT,
                passData: { devices: monitoringDevices.floor.womensToilet, locationId: floorId, roomIds },
              }) as ExecuteResultType[],
              cctv: filterDevices({
                deviceType: DeviceCategory.CCTV,
                passData: { devices: cctvDevices, locationId: floorId },
              }) as CctvDeviceType[],
            },
          };
        }) || [];

    return _floorList;
  }, [monitoringDevices]);

  //
  //
  //========================건물 공용공간 데이터 ========================
  const commomFacility = useMemo(() => {
    //건물 회의실 목록
    let meetingRooms = building?.buildingCommonFacility?.meetingRoomList || [];
    meetingRooms = spaceSorting(meetingRooms);

    //건물 좌석 목록
    let deskList = building?.buildingCommonFacility?.deskSpace?.deskGroupList?.flatMap((item) => item.deskList) || [];
    if (deskList.length > 0) {
      deskList = spaceSorting(deskList as CommonFacilityModel[]);
    }

    //건물 편의시설 목록
    let refreshRooms = building?.buildingCommonFacility?.refreshRoomList || [];
    refreshRooms = spaceSorting(refreshRooms);

    //
    // 건물 공용공간 통합 목록
    const _commomFacility = [...meetingRooms, ...deskList, ...refreshRooms].map((facility) => {
      const floorValue = facility?.isGround ? `${facility?.floorNum}F` : `B${facility?.floorNum}` || "";

      const facilityId = String(facility?.id);

      return {
        id: String(facility?.id || ""),
        facilityName: facility?.facilityName || "",
        floorValue,
        facilityDevices: {
          access: filterDevices({
            deviceType: DeviceCategory.ACCESS,
            passData: { location: BuildingLocation.FACILITY, value: facility?.locationCode },
          }) as Ac2AccessDeviceModel[],
          sensor: filterDevices({
            deviceType: DeviceCategory.IOT,
            passData: { devices: monitoringDevices.facility.sensor, locationId: facilityId },
          }) as ExecuteResultType[],
          indoorUnit: filterDevices({
            deviceType: DeviceCategory.IOT,
            passData: { devices: monitoringDevices.facility.indoorUnit, locationId: facilityId },
          }) as ExecuteResultType[],
          temperature: filterDevices({
            deviceType: DeviceCategory.IOT,
            passData: { devices: monitoringDevices.facility.temperature, locationId: facilityId },
          }) as ExecuteResultType[],
          light: filterDevices({
            deviceType: DeviceCategory.IOT,
            passData: { devices: monitoringDevices.facility.light, locationId: facilityId },
          }) as ExecuteResultType[],
          mensToilet: filterDevices({
            deviceType: DeviceCategory.IOT,
            passData: { devices: monitoringDevices.facility.mensToilet, locationId: facilityId },
          }) as ExecuteResultType[],
          womensToilet: filterDevices({
            deviceType: DeviceCategory.IOT,
            passData: { devices: monitoringDevices.facility.womensToilet, locationId: facilityId },
          }) as ExecuteResultType[],
        },
      };
    });
    return _commomFacility;
  }, [monitoringDevices]);
  //

  //======================== 최종 모니터링 데이터 ========================
  const iotTable = useMemo(() => {
    let result: MonitoringType[] = floorList
      .filter((floor) => {
        return true;
      })
      .map((floor) => {
        let filteredFacility = commomFacility.filter((facility) => facility.floorValue === floor.floorValue);

        return {
          ...floor,
          facilityList: filteredFacility,
        };
      });

    if (hasDevices) {
      result = result
        .map((floor) => {
          // 층에 디바이스가 있는지 체크
          const hasFloorDevices = Object.values(floor.floorDevices).some((device) => device.length > 0);

          // 시설들 중에서 디바이스가 있는 시설만 필터링
          const facilitiesWithDevices = floor.facilityList.filter((facility) =>
            Object.values(facility.facilityDevices).some((device) => device.length > 0),
          );

          // 층에 디바이스가 있고, 시설에는 디바이스가 없는 경우
          if (hasFloorDevices && facilitiesWithDevices.length === 0) {
            return {
              ...floor,
              facilityList: [], // 시설 목록을 비움
            };
          }

          // 층에 디바이스가 없고, 시설에만 디바이스가 있는 경우
          if (!hasFloorDevices && facilitiesWithDevices.length > 0) {
            return {
              ...floor,
              facilityList: facilitiesWithDevices, // 디바이스가 있는 시설만 포함
            };
          }

          // 둘 다 디바이스가 있는 경우
          if (hasFloorDevices && facilitiesWithDevices.length > 0) {
            return {
              ...floor,
              facilityList: facilitiesWithDevices,
            };
          }

          return null; // 둘 다 디바이스가 없는 경우
        })
        .filter(Boolean) as MonitoringType[]; // null 값 제거
    }

    return result;
  }, [commomFacility, floorList, hasDevices]);

  // 제어실행 결과값 parse 여부 확인
  const isValidJSONString = (value: string) => {
    try {
      JSON.parse(value);
      return true;
    } catch (e) {
      return false;
    }
  };

  //제어 디바이스 labels 에 따라 실행처리 적용
  const isPassExecute = (item: DeviceControlType) => {
    //재실 실행
    const isSensorExecute = item.labelType === DeviceType.SENSOR && item.labels?.some((label) => label.name === "용도" && label.value === "동작감지");

    //실내기 실행
    let isIndoorUnitExecute = false;

    if (item.labelType === DeviceType.INDOOR_UNIT) {
      if (item.platform === "ST") {
        isIndoorUnitExecute =
          item.labels?.some((label) => label.name === "용도" && label.value === "전원") || // 활성화 // 상태
          item.labels?.some((label) => label.name === "용도" && label.value === "공조/모드") || // 모드
          item.labels?.some((label) => label.name === "용도" && label.value === "공조/희망온도") || // 희망온도
          item.labels?.some((label) => label.name === "용도" && label.value === "측정온도") || // 측정온도
          false;
      } else {
        // console.log("item", item);
        isIndoorUnitExecute =
          item.labels?.some((label) => label.name === "용도" && label.value === "공조/상태") ||
          item.labels?.some((label) => label.name === "용도" && label.value === "공조") ||
          item.labels?.some((label) => label.name === "용도" && label.value === "습도") || // 모드
          item.labels?.some((label) => label.name === "용도" && label.value === "측정온도") || // 측정온도
          item.labels?.some((label) => label.name === "용도" && label.value === "공조/환기") || // 측정온도
          item.labels?.some((label) => label.name === "용도" && label.value === "공조/희망온도") || // 측정온도
          item.labels?.some((label) => label.name === "용도" && label.value === "CO2") || // 측정온도
          false;
      }
    }
    //  온도
    let isTemperatureExecute = false;
    if (item.labelType === DeviceType.TEMPERATURE) {
      if (item.platform === "ST") {
        isTemperatureExecute = item.labels?.some((label) => label.name === "용도" && label.value === "측정온도") || false;
      } else {
        isTemperatureExecute = item.labels?.some((label) => label.name === "용도" && label.value === "공조/상태") || false;
      }
    }

    // 조명
    if (item.labelType === DeviceType.LIGHT) {
    }
    const isLightExecute =
      (item.labelType === DeviceType.LIGHT && item.labels?.some((label) => label.name === "용도" && label.value === "전원")) ||
      item.labels?.some((label) => label.name === "용도" && label.value === "전원/레벨") ||
      item.labels?.some((label) => label.name === "용도" && label.value === "조명/상태");

    //화장실
    const isToiltetExecute =
      ((item.labelType === DeviceType.MENS_TOILET || item.labelType === DeviceType.WOMEN_TOILET) &&
        item.labels?.some((label) => label.name === "용도" && label.value === "접촉감지")) ||
      item.labels?.some((label) => label.name === "용도" && label.value === "동작감지");

    return isSensorExecute || isIndoorUnitExecute || isTemperatureExecute || isLightExecute || isToiltetExecute;
  };

  // 모니터링 테이블에 보여지는 데이터 구조로 변경
  const handleSetMonitoringData = (iotList: ExecuteResultType[]) => {
    const monitoringTableData: IotMonitoringDevice = {
      floor: {
        sensor: iotList.filter((item) => item.location.category !== BuildingLocation.FACILITY && item.labelType === DeviceType.SENSOR),
        indoorUnit: iotList.filter((item) => item.location.category !== BuildingLocation.FACILITY && item.labelType === DeviceType.INDOOR_UNIT),
        temperature: iotList.filter((item) => item.location.category !== BuildingLocation.FACILITY && item.labelType === DeviceType.TEMPERATURE),
        light: iotList.filter((item) => item.location.category !== BuildingLocation.FACILITY && item.labelType === DeviceType.LIGHT),
        mensToilet: iotList.filter((item) => item.location.category !== BuildingLocation.FACILITY && item.labelType === DeviceType.MENS_TOILET),
        womensToilet: iotList.filter((item) => item.location.category !== BuildingLocation.FACILITY && item.labelType === DeviceType.WOMEN_TOILET),
      },
      facility: {
        sensor: iotList.filter((item) => item.location.category === BuildingLocation.FACILITY && item.labelType === DeviceType.SENSOR),
        indoorUnit: iotList.filter((item) => item.location.category === BuildingLocation.FACILITY && item.labelType === DeviceType.INDOOR_UNIT),
        temperature: iotList.filter((item) => item.location.category === BuildingLocation.FACILITY && item.labelType === DeviceType.TEMPERATURE),
        light: iotList.filter((item) => item.location.category === BuildingLocation.FACILITY && item.labelType === DeviceType.LIGHT),
        mensToilet: iotList.filter((item) => item.location.category === BuildingLocation.FACILITY && item.labelType === DeviceType.MENS_TOILET),
        womensToilet: iotList.filter((item) => item.location.category === BuildingLocation.FACILITY && item.labelType === DeviceType.WOMEN_TOILET),
      },
    };

    setMonitoringDevices(monitoringTableData);
  };

  //

  // 메인 배열 > size 별로 나눈 배열 데이터 ex) [[...],[...],[...]]

  const chunkControlDevices = (controls: any[]) => {
    const divideSize = 10; // 배열 총 갯수 > 몇 개로 나눌 건지

    const result = [];
    for (let i = 0; i < controls.length; i += divideSize) {
      result.push(controls.slice(i, i + divideSize));
    }
    return result;
  };

  //iot 기기 제어 실행 호출
  const executeControlDevice = async (controls: DeviceControlType[], convertedControlDevices: ExecuteResultType[]) => {
    const execute = async (item: DeviceControlType) => {
      if (isPassExecute(item)) {
        try {
          const { data, status } = await deviceControlExcute({
            deviceType: "building",
            deviceId: item.buildingDeviceId,
            controlId: item.control.id,
            controlMethod: item.control.method,
            arguments: "",
            cacheable: true,
          });

          if (status >= 200 && status <= 299) {
            let parsedResult = isValidJSONString(data.data.result) ? JSON.parse(data.data.result) : data.data.result;

            // 실내기 + B_IOT 조건 확인
            const isIndoorUnit = item.labelType === "실내기" && item.platform === "B_IOT";

            if (isIndoorUnit && parsedResult.controlPoint) {
              // 실내기 + B_IOT + controlPoint가 있는 경우에만 수정
              parsedResult = {
                ...parsedResult,
                controlPoint: {
                  ...parsedResult.controlPoint,
                  label: item.labels?.[0]?.value || "",
                },
              };
            }
            // else 부분 제거 - 아무 처리도 하지 않음

            const executeResult = {
              ...item,
              result: parsedResult,
            };

            return executeResult;
          }
        } catch (error) {
          console.error(`Failed to execute control for device ${item.buildingDeviceId}:`, error);
        }
      }
    };

    try {
      // 디바이스 제어(controls) 목록 나누기

      // 메인 배열 > size 별로 나눈 배열 데이터 ex) [[...],[...],[...]]

      const executedList = controls.map(execute);
      const allPromiseResults = await Promise.all(executedList);

      const mapMerge = new Map();
      allPromiseResults.forEach((item) => {
        const key = `${item?.buildingDeviceId}-${item?.labelType}`;
        if (!mapMerge.has(key)) {
          mapMerge.set(key, { ...item, result: [item?.result] });
        } else {
          const existingItem = mapMerge.get(key);
          existingItem.result.push(item?.result);
        }
      });
      const controlsExecute: ExecuteResultType[] = Array.from(mapMerge.values());
      const iotList = convertedControlDevices.map((device) => {
        controlsExecute.forEach((control) => {
          if (control.buildingDeviceId === device.buildingDeviceId && control.labelType === device.labelType) {
            if (device.labelType === DeviceType.INDOOR_UNIT && device.platform === "B_IOT") {
              const hasControlPoint = control.result.find((item: any) => item.controlPoint);
              device.result = hasControlPoint ? control.result : Object.assign({}, ...control.result);
            } else {
              device.result = Object.assign({}, ...control.result);
            }
            device.isLoading = false;
          }
        });
        return { ...device };
      });
      handleSetMonitoringData(iotList);

      const date = moment().format(YmdFormat.YYYY_MM_DD_HH_MM);
      setUpdateTime(date);
    } catch (error) {
      console.error("Error during device execution:", error);
    }
  };

  // iot 기기의 제어목록 호출
  const handleDeviceControlList = async (item: ExecuteResultType) => {
    try {
      const { data, status } = await getIotDeviceControl({ id: item.buildingDeviceId, deviceType: "building" });
      if (status >= 200 && status <= 299) {
        const result = data.data.controls.map((control) => ({
          ...control,
          labelType: item.labelType,
          location: item.location,
          platform: item.platform,
        }));

        return result;
      }
    } catch (error) {
      console.error(`실패:Failed to execute control for device ${item.buildingDeviceId}:`, error);
    }
  };

  //등록된 디바이스 제어목록 + 실행 호출
  const getControlDevicesAndExecute = async (convertedControlDevices: ExecuteResultType[]) => {
    try {
      // const promise = [];

      const controlDevices = chunkControlDevices(convertedControlDevices);

      for (const devices of controlDevices) {
        // 제어 목록 api 호출 결과 > 배열 데이터
        const promise = await Promise.all(devices.map(handleDeviceControlList));
        const executeList = promise
          .filter((result) => result !== undefined)
          .flat()
          .filter((item) => item?.control.method === "READ_STATUS");
        await executeControlDevice(executeList as DeviceControlType[], convertedControlDevices);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div className="page-monitoring" style={{ width: 980, maxWidth: 980 }}>
      <div className="contents-container__table">
        <div className="contents-container__search-wrap">
          <div className="left-area">
            <div className="flex-center">
              <BuildingSelect building={building || undefined} buildingOptions={buildingOptions} getBuildingDetail={getBuildingDetail} />
              <BaseCheckbox
                className="mb8"
                id={"monitoringData"}
                name={"monitoringData"}
                label="데이터 있는 공간만 보기"
                onChange={setHasDevices}
                checked={hasDevices}
              />
            </div>
          </div>
        </div>
        <div className="px30">
          <MonitoringTable
            monitoring={iotTable as MonitoringType[]}
            handleRefresh={handleRefresh}
            buildingId={Number(building?.id)}
            date={updateTime}
          />
        </div>
      </div>
    </div>
  );
};

export default SpaceMonitoring;
