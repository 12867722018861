import { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { BaseButton, ContentsTitle } from "src/components";
import GoToListButton from "src/components/GoToListButton";
import MetaTag from "src/components/layout/MetaTag";
import { useQueryParams } from "src/hooks/useQueryParams";

import InvitationInfo from "../components/InvitationInfo";

import { ServiceTypes } from "src/api/adminMemo/adminmemo-types";
import { useApiOperation } from "src/api/hooks";
import { deleteVisitorGroupAsync } from "src/api/visitor/visitor-api";
import { VisitorFormData } from "src/api/visitor/visitor-types";
import AdminMemoV3 from "src/components/AdminMemoV3/AdminMemoV3";
import RegistrationInformation from "src/components/RegistrationInformation";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import { useModal } from "src/recoil/modalState/hook";
import { useToast } from "src/recoil/toast/hook";
import PermissionInfo from "../components/PermissionInfo";
import VisitInfo from "../components/VisitInfo";
import VisitorEditAccessGroup from "../components/VisitorEditAccessGroup";
import VisitorInfo from "../components/VisitorInfo";
import VisitorOnlyViewAccessGroup from "../components/VisitorOnlyViewAccessGroup";
import useGetPermissionAcGroup from "../hooks/useGetPermissionAcGroup";
import useVisitor from "../hooks/useVisitor";
import { PagePath } from "src/pages/product/details";
import useNavigate from "src/hooks/usePartnerNavigate";

const tabs = [
  {
    label: "상세정보",
    value: "basic",
    disabled: false,
  },
];

function VisitorDetail() {
  const { contractApplyNumber, visitApplyNumber } = useParams();
  const { queryParams } = useQueryParams<{ tab?: string; reservationId?: string }>();

  const navigate = useNavigate();

  const { isAuthority } = usePartnerAuthority();
  const { openToast } = useToast();
  const { setBaseModal } = useModal();

  const { executeAsync: deleteVisitorGroup } = useApiOperation(deleteVisitorGroupAsync);

  // 현재 활성화 되어야하는 tab
  const activeTab = tabs.find((tab) => tab.value === queryParams?.tab) ?? tabs[0];

  const { buildingList, contractManage, visitorGroup } = useVisitor({
    contractApplyNumber: contractApplyNumber,
    visitApplyNumber: visitApplyNumber,
  });

  // 계약 관리 출입 설정 상세 조회(커스텀 권한 조회)
  // visitorDefaultAccessGroupYn 값이 false 일 때 커스텀 권한으로 커스텀 출입그룹 등록가능
  // visitorDefaultAccessGroupYn 값이 true 일 때 기본 권한으로 신청계약 출입그룹을 보여주기만함 (수정불가)
  const { visitorDefaultAccessGroupYn } = useGetPermissionAcGroup(Number(contractManage?.contractManageId));

  const useFormReturn = useForm<VisitorFormData>();
  const { control, setValue, reset } = useFormReturn;

  const {
    fields: formVisitorInfoList,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "visitorInfoList",
  });

  useEffect(() => {
    if (visitorGroup) {
      const { contractVisitorList, ...invitationInfo } = visitorGroup;

      reset({
        //빌딩id만 스트링 타입으로 변경
        invitationInfo: { ...invitationInfo, buildingId: invitationInfo.buildingId?.toString() },
        visitorInfoList: visitorGroup?.contractVisitorList,
      });
    }
  }, [visitorGroup, contractManage, buildingList, reset]);

  useEffect(() => {
    if (buildingList?.find((building) => building.isPrimary)?.buildingId) {
      //대표건물을 찾아서 id set
      const buildingId = String(buildingList?.find((building) => building.isPrimary)?.buildingId);
      setValue("invitationInfo.buildingId", buildingId);
    }
  }, [buildingList, contractManage, setValue]);

  return (
    <div>
      <MetaTag />
      <ContentsTitle title="방문자 초대" tabs={tabs} activeTab={activeTab} />
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <form>
            <InvitationInfo useFormReturn={useFormReturn} pageType="detail" />
            <VisitInfo useFormReturn={useFormReturn} pageType="detail" />
            <VisitorInfo
              pageType="detail"
              append={append}
              remove={remove}
              control={control}
              formVisitorInfoList={formVisitorInfoList}
              useFormReturn={useFormReturn}
            />
            <PermissionInfo useFormReturn={useFormReturn} pageType="detail" />
            {/* 커스텀 권한이 있고 대기 상태일때만 편집 가능함 */}
            {visitorDefaultAccessGroupYn === false && <VisitorEditAccessGroup />}

            {visitorDefaultAccessGroupYn === true && <VisitorOnlyViewAccessGroup pageType="detail" />}
          </form>
        </div>

        <RegistrationInformation data={visitorGroup} />

        <AdminMemoV3 serviceId={Number(visitApplyNumber)} serviceType={ServiceTypes.SERVICE_INVITED_VISITOR_GROUP} />
      </div>

      <div className="contents-container__btn-wrap">
        <div className="left-area d-flex">
          <GoToListButton />
          {isAuthority("d") && visitorGroup?.visitStatus !== "VISIT_DELETE" && (
            <BaseButton
              title="삭제"
              className="no-outline color-white size-large text-red900 ml10"
              onClick={() =>
                setBaseModal({
                  isOpen: true,
                  title: "삭제하시겠습니까?",
                  onClick: async () => {
                    const response = await deleteVisitorGroup({ visitApplyNumber: visitApplyNumber! });
                    if (response.status >= 200 && response.status <= 299) {
                      openToast({
                        content: `정상적으로 삭제 되었습니다.`,
                      });
                      navigate(PagePath.visitor.list);
                    }

                    setBaseModal({
                      isOpen: false,
                    });
                  },
                  onClose: () => {
                    setBaseModal({
                      isOpen: false,
                    });
                  },
                  btnRightTitle: "확인",
                  btnLeftTitle: "취소",
                })
              }
            />
          )}
        </div>
      </div>
    </div>
  );
}
export default VisitorDetail;
