import React from "react";

const VisitStatusTag = ({ value }: { value: string }) => {
  return (
    <div
      className={`${
        (value === "VISIT_USE" && "status B") ||
        (value === "VISIT_PENDING" && "status A") ||
        (value === "VISIT_CLOSE" && "status D") ||
        (value === "VISIT_DELETE" && "status D")
      }`}
    >
      {(value === "VISIT_USE" && "사용") ||
        (value === "VISIT_PENDING" && "대기") ||
        (value === "VISIT_CLOSE" && "종료") ||
        (value === "VISIT_DELETE" && "삭제")}
    </div>
  );
};

export default VisitStatusTag;
