import React from "react";

const ApprovalStatusTag = ({ value }: { value: string }) => {
  return (
    <div
      className={`${
        (value === "APPROVAL_AUTO" && "status F") ||
        (value === "APPROVAL_PENDING" && "status H") ||
        (value === "APPROVAL_COMPLETE" && "status F") ||
        (value === "APPROVAL_REJECT" && "status G")
      }`}
    >
      {(value === "APPROVAL_AUTO" && "자동승인") ||
        (value === "APPROVAL_PENDING" && "승인대기") ||
        (value === "APPROVAL_COMPLETE" && "승인완료") ||
        (value === "APPROVAL_REJECT" && "반려")}
    </div>
  );
};

export default ApprovalStatusTag;
