import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ServiceTypes } from "src/api/adminMemo/adminmemo-types";
import { CommonFacilityModel } from "src/api/building/building-types";
import { getCommonfacilityAsync } from "src/api/building/commonfacility-api";
import { useApiOperation } from "src/api/hooks";
import { BaseButton, BaseModal } from "src/components";
import AdminMemoV3 from "src/components/AdminMemoV3/AdminMemoV3";
import BaseNewTabLink from "src/components/BaseNewTabLink";
import GoToListButton from "src/components/GoToListButton";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";
import RegistrationInformation from "src/components/RegistrationInformation";
import useNavigate from "src/hooks/usePartnerNavigate";
import PagePath from "src/pagePath.json";
import { useErrorModal } from "src/recoil/errorModal/hook";
import { ViewYmdFormat } from "src/utils";
import { ExternalServiceType, Modal, ReservationState } from "../../reservation-types";
import { ReservationType } from "../ReservationDetail";
import { DeskSection, MeetingRoomSection, RefreshRoomSection, ReservationHistorySection } from "./components";
import VisitorSection from "./components/VisitorSection";

/* 
  공용공간 예약 > 상세 > 기본 정보
  feature :: 탭내 상태변경을 사용하기때문에 상위 ReservationDetail 에서 공용공간상세 호출.
 */

type Props = {
  reservation?: ReservationType;
};

const BasicInfoDetail = ({ reservation }: Props) => {
  const navigate = useNavigate();
  const { id: reservationId } = useParams();
  const { openErrorModal } = useErrorModal();
  // 건물 공용공간 상세 조회 api
  const { executeAsync: getCommonfacility } = useApiOperation(getCommonfacilityAsync, { noHandleError: true });

  // 건물 공용공간 상세
  const [commonFacility, setCommonFacility] = useState<CommonFacilityModel | null>(null);

  // 확인버튼만 있는 alert 모달
  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
  });

  // 수정버튼 노출 > 상태가 접수/점유, 채널이 ctrl.room 일 경우
  const isEditable = useMemo(() => {
    return (
      (reservation?.status.code === ReservationState.ACCEPTED || reservation?.status.code === ReservationState.ACKNOWLEDGED) &&
      reservation?.inboundChannel === ExternalServiceType.CTRL_ROOM
    );
  }, [reservation]);

  // 공용공간 예약 상세 후 데이터 바인딩
  const fetchReservation = useCallback(async () => {
    if (reservation) {
      if (!reservation.facility.building.id) {
        throw Error("reservation.facility.building.id is not found.");
      }
      if (!reservation.facility.id) {
        throw Error("reservation.facility.id is not found.");
      }
      const response = await getCommonfacility({
        buildingId: String(reservation.facility.building.id),
        facilityId: String(reservation.facility.id),
      });

      if (response.status >= 200 && response.status <= 299) {
        const facilityData = response.data?.data?.content;
        if (facilityData) setCommonFacility(facilityData);
      } else {
        let errorCode = response.data.meta?.errorCode;
        let statusCode = response.status;
        if (errorCode === "ePR0514") {
          // 없는 회의실 모달 비오픈
          return;
        } else {
          openErrorModal({ errorCode, statusCode });
        }
      }
    }
  }, [getCommonfacility, reservation]);

  useEffect(() => {
    fetchReservation();
  }, [fetchReservation]);

  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <article className="contents-container__wrap-article">
            {/* 기본정보 */}
            <BaseSectionTitle title={"기본정보"} />

            {reservation?.status.code === ReservationState.CANCELLED && (
              <>
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p className="">취소일시</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="minmax400">
                      <p>{reservation?.lastModifiedDate ? moment(reservation?.lastModifiedDate).format(ViewYmdFormat.FULL) : "-"} </p>
                    </div>
                  </div>
                </section>
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p className="">최종수정자</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="minmax400">
                      <p>{reservation?.lastModifiedBy.name || "-"}</p>
                    </div>
                  </div>
                </section>
              </>
            )}

            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">신청/계약명</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400 flex-row flex-center-start">
                  <BaseNewTabLink
                    path={`${PagePath.contract.detail.replace(":id", String(reservation?.contract.id))}`}
                    value={reservation?.contractName || "-"}
                    className="w-100 text-left"
                  />
                </div>
              </div>
            </section>
          </article>

          {/* 예약 내역 */}
          <ReservationHistorySection reservation={reservation} />

          {/* 방문자 */}
          {reservation?.facility.type === "MEETING_ROOM" && <VisitorSection reservation={reservation} commonFacility={commonFacility} />}

          {/* 회의실 */}
          {reservation?.facility.type === "MEETING_ROOM" && <MeetingRoomSection reservation={reservation} commonFacility={commonFacility} />}

          {/* 좌석 */}
          {reservation?.facility.type === "DESK" && <DeskSection commonFacility={commonFacility} />}

          {/* 편의시설 */}
          {reservation?.facility.type === "REFRESH_ROOM" && <RefreshRoomSection commonFacility={commonFacility} />}
          <RegistrationInformation
            data={{
              createdBy: reservation?.createdBy.name,
              modifiedBy: reservation?.lastModifiedBy.name,
              createdDate: reservation?.createdDate,
              modifiedDate: reservation?.lastModifiedDate,
            }}
          />

          <AdminMemoV3 serviceId={Number(reservationId)} serviceType={ServiceTypes.SERVICE_RESERVATION} />

          {/* 확인버튼만 있는 alert 모달 */}
          <BaseModal
            title={alertModal.message}
            isOpen={alertModal.isOpen}
            btnRightTitle="확인"
            onClick={() => setAlertModal({ isOpen: false })}
          ></BaseModal>
        </div>
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area d-flex">
          <GoToListButton />
        </div>
        <div className="right-area ">
          {isEditable && (
            <BaseButton title="수정" className="size-large" onClick={() => navigate(PagePath.reservation.form + `?id=${reservationId}`)} />
          )}
        </div>
      </div>
    </>
  );
};

export default BasicInfoDetail;
