import { useParams } from "react-router-dom";
import { ContentsTitle } from "src/components";
import MetaTag from "src/components/layout/MetaTag";
import useActiceTab from "src/hooks/useActiceTab";
import { accessDevicesTabs } from "../const";
import BasicInfoDetail from "./BasicInfoDetail";
import { getAccessDeviceDetailAsync } from "src/api/access/productac-api";
import { useApiOperation } from "src/api/hooks";
import { useState, useEffect } from "react";
import { Ac2AccessDeviceModel } from "src/api/access/productac-types";

const AccessDeviceDetail = () => {
  const [accessDevice, setAccessDevice] = useState<Ac2AccessDeviceModel>();

  const { id: accessDeviceId } = useParams();
  const { executeAsync: getAccessDevice } = useApiOperation(getAccessDeviceDetailAsync);
  const { activeTab } = useActiceTab(accessDevicesTabs);

  useEffect(() => {
    (async () => {
      const { data } = await getAccessDevice({ id: accessDeviceId || "" });
      setAccessDevice(data.data.accessGroup);
    })();
  }, [accessDeviceId]);

  return (
    <div>
      <MetaTag subTitle={accessDeviceId} />
      <ContentsTitle
        title="출입장치 관리"
        tabs={accessDevicesTabs}
        activeTab={activeTab}
        detailInfo={[{ title: "id", value: accessDeviceId || "" }]}
      />
      {/* 기본정보 */}
      {activeTab?.value === "basicInfo" && accessDevice && <BasicInfoDetail accessDevice={accessDevice} />}
    </div>
  );
};

export default AccessDeviceDetail;
