import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { getBuildingsAsync } from "src/api/building/building-api";
import { BuildingModel, CommonFacilityListModel } from "src/api/building/building-types";
import { useApiOperation } from "src/api/hooks";
import { BaseSelect } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import { useI18n } from "src/hooks/useI18n";
import { sortByASC } from "src/utils";
type Props = {
  data: any;
  onClose: () => void;
  selectedBuildings: (id: number) => void;
  selectedFloor: (floor: number) => void;
  selectedFacilityType: (type: string) => void;
};
const ReservationSettingModal = ({ data, onClose, selectedBuildings, selectedFloor, selectedFacilityType }: Props) => {
  const [buildings, setBuildings] = useState<BuildingModel[]>([]);
  const { executeAsync: getBuildings } = useApiOperation(getBuildingsAsync, { noLoading: true, noHandleError: true });
  const { t } = useI18n();
  const location = useLocation();
  const fetchBuildings = useCallback(async () => {
    const { data, status } = await getBuildings({ page: 0, size: 999 });
    if (status >= 200 && status < 300) {
      setBuildings(data.data.content);
    }
  }, [getBuildings]);

  const queryParams: any = useMemo(() => {
    const _queryParams: any = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      allowDots: true,
      decoder: (c) => c,
    });
    return {
      ..._queryParams,
    };
  }, [location]);

  useEffect(() => {
    fetchBuildings();
  }, []);

  const getBuildingOptions = buildings.map((building) => {
    return { value: building.id, label: building.buildingName };
  });
  const buildingOptions = [{ value: 0, label: t("reservation.display.setting.all") }, ...getBuildingOptions];

  const spaceTypeOptions = [
    { value: "", label: t("reservation.display.setting.all") },
    { value: "MEETING_ROOM", label: t("reservation.display.setting.room") },
    { value: "DESK", label: t("reservation.display.setting.desk") },
    { value: "REFRESH_ROOM", label: t("reservation.display.setting.amenity") },
  ];

  const generateFloorArray = () => {
    const findBuilding = buildings.find((building) => building.id === queryParams.buildingIdList);

    if (findBuilding) {
      const addAbsoluteFloorFacility = data
        .filter((facility: CommonFacilityListModel) => facility.buildingId === findBuilding?.id)
        .map((facility: CommonFacilityListModel) => {
          const floorNum = typeof facility.floorNum === "string" ? parseInt(facility.floorNum, 10) : facility.floorNum || 0; // null/undefined일 경우 0으로 처리
          if (facility.isGround) {
            return { ...facility, absoluteFloor: floorNum };
          } else {
            return { ...facility, absoluteFloor: -floorNum };
          }
        });

      // const floors = [...new Set(addAbsoluteFloorFacility)];
      const sortedFloor = new Set(
        sortByASC(addAbsoluteFloorFacility, "absoluteFloor")
          ?.map((facility) => facility.absoluteFloor)
          .filter((floor) => !!floor),
      );

      const getFloorOptions = [...sortedFloor].map((floor) => {
        return { value: floor, label: floor > 0 ? `${floor}F` : `B${Math.abs(floor)}` };
      });

      return [{ value: "", label: t("reservation.display.setting.all") }, ...getFloorOptions];
    } else return [{ value: "", label: t("reservation.display.setting.all") }];
  };

  return (
    <BaseAbstractModal
      containerClassName="display-reservation-setting-modal-container"
      opacity={0}
      isOpen={true}
      className="display-reservation-setting-modal bottom-to-top"
    >
      <h2>{t("reservation.display.setting.title")}</h2>
      <div className="select-container">
        <div>
          <p>{t("reservation.display.setting.building")}</p>
          <BaseSelect
            className="minmax320 display-select"
            menuPlacement="top"
            stateOptions={buildingOptions}
            value={queryParams.buildingIdList}
            setStateValue={(value: any) => {
              selectedBuildings(value);
            }}
            placeholder={t("reservation.display.setting.all")}
          />
        </div>
        <div>
          <p>{t("reservation.display.setting.floor")}</p>
          <BaseSelect
            className="minmax160 display-select"
            menuPlacement="top"
            stateOptions={generateFloorArray() || []}
            value={queryParams.floor}
            setStateValue={(value: any) => {
              selectedFloor(value);
            }}
            isDisabled={!queryParams.buildingIdList}
            placeholder={t("reservation.display.setting.all")}
          />
        </div>
        <div>
          <p>{t("reservation.display.setting.type")}</p>
          <BaseSelect
            className="minmax240 display-select"
            menuPlacement="top"
            stateOptions={spaceTypeOptions}
            value={queryParams.commonFacilityTypeList}
            setStateValue={(value: string) => {
              selectedFacilityType(value);
            }}
            placeholder={t("reservation.display.setting.all")}
          />
        </div>
      </div>
      <button className="close-btn" onClick={onClose}></button>
    </BaseAbstractModal>
  );
};

export default ReservationSettingModal;
