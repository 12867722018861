import React, { useEffect, useMemo, useState } from "react";
import { Control, FieldArrayWithId, UseFieldArrayAppend, UseFieldArrayRemove, UseFormReturn } from "react-hook-form";
import { PageType, PreDiscountModal, VisitorFormData } from "src/api/visitor/visitor-types";

import { BaseButton } from "src/components";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import SpaceBaseTable from "src/pages/product/forms/commonSpaceForm/components/SpaceBaseTable";
import { visitorAddColumns, visitorDetailColumns } from "./columns";
import { useApiOperation } from "src/api/hooks";
import { getVisitorParkingPreDiscountListAsync } from "src/api/visitor/visitor-api";
import useVisitor from "../hooks/useVisitor";
import { useParams } from "react-router-dom";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";

// 방문자 정보 컴포넌트
const VisitorInfo = ({
  pageType,
  control,
  formVisitorInfoList,
  append,
  remove,
  useFormReturn,
}: {
  pageType: PageType;
  control: Control<VisitorFormData>;
  formVisitorInfoList: FieldArrayWithId<VisitorFormData, "visitorInfoList", "id">[];
  append: UseFieldArrayAppend<VisitorFormData, "visitorInfoList">;
  remove: UseFieldArrayRemove;
  useFormReturn: UseFormReturn<VisitorFormData>;
}) => {
  const { isAuthority } = usePartnerAuthority();
  const { visitApplyNumber, contractApplyNumber } = useParams();
  const { contractId } = useVisitor({ visitApplyNumber, contractApplyNumber });

  const {
    formState: { errors },
  } = useFormReturn;

  const [preDiscountList, setPreDiscountList] = useState<PreDiscountModal[]>();
  const handleClickAddButton = () => {
    append({ contractVisitorId: 0, invitationType: "phone" });
  };

  const newVisitorColumns = useMemo(() => {
    if (pageType === "add") return visitorAddColumns;

    if (isAuthority("d")) return visitorDetailColumns;

    if (!isAuthority("d")) return visitorDetailColumns.filter((column) => column.accessor !== "delete");

    return [];
  }, [pageType, isAuthority]);

  const { executeAsync: getVisitorParkingPreDiscountList } = useApiOperation(getVisitorParkingPreDiscountListAsync);

  useEffect(() => {
    const fetchApi = async () => {
      if (formVisitorInfoList && formVisitorInfoList[0]?.preDiscountId && contractId) {
        if (String(formVisitorInfoList[0].preDiscountId) === "0") return;

        const preDiscountIds = formVisitorInfoList.map((data) => data.preDiscountId).join(",");

        const getVisitorParkingPreDiscountListData = await getVisitorParkingPreDiscountList({
          contractId: String(contractId),
          preDiscountIds: preDiscountIds,
        }).then((data) => data?.data?.data?.preDiscount);

        setPreDiscountList(getVisitorParkingPreDiscountListData);
      }
    };

    fetchApi();
  }, [formVisitorInfoList, contractId, getVisitorParkingPreDiscountList]);

  return (
    <article className="contents-container__wrap-article">
      <BaseSectionTitle
        title={"방문자 정보"}
        noHashLink={pageType === "add"}
        rightArea={isAuthority("w") && pageType === "add" && <BaseButton title="방문자 추가" onClick={handleClickAddButton} />}
      />

      {formVisitorInfoList && (
        <div>
          <SpaceBaseTable
            data={formVisitorInfoList || []}
            columns={newVisitorColumns}
            useFormReturn={useFormReturn}
            control={control}
            pageType={pageType}
            remove={remove}
            preDiscountList={preDiscountList}
          />
          {errors.visitorInfoList && <p className="validation-text">이름, 방문자 연락처 및 이메일은 필수입력 항목입니다.</p>}
        </div>
      )}
    </article>
  );
};

export default VisitorInfo;
