export const localesKo = {
  "lnb.sideBar.body.main.link": "대시보드",
  "lnb.sideBar.body.monitoring.link": "공간 모니터링",
  "lnb.sideBar.body.building.link": "건물",
  "lnb.sideBar.body.product.link": "상품",
  "lnb.sideBar.body.workorder.link": "워크오더",
  "lnb.sideBar.body.cs.link": "CS",
  "lnb.sideBar.body.contract.link": "신청/계약",
  "lnb.sideBar.body.provider.link": "정산정보",
  "lnb.sideBar.body.reservation.link": "공용공간 예약",
  "lnb.sideBar.body.user.link": "이용자 초대",
  "lnb.sideBar.body.visitor.link": "방문자 초대",
  "lnb.sideBar.body.notice.link": "공지사항",
  "lnb.sideBar.body.org.link": "사업자",
  "lnb.sideBar.body.billing.link": "빌링 스케줄",
  "lnb.sideBar.body.option.link": "설정",
  "lnb.sideBar.body.guide.link": "이용안내",
  "lnb.sideBar.body.voc.link": "VoC 항목",
  "lnb.sideBar.body.price.link": "공용공간 요금 정책",
  "lnb.sideBar.body.cctv.link": "CCTV 장치",
  "lnb.sideBar.body.login": "마지막 로그인",
  "lnb.sideBar.body.accessDevice.link": "출입장치 관리",
  "lnb.sideBar.body.accessGroup.link": "출입그룹 관리",
  "lnb.sideBar.body.reservationConfig.link": "예약정보 관리",

  // 공간 예약 현황
  "reservation.display.body.title": "공간 예약 현황",
  "reservation.display.toast.title": "회사마다 공간 운영 정책이 다르므로, 예약 가능 시간은 Taap앱에서 확인해주세요.",
  "reservation.display.body.private": "비공개",
  "reservation.display.body.attendeeInfo.private": "주최자 비공개 참석자 비공개",
  "reservation.display.detail.private": "비공개",
  "reservation.display.setting.building": "건물",
  "reservation.display.setting.floor": "층",
  "reservation.display.setting.type": "공간유형",
  "reservation.display.setting.room": "회의실",
  "reservation.display.setting.desk": "좌석",
  "reservation.display.setting.amenity": "편의시설",
  "reservation.display.setting.all": "전체",
  "reservation.display.setting.title": "조회하실 조건을 선택해 주세요",
};
