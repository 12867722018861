import { useCallback, useEffect, useMemo, useState } from "react";
import { useBlockLayout, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { getAccessDevicesAsync } from "src/api/access/productac-api";
import { Ac2AccessDeviceModel, AccessPageQueryParams } from "src/api/access/productac-types";
import { useApiOperation } from "src/api/hooks";
import { PageMeta } from "src/api/public-types";
import { BaseButton, BaseCheckbox, BaseInput, BasePagination } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import { AccessDeviceColumns } from "src/pages/accessDevices/columns/AccessDeviceColumns";
import { modalSelectItem, modalSelectedOptions } from "src/pages/product/hooks/atom";

type Props = {
  setShowModal: Function;
  onSelectedAccessGroups: Function;
  devices: Ac2AccessDeviceModel[];
};

const queryParams = {
  page: 0,
  size: 20,
  sort: {
    orders: [
      {
        property: "id",
        direction: "DESC",
      },
    ],
  },
};

const AcDevicesModal = ({ setShowModal, onSelectedAccessGroups, devices }: Props) => {
  // 액세스그룹
  const [accessDevices, setAccessDevices] = useState<Ac2AccessDeviceModel[]>([]);

  const selectedOptions = useRecoilValue(modalSelectedOptions);

  const setAccessSelect = useSetRecoilState(modalSelectItem);

  const [pageMeta, setPageMeta] = useState<PageMeta>();

  const [params, setParams] = useState<any>(queryParams);

  const columns: any = useMemo(
    () => AccessDeviceColumns.map((column) => ({ ...column, width: column.accessor === "deviceName" ? 400 : column.width })),
    [],
  );

  const data = useMemo(
    () =>
      accessDevices.map((item) => {
        const selects = selectedOptions.map((select: any) => Number(select.id));

        return {
          checked: selects.includes(Number(item.id)),
          ...item,
        };
      }),
    [accessDevices, selectedOptions],
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns: columns,
      data: data,
    },
    useBlockLayout,
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns: Array<any>) => [
        {
          width: 100,
          Header: "선택",
          accessor: "checked",
          Cell: ({ row }: any) => {
            let newRow = { ...row, disabled: false };
            devices.forEach((item) => {
              if (Number(item.id) === Number(row.original.id)) newRow.disabled = true;
            });
            return (
              <div>
                <BaseCheckbox
                  id={`${row.original.id}`}
                  name={`${row.original.id}`}
                  disabled={newRow.disabled}
                  checked={row.original.checked}
                  onChange={(checked) => {
                    row.original.checked = checked;
                    if (checked) {
                      setAccessSelect((prev: any) => [...prev, row.original]);
                    } else {
                      setAccessSelect((prev: any) => prev.filter((item: any) => item.id !== row.original.id));
                    }
                  }}
                />
              </div>
            );
          },
        },
        ...columns,
      ]);
    },
  );

  const { executeAsync: getAccessDevices } = useApiOperation(getAccessDevicesAsync);

  const getAccessDevicesList = useCallback(
    async (passParams?: AccessPageQueryParams) => {
      const { data } = await getAccessDevices(passParams);
      setAccessDevices(data?.data.content || null);
      setPageMeta(data?.meta.pageMeta);
    },
    [getAccessDevices],
  );

  useEffect(() => {
    getAccessDevicesList(queryParams);
  }, [getAccessDevicesList]);

  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);

  return (
    <div>
      <BaseAbstractModal className="dialog-modal " isOpen={true}>
        {/*table */}
        <section className="base-abstract-modal__title flex-center-between ">
          <h1>출입장치 추가</h1>
          <div className="right-area">
            <div className="minmax260">
              <BaseInput
                name="keyword"
                type="text"
                placeholder="검색어를 입력해주세요"
                value={params.searchValue}
                onChange={(value: string) => {
                  // onChangeKeyword(value);
                  setParams({ ...params, searchValue: value });
                }}
                onKeyUp={() => {
                  getAccessDevicesList({ ...params, page: 0 });
                }}
                onSearchClick={() => {
                  getAccessDevicesList({ ...params, page: 0 });
                }}
              />
            </div>
          </div>
        </section>
        <section className="base-abstract-modal__contents py0">
          <div className="contents-container__table px30 ">
            <div {...getTableProps()} className="base-table view-data-table sticky">
              <div className="header">
                {headerGroups.map((headerGroup) => (
                  <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                    {headerGroup.headers.map((header) => {
                      return (
                        <div {...header.getHeaderProps()} className="base-table__th">
                          {header.render("Header")}
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
              <div {...getTableBodyProps()} className="body">
                {rows.length > 0 ? (
                  rows.map((row, idx: number) => {
                    prepareRow(row);
                    return (
                      <div {...row.getRowProps()} className="base-table__tr">
                        {row.cells.map((cell) => {
                          return (
                            <div {...cell.getCellProps()} className="base-table__td">
                              {cell.render("Cell")}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })
                ) : (
                  <div className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
                    <div className="base-table__td w-100 text-center">
                      <div className="w-100">
                        <span>데이터가 없습니다.</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        <section className="px30">
          <BasePagination
            sizeOption={(size) => {
              setParams({ ...params, ...{ page: 0, size } });
              getAccessDevicesList({ ...params, page: 0, size });
            }}
            currentSize={Number(params.size) || 20}
            pageIndex={pageMeta?.pageRequest.page || 0}
            totalElements={pageMeta?.totalElements}
            totalPages={pageMeta?.totalPages || 0}
            goPage={(page: number) => {
              setParams({ ...params, ...{ page } });
              getAccessDevicesList({ ...params, page });
            }}
            children={
              <>
                <BaseButton
                  title="취소"
                  onClick={() => {
                    setShowModal(false);
                    setAccessSelect([]);
                  }}
                  className="color-white mr10"
                />
                <BaseButton
                  title="선택"
                  onClick={() => {
                    setShowModal(false);
                    onSelectedAccessGroups(selectedOptions);
                    // onSelectedAccessGroups(selectedAccessGroups);
                    setAccessSelect([]);
                  }}
                />
              </>
            }
          />
        </section>
      </BaseAbstractModal>
    </div>
  );
};

export default AcDevicesModal;
