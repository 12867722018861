import { useMemo, useState } from "react";
import { Control, Controller, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { Cell } from "react-table";
import { Ac2AccessGroupModel, Ac2AccessGroupRequest } from "src/api/access/ac-group-types";
import { Ac2AccessDeviceModel } from "src/api/access/productac-types";
import { Modal } from "src/api/public-types";
import { BaseButton, BaseTooltip } from "src/components";
import InnerTable from "src/components/InnerTable";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";
import { useModal } from "src/recoil/modalState/hook";
import AcDevicesModal from "./AcDevicesModal";
import { UnionPartnerPrivilegeCode } from "src/types/partner";
import { UnionPartnerPermission } from "src/types/partner";

type Props = {
  accessGroup?: Ac2AccessGroupModel;
  control?: Control<Ac2AccessGroupRequest>;
  watch?: UseFormWatch<Ac2AccessGroupRequest>;
  setValue?: UseFormSetValue<Ac2AccessGroupRequest>;
  editAcGroupHandler?: (request: Ac2AccessGroupRequest) => Promise<void>;
  isAuthority: (permission: UnionPartnerPermission, code?: UnionPartnerPrivilegeCode) => boolean;
};

const AcDeviceSection = ({ accessGroup, control, isAuthority, setValue, editAcGroupHandler }: Props) => {
  const [openAcDevicesModal, setOpenAcDevicesModal] = useState<Modal>({ isOpen: false });
  const [selectedDevices, setSelectedDevices] = useState<Ac2AccessDeviceModel[]>([]);
  const { setBaseModal } = useModal();
  //  출입그룹 관리 > 장치추가 모달 테이블 컬럼
  const DeviceColumnsForAccessGroup = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        width: 80,
        sticky: "left",
        Cell: ({ value }: Cell<Ac2AccessDeviceModel>) => {
          return <span>{value}</span>;
        },
      },

      {
        Header: "장치ID",
        accessor: "externalDeviceId",
        width: 360,
        sticky: "left",
        Cell: ({ value }: Cell<Ac2AccessDeviceModel>) => {
          return <BaseTooltip contents={value || "-"} isSingleLine className="text-start w-100" />;
        },
      },
      {
        Header: "장치명",
        accessor: "deviceName",
        width: 550,
        Cell: ({ value }: Cell<Ac2AccessDeviceModel>) => {
          return <BaseTooltip contents={value || "-"} isSingleLine className="text-start w-100" />;
        },
      },
      {
        Header: "삭제",
        width: 80,
        Cell: ({ row }: Cell<Ac2AccessDeviceModel>) => {
          return (
            <button
              className="base-trash-btn color-gray"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (!accessGroup) {
                  const filteredDevices = selectedDevices.filter((device) => device.id !== row.original.id);
                  setSelectedDevices(filteredDevices);
                  setValue?.("deviceAddIds", filteredDevices.map((device) => Number(device.id)) || []);
                } else {
                  console.log("hi");
                  setBaseModal({
                    isOpen: true,
                    title: "삭제하시겠습니까?",
                    onClick: () => {
                      editAcGroupHandler?.({ deviceDeleteIds: [Number(row.original.id)] });
                      setBaseModal({ isOpen: false });
                    },
                    onClose: () => setBaseModal({ isOpen: false }),
                    btnLeftTitle: "취소",
                    btnRightTitle: "확인",
                  });
                }
              }}
            ></button>
          );
        },
      },
    ],
    [accessGroup, selectedDevices],
  );
  return (
    <article className="contents-container__wrap-article">
      <div className="flex-center-between">
        <BaseSectionTitle title={"출입장치"} />
        {isAuthority?.("w") && <BaseButton title="장치 추가" className="color-white mb10" onClick={() => setOpenAcDevicesModal({ isOpen: true })} />}
      </div>

      <div className="contents-container__grid-contents">
        <InnerTable
          columns={DeviceColumnsForAccessGroup.filter((column) => (isAuthority?.("d") ? column : column.Header !== "삭제"))}
          data={accessGroup?.acDevices || selectedDevices}
        />
      </div>

      {openAcDevicesModal.isOpen && (
        <>
          {control ? (
            <Controller
              control={control}
              name="deviceAddIds"
              render={({ field: { onChange, value } }) => (
                <AcDevicesModal
                  setShowModal={setOpenAcDevicesModal}
                  onSelectedAccessGroups={(selected: Ac2AccessDeviceModel[]) => {
                    setSelectedDevices(selected);
                    onChange(selected.map((device) => device.id));
                  }}
                  devices={accessGroup?.acDevices || []}
                />
              )}
            />
          ) : (
            <AcDevicesModal
              setShowModal={setOpenAcDevicesModal}
              onSelectedAccessGroups={(selected: Ac2AccessDeviceModel[]) => {
                setSelectedDevices(selected);
                editAcGroupHandler?.({ deviceAddIds: selected.map((device) => Number(device.id)) || [] });
              }}
              devices={accessGroup?.acDevices || []}
            />
          )}
        </>
      )}
    </article>
  );
};

export default AcDeviceSection;
