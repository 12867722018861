import { Cell } from "react-table";
import { Ac2AccessGroupModel } from "src/api/access/ac-group-types";
import { visitorSettings } from "src/pages/accessGroup/const";

export const SelectProductAccessGroupColumn = [
  {
    Header: "출입그룹 id",
    accessor: "id",
    width: 80,
  },
  {
    Header: "출입그룹명",
    accessor: "accessGroupName",
    width: 380,
    Cell: ({ value }: Cell<Ac2AccessGroupModel>) => <div className="w-100 text-left">{value}</div>,
  },
  {
    Header: "이용자 출입",
    accessor: "memberAccessYn",
    width: 80,
    Cell: ({ value }: Cell<Ac2AccessGroupModel>) => {
      return <span>{value ? "출입가능" : "출입불가"}</span>;
    },
  },
  {
    Header: "방문자 초대",
    accessor: "visitorAccessYn",
    width: 80,
    Cell: ({ value }: Cell<Ac2AccessGroupModel>) => {
      return <span>{value ? "초대가능" : "초대불가"}</span>;
    },
  },
  {
    Header: "Taap 노출",
    accessor: "visitorAccessGroupSelectableYn",
    width: 80,
    Cell: ({ value }: Cell<Ac2AccessGroupModel>) => {
      return (
        <span>
          {visitorSettings.find((item) => item.name === "visitorAccessGroupSelectableYn")?.options.find((option) => option.value === value)?.label ||
            "-"}
        </span>
      );
    },
  },
  {
    Header: "관리자 승인",
    accessor: "visitorInvitationApprovalYn",
    width: 80,
    Cell: ({ value }: Cell<Ac2AccessGroupModel>) => {
      return (
        <span>
          {visitorSettings.find((item) => item.name === "visitorInvitationApprovalYn")?.options.find((option) => option.value === value)?.label ||
            "-"}
        </span>
      );
    },
  },
  {
    Header: "QR 발급",
    accessor: "visitorAccessType",
    width: 80,
    Cell: ({ value }: Cell<Ac2AccessGroupModel>) => {
      return (
        <span>
          {visitorSettings.find((item) => item.name === "visitorAccessType")?.options.find((option) => option.value === value)?.label || "-"}
        </span>
      );
    },
  },
  // {
  //   Header: "방문자 허용",
  //   accessor: "visitorAccessYn",
  //   width: 160,
  //   Cell: ({ value }: Cell<Ac2AccessDeviceModel>) => <div>{value ? "허용" : "미허용"}</div>,
  // },
];
