import { AxiosInstance, AxiosResponse } from "axios";
import { ApiResponse } from "../public-types";
import {
  Ac2AccessDeviceResponse,
  AccessPageQueryParams,
  ProductAccessUpdateData,
  ProductAccessResponseData,
  ProductAccessGroupModel,
  Ac2AccessDeviceModel,
} from "./productac-types";
import { PagePath } from "src/pages/product/details";
import { ApiPath } from "..";
import qs from "qs";
import { Ac2AccessGroupModel } from "./ac-group-types";

// 공간상품 출입그룹 상세 목록
export async function getProductAccessGroupAsync(
  axios: AxiosInstance,
  params?: {
    productId: number;
  },
): Promise<AxiosResponse<ApiResponse<ProductAccessResponseData>>> {
  if (!params?.productId) {
    throw Error("Please check productId");
  }
  return await axios.get(`/api/ctrl/partners/{partnerId}/pr/products/${params.productId}/productAccessGroup`, { params });
}

// 출입그룹 등록
export async function postProductAccessAsync(
  axios: AxiosInstance,
  params?: ProductAccessUpdateData,
): Promise<AxiosResponse<ApiResponse<{ content: ProductAccessGroupModel }>>> {
  return axios.post(`/api/ctrl/partners/{partnerId}/pr/products/${params?.productId}/productAccessGroup`, { accessGroupId: params?.accessGroupId });
}

// RAD21. 출입그룹 페이지 조회 (출입그룹 추가 팝업)
export async function getAccessGroupListAsync(
  axios: AxiosInstance,
  params?: AccessPageQueryParams,
  // ): Promise<AxiosResponse<ApiResponse<Ac2AccessDeviceResponse>>> {
): Promise<AxiosResponse<ApiResponse<{ content: Ac2AccessGroupModel[] }>>> {
  return axios.get(ApiPath.accessGroup.list, {
    params,
    paramsSerializer: {
      serialize: (params) => {
        return qs.stringify(params, { allowDots: true, encode: true });
      },
    },
  });
}

// RPP21. 공간상품 출입그룹 삭제

export async function deleteProductAccessAsync(
  axios: AxiosInstance,
  params?: {
    ids: string[];
  },
) {
  return axios.delete(`/api/ctrl/partners/{partnerId}/pr/products/productAccessGroup`, { data: { ids: params?.ids } });
}

// RAD11. 디바이스 목록 조회
export async function getAccessDevicesAsync(
  axios: AxiosInstance,
  params?: {
    ids?: string;
    accessGroupId?: string;
    includeDeviceStatusInfo?: boolean;
    cacheable?: boolean;
  },
): Promise<AxiosResponse<ApiResponse<Ac2AccessDeviceResponse>>> {
  return axios.get(`/api/ctrl/partners/{partnerId}/ac2/devices`, {
    params,
    paramsSerializer: {
      serialize: (params) => {
        return qs.stringify(params, { allowDots: true, encode: true });
      },
    },
  });
}

// RAD12. 디바이스 상세 조회
export async function getAccessDeviceDetailAsync(
  axios: AxiosInstance,
  params?: {
    id: string;
  },
): Promise<AxiosResponse<ApiResponse<{ accessGroup: Ac2AccessDeviceModel }>>> {
  return axios.get(ApiPath.accessDevice.detail.replace("{id}", String(params?.id)));
}
