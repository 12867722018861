import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { getReservationUsagesAsync } from "src/api/reservation/reservation-api";
import { ReservationAttendee, ReservationListModel, ReservationUsageResponse } from "src/api/reservation/reservation-types";
import BaseNewTabLink from "src/components/BaseNewTabLink";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";
import PhoneNumberColumn from "src/components/PhoneNumberColumn";
import PagePath from "src/pagePath.json";
import { getReservationStatusDetail, ReservationAttendeeResponseStatus, reservationInboundChannel } from "src/pages/reservation/reservation-types";

type Props = {
  reservation?: ReservationListModel | null;
};

const ReservationHistorySection = ({ reservation }: Props) => {
  const [usage, setUsage] = useState<ReservationUsageResponse>();
  const { executeAsync: getReservationUsages } = useApiOperation(getReservationUsagesAsync);
  const { id } = useParams();

  // 예약 경로 표시 텍스트 찾기
  const getInboundChannelText = useCallback(() => {
    const channel = reservationInboundChannel.find((channel) => channel.value === reservation?.inboundChannel);
    return channel?.label || "알 수 없음";
  }, [reservation?.inboundChannel]);

  // 층수 표시 텍스트
  const getFloorText = useCallback(() => {
    const floorNum = reservation?.facility.floor;
    if (!floorNum) return "-";

    const isGround = floorNum > 0 ? "지상" : "지하";
    return `${isGround} ${Math.abs(floorNum)}층`;
  }, [reservation?.facility.floor]);

  // 공간 타입 표시 텍스트
  const getSpaceTypeText = useCallback(() => {
    const typeMap = {
      MEETING_ROOM: "회의실",
      DESK: "좌석",
      REFRESH_ROOM: "편의시설",
    };
    return typeMap[reservation?.facility.type as keyof typeof typeMap] || "-";
  }, [reservation?.facility.type]);

  useEffect(() => {
    const fetchUsage = async (id: string) => {
      const { data } = await getReservationUsages({ id });
      if (data.data) {
        setUsage(data.data);
      }
    };
    if (id && reservation) {
      fetchUsage(id);
    }
  }, [getReservationUsages, id, reservation]);

  //전용/공용 인지 확인
  const checkIsOver24H = () => {
    const start = reservation?.start;
    const end = reservation?.end;
    const diffInHours = moment(end).diff(moment(start), "hours", true);

    const isOver24h = diffInHours >= 24;
    return isOver24h ? "전용" : "공용";
  };

  return (
    <article className="contents-container__wrap-article">
      <BaseSectionTitle title="예약 내역" />

      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>예약 유형</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span className="font14">{checkIsOver24H()}</span>
          </div>
        </div>
      </section>

      {/* 예약 경로 */}
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>예약경로</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span className="font14">{getInboundChannelText()}</span>
          </div>
        </div>
      </section>

      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>예약명</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span className="font14">{reservation?.summary || "-"}</span>
          </div>
        </div>
      </section>

      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>예약 내용</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span className="font14">{reservation?.description || "-"}</span>
          </div>
        </div>
      </section>

      <div className="flex-center">
        {/* 건물명 */}
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>건물명</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <span className="font14">
                {reservation?.facility.building.name ? (
                  <div className="no-wrap">
                    <BaseNewTabLink
                      path={`${PagePath.building.detail.replace(":id", String(reservation.facility.building.id))}?tab=facility`}
                      value={reservation.facility.building.name}
                      className="w-100 text-left"
                    />
                  </div>
                ) : (
                  "-"
                )}
              </span>
            </div>
          </div>
        </section>

        {/* 위치 */}
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>위치</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <p className="font14">{getFloorText()}</p>
            </div>
          </div>
        </section>
      </div>

      {/* 예약시간 */}
      <div className="flex-center">
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>예약 시작일시</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <span className="font14">{moment(reservation?.start).format("YYYY.MM.DD a hh:mm")}</span>
            </div>
          </div>
        </section>

        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>예약 종료일시</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <span className="font14">{moment(reservation?.end).format("YYYY.MM.DD a hh:mm")}</span>
            </div>
          </div>
        </section>
      </div>

      {/* 소진시간 */}
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>소진시간</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="minmax400">
            <span className="font14">{usage && Number(usage.duration) >= 0 ? `${String(usage.duration)} 분` : "-"}</span>
          </div>
        </div>
      </section>

      <div className="flex-center">
        {/* 회원번호 */}
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>주최자 이메일</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <span className="font14">{reservation?.organizer.userEmail || "-"}</span>
            </div>
          </div>
        </section>

        {/* 닉네임 */}
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>주최자 닉네임</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <span className="font14">{reservation?.organizer.displayName || "-"}</span>
            </div>
          </div>
        </section>
      </div>

      <div className="flex-center">
        {/* 휴대폰번호 */}
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>주최자 휴대폰번호</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <span className="font14">
                {reservation?.organizer.phoneNumber ? PhoneNumberColumn({ phoneNumber: reservation.organizer.phoneNumber }) : "-"}
              </span>
            </div>
          </div>
        </section>

        {/* 회원번호 */}
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>주최자 회원번호</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400">
              <span className="font14">{reservation?.organizer.memberNo || "-"}</span>
            </div>
          </div>
        </section>
      </div>

      {/* 참석자 */}
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p className="">참석자</p>
        </div>
        <div className="contents-container__grid-contents">
          <div>
            <ul className="pb5">
              {reservation?.attendees.map((attendee: ReservationAttendee) => {
                let invitee = "";
                if (attendee.responseStatus === ReservationAttendeeResponseStatus.ACCEPTED) {
                  invitee = "수락";
                } else if (attendee.responseStatus === ReservationAttendeeResponseStatus.DECLINED) {
                  invitee = "거부";
                } else if (attendee.responseStatus === ReservationAttendeeResponseStatus.TENTATIVE) {
                  invitee = "임시적 수락";
                } else {
                  invitee = "응답없음";
                }
                return (
                  <li className="flex-center mt5" key={attendee.memberNo}>
                    <div className="font14 minmax200">
                      <span className="">{attendee.userEmail}</span>
                    </div>
                    <div className="font14 minmax170">
                      <span> {invitee}</span>
                    </div>
                  </li>
                );
              })}
            </ul>
            {reservation?.attendees.length === 0 && <p className="flex-center">-</p>}
          </div>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>점유상태</p>
        </div>
        <div className="contents-container__grid-contents">
          <p>
            {/* {reservation?.status?.code && reservation?.start && reservation?.end
              ? getStatusTextAndColor(reservation?.status.code, reservation?.start, reservation?.end).label
              : "-"} */}
            {reservation?.status ? getReservationStatusDetail(reservation?.status.code) : "-"}
          </p>
        </div>
      </section>
    </article>
  );
};

export default ReservationHistorySection;
