import moment from "moment";
import React from "react";
import { Cell } from "react-table";
import { Ac2AccessGroupModel } from "src/api/access/ac-group-types";
import { BaseTooltip } from "src/components";
import DetailLink from "src/components/DetailLink";
import { PagePath } from "src/pages/product/details";
import { YmdFormat } from "src/utils";
import { visitorSettings } from "../const";

const AccessGroupColumns = [
  {
    Header: "id",
    accessor: "id",
    width: 80,
    sticky: "left",
    Cell: ({ value }: Cell<Ac2AccessGroupModel>) => {
      const detailPath = `${PagePath.accessGroup.detail.replace(":id", value)}`;

      return (
        <DetailLink to={detailPath} className="text-underline">
          {value}
        </DetailLink>
      );
    },
  },
  {
    Header: "출입그룹명",
    accessor: "accessGroupName",
    width: 400,
    Cell: ({ value }: Cell<Ac2AccessGroupModel>) => {
      return <BaseTooltip className="text-start w-100" contents={value || "-"} isSingleLine={true} />;
    },
  },
  {
    Header: "장치 수",
    accessor: "deviceCount",
    width: 80,
    Cell: ({ row }: Cell<Ac2AccessGroupModel>) => {
      return <span>{row.original.deviceIds?.length || "-"}</span>;
    },
  },
  {
    Header: "이용자 출입",
    accessor: "memberAccessYn",
    width: 80,
    Cell: ({ value }: Cell<Ac2AccessGroupModel>) => {
      return <span>{value ? "출입가능" : "출입불가"}</span>;
    },
  },
  {
    Header: "방문자 초대",
    accessor: "visitorAccessYn",
    width: 80,
    Cell: ({ value }: Cell<Ac2AccessGroupModel>) => {
      return <span>{value ? "초대가능" : "초대불가"}</span>;
    },
  },
  {
    Header: "Taap 노출",
    accessor: "visitorAccessGroupSelectableYn",
    width: 80,
    Cell: ({ value }: Cell<Ac2AccessGroupModel>) => {
      return (
        <span>
          {visitorSettings.find((item) => item.name === "visitorAccessGroupSelectableYn")?.options.find((option) => option.value === value)?.label ||
            "-"}
        </span>
      );
    },
  },
  {
    Header: "관리자 승인",
    accessor: "visitorInvitationApprovalYn",
    width: 80,
    Cell: ({ value }: Cell<Ac2AccessGroupModel>) => {
      return (
        <span>
          {visitorSettings.find((item) => item.name === "visitorInvitationApprovalYn")?.options.find((option) => option.value === value)?.label ||
            "-"}
        </span>
      );
    },
  },
  {
    Header: "QR 발급",
    accessor: "visitorAccessType",
    width: 80,
    Cell: ({ value }: Cell<Ac2AccessGroupModel>) => {
      return (
        <span>
          {visitorSettings.find((item) => item.name === "visitorAccessType")?.options.find((option) => option.value === value)?.label || "-"}
        </span>
      );
    },
  },
  {
    Header: "최종 수정일",
    accessor: "lastModifiedDate",
    width: 120,
    Cell: ({ value }: Cell<Ac2AccessGroupModel>) => {
      return <BaseTooltip contents={moment(value).format(YmdFormat.YYYY_MM_DD_HH_MM)} type="date" />;
    },
  },
  {
    Header: "최종 수정자",
    accessor: "lastModifiedBy",
    width: 120,
    Cell: ({ value }: Cell<Ac2AccessGroupModel>) => <BaseTooltip contents={value || "-"} isSingleLine={true} />,
  },
];

export default AccessGroupColumns;
