export const columns: any = [
  {
    Header: "id",
    // sticky: "left",
    columns: [
      {
        Header: "",
        accessor: "itemId",
        width: 90,
      },
    ],
    Cell: ({ value }: any) => {
      return (
        <>
          {!value && <div className="ic_ho"></div>}
          {value && value}
        </>
      );
    },
    width: 60,
  },
  {
    Header: "항목",
    // sticky: "left",
    columns: [
      {
        Header: "",
        accessor: "itemName",
      },
    ],
    Cell: ({ value }: any) => {
      return (
        <>
          {!value && ""}
          {value && value}
        </>
      );
    },
    width: 80,
  },
  {
    Header: "고지서 표기명",
    columns: [
      {
        Header: "",
        accessor: "itemLabel",
        width: 120,
        Cell: ({ value }: any) => {
          return (
            <>
              <span className="ellipsis2">{value}</span>
            </>
          );
        },
      },
    ],
  },
  {
    Header: "부과기준",
    columns: [
      {
        Header: "부과방식",
        accessor: "level1",
        width: 80,
        Cell: ({ value }: any) => {
          return (
            <>
              <span className="ellipsis2">
                {value === "MNT_AREA" && "면적단가"}
                {value === "MNT_USAGE" && "사용량"}
                {value === "MNT_SEPARATE" && "별도금액"}
              </span>
            </>
          );
        },
      },
      {
        Header: "산정기준",
        accessor: "level2",
        width: 80,
        Cell: ({ row, value }: any) => {
          return (
            <>
              <span className="ellipsis2">
                {row.original.level1 === "MNT_AREA" && value === "CONTRACT" && "계약면적"}
                {row.original.level1 === "MNT_AREA" && value === "EXCLUSIVE" && "전용면적"}
                {row.original.level1 === "MNT_USAGE" && value === "ELECTRICITY" && "전기"}
                {row.original.level1 === "MNT_USAGE" && value === "WATER_SUPPLY" && "수도"}
                {row.original.level1 === "MNT_USAGE" && value === "HOT_WATER" && "급탕"}
                {row.original.level1 === "MNT_USAGE" && value === "HEATING" && "난방"}
                {row.original.level1 === "MNT_USAGE" && value === "GAS" && "가스"}
                {row.original.level1 === "MNT_USAGE" && value === "PURIFY_WATER" && "정수"}
                {row.original.level1 === "MNT_USAGE" && value === "WASTE_WATER" && "하수"}
                {row.original.level1 === "MNT_SEPARATE" && "-"}
              </span>
            </>
          );
        },
      },
    ],
  },
  {
    Header: "과세기준",
    columns: [
      {
        Header: "",
        accessor: "isApplyTax",
        width: 80,
        Cell: ({ row, value }: any) => {
          return (
            <>
              <span className="ellipsis2">
                {String(value) === "true" && "과세"}
                {String(value) === "false" && "면세"}
              </span>
            </>
          );
        },
      },
    ],
  },
  {
    Header: "연체료",
    columns: [
      {
        Header: "",
        accessor: "isApplyOverdue",
        width: 80,
        Cell: ({ row, value }: any) => {
          return (
            <>
              <span className="ellipsis2">
                {String(value) === "true" && "적용"}
                {String(value) === "false" && "미적용"}
              </span>
            </>
          );
        },
      },
    ],
  },
  {
    Header: "중간정산",
    columns: [
      {
        Header: "",
        accessor: "isApplyAdjust",
        width: 80,
        Cell: ({ row, value }: any) => {
          return (
            <>
              <span className="ellipsis2">
                {String(value) === "true" && "적용"}
                {String(value) === "false" && "미적용"}
              </span>
            </>
          );
        },
      },
    ],
  },
  {
    Header: "부과계산방법",
    columns: [
      {
        Header: "소숫점",
        accessor: "priceUnit",
        width: 100,
        Cell: ({ row, value }: any) => {
          return (
            <>
              <span className="ellipsis2">
                {value === "MNT_ORIGIN" && "원금 그대로"}
                {value === "MNT_THOUSAND" && "1000원 단위"}
                {value === "MNT_HUNDRED" && "100원 단위"}
                {value === "MNT_TEN" && "10원 단위"}
                {value === "MNT_ONE" && "1원 단위"}
              </span>
            </>
          );
        },
      },
      {
        Header: "반올림",
        accessor: "roundingMode",
        width: 80,
        Cell: ({ row, value }: any) => {
          return (
            <>
              <span className="ellipsis2">
                {row.original.priceUnit === "MNT_ORIGIN" && value === "UNNECESSARY" && "-"}
                {row.original.priceUnit !== "MNT_ORIGIN" && value === "UP" && "절상"}
                {row.original.priceUnit !== "MNT_ORIGIN" && value === "HALF_UP" && "반올림"}
                {row.original.priceUnit !== "MNT_ORIGIN" && value === "DOWN" && "절하"}
              </span>
            </>
          );
        },
      },
    ],
  },

  {
    Header: "사용여부",
    columns: [
      {
        Header: "",
        accessor: "isUse",
        width: 80,
        Cell: ({ row, value }: any) => {
          return (
            <>
              <span>{value ? "사용" : "미사용"}</span>
            </>
          );
        },
      },
    ],
  },
];
