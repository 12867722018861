import axios from "axios";
import { useCallback, useRef, useState } from "react";
import { Control, Controller } from "react-hook-form";
import { FilePolicy, MediaFile } from "src/api/file/file-types";
import { Modal } from "src/api/public-types";
import { WorkOrderDetail as WorkOrder } from "src/api/work-order/workorder-types";
import BaseConfirmModal from "src/components/BaseConfirmModal";
import { useErrorModal } from "src/recoil/errorModal/hook";
import ImagesFullModal from "./ImagesFullModal";
import { resizedImageUrl } from "src/utils";

/**
 * 워크오더 > 이미지 리스트
 */
type Props = {
  isReadonly?: boolean; // 이미지 추가가 있는지 여부
  isHideAddBtn?: boolean; // 이미지 추가 버튼 여부
  changedWorkSheet: WorkOrder;
  setChangedWorkSheet?: Function;
  setValue: Function;
  getValues: any;
  serviceType: string;
  control?: Control<WorkOrder>;
  policy: FilePolicy;
  maxFiles?: number;
};

const ImagesList = ({
  isReadonly,
  setChangedWorkSheet,
  changedWorkSheet,
  setValue,
  serviceType,
  control,
  getValues,
  isHideAddBtn,
  policy,
  maxFiles,
}: Props) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const maxFilesCount = maxFiles || 3;
  const [isModalOpen, setIsModalOpen] = useState<Modal>({ isOpen: false }); // 파일사이즈, 파일갯수체크시 사용하는 컨펌 모달

  const [isOpenImagesFullModal, setIsOpenImagesFullModal] = useState<boolean>(false);

  const { openErrorModal } = useErrorModal();
  const s3URL = process.env.REACT_APP_S3_BASEURL;
  const reCAPTCHAsiteKey = process.env.REACT_APP_RE_CAPTCHA_SITE_KEY;

  // 타입별 이미지 검색
  const findServiceTypeImg = useCallback((mediaList: Array<MediaFile>, mediaServiceType: string) => {
    const filteredMediaList = mediaList?.filter((media) => media.mediaServiceType === mediaServiceType);
    return filteredMediaList;
  }, []);

  // 파일 업로드
  const handleChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>, onChange: Function) => {
      if (e.target.files && changedWorkSheet?.mediaList && setChangedWorkSheet) {
        const filteredTypeImg = findServiceTypeImg(changedWorkSheet?.mediaList, serviceType);
        const sum = e.target.files["length"] + filteredTypeImg.length;
        const uploadImagesList = Array.from(e.target.files);

        const isFileSizeOver = uploadImagesList.some((imgfile) => {
          if (policy.maxFileSize) {
            return imgfile.size > policy?.maxFileSize;
          }
          return false;
        });

        if (sum > maxFilesCount) {
          setIsModalOpen({ isOpen: true, message: `파일은 최대 ${maxFilesCount}개 올릴 수 있습니다` });
        } else if (isFileSizeOver) {
          setIsModalOpen({ isOpen: true, message: "파일 사이즈를 초과했습니다" });
        } else {
          let onceUploadFiles = []; // 1회에 업로드한 파일들

          for (let i = 0; i < uploadImagesList.length; i++) {
            let imgFile = uploadImagesList[i];
            const token = await grecaptcha.enterprise.execute(reCAPTCHAsiteKey, { action: "ctrlroom/work_order/page_load" });
            const formData = new FormData();
            formData.append("imageFile", imgFile);

            try {
              const res = await axios.post(`/api/ctrl/fs/public/files`, formData, {
                baseURL: process.env.REACT_APP_API_BASEURL,
                headers: {
                  "X-Recaptcha-Token": token,
                  "Content-Type": "multipart/form-data",
                },
              });
              const tempUploadedImage = {
                ...res.data.data.media,
                ...{ mediaServiceType: serviceType },
              };
              onceUploadFiles.push(tempUploadedImage);
            } catch (_error: any) {
              console.log(_error);
              let errorCode = _error.errorCode;
              let errorMessage = _error.errorCode;
              let errorData = "";
              // 에러 모달
              openErrorModal({ errorCode, errorMessage, errorData });
            }

            const newArray: any = [...changedWorkSheet.mediaList, ...onceUploadFiles];
            setChangedWorkSheet({ ...changedWorkSheet, mediaList: newArray });
          }
          onChange(getValues("mediaList") ? [...getValues("mediaList"), ...onceUploadFiles] : [...onceUploadFiles]);
          // 파일 객체 초기화. (같은 파일 업로드시 작동되게)
          e.target.value = "";
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [policy, changedWorkSheet, findServiceTypeImg, getValues, serviceType, setChangedWorkSheet],
  );

  return (
    <div className="contents__img">
      {!isHideAddBtn && (
        <>
          <Controller
            name="mediaList"
            control={control}
            render={({ field: { onChange, name }, fieldState: { error } }) => (
              <>
                <input
                  type="file"
                  className="base-input-file"
                  name={serviceType}
                  disabled={isReadonly}
                  multiple
                  ref={fileRef}
                  onChange={(value) => handleChange(value, onChange)}
                  style={{ display: "none" }}
                  accept={policy?.allowContentTypes?.join() || "*"}
                />
                <div
                  className="contents__img-add"
                  data-max-size={maxFilesCount}
                  onClick={() => {
                    fileRef.current?.click();
                  }}
                >
                  <p>
                    {changedWorkSheet &&
                      changedWorkSheet.mediaList &&
                      `${findServiceTypeImg(changedWorkSheet?.mediaList, serviceType)?.length}/${maxFilesCount}`}
                  </p>
                </div>
              </>
            )}
          />
        </>
      )}
      {changedWorkSheet && changedWorkSheet.mediaList && setChangedWorkSheet
        ? findServiceTypeImg(changedWorkSheet?.mediaList, serviceType)?.map((image: MediaFile, idx: number) => {
            return (
              <div
                className="contents__img-wrap"
                key={idx}
                onClick={(e) => {
                  e.preventDefault();
                  setIsOpenImagesFullModal(true);
                }}
              >
                {!isReadonly && (
                  <button
                    className="img-remove-btn pa12"
                    type="button"
                    onClick={(e) => {
                      // changedWorkSheet의 이미지객체 제거
                      e.preventDefault();
                      e.stopPropagation();
                      const removedChanged = changedWorkSheet?.mediaList?.filter((item) => item.key !== image.key);
                      setChangedWorkSheet({ ...changedWorkSheet, mediaList: removedChanged });
                      // defaultValue mediaList에 id, cmdType D 추가
                      const previousList = getValues("mediaList");
                      if (image.id) {
                        const removedImage = {
                          id: image.id,
                          cmdType: "D",
                        };
                        setValue("mediaList", [...previousList, removedImage]);
                      } else {
                        const removedDefault = previousList.filter((img: MediaFile) => img.key !== image.key);
                        setValue("mediaList", [...removedDefault]);
                      }
                    }}
                  ></button>
                )}
                <img
                  className="contents__img-box"
                  onError={(e) => {
                    e.currentTarget.src = image.url ? image.url : s3URL + image.key!;
                  }}
                  src={resizedImageUrl(image.url ? image.url : s3URL + image.key!)}
                  alt={`워크시트 이미지`}
                ></img>
              </div>
            );
          })
        : null}
      {isModalOpen.isOpen && (
        <BaseConfirmModal
          isOpen={isModalOpen.isOpen}
          btnRightTitle="확인"
          btnRightType="button"
          onClick={() => {
            setIsModalOpen({ isOpen: false });
          }}
        >
          <p className="font18 font-weight-600">{isModalOpen.message || ""}</p>
        </BaseConfirmModal>
      )}
      {isOpenImagesFullModal && changedWorkSheet.mediaList && (
        <ImagesFullModal
          isOpen={isOpenImagesFullModal}
          setIsOpenImagesFullModal={setIsOpenImagesFullModal}
          imagesLIst={findServiceTypeImg(changedWorkSheet?.mediaList, serviceType)}
        />
      )}
    </div>
  );
};
export default ImagesList;
