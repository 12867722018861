import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { CommonFacilityModel, DeskGroupModel, DeskItemModel, PostDeskChartRequestBody } from "src/api/building/building-types";
import { getDesksAsync, postDeskChartAsync } from "src/api/building/deskgroup-api";
import { uploadFilesAsync } from "src/api/file/file-api";
import { FileData } from "src/api/file/file-types";
import { useApiOperation } from "src/api/hooks";
import { MediaFile } from "src/api/public-types";
import { BaseButton, BaseInput, BaseModal } from "src/components";
import { DeskItem, Modal } from "src/pages/building/building-types";
// import { useLoadingBarContext, useToastContext } from "src/pages/hooks";
import DeskItemComponent from "./DeskItemComponent";
import { resizedImageUrl } from "src/utils";

type Props = {
  buildingId: number | undefined;
  deskGroup: DeskGroupModel | undefined;
};

type FormData = {
  mediaFile?: MediaFile | null; // 좌석배치도 이미지
  deskChartDescription?: string; // 좌석배치도 설명
};

const s3Url = process.env.REACT_APP_S3_BASEURL;

/* 
  건물 > 등록 or 수정 > 좌석 배치도 > 좌석 그룹 활성화시 노출되는 하단 컴포넌트
 */
const DeskLayout = ({ buildingId, deskGroup }: Props) => {
  // 로딩바
  //
  // 토스트
  // const { openToast } = useToast();

  const fileRef = useRef<HTMLInputElement>(null);
  const previewContainerRef = useRef<HTMLDivElement>(null);

  // 좌석 그룹별 좌석 목록 조회 api
  const { executeAsync: getDesks } = useApiOperation(getDesksAsync);

  // 파일 업로드 api hook
  const { executeAsync: postImageFile } = useApiOperation(uploadFilesAsync, {
    noHandleError: true,
  });

  // 좌석배치도 등록/수정 api
  const { executeAsync: postDeskChart } = useApiOperation(postDeskChartAsync);

  // 좌석 목록
  const [deskItems, setDeskItems] = useState<DeskItem[]>([]);

  // 배치도에 추가한 좌석 목록
  const [addedDeskItems, setAddedDeskItems] = useState<DeskItem[]>([]);

  // 취소, 확인 버튼이 있는 confirm 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });

  // 확인버튼만 있는 alert 모달
  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
  });

  // useForm defaultValues
  const defaultValues: FormData = useMemo(() => {
    return {
      mediaFile: null,
      deskChartDescription: "",
    };
  }, []);

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues,
  });

  // react hook form 에서 사용하는 validation rules, error message 정의
  useEffect(() => {
    register("mediaFile", {
      validate: {
        required: (mediaFile?: MediaFile | null) => {
          const requiredMessage = "배경 이미지는 필수입력 항목입니다.";
          return !!mediaFile || requiredMessage;
        },
      },
    });
  }, [register]);

  // form 리셋
  const resetForm = useCallback(() => {
    setDeskItems([]);
    setAddedDeskItems([]);
    clearErrors();
    setValue("mediaFile", null);
    setValue("deskChartDescription", "");
  }, [clearErrors, setValue]);

  // 좌석 그룹별 좌석 목록 조회
  const fetchDesks = useCallback(
    async (buildingId: number, deskGroupId: number) => {
      //
      const { data } = await getDesks({ buildingId, deskGroupId });
      const deskGroup = data?.data?.content || null;

      // 좌석 배치도 설명
      setValue("deskChartDescription", deskGroup?.deskChartDescription || "");

      // 좌석 배치도 이미지
      if ((deskGroup.mediaList || []).length > 0) {
        setValue("mediaFile", deskGroup.mediaList[0]);
      }
      // 좌석 컴포넌트
      const deskList = deskGroup?.deskList || [];
      const deskItems: DeskItem[] = deskList
        .map((desk: CommonFacilityModel) => {
          const deskItem: DeskItem = {
            id: String(desk?.id || ""),
            name: desk?.facilityName || "",
            left: Number(desk?.centerX || 0),
            top: Number(desk?.centerY || 0),
          };
          return deskItem;
        })
        .sort((a: DeskItem, b: DeskItem) => Number(b.id || 0) - Number(a.id || 0));
      setDeskItems(deskItems);

      // 좌석 배치되어있는 컴포넌트
      const addedDeskItems = deskItems.filter((deskItem: DeskItem) => deskItem.left > 0 && deskItem.top > 0);
      setAddedDeskItems(addedDeskItems);

      //
    },
    [getDesks, setValue],
  );

  useEffect(() => {
    if (buildingId && deskGroup && deskGroup.id) {
      resetForm();
      fetchDesks(buildingId, Number(deskGroup.id));
    }
  }, [buildingId, resetForm, deskGroup, fetchDesks]);

  // 배경 이미지 파일 변경 이벤트
  const onChangeFile = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();

      //

      // input file 객체
      const files = event.target.files;

      if (files) {
        const file = files[0];

        const formData = new FormData();
        formData.append("file", file);

        // 파일 업로드
        const res = await postImageFile({
          file: formData,
          type: "public",
        });
        if (res.status >= 200 && res.status <= 299 && res?.data?.data?.media) {
          const fileData: FileData = res.data.data.media;
          const mediaFile: MediaFile = convertMediaFile(fileData);
          setValue("mediaFile", mediaFile, { shouldValidate: true });
        } else {
          throw Error("파일 업로드를 실패했습니다.");
        }
      }

      //

      // 파일 객체 초기화
      event.target.files = null;
    },
    [postImageFile, setValue],
  );

  // 업로드한 FileData -> MediaFile 로 변환
  const convertMediaFile = (fileData: FileData) => {
    const mediaFile: MediaFile = {
      description: "",
      fileStorageType: fileData.fileStorageType,
      key: fileData.key,
      filename: fileData.filename,
      contentType: fileData.contentType,
      fileSize: fileData.fileSize,
    };
    return mediaFile;
  };

  // 좌석배치도 저장
  const saveDeskChart = useCallback(
    async (formData: FormData) => {
      if (!buildingId) throw Error("buildingId is not found");
      if (!deskGroup || !deskGroup?.id) throw Error("deskGroup is not found");
      if (!formData?.mediaFile) throw Error("mediaFile is not found");

      // 좌석배치도에 올라와있는 좌석 컴포넌트 목록
      const addedDeskList: DeskItemModel[] = addedDeskItems.map((deskItem: DeskItem) => {
        const deskItemModel: DeskItemModel = {
          id: String(deskItem.id), // 좌석 ID
          centerX: String(deskItem.left),
          centerY: String(deskItem.top),
        };
        return deskItemModel;
      });

      // 좌석배치도에서 삭제된 좌석 컴포넌트 목록
      const deletedDeskList: DeskItemModel[] = deskItems
        .filter((current: DeskItem) => !addedDeskItems.map((added: DeskItem) => added.id).includes(current.id))
        .map((deskItem: DeskItem) => {
          const deskItemModel: DeskItemModel = {
            id: String(deskItem.id),
            centerX: "0", // 삭제인 경우 0으로
            centerY: "0", // 삭제인 경우 0으로
          };
          return deskItemModel;
        });

      // 새로 등록할 좌석 목록
      const newDeskList = [...addedDeskList, ...deletedDeskList];

      const deskChart: PostDeskChartRequestBody = {
        id: deskGroup.id,
        deskChartDescription: formData.deskChartDescription,
        deskList: newDeskList,
        mediaList: [{ ...formData.mediaFile, ...{ serviceId: deskGroup.id } }],
      };
      const { data: postResultData } = await postDeskChart({
        buildingId,
        deskChart,
      });
      if (postResultData?.data?.content) {
        // openToast("정상적으로 저장 되었습니다.");

        // form reset
        resetForm();

        // 좌석 그룹별 좌석 목록 다시 조회
        await fetchDesks(buildingId, Number(deskGroup.id));
      }
    },
    [buildingId, deskGroup, deskItems, addedDeskItems, postDeskChart, resetForm, fetchDesks],
  );

  // Modal 확인 버튼 클릭
  const clickModalConfirm = useCallback(async () => {
    setConfirmModal({ isOpen: false });
    if (!confirmModal?.payload) throw Error("confirmModal.payload is not found");
    if (confirmModal.type === "DESK_LAYOUT_SAVE") {
      // 좌석배치도 저장
      saveDeskChart(confirmModal.payload);
    } else if (confirmModal.type === "DESK_ITEM_DELETE") {
      // 좌석 컴포넌트 삭제
      if (!confirmModal.payload.deskItemId) throw Error("confirmModal.payload.deskItemId is not found");
      const anothers = addedDeskItems.filter((v: DeskItem) => v.id !== confirmModal.payload.deskItemId);
      setAddedDeskItems(anothers);
    }
  }, [confirmModal, addedDeskItems, saveDeskChart]);

  // validation 통과 후 submit 될때 실행
  const onSubmit = useCallback(
    (data: FormData, e?: any) => {
      e.preventDefault();

      if (addedDeskItems.length === 0) {
        setAlertModal({ isOpen: true, message: "컴포넌트를 배치해주세요." });
        return;
      }

      setConfirmModal({
        message: "저장하시겠습니까?",
        isOpen: true,
        type: "DESK_LAYOUT_SAVE",
        payload: data,
      });

      return false;
    },
    [addedDeskItems],
  );

  // validation 통과하지 못하고 error 발생시 실행
  const onError = (errors: any, e?: any) => {
    // console.log("onError errors", errors);
    e.preventDefault();
    return false;
  };

  // X 좌표, Y 좌표 input value 변경
  const onChangeCoordinate = useCallback(
    (deskItemId: string, target: string, value?: number) => {
      const targetItem = addedDeskItems.find((d: DeskItem) => d.id === deskItemId);
      if (targetItem) {
        const anothers = addedDeskItems.filter((v: DeskItem) => v.id !== targetItem.id);
        const newDeskItem = { ...targetItem, ...{ [target]: Number(value || 0) } };
        const sorted = [...anothers, newDeskItem].sort((a: DeskItem, b: DeskItem) => Number(b.id || 0) - Number(a.id || 0));
        setAddedDeskItems(sorted);
      }
    },
    [addedDeskItems],
  );

  if (!deskGroup) return null;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="required">배경 이미지</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400 flex-center-start flex-row">
              {getValues("mediaFile")?.filename && <p className="mr16">{getValues("mediaFile")?.filename}</p>}
              <input type="file" name="img-loader-input" ref={fileRef} className="d-none" accept=".png, .jpg, .svg" onChange={onChangeFile} />
              <BaseButton title="파일찾기" className="color-white" onClick={() => fileRef.current?.click()} />
            </div>
            {errors.mediaFile && <p className="validation-text">필수선택 항목입니다</p>}
          </div>
        </section>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="">설명</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="minmax400 flex-center-start flex-row">
              <Controller
                control={control}
                name="deskChartDescription"
                render={({ field: { value, name, onChange } }) => (
                  <BaseInput
                    type="text"
                    value={value}
                    name={name}
                    onChange={onChange}
                    placeholder="상세 위치, 기자재 등 설명을 입력해주세요."
                    maxLength={1000}
                  />
                )}
              ></Controller>
            </div>
          </div>
        </section>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className="required">이미지</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="flex-center-start flex-row">
              {getValues("mediaFile")?.filename ? (
                <div className="image-preview-area responsive" ref={previewContainerRef}>
                  {addedDeskItems.map((deskItem: DeskItem) => (
                    <DeskItemComponent
                      key={deskItem.id}
                      previewContainerRef={previewContainerRef}
                      item={deskItem}
                      setDeskItem={(newDeskItem: DeskItem) => {
                        const anothers = [...addedDeskItems].filter((currentDeskItem: DeskItem) => currentDeskItem.id !== newDeskItem.id);
                        setAddedDeskItems([...anothers, newDeskItem]);
                      }}
                    />
                  ))}
                  <img
                    onError={(e) => {
                      e.currentTarget.src = s3Url + (getValues("mediaFile")?.key || "");
                    }}
                    src={resizedImageUrl(s3Url + (getValues("mediaFile")?.key || ""))}
                    alt="컴포넌트"
                    draggable={false}
                  />
                </div>
              ) : (
                <p className="text-gray600">배경이미지를 선택해주세요</p>
              )}
            </div>
          </div>
        </section>
        <section className="contents-container__grid contents-container__1200">
          <div className="contents-container__grid-index">
            <p>컴포넌트</p>
          </div>
          <div className="contents-container__grid-contents">
            <div>
              <table className="inner-table text-center">
                <thead>
                  <tr>
                    <th>
                      <span>상태</span>
                    </th>
                    <th>
                      <span>id</span>
                    </th>
                    <th>
                      <span>좌석명</span>
                    </th>
                    <th>
                      <span>X 좌표 / Y 좌표</span>
                    </th>
                    <th>
                      <span>추가/삭제</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {deskItems.length === 0 && (
                    <tr>
                      <td colSpan={4}>
                        <span>데이터가 없습니다.</span>
                      </td>
                    </tr>
                  )}
                  {deskItems.map((deskItem: DeskItem) => (
                    <tr key={deskItem.id}>
                      <td width="80">
                        <div className="flex-center-center">
                          {!!addedDeskItems.find((d: DeskItem) => d.id === deskItem.id) ? (
                            <div className="color-chip green">
                              <span>추가됨</span>
                            </div>
                          ) : (
                            <div className="color-chip gray">
                              <span>미추가</span>
                            </div>
                          )}
                        </div>
                      </td>
                      <td width="160">
                        <div>
                          <p>{deskItem.id}</p>
                        </div>
                      </td>
                      <td width="auto">
                        <p>{deskItem.name}</p>
                      </td>
                      <td width="200">
                        <div className="flex-center-center">
                          <BaseInput
                            placeholder="X"
                            className="mr4"
                            type="number"
                            readonly={!addedDeskItems.find((d: DeskItem) => d.id === deskItem.id)}
                            value={Number(addedDeskItems.find((d: DeskItem) => d.id === deskItem.id)?.left || 0)}
                            onChange={(v?: number) => {
                              onChangeCoordinate(deskItem.id, "left", v);
                            }}
                            name="deskCenterX"
                          />
                          <BaseInput
                            placeholder="Y"
                            type="number"
                            readonly={!addedDeskItems.find((d: DeskItem) => d.id === deskItem.id)}
                            value={Number(addedDeskItems.find((d: DeskItem) => d.id === deskItem.id)?.top || 0)}
                            onChange={(v: number) => {
                              onChangeCoordinate(deskItem.id, "top", v);
                            }}
                            name="deskCenterY"
                          />
                        </div>
                      </td>
                      <td width="140">
                        <div className="flex-center-center">
                          <div className="minmax40 flex-center-center">
                            {!!addedDeskItems.find((d: DeskItem) => d.id === deskItem.id) ? (
                              // 삭제 버튼
                              <button
                                type="button"
                                className="base-erase-btn"
                                onClick={() => {
                                  setConfirmModal({
                                    message: "삭제하시겠습니까?",
                                    isOpen: true,
                                    type: "DESK_ITEM_DELETE",
                                    payload: { deskItemId: deskItem.id },
                                  });
                                }}
                              ></button>
                            ) : (
                              // 추가 버튼
                              <button
                                type="button"
                                className="base-add-btn"
                                onClick={() => {
                                  if (getValues("mediaFile")?.filename) {
                                    setAddedDeskItems([...addedDeskItems, deskItem]);
                                  } else {
                                    setAlertModal({
                                      isOpen: true,
                                      message: "배경 이미지를 선택해주세요.",
                                    });
                                  }
                                }}
                              ></button>
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-contents-end mt10">
              <BaseButton type="submit" title="저장" />
            </div>
          </div>
        </section>
      </form>

      {/* 취소, 확인 버튼이 있는 confirm 모달 */}
      <BaseModal
        isOpen={confirmModal.isOpen}
        btnLeftTitle="취소"
        btnRightTitle="확인"
        onClose={() => setConfirmModal({ isOpen: false })}
        onClick={() => clickModalConfirm()}
        title={confirmModal.message}
      ></BaseModal>

      {/* 확인버튼만 있는 alert 모달 */}
      <BaseModal isOpen={alertModal.isOpen} btnRightTitle="확인" onClick={() => setAlertModal({ isOpen: false })}>
        <p>{alertModal.message}</p>
      </BaseModal>
    </>
  );
};

export default DeskLayout;
