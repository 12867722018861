import { Order } from "../public-types";

/* 
  방문자 목록 조회 query params 1.24 이후
*/
export interface VisitorListQueryParams {
  page?: number;
  size?: number;
  sort?: {
    orders?: Array<Order>;
  };
  contractApplyNumber?: string; // 이용신청 번호
  visitStatus?: string; // 방문상태 (VISIT_USE : 사용, VISIT_PAUSE : 일시정지, VISIT_DELETE : 삭제, VISIT_CLOSE : 종료)
  searchType?: string; // 검색대상 (ALL : 전체VISITOR_NAME : 방문자 이름, VISITOR_MOBILE_NUMBER : 방문자 휴대폰번호, VISITOR_CAR_NUMBER : 방문자 차량번호, SPACE_PRODUCT_ID : 공간상품 id, SPACE_PRODUCT_NAME : 공간상품명)
  searchValue?: string; // 검색어
  visitPurpose?: string; // 방문목적 (TOUR_COUNSELING : 투어/상담FRIEND_VISIT : 지인방문PACKAGE_DELIVERY : 택배MAIL_DELIVERY : 우편ETC_DELIVERY: 배달)
  visitType?: string; // 방문타입 (INVITE_REGIST : 초대SELF_REGIST : 셀프등록)
  searchStartTime?: string; // 검색일(From) : 방문일시 (시작)
  searchEndTime?: string; // 검색일(To) : 방문일시 (종료)
  contractManageId?: string;
  invitationChannel?: string; // 초대경로
  approvalStatus?: string; // "APPROVAL_AUTO" | "APPROVAL_PENDING" | "APPROVAL_COMPLETE" | "APPROVAL_REJECT"; 승인상태 (APPROVAL_AUTO: 자동 ,APPROVED : 승인, REJECTED : 거절, PENDING : 대기)
  reservationId?: string;
}

// 방문자 등록 response
export interface PostVisitorResponseData {
  visitApplyNumber: string;
  contractVisitorList: Array<{ value: number }>;
}

// 방문자 수정 response
export interface PatchVisitorResponseData {
  visitApplyNumber: string;
  contractVisitorId: number;
}

// 목록페이지 방문자 목록 조회 1.24 이후
export interface ContractVisitorListModel {
  contractVisitorId?: number; // 방문자 id
  contractApplyNumber?: string; // 계약 신청 번호
  visitApplyNumber?: string; // 방문신청 번호
  contractManageId?: number; // 계약 관리 id
  visitorName?: string; // 방문자 이름
  visitorMobileNumber?: string; // 방문자 휴대폰번호
  visitorCarNumber?: string; // 방문자 차량번호
  visitStartTime?: string; // 방문일시 (시작)
  visitEndTime?: string; // 방문일시 (종료)
  visitStatus?: string; // 방문상태 (사용/일시정지/종료)
  inviteeMemberId?: string; // 초대자 ID (관리자)
  inviteeMemberNo?: string; // 초대자 회원번호 (회원)
  buildingId?: number; // 방문건물 ID
  meetingPlace?: string; // 만남장소
  reservationId?: number; // 예약 ID (공간상품 예약)
  isDeleted?: boolean; // 삭제여부
  createdDate?: string; // 생성일
  createdBy?: string; // 생성자
  modifiedDate?: string; // 수정일
  modifiedBy?: string; // 수정자
  visitPurpose?: string;
  visitType?: string;
  productName?: string;
  preDiscountId?: string; //주차 사전할인 ID (KPS)
  visitorEmail?: string;
  visitorEmailLang?: string; // (default)ko: 국문, en: 영문  (미입력시 ko)
  invitationType?: string; //fe 전용
  invitationChannel?: string; // 초대경로
  invitationDate?: string; // 초대일시
  visitorGuideMemo?: string; // 방문자 안내 메모
  approvalRequestMemo?: string; // 승인 요청 메모
  approvalStatus?: string; // 승인 상태
  approvalAuthMemo?: string; // 승인/반려 사유 메모
  approvalDate?: string; // 승인/반려 일시
  approverMemberId?: string; // 승인자 ID
  approverMemberNo?: string; // 승인자 회원번호
}

// 방문자 1.24 이후
export interface ContractVisitor {
  /** 방문자 id */
  contractVisitorId?: number;
  /** 방문자 그룹 ID */
  contractVisitorGroupId?: number;
  /** 방문자 이름 */
  visitorName?: string; // 홍길동
  /** 방문자 휴대폰번호 (E164 포맷) */
  visitorMobileNumber?: string; // +821022223333
  /** 방문자 이메일 */
  visitorEmail?: string;
  /** 방문자 이메일 언어 (default)ko: 국문, en: 영문  (미입력시 ko) */
  visitorEmailLang?: string;
  /** 방문자 차량번호 */
  visitorCarNumber?: string; // 17버1558
  /** 주차 사전할인 ID (KPS) */
  preDiscountId?: number;
  /** 삭제여부 */
  isDeleted?: boolean;
  /** 생성일 */
  createdDate?: string;
  /** 생성자 */
  createdBy?: string;
  /** 수정일 */
  modifiedDate?: string;
  /** 수정자 */
  modifiedBy?: string;
}

// 방문자 그룹 1.24 이후
export interface ContractVisitorGroup {
  /** 방문자 그룹 id */
  contractVisitorGroupId?: number;
  /** 계약 관리 id */
  contractManageId?: number;
  /** 방문신청 번호 */
  visitApplyNumber?: string;
  /** 방문일시 (시작) */
  visitStartTime?: string;
  /** 방문일시 (종료) */
  visitEndTime?: string;
  /** 방문건물 ID */
  buildingId?: number;
  /** 만남장소 */
  meetingPlace?: string;
  /** 워크시트 ID (워크오더) */
  workOrderSheetId?: number;
  /** 방문목적(TOUR_COUNSELING : 투어/상담, FRIEND_VISIT : 지인방문, PACKAGE_DELIVERY : 택배, MAIL_DELIVERY : 우편, ETC_DELIVERY: 배달) */
  visitPurpose?: string; // TOUR_COUNSELING : 투어/상담FRIEND_VISIT : 지인방문PACKAGE_DELIVERY : 택배MAIL_DELIVERY : 우편ETC_DELIVERY: 배달
  /** 방문타입(INVITE_REGIST : 초대, SELF_REGIST : 셀프등록, S2_REGIST : 공간예약 등록) */
  visitType?: string; // INVITE_REGIST : 초대SELF_REGIST : 셀프등록S2_REGIST : 공간예약 등록
  /** 방문상태 (VISIT_PENDING : 대기, VISIT_USE : 사용, VISIT_DELETE : 삭제, VISIT_CLOSE : 종료) */
  visitStatus?: string; // VISIT_PENDING : 대기VISIT_USE : 사용VISIT_DELETE : 삭제VISIT_CLOSE : 종료
  /** 초대자 ID (관리자 : Ctrl.room에서 관리자에 의한 초대일 경우 등록되는 유저 이메일 정보) */
  inviteeMemberId?: string;
  /** 초대자 회원번호 (이용자 : 이용자 회원번호 또는 Court에서 회원에 의한 초대일 경우 등록되는 로그인 회원번호) */
  inviteeMemberNo?: string;
  /** 초대경로 (TaapSpace, Taap, Ctrl.room, SelfVisit) */
  invitationChannel?: string; // TaapSpace : 공간예약(S2) 상품 결재한 경우Taap : Taap 이용자가 방문자 초대한 경우Ctrl.room : Ctrl.room 파트너회원/이용자 자격으로 방문자 초대한 경우SelfVisit : (키오스크) 셀프 방문등록한 경우
  /** 초대일시 */
  invitationDate?: string;
  /** 방문자 안내 메모(방문자에게 남길 메시지) */
  visitorGuideMemo?: string;
  /** 방문초대 승인요청 메모 */
  approvalRequestMemo?: string;
  /** 방문초대 승인 상태(APPROVAL_AUTO : 자동승인, APPROVAL_PENDING : 승인대기, APPROVAL_COMPLETE : 승인완료, APPROVAL_REJECT : 승인반려) */
  approvalStatus?: string; // APPROVAL_AUTO : 자동승인APPROVAL_PENDING : 승인대기APPROVAL_COMPLETE : 승인완료APPROVAL_REJECT : 승인반려
  /** 방문초대 승인 메모 (승인/반려 사유) */
  approvalAuthMemo?: string;
  /** 방문초대 승인일시 (승인/반려) */
  approvalDate?: string;
  /** 방문초대 승인자 ID */
  approverMemberId?: string;
  /** 방문초대 승인자 회원번호 */
  approverMemberNo?: string;
  /** 예약 ID (공용공간) */
  reservationId?: number;
  /** 등록일자 */
  createdDate?: string;
  /** 등록자ID */
  createdBy?: string;
  /** 수정일자 */
  modifiedDate?: string;
  /** 수정자 */
  modifiedBy?: string;
  /** 방문자 목록 */
  contractVisitorList: Array<ContractVisitor>;
}

export type PatchContractVisitor = Required<
  Pick<ContractVisitorListModel, "contractVisitorId" | "visitorName" | "visitorMobileNumber" | "visitStartTime" | "visitEndTime">
> &
  Partial<Pick<ContractVisitorListModel, "visitorCarNumber" | "meetingPlace" | "reservationId" | "isDeleted">>;

export interface PatchContractVisitorsParams {
  visitApplyNumber: string;
  contractVisitorGroup?: Omit<ContractVisitorGroup, "contractVisitorList">;
  contractVisitorList?: ContractVisitor[];
}

export interface PostContractVisitor {
  visitorName: string; // 방문자 이름
  visitorMobileNumber: string; // 방문자 휴대폰번호 (E164 포맷)
  visitorEmail?: string; // 방문자 이메일
  visitorEmailLang?: string; // 방문자 이메일 언어 (ko: 한국어, en: 영어)
  visitorCarNumber?: string; // 방문자 차량번호
}

export interface PostVisitorParams {
  contractManageId: number;
  contractVisitorGroup: {
    contractManageId: number; // 계약 관리 ID
    visitStartTime: string; // 방문일시 (시작)
    visitEndTime: string; // 방문일시 (종료)
    buildingId?: number; // 방문건물 ID
    meetingPlace?: string; // 만남장소
    inviteeMemberId?: string; // 초대자 ID (관리자)
    inviteeMemberNo?: string; // 초대자 회원번호 (이용자)
    visitorGuideMemo?: string; // 방문자 안내 메모
    approvalRequestMemo?: string; // 방문초대 승인요청 메모
    contractVisitorList: PostContractVisitor[];
    reservationId?: number; // 예약 ID (공간상품 예약)
    accessGroupAddIdsList?: string[]; // 출입그룹 ID 목록 쉼표로 구분
  };
}

export interface DeleteVisitorGroupParams {
  visitApplyNumber: string;
}

export interface DeleteVisitorGroupResponse {
  contractManageId: string;
  contractVisitorId: number[];
}

export interface ExternalQrResponse {
  userId: number;
  cardSerialNumber: string;
  creationDate: string;
  expirationDate: string;
}

export interface VisitorQr {
  userId?: number; // 사용자ID
  cardSerialNumber?: string; // CSN
  creationDate?: string; // 카드 생성일자
  expirationDate?: string; // 카드 만료일자
}

export interface PreDiscountModal {
  preDiscountId: string;
  webDiscountType: string;
  carNumber: string;
  tenantName: string;
  description: string; // 메모: 방문신청 번호(visitApplyNumber) + 계약방문자 id(contractVisitorId)
  isFree: boolean;
  amount: number; // 무료할인 시간(분)
  isUsed: boolean;
  accountName: string; // 사용자 이름 (방문자 폰번호 - 마스킹처리 상태 eg. 010-xxxx-1234)
  datetimeExpired: string; // 차량 입차 예정일(사전할인 만료일)
}

export interface PreDiscountResponse {
  preDiscount: PreDiscountModal[];
}

export interface VisitorBuilding {
  isPrimary?: boolean;
  buildingId?: string;
  buildingName?: string;
  address?: string;
}

export interface VisitorFormData {
  invitationInfo: Omit<ContractVisitorGroup, "contractVisitorList" | "buildingId"> & {
    buildingId?: string;
    accessGroupAddIdsList?: Array<string>;
  };
  visitorInfoList: Array<ContractVisitor & { invitationType?: string }>;
}

export type PageType = "add" | "edit" | "detail";

export interface Ac2AccessCode {
  id?: number; // Access Code ID
  memberNo?: string; // 회원번호
  contractVisitorId?: number; // 방문자 id
  accessToken?: string; // 출입토큰
  accessTokenType?: string; // 출입토큰 타입
  createdBy?: string; // 생성자
  createdDate?: string; // 생성 일시
  lastModifiedBy?: string; // 수정자
  lastModifiedDate?: string; // 수정 일시
}

export const ApprovalStatusMap = {
  APPROVAL_AUTO: "자동승인",
  APPROVAL_PENDING: "승인대기",
  APPROVAL_COMPLETE: "승인완료",
  APPROVAL_REJECT: "반려",
} as const;

// 방문상태 options
export const VisitStatusMap = {
  VISIT_PENDING: "대기",
  VISIT_USE: "사용",
  VISIT_DELETE: "삭제",
  VISIT_CLOSE: "종료",
} as const;
