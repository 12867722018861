import _ from "lodash";
import moment from "moment";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Modal, Order, PageMeta } from "src/api/public-types";
import { CsCategoryTypeUnionType, QuestionListModel, QuestionQueryParams, QuestionSiteUnionType } from "src/api/question/question-type";
import { BaseButton, BaseInput, BaseInputWithSearch, BaseModal, BaseSelect, BaseTable, BaseTooltip } from "src/components";
import BaseMultiSelect from "src/components/BaseMultiSelect";
import RangeDatepicker from "src/components/RangeDatepicker";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import useNavigate from "src/hooks/usePartnerNavigate";
import SearchBuildingPopup from "src/pages/commonPopup/SearchBuildingPopup";
import { PagePath } from "src/pages/product/details";
import ExcelDownloadButton from "src/pages/visitor/list/ExcelDownloadButton";
import { useCsOptions } from "src/pages/voc/hooks/useCsOptions";
import { useModal } from "src/recoil/modalState/hook";
import { globalPartnerState } from "src/recoil/partners/atom";
import { downloadExcelV2, qsParse } from "src/utils";
import { csCategoryOptions, questionSearchTypes, siteOptions, statusOptions } from "../question-types";
import useCsHooks from "../useCsHooks";
import QuestionColmns from "./column/QuestionColmns";
import DateSelectModal from "./components/DateSelectModal";

const QuestionList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const { isAuthority } = usePartnerAuthority();

  const { setAbstractModalZ1 } = useModal();

  const partner = useRecoilValue(globalPartnerState);

  /** 파트너의 CS AI 기능 활성 여부  */
  const usePartnerCsAi = partner?.features?.filter((PaPartner) => PaPartner.name === "CTRLROOM_CS_AI")?.[0]?.active;

  const queryParams: QuestionQueryParams = useMemo(() => {
    const _queryParams: QuestionQueryParams = qsParse(location.search);
    // page, size, sort 없이 최초 진입했을때 default 값 바인딩
    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams?.size) {
      _queryParams.size = 20;
    }
    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [{ property: "id", direction: "DESC" }],
      };
    }
    if (!_queryParams?.csCategoryType) {
      _queryParams.csCategoryType = "";
    }

    if (!_queryParams?.csTypeId) {
      _queryParams.csTypeId = "";
    }

    if (_queryParams.statusList && typeof _queryParams.statusList === "string") {
      _queryParams.statusList = _queryParams.statusList;
    }

    if (_queryParams?.keywordList) {
      _queryParams.keywordList = decodeURIComponent(_queryParams.keywordList);
    }
    const dump = _.cloneDeep(dateRange);
    if (_queryParams?.startDate !== undefined && _queryParams?.startDate !== "") {
      dump[0] = moment(_queryParams?.startDate).toDate();
    } else {
      dump[0] = null;
    }
    if (_queryParams?.endDate !== undefined && _queryParams?.endDate !== "") {
      dump[1] = moment(_queryParams?.endDate).toDate();
    } else {
      dump[1] = null;
    }
    setDateRange(dump);
    return _queryParams;
  }, [location]);

  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const [isBuildingModalOpen, setIsBuildingModalOpen] = useState({ isOpen: false });

  const [params, setParams] = useState<QuestionQueryParams>({ ...queryParams });

  const [questions, setQuestions] = useState<Array<QuestionListModel>>([]);

  // voc 유형
  const { options } = useCsOptions(params.csCategoryType as CsCategoryTypeUnionType | undefined);

  const csTypeOptions = [
    {
      label: "전체",
      value: "",
    },
    ...options,
  ];

  const { fetchQuestionList, makeExcelData, getDurationTime } = useCsHooks();

  useEffect(() => {
    setParams({ ...queryParams });

    fetchQuestionList(queryParams).then((data) => {
      setQuestions(data?.csList ?? []);
      setPageMeta(data?.meta.pageMeta);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  // 날짜 검색
  const handleOnDateRangeChange = (dateRange: [Date | null, Date | null]) => {
    setDateRange(dateRange);
    let start = "";
    let end = "";

    if (dateRange[0] !== null) {
      start = moment(dateRange[0]).format("YYYY-MM-DD") + "T00:00:00.000+09:00";
      setParams({ ...params, ...{ startDate: start } });
    }
    if (dateRange[1] !== null) {
      end = moment(dateRange[1]).format("YYYY-MM-DD") + "T23:59:59.999+09:00";
      setParams({ ...params, ...{ startDate: start, endDate: end } });

      navigateWithQueryParams({ ...params, ...{ startDate: start, endDate: end } });
    }
  };

  const navigateWithQueryParams = useCallback(
    (passParams?: QuestionQueryParams, type?: "search" | "pagination") => {
      let data;
      if (type) {
        type === "search" ? (data = { ...params }) : (data = { ...queryParams });
      }

      const newQueryParams = { ...data, ...(passParams || {}) };
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });

      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location, params, queryParams],
  );

  // 엑셀 다운로드
  const onDownloadCSExcel = async () => {
    try {
      const newParams = { ...params };
      delete newParams.size;
      delete newParams.page;

      const downloadData = await makeExcelData(newParams);

      if (!downloadData) {
        throw Error;
      }

      const fileName = `Ctrl.room_CS_${moment().format("YYYY-MM-DD HH.mm")}`;

      await downloadExcelV2({
        data: downloadData,
        fileName,
        columns: QuestionColmns,
      });

      setAlertModal({ isOpen: true, message: "엑셀 다운로드가 완료되었습니다." });
    } catch (error) {
      setAlertModal({ isOpen: true, message: "엑셀 다운로드에 실패하였습니다." });
    }
  };

  return (
    <>
      <div className="page-product-list">
        <div className="contents-container__table">
          <div className="contents-container__search-wrap">
            <div className="left-area">
              <section>
                <div className="left-area__index">
                  <span>지정검색</span>
                </div>
                <div className="left-area__contents">
                  <div className="minmax140">
                    <BaseInput
                      type="text"
                      value={params?.buildingName || ""}
                      placeholder="건물명"
                      readonly={true}
                      onClick={(e: any) => {
                        e.preventDefault();
                        setIsBuildingModalOpen({ isOpen: true });
                      }}
                      onClearClick={() => {
                        delete params.buildingId;
                        delete params.buildingName;
                        navigateWithQueryParams({ ...params, page: 0 });
                      }}
                    />
                    {isBuildingModalOpen.isOpen && (
                      <SearchBuildingPopup
                        buildingName={""}
                        isOpen={true}
                        onClick={(building: any) => {
                          setParams({ ...params, buildingId: String(building.id), buildingName: building.buildingName });
                          navigateWithQueryParams({
                            ...params,
                            buildingId: String(building.id), //
                            buildingName: building.buildingName,
                            page: 0,
                          });
                          setIsBuildingModalOpen({ isOpen: false });
                        }}
                        onClose={() => setIsBuildingModalOpen({ isOpen: false })}
                      />
                    )}
                  </div>
                </div>
              </section>
              <section>
                <div className="left-area__index">
                  <span>조건검색</span>
                </div>
                <div className="left-area__contents">
                  <div className="minmax201 ">
                    <RangeDatepicker dateRange={dateRange} onChange={handleOnDateRangeChange} />
                  </div>
                  <div className="minmax120 mr8">
                    <BaseMultiSelect
                      stateOptions={statusOptions}
                      value={(params.statusList as any as string[]) ?? []}
                      setStateValue={(statusList: Array<{ value: string; label: string }>) => {
                        setParams({ ...params, page: 0, statusList: statusList.map((item) => item.value) as any as string });
                        navigateWithQueryParams({ ...params, page: 0, statusList: statusList.map((item) => item.value) as any as string });
                      }}
                      placeholder="상태"
                    />
                  </div>
                  <div className="minmax140 mr8">
                    <BaseSelect
                      placeholder="구분"
                      stateOptions={csCategoryOptions}
                      setStateValue={(csCategoryType: QuestionSiteUnionType) => {
                        setParams({ ...params, csCategoryType });
                        navigateWithQueryParams({ ...params, page: 0, csCategoryType });
                      }}
                      value={params.csCategoryType}
                    />
                  </div>

                  <div className="minmax140 mr8">
                    <BaseSelect
                      placeholder="유입경로"
                      stateOptions={siteOptions}
                      setStateValue={(siteList: QuestionSiteUnionType) => {
                        setParams({ ...params, siteList: siteList });
                        navigateWithQueryParams({ ...params, page: 0, siteList: siteList });
                      }}
                      value={params.siteList}
                    />
                  </div>

                  <div className="minmax120 mr8">
                    <BaseSelect
                      placeholder="유형"
                      stateOptions={csTypeOptions}
                      setStateValue={(csTypeOption: string) => {
                        setParams({ ...params, csTypeId: csTypeOption });
                        navigateWithQueryParams({ ...params, page: 0, csTypeId: csTypeOption });
                      }}
                      value={csTypeOptions?.find((data) => data.value === params.csTypeId)?.value ?? "-"}
                    />
                  </div>
                  <BaseInputWithSearch
                    type="text"
                    selectValue={params.searchType}
                    inputValue={params?.keywordList ? String(params?.keywordList) : ""}
                    stateOptions={questionSearchTypes}
                    setStateValue={(searchType: string) => {
                      setParams({ ...params, searchType });
                    }}
                    onChange={(keywordList: string) => {
                      setParams({ ...params, keywordList: keywordList });
                    }}
                    onClearClick={() => {
                      const editParams = { ...params };
                      delete editParams.keywordList;
                      setParams(editParams);
                      navigateWithQueryParams({ ...params, page: 0, keywordList: "" });
                    }}
                    onKeyUp={() => navigateWithQueryParams({ page: 0 }, "search")}
                    onSearchClick={() => navigateWithQueryParams({ page: 0 }, "search")}
                  />
                </div>
              </section>
            </div>
            <div className="right-area flex-row">
              {usePartnerCsAi && (
                <div className="flex-center-center gap4">
                  <BaseTooltip
                    touchIcon="QUESTION"
                    tooltip="고객 문의 속 숨겨진 인사이트를 확인하세요! AI가 분석한 개선 방안을 통해 문제를 미리 예측하고 대응할 수 있게 도와드려요."
                  />

                  <BaseButton
                    title={
                      (
                        <div className="flex-row align-center gp4">
                          <i className="ic-chart-dot" />
                          <span>AI 분석</span>
                        </div>
                      ) as any as string
                    }
                    className="color-blue size-medium"
                    onClick={() =>
                      setAbstractModalZ1({
                        isOpen: true,
                        size: "medium",
                        children: <DateSelectModal />,
                      })
                    }
                  />
                </div>
              )}
              {isAuthority("w") && <BaseButton className="ml10" title="+ CS 등록" onClick={() => navigate(PagePath.question.form)} />}
            </div>
          </div>
          <BaseTable
            data={questions}
            currentSize={Number(queryParams.size) || 20}
            sizeOption={(sizeValue) => {
              navigateWithQueryParams({ ...params, size: sizeValue, page: 0 });
            }}
            pageIndex={Number(params?.page || 0)}
            totalElements={pageMeta?.totalElements}
            totalPages={pageMeta?.totalPages || 0}
            goPage={(page: number) => {
              navigateWithQueryParams({ page }, "pagination");
            }}
            orders={params?.sort?.orders}
            setOrders={(orders?: Array<Order>) => {
              if (orders) {
                navigateWithQueryParams({ sort: { orders } }, "search");
              }
            }}
            disabledSortHeaders={[
              "buildingName",
              "contractName",
              "mbOrganizationName",
              "reporterName",
              "completedBy",
              "answerList",
              "siteSummary",
              "expense",
              "durationTime",
              "createDate",
              "completedDate",
              "modifiedDate",
              "floorNum",
            ]}
            columns={QuestionColmns}
            children={
              <ExcelDownloadButton
                onClick={() => {
                  onDownloadCSExcel();
                }}
              >
                엑셀받기
              </ExcelDownloadButton>
            }
          />
        </div>
        {alertModal.isOpen && (
          <BaseModal isOpen={true} btnRightTitle="확인" onClick={() => setAlertModal({ isOpen: false })} title={alertModal.message}></BaseModal>
        )}
      </div>
    </>
  );
};

export default QuestionList;
