import { useApiOperation } from "src/api/hooks";

import GoToListButton from "src/components/GoToListButton";

import { useQuery } from "@tanstack/react-query";
import { isArray } from "lodash";
import moment from "moment";
import { Row } from "react-table";
import { getHistoryListAsync } from "src/api/history/history-api";
import { HistoryUpdate, HistoryUpdateListParams } from "src/api/history/history-types";
import { Order } from "src/api/public-types";
import { BaseButton, BaseTable, BaseTooltip } from "src/components";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";
import { useQueryParams } from "src/hooks/useQueryParams";
import { useModal } from "src/recoil/modalState/hook";
import { ViewYmdFormat } from "src/utils";
import HistoryDetailModal from "./HistoryDetailModal";
import { KeyNamesMap, KeyNamesMapKey } from "./libs";

export const HistoryDetailColumns = [
  {
    Header: "변경일시",
    accessor: "revisionTime",
    width: 220,
    Cell: ({ row }: { row: Row<HistoryUpdate> }) => {
      return <div>{row?.original?.revisionTime ? moment(row?.original?.revisionTime).format(ViewYmdFormat.YYYY_MM_DD_HH_MM) : "-"}</div>;
    },
  },

  {
    Header: "카테고리",
    accessor: "category",
    width: 120,
    Cell: ({ row }: { row: Row<HistoryUpdate> }) => {
      const category = row?.original?.category;

      return <div>{category}</div>;
    },
  },
  {
    Header: "대상경로",
    accessor: "path",
    width: 350,
    Cell: ({ row }: { row: Row<HistoryUpdate> }) => {
      const messages = row?.original?.message?.data?.data ?? [];

      if (!isArray(messages) && row?.original?.message?.type?.includes("C")) {
        ///AL202-C01
        //초기 생성시에 상세 데이터 보여주지 않음
        return <div className="flex-start w-100">-</div>;
      }

      return (
        <div className="flex-start w-100">
          <BaseTooltip
            contents={
              messages.length > 0
                ? (messages?.map((item, index) => {
                    const matchedPathName = item.path
                      .split("/")
                      .map((pathKey: string) => {
                        if (!isNaN(Number(pathKey))) return pathKey;
                        const matchedKeyName =
                          KeyNamesMap[row?.original.category as KeyNamesMapKey]?.[pathKey as keyof (typeof KeyNamesMap)[KeyNamesMapKey]];
                        return matchedKeyName || pathKey;
                      })
                      .join("/");

                    return <div key={index}>{"•" + matchedPathName || "-"}</div>;
                  }) as any as string)
                : "-"
            }
          ></BaseTooltip>
        </div>
      );
    },
  },
  {
    Header: "메시지",
    accessor: "message",
    width: 350,
    Cell: ({ row }: { row: Row<HistoryUpdate> }) => {
      const messageData = row?.original?.message;
      const supplyTypeMap = {
        RENTAL: "임대료",
        MNT: "관리비",
      };

      const makeMessageText = () => {
        if (messageData?.data?.error && messageData?.type === "AL999-E01") {
          return `[Error] 요청 할 수 있는 로그 데이터 사이즈는 ${messageData?.data?.error?.limitMessageDataBytes} Bytes 입니다(요청: ${messageData?.data?.error?.originalCategory}, ${messageData?.data?.error?.originalMessageType}, ${messageData?.data?.error?.originalMessageDataBytes} Bytes)`;
        }

        if (messageData?.type === "AL141-C001") {
          return `${supplyTypeMap[messageData?.data?.supplyType as keyof typeof supplyTypeMap] || ""} 스케줄정보(providerId:${
            messageData?.data?.providerId ?? "-"
          })가 등록되었습니다.`;
        }

        if (messageData?.type === "AL141-U001") {
          return `${supplyTypeMap[messageData?.data?.supplyType as keyof typeof supplyTypeMap] || ""} 스케줄정보(providerId:${
            messageData?.data?.providerId ?? "-"
          })가 수정되었습니다.`;
        }

        if (messageData?.type === "AL101-C001") {
          return `정산 정보가 등록되었습니다.`;
        }

        if (messageData?.type === "AL101-U001") {
          return `정산 정보가 수정되었습니다.`;
        }

        if (messageData?.type === "AL131-C001") {
          return `계약 정보가 등록되었습니다.`;
        }

        if (messageData?.type === "AL131-U001") {
          return `계약 정보가 수정되었습니다.`;
        }

        if (messageData?.type === "AL201-C01") {
          return `건물(${messageData.data.id})이 등록되었습니다.`;
        }

        if (messageData?.type === "AL201-U01") {
          return `건물(${messageData.data.id})이 변경되었습니다.`;
        }

        if (messageData?.type === "AL201-D01") {
          return `건물(${messageData.data.id})이 삭제되었습니다.`;
        }

        if (messageData?.type === "AL202-C01") {
          return `상품(${messageData.data.id})이 등록되었습니다.`;
        }

        if (messageData?.type === "AL202-U01") {
          return `상품(${messageData.data.id})이 변경되었습니다.`;
        }

        if (messageData?.type === "AL202-D01") {
          return `상품(${messageData.data.id})이 삭제되었습니다.`;
        }

        if (!isArray(messageData?.data?.data) && messageData?.type?.includes("C")) {
          ///AL202-C01
          //초기 생성시에 상세 데이터 보여주지 않음
          return "항목이 성공적으로 생성되었습니다";
        }

        return "-";
      };

      return (
        <div className="flex-start w-100">
          <BaseTooltip contents={makeMessageText()}></BaseTooltip>
        </div>
      );
    },
  },
  {
    Header: "변경내용",
    accessor: "setting",
    width: 120,
    Cell: ({ row }: { row: Row<HistoryUpdate> }) => {
      const { setAbstractModalZ1 } = useModal();

      if (row?.original?.message?.type.includes("C")) {
        //초기 생성시에 상세 데이터 보여주지 않음
        return "-";
      }

      return (
        <div>
          <BaseButton
            title="상세보기"
            className="color-white"
            onClick={() =>
              setAbstractModalZ1({
                isOpen: true,
                size: "xlarge",
                children: <HistoryDetailModal historyUpdate={row.original}></HistoryDetailModal>,
              })
            }
          ></BaseButton>
        </div>
      );
    },
  },
  {
    Header: "사용자타입",
    accessor: "userType",
    width: 120,
    Cell: ({ row }: { row: Row<HistoryUpdate> }) => {
      return <BaseTooltip contents={row?.original?.user?.type ?? "-"}></BaseTooltip>;
    },
  },
  {
    Header: "변경자",
    accessor: "displayName",
    width: 120,
    Cell: ({ row }: { row: Row<HistoryUpdate> }) => {
      return <BaseTooltip contents={row?.original?.user?.displayName ?? "-"}></BaseTooltip>;
    },
  },
];

interface IProps {
  identifier: HistoryUpdateListParams["identifier"];
  // productId: string;
  // buildingId: string;
}

/* 
   상세/수정 > 변경내역
 */
const HistoryDetail = ({ identifier }: IProps) => {
  // 공간상품 상세 삭제 api
  const { executeAsync: getHistoryList } = useApiOperation(getHistoryListAsync);

  const { queryParams, queryParamsNavigate } = useQueryParams<HistoryUpdateListParams>({
    page: 0,
    size: 10,
    sort: {
      orders: [{ property: "revisionTime", direction: "DESC" }], // 초기 정렬 설정
    },
    identifier: identifier,
  });

  const { data: historyList } = useQuery({
    queryKey: ["product_historyList", queryParams],
    enabled: !!queryParams,
    queryFn: () => getHistoryList(queryParams),
  });

  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <div className="contents-container__wrap-contents">
            <BaseSectionTitle title={"변경내역"} />
            {historyList?.data?.data?.content.filter((data) => data.message.type) && (
              <BaseTable
                className="px0"
                data={historyList?.data?.data?.content.filter((data) => data.message.type) ?? []}
                columns={HistoryDetailColumns}
                currentSize={historyList.data.meta.pageMeta?.pageRequest.size || 0}
                pageIndex={historyList.data.meta.pageMeta?.pageRequest.page || 0}
                totalElements={historyList?.data?.meta.pageMeta?.totalElements || 0}
                totalPages={historyList?.data?.meta.pageMeta?.totalPages || 0}
                goPage={(page: number) => {
                  queryParamsNavigate({ ...queryParams, page: page });
                  // 페이지 이동 핸들러
                }}
                orders={queryParams.sort?.orders}
                setOrders={(orders?: Array<Order>) => {
                  queryParamsNavigate({ ...queryParams, sort: { orders } });
                }}
                disabledSortHeaders={["displayName", "category", "path", "setting", "userType", "message"]}
                sizeOption={(sizeValue: number) => {
                  queryParamsNavigate({ ...queryParams, size: sizeValue, page: 0 });
                  // 페이지 사이즈 변경 핸들러
                }}
              />
            )}
          </div>
        </div>
      </div>
      {/* 버튼영역 */}
      <div className="contents-container__btn-wrap">
        <div className="left-area d-flex">
          <GoToListButton />
        </div>
      </div>
    </>
  );
};

export default HistoryDetail;
