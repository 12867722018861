import moment from "moment";
import { useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { useParams } from "react-router-dom";
import { PageType, VisitorFormData } from "src/api/visitor/visitor-types";
import { BaseButton, BaseDatePicker, BaseInput, BaseSelect } from "src/components";
import usePatchVisitorGroup from "../hooks/usePatchVisitorGroup";
import useVisitor from "../hooks/useVisitor";
import VisitStatusTag from "../list/columns/VisitStatusTag";
import { checkTodayTimer, getFormErrorText } from "../utils";
import { ViewYmdFormat, YmdFormat } from "src/utils";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";

const VisitInfo = ({ useFormReturn, pageType: defaultPageType }: { pageType: PageType; useFormReturn: UseFormReturn<VisitorFormData> }) => {
  const { watch, control, getValues, setValue } = useFormReturn;

  const { contractApplyNumber, visitApplyNumber } = useParams();

  const { isAuthority } = usePartnerAuthority();
  const { buildingList, visitorGroup } = useVisitor({ contractApplyNumber: contractApplyNumber, visitApplyNumber: visitApplyNumber });

  const [pageType, setPageType] = useState(defaultPageType);

  const { mutate: patchVisitorGroup } = usePatchVisitorGroup();

  const patchVisitorGroupHandler = () => {
    patchVisitorGroup({
      visitApplyNumber: visitApplyNumber!,
      contractVisitorGroup: {
        meetingPlace: getValues("invitationInfo.meetingPlace"),
        visitorGuideMemo: getValues("invitationInfo.visitorGuideMemo"),
      },
    });

    setPageType(defaultPageType);
  };

  return (
    <article className="contents-container__wrap-article">
      <BaseSectionTitle
        title={"방문 정보"}
        noHashLink={pageType === "add"}
        rightArea={
          isAuthority("w") && (
            <>
              {/* 종료,삭제일때 수정불가 */}
              {visitorGroup?.visitStatus !== "VISIT_CLOSE" && visitorGroup?.visitStatus !== "VISIT_DELETE" && (
                <>
                  {pageType === "detail" && <BaseButton className="size-small" title="수정" onClick={() => setPageType("edit")} />}
                  {pageType === "edit" && (
                    <div className="flex-start-start gap10">
                      <BaseButton className="size-small color-white" title="취소" onClick={() => setPageType(defaultPageType)} />
                      <BaseButton className="size-small" title="저장" onClick={patchVisitorGroupHandler} />
                    </div>
                  )}
                </>
              )}
            </>
          )
        }
      ></BaseSectionTitle>

      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>방문상태</p>
        </div>
        <div className="contents-container__grid-contents">
          <p>
            {pageType === "add" && "-"}
            {pageType === "edit" && <VisitStatusTag value={watch("invitationInfo.visitStatus")!} />}
            {pageType === "detail" && <VisitStatusTag value={watch("invitationInfo.visitStatus")!} />}
          </p>
        </div>
      </section>

      <div className="flex-start">
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className={pageType !== "detail" ? "required" : ""}>건물명</p>
          </div>
          <div className="contents-container__grid-contents">
            {pageType === "detail" ? (
              <p>
                {buildingList.find((building) => Number(building.buildingId) === Number(watch("invitationInfo.buildingId")))?.buildingName ?? "-"}
              </p>
            ) : (
              <Controller
                name="invitationInfo.buildingId"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <BaseSelect
                    className="minmax200"
                    isDisabled={pageType === "edit"}
                    value={String(field.value)}
                    stateOptions={buildingList.map((building) => ({ label: building.buildingName, value: String(building.buildingId) }))}
                    setStateValue={(value: string) => {
                      field.onChange(value);
                    }}
                    errorText={getFormErrorText(fieldState.error?.type!)}
                  />
                )}
              />
            )}
          </div>
        </section>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>방문장소</p>
          </div>
          <div className="contents-container__grid-contents">
            <p>{buildingList.find((building) => Number(building.buildingId) === Number(watch("invitationInfo.buildingId")))?.address ?? "-"}</p>
          </div>
        </section>
      </div>

      <div className="flex-start">
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className={pageType !== "detail" ? "required" : ""}>방문 시작일시</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="flex-row flex-center-start">
              {pageType === "detail" ? (
                <p>{watch("invitationInfo.visitStartTime") ? moment(watch("invitationInfo.visitStartTime")).format(ViewYmdFormat.FULL) : "-"}</p>
              ) : (
                <>
                  <div className="minmax140 mr10">
                    <Controller
                      name="invitationInfo.visitStartTime"
                      control={control}
                      rules={{ required: true }}
                      render={({ field, fieldState }) => (
                        <BaseDatePicker
                          disabled={pageType === "edit"}
                          selectedDate={field.value ? moment(field.value).toDate() : undefined}
                          setDate={(value: Date) => {
                            field.onChange(checkTodayTimer(value, "floor"));
                            setValue("invitationInfo.visitEndTime", "");
                          }}
                          errorText={getFormErrorText(fieldState.error?.type!)}
                          minDate={new Date()}
                        />
                      )}
                    />
                  </div>
                  <div className="minmax140">
                    <Controller
                      name="invitationInfo.visitStartTime"
                      control={control}
                      rules={{ required: true }}
                      render={({ field, fieldState }) => (
                        <BaseDatePicker
                          disabled={pageType === "edit"}
                          type="time"
                          selectedDate={field.value ? moment(field.value).toDate() : undefined}
                          setDate={(value: Date) => {
                            const minTime = moment().subtract(10, "minute").toDate(); // 최소 시간

                            if (moment(value).minute() % 10 !== 0) {
                              //10 단위 입력만 가능
                              return;
                            }

                            if (moment(field.value).isSame(moment(), "day") && value <= minTime) {
                              //시간 간격이 10분 단위가 아니면 변경하지 않음
                              //오늘이라면 최소 시간보다 작으면 변경하지 않음
                              return;
                            }
                            field.onChange(value);
                            setValue("invitationInfo.visitEndTime", "");
                          }}
                          timeIntervals={10}
                          errorText={getFormErrorText(fieldState.error?.type!)}
                          //오늘이라면 최소시간 설정
                          minTime={moment(field.value).isSame(moment(), "day") ? moment().subtract(10, "minute").toDate() : undefined}
                        />
                      )}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </section>
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className={pageType !== "detail" ? "required" : ""}>방문 종료일시</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="flex-row flex-center-start">
              {pageType === "detail" ? (
                <p>{watch("invitationInfo.visitEndTime") ? moment(watch("invitationInfo.visitEndTime")).format(ViewYmdFormat.FULL) : "-"}</p>
              ) : (
                <>
                  <div className="minmax140 mr10">
                    <Controller
                      name="invitationInfo.visitEndTime"
                      control={control}
                      rules={{ required: true }}
                      render={({ field, fieldState }) => (
                        <BaseDatePicker
                          disabled={pageType === "edit"}
                          selectedDate={field.value ? moment(field.value).toDate() : undefined}
                          setDate={(value: Date) => {
                            field.onChange(checkTodayTimer(value, "ceil"));
                          }}
                          errorText={getFormErrorText(fieldState.error?.type!)}
                          minDate={watch("invitationInfo.visitStartTime") ? moment(watch("invitationInfo.visitStartTime")).toDate() : new Date()}
                        />
                      )}
                    />
                  </div>
                  <div className="minmax140">
                    <Controller
                      name="invitationInfo.visitEndTime"
                      control={control}
                      rules={{ required: true }}
                      render={({ field, fieldState }) => (
                        <BaseDatePicker
                          disabled={pageType === "edit"}
                          type="time"
                          selectedDate={field.value ? moment(field.value).toDate() : undefined}
                          setDate={(value: Date) => {
                            if (moment(value).minute() % 10 !== 0) {
                              //10 단위 입력만 가능
                              return;
                            }

                            if (value < moment(getValues("invitationInfo.visitStartTime")).toDate()) {
                              // 시작일보다 전이면 변경하지 않음
                              console.log("first", getValues("invitationInfo.visitStartTime"));
                              //시간 간격이 10분 단위가 아니면 변경하지 않음
                              return;
                            }

                            field.onChange(value);
                          }}
                          errorText={getFormErrorText(fieldState.error?.type!)}
                          minTime={
                            moment(getValues("invitationInfo.visitStartTime")).isSame(moment(getValues("invitationInfo.visitEndTime")), "day")
                              ? moment(getValues("invitationInfo.visitStartTime")).toDate()
                              : undefined
                          }
                        />
                      )}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </section>
      </div>

      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>만남 장소</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="">
            {pageType === "detail" ? (
              <p>{watch("invitationInfo.meetingPlace") || "-"}</p>
            ) : (
              <Controller
                name="invitationInfo.meetingPlace"
                control={control}
                render={({ field }) => <BaseInput value={field.value} onChange={field.onChange} placeholder="만날 장소를 입력해 주세요" />}
              />
            )}
          </div>
        </div>
      </section>
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>방문자 메모</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="">
            {pageType === "detail" ? (
              <p>{watch("invitationInfo.visitorGuideMemo") || "-"}</p>
            ) : (
              <Controller
                name="invitationInfo.visitorGuideMemo"
                control={control}
                render={({ field }) => <BaseInput value={field.value} onChange={field.onChange} placeholder="방문자 메모를 입력해 주세요" />}
              />
            )}
          </div>
        </div>
      </section>
    </article>
  );
};

export default VisitInfo;
