import { ReactChild } from "react";
import { PreDiscountModal } from "src/api/visitor/visitor-types";

// modal 공통
export type Modal = {
  isOpen: boolean;
  children?: ReactChild;
  title?: string;
  message?: string;
  type?: "VISITOR_EDIT" | "VISITOR_ADD" | "INVALID_CONTRACT_APPLY_NUMBER";
  payload?: any;
};

// export interface VisitorInfo {
//   contractVisitorId: number;
//   name: string;
//   phoneNumber: string;
//   carNumber: string;
//   QRLink: string;
//   inviteTime: string;
//   createdBy: string;
//   isDeleted: boolean;
//   createdAt: string;
//   contractApplyNumber?: string;
//   contractManageId?: string;
//   visitApplyNumber?: string;
//   visitType?: string;
//   preDiscountId?: string; ////주차 사전할인 ID (KPS) "0" or "1234123412"
//   visitorEmail?: string;
//   visitorEmailLang?: string; // (default)ko: 국문, en: 영문  (미입력시 ko)
//   invitationType?: string; //fe 전용
// }

// export interface InvitationDetailItem {
//   label: string;
//   render: (invitationInfo: InvitationInfoType) => React.ReactNode;
// }

// export interface VisitorDetailItem {
//   key: string;
//   render: (visitorInfo: VisitorInfo, preDiscountList?: PreDiscountModal[]) => React.ReactNode;
// }

// export interface VisitorEditForm {
//   invitationInfo: Partial<InvitationInfoType>;
//   visitorInfoList: Partial<VisitorInfo>[];
// }

// export interface VisitorAddForm {
//   invitationInfo: Partial<InvitationInfoType>;
//   visitorInfoList: Partial<VisitorInfo>[];
// }

// export interface InvitationInfoType {
//   //초대정보
//   inviteeMemberId: string;
//   inviteeMemberNo: string;
//   //방문정보
//   buildingId: string;
//   visitStartTime: string;
//   visitEndTime: string;
//   meetingPlace: string; //만남장소
//   visitorGuideMemo: string; //방문자 메모
//   //승인정보
//   approvalRequestMemo: string; //승인요청 메모
//   //출입그룹
//   accessGroupAddIdsList: string[];
// }

// export type PageType = "add" | "edit" | "detail";

export const getApprovalStatusToText = (approvalStatus: string) => {
  switch (approvalStatus) {
    case "APPROVAL_AUTO":
      return "자동승인";
    case "APPROVAL_PENDING":
      return "승인대기";
    case "APPROVAL_COMPLETE":
      return "승인완료";
    case "APPROVAL_REJECT":
      return "승인반려";
    default:
      return "-";
  }
};
